import "./ReportsPage.css";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BaselineReport from "./BaselineReport";
import DecarbonizationReport from "./DecarbonizationReport";
import ReportsHeader from "./ReportsHeader";

function ReportsPage() {
    return <Reports />;
}


function Reports() {

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTabFromUrl = (searchParams.get("tab") as ReportTab) || "currentState";

    const isValidTabType = (tab: string): tab is ReportTab => ["currentState", "decarbonization", "projects"].includes(tab);

    const defaultTab: ReportTab = isValidTabType(selectedTabFromUrl) ? selectedTabFromUrl : "currentState";
    const [selectedTab, setSelectedTab] = useState<ReportTab>(defaultTab);
    
    const [currentActiveTab, setCurrentActiveTab] =
        useState<ReportTab>(defaultTab);

    useEffect( () => {
        setSearchParams({ tab: selectedTab });
    }, [selectedTab, selectedTabFromUrl])  

    useEffect(() => {
        setCurrentActiveTab(defaultTab);
    }, [defaultTab]);

    const tabClickHandler = (tabName: ReportTab) => {
        setSearchParams({ tab: tabName });
        setSelectedTab(tabName)
    }

    return (
        <div className="reports-page">
            <ReportsHeader
                currentActiveTab={currentActiveTab}
                setCurrentActiveTab={tabClickHandler}
            />
            <div className="report">
                {currentActiveTab === "currentState" && <BaselineReport />}
                {currentActiveTab === "decarbonization" && (
                    <DecarbonizationReport />
                )}
            </div>
        </div>
    );
}
export default ReportsPage;
