import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useSubmitEquipmentData } from "queries/Equipment/useSubmitEquipmentSurvey";
import { Header } from "components/atoms/Typography";
import { EquipmentSurveyInput as EquipmentSurveyAPI } from "gql/graphql";
import EquipmentSizeAndInstallationYearSurveyBody from "../SurveyElements/Equipment/EquipmentSizeAndInstallationYearSurveyBody";
import SurveyFooter from "../SurveyElements/Survey/SurveyFooter";
import {
    ActiveView,
    DEFAULT_CENTRAL_PLANT_HEATER,
    DEFAULT_TERMINAL_HEATER,
    FURNACES,
    NONE,
} from "../Survey.constants";
import {
    buildEquipmentInstallationYearAndSizeStruct,
    EquipmentAndInstallationYearSurveyItem,
    resetEquipmentInstallationYearAndSize,
    updatePageStackAndViewOnBackClick,
    useSaveButtonIsDisabled,
} from "../Survey.helpers";
import { useQueryOnStatusUpdate } from "../Survey.hooks";
import EquipmentSizeAndInstallationYearSurveyHeader from "../SurveyElements/Equipment/EquipmentSizeAndInstallationYearSurveyHeader";
import EquipmentSizeAndInstallationYearSurveyBodyItem from "../SurveyElements/Equipment/EquipmentSizeAndInstallationYearSurveyBodyItem";

interface EquipmentSizeAndInstallationYearSurveyProps {
    rooftopUnit: RooftopUnit;
    setRooftopUnit: React.Dispatch<React.SetStateAction<RooftopUnit>>;

    airHandlingEquipment: AirHandlingEquipment;
    setAirHandlingEqipment: React.Dispatch<
        React.SetStateAction<AirHandlingEquipment>
    >;

    centralPlantCooler: CentralPlantCooler;
    setCentralPlantCooler: React.Dispatch<
        React.SetStateAction<CentralPlantCooler>
    >;
    centralPlantHeater: CentralPlantHeater;
    setCentralPlantHeater: React.Dispatch<
        React.SetStateAction<CentralPlantHeater>
    >;
    centralPlantHeatPump: CentralPlantHeatPump;
    setCentralPlantHeatPump: React.Dispatch<
        React.SetStateAction<CentralPlantHeatPump>
    >;
    centralPlantGenericEquipment: CentralPlantGenericEquipment;
    setCentralPlantGenericEquipment: React.Dispatch<
        React.SetStateAction<CentralPlantGenericEquipment>
    >;

    terminalHeater: TerminalHeater;
    setTerminalHeater: React.Dispatch<React.SetStateAction<TerminalHeater>>;

    terminalCooler: TerminalCooler;
    setTerminalCooler: React.Dispatch<React.SetStateAction<TerminalCooler>>;

    terminalHeatPump: HeatPump;
    setTerminalHeatPump: React.Dispatch<React.SetStateAction<HeatPump>>;

    domesticHotWaterHeater: DomesticHotWaterHeater;
    setDomesticHotWaterHeater: React.Dispatch<
        React.SetStateAction<DomesticHotWaterHeater>
    >;

    otherEquipment: OtherEquipment;
    setOtherEquipment: React.Dispatch<React.SetStateAction<OtherEquipment>>;

    setActiveView: (view: ActiveView) => void;
    setShowSurveyView: React.Dispatch<React.SetStateAction<boolean>>;

    booleanQuestionResult: boolean | null;
    setBooleanQuestionResult: React.Dispatch<
        React.SetStateAction<boolean | null>
    >;

    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function EquipmentSizeAndInstallationYearSurvey({
    rooftopUnit,
    setRooftopUnit,
    airHandlingEquipment,
    setAirHandlingEqipment,
    centralPlantCooler,
    setCentralPlantCooler,
    centralPlantHeater,
    setCentralPlantHeater,
    centralPlantHeatPump,
    setCentralPlantHeatPump,
    centralPlantGenericEquipment,
    setCentralPlantGenericEquipment,
    terminalCooler,
    setTerminalCooler,
    terminalHeater,
    setTerminalHeater,
    terminalHeatPump,
    setTerminalHeatPump,
    domesticHotWaterHeater,
    setDomesticHotWaterHeater,
    otherEquipment,
    setOtherEquipment,
    setActiveView,
    setShowSurveyView,
    booleanQuestionResult,
    setBooleanQuestionResult,
    pageStack,
    setPageStack,
}: EquipmentSizeAndInstallationYearSurveyProps) {
    const interfaceStructure = useMemo(
        () =>
            buildEquipmentInstallationYearAndSizeStruct(
                rooftopUnit,
                airHandlingEquipment,
                centralPlantCooler,
                centralPlantHeater,
                centralPlantHeatPump,
                terminalCooler,
                terminalHeater,
                terminalHeatPump,
                domesticHotWaterHeater,
                otherEquipment
            ),
        [
            rooftopUnit,
            airHandlingEquipment,
            centralPlantCooler,
            centralPlantHeater,
            centralPlantHeatPump,
            terminalCooler,
            terminalHeater,
            terminalHeatPump,
            domesticHotWaterHeater,
            otherEquipment,
        ]
    );

    const { buildingModelUid } = useParams();
    if (!buildingModelUid)
        throw new Error("buildingModelUid should be defined");

    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);
    const { onSuccess, onError } = useQueryOnStatusUpdate(buildingModelUid);

    const { mutate: submitEquipmentSurvey } = useSubmitEquipmentData(
        buildingModelUid,
        onSuccess,
        onError
    );

    const handleSaveSurvey = () => {
        const equipmentData: EquipmentSurvey = {
            rooftopUnit,
            airHandlingEquipment,
            centralPlantCooler,
            centralPlantHeater,
            centralPlantHeatPump,
            centralPlantGenericEquipment,
            terminalCooler,
            terminalHeater,
            genericHVACEquipment: [],
            heatPump: terminalHeatPump,
            domesticHotWaterHeater,
            otherEquipment,
        };

        Object.keys(equipmentData).forEach((key) => {
            const obj = equipmentData[key as keyof EquipmentSurvey] as any;
            Object.keys(obj).forEach((sectionKey) => {
                /* Update the central plant struct to longer show water_loop_heatpumps as selected 
                The water loop heatpump should be in the heatpump object */
                if (
                    (obj[sectionKey] === "water_loop_heat_pump" ||
                        obj[sectionKey] === "split_air_source_heat_pump") &&
                    sectionKey === "centralPlantHeaterTerminalUnits"
                ) {
                    obj[sectionKey] = null;
                }

                // Update all NONE values as null
                if (obj[sectionKey] === NONE) obj[sectionKey] = null;

                /* If a key that contains exists (e.g CentralPlantHeaterExists) has been set to null, that means it was never selected
                and therefore it is false
                */
                if (
                    sectionKey.toLowerCase().includes("exists") &&
                    obj[sectionKey] === null
                )
                    obj[sectionKey] = false;
            });
        });

        // if the terminal heater is a furnace, we want to add it to the central plant (different than displayed on UI)
        if (
            FURNACES.includes(equipmentData.terminalHeater.terminalHeaterUnits)
        ) {
            equipmentData.centralPlantHeater = {
                ...DEFAULT_CENTRAL_PLANT_HEATER,
                centralPlantHeaterExists: true,
                centralPlantHeaterType:
                    equipmentData.terminalHeater.terminalHeaterUnits,
                centralPlantHeaterSize:
                    equipmentData.terminalHeater.terminalHeaterSize,
                centralPlantHeaterAverageInstallationYear:
                    equipmentData.terminalHeater
                        .terminalHeaterAverageInstallationYear,
            };
            equipmentData.terminalHeater = {
                ...DEFAULT_TERMINAL_HEATER,
                terminalHeaterExists: false,
            };
        }

        if (
            equipmentData.centralPlantGenericEquipment
                .centralPlantGenericEquipmentExists
        ) {
            const equipment = {
                name: equipmentData.centralPlantGenericEquipment
                    .centralPlantGenericEquipmentName!,
                size: equipmentData.centralPlantGenericEquipment
                    .centralPlantGenericEquipmentSize!,
                sizeUnits:
                    equipmentData.centralPlantGenericEquipment
                        .centralPlantGenericEquipmentSizeUnits!,
                coefficientOfPerformance:
                    equipmentData.centralPlantGenericEquipment
                        .centralPlantGenericEquipmentCoefficientOfPerformance!,
                fuel: equipmentData.centralPlantGenericEquipment
                    .centralPlantGenericEquipmentFuel!,
                endUse: equipmentData.centralPlantGenericEquipment
                    .centralPlantGenericEquipmentEndUse!,
                loadRatio:
                    equipmentData.centralPlantGenericEquipment
                        .centralPlantGenericEquipmentLoadRatio!,
                installationYear:
                    equipmentData.centralPlantGenericEquipment
                        .centralPlantGenericEquipmentInstallationYear!,
                lifeSpan: 15,
            };
            equipmentData.genericHVACEquipment.push(equipment);
        }

        setLoadingState((prev) => ({
            ...prev,
            equipmentSidebarLoading: true,
            buildingReportsLoading: true,
        }));

        const {
            // eslint-disable-next-line
            centralPlantGenericEquipment: genericEquipFromSurvey,
            ...payload
        } = equipmentData;
        submitEquipmentSurvey(payload as EquipmentSurveyAPI);
        setShowSurveyView(false);
    };

    const saveButtonDisabled = useSaveButtonIsDisabled(
        rooftopUnit,
        airHandlingEquipment,
        centralPlantCooler,
        centralPlantHeater,
        terminalCooler,
        terminalHeater,
        terminalHeatPump,
        domesticHotWaterHeater,
        otherEquipment,
        interfaceStructure,
        booleanQuestionResult
    );

    const reset = () => {
        resetEquipmentInstallationYearAndSize(
            setRooftopUnit,
            setAirHandlingEqipment,
            setCentralPlantCooler,
            setCentralPlantHeater,
            setCentralPlantHeatPump,
            setCentralPlantGenericEquipment,
            setTerminalHeater,
            setTerminalCooler,
            setDomesticHotWaterHeater,
            setOtherEquipment
        );
    };

    useEffect(() => {
        if (booleanQuestionResult === false) reset();
    }, [booleanQuestionResult]);

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setBooleanQuestionResult(null);
        reset();
    };

    return (
        <>
            <div className="building-equipment-survey-view">
                <EquipmentSizeAndInstallationYearSurveyHeader
                    booleanSurveyResult={booleanQuestionResult}
                    setBooleanSurveyResult={setBooleanQuestionResult}
                />
                <EquipmentSizeAndInstallationYearSurveyBody
                    section={interfaceStructure.rooftopUnit}
                    booleanQuestionResult={booleanQuestionResult}
                    state={rooftopUnit}
                    setState={setRooftopUnit}
                />
                <EquipmentSizeAndInstallationYearSurveyBody
                    section={interfaceStructure.airHandlingEquipment}
                    booleanQuestionResult={booleanQuestionResult}
                    state={airHandlingEquipment}
                    setState={setAirHandlingEqipment}
                />
                {interfaceStructure.centralPlant.show &&
                    booleanQuestionResult === true && (
                        <div
                            className="building-equipment-survey-view__section 
                            building-equipment-survey-view__section--size-and-year-survey"
                            key={interfaceStructure.centralPlant.sectionTitle}
                        >
                            <Header size="medium">
                                {interfaceStructure.centralPlant.sectionTitle}
                            </Header>
                            {interfaceStructure.centralPlant.items.map(
                                (item) => {
                                    if (item.key in centralPlantCooler) {
                                        return (
                                            <EquipmentSizeAndInstallationYearSurveyBodyItem
                                                state={centralPlantCooler}
                                                setState={setCentralPlantCooler}
                                                item={
                                                    // eslint-disable-next-line max-len
                                                    item as EquipmentAndInstallationYearSurveyItem<CentralPlantCooler>
                                                }
                                                key={item.key}
                                            />
                                        );
                                    }
                                    if (item.key in centralPlantHeatPump) {
                                        return (
                                            <EquipmentSizeAndInstallationYearSurveyBodyItem
                                                state={centralPlantHeatPump}
                                                setState={
                                                    setCentralPlantHeatPump
                                                }
                                                item={
                                                    // eslint-disable-next-line max-len
                                                    item as EquipmentAndInstallationYearSurveyItem<CentralPlantHeatPump>
                                                }
                                                key={item.key}
                                            />
                                        );
                                    }
                                    return (
                                        <EquipmentSizeAndInstallationYearSurveyBodyItem
                                            state={centralPlantHeater}
                                            setState={setCentralPlantHeater}
                                            item={
                                                // eslint-disable-next-line max-len
                                                item as EquipmentAndInstallationYearSurveyItem<CentralPlantHeater>
                                            }
                                            key={item.key}
                                        />
                                    );
                                }
                            )}
                        </div>
                    )}
                {interfaceStructure.terminalUnits.show &&
                    booleanQuestionResult === true && (
                        <div
                            className="building-equipment-survey-view__section 
                            building-equipment-survey-view__section--size-and-year-survey"
                            key={interfaceStructure.terminalUnits.sectionTitle}
                        >
                            <Header size="medium">
                                {interfaceStructure.terminalUnits.sectionTitle}
                            </Header>
                            {interfaceStructure.terminalUnits.items.map(
                                (item) => {
                                    if (item.key in terminalCooler) {
                                        return (
                                            <EquipmentSizeAndInstallationYearSurveyBodyItem
                                                state={terminalCooler}
                                                setState={setTerminalCooler}
                                                item={
                                                    // eslint-disable-next-line max-len
                                                    item as EquipmentAndInstallationYearSurveyItem<TerminalCooler>
                                                }
                                                key={item.key}
                                            />
                                        );
                                    }
                                    if (item.key in terminalHeatPump)
                                        return (
                                            <EquipmentSizeAndInstallationYearSurveyBodyItem
                                                state={terminalHeatPump}
                                                setState={setTerminalHeatPump}
                                                item={
                                                    // eslint-disable-next-line max-len
                                                    item as EquipmentAndInstallationYearSurveyItem<HeatPump>
                                                }
                                                key={item.key}
                                            />
                                        );

                                    return (
                                        <EquipmentSizeAndInstallationYearSurveyBodyItem
                                            state={terminalHeater}
                                            setState={setTerminalHeater}
                                            item={
                                                // eslint-disable-next-line max-len
                                                item as EquipmentAndInstallationYearSurveyItem<TerminalHeater>
                                            }
                                            key={item.key}
                                        />
                                    );
                                }
                            )}
                        </div>
                    )}

                <EquipmentSizeAndInstallationYearSurveyBody
                    section={interfaceStructure.domesticHotWaterHeater}
                    booleanQuestionResult={booleanQuestionResult}
                    state={domesticHotWaterHeater}
                    setState={setDomesticHotWaterHeater}
                />

                <EquipmentSizeAndInstallationYearSurveyBody
                    section={interfaceStructure.otherEquipment}
                    booleanQuestionResult={booleanQuestionResult}
                    state={otherEquipment}
                    setState={setOtherEquipment}
                />
            </div>
            <SurveyFooter
                handleSaveClick={handleSaveSurvey}
                handleBackClick={handleBackClick}
                saveButtonDisabled={saveButtonDisabled}
            />
        </>
    );
}

export default EquipmentSizeAndInstallationYearSurvey;
