import { ChangeEvent, useEffect, useMemo, useState } from "react";
import SingleSelectDropdown from "components/atoms/Dropdown";
import { getDisplayInfo } from "utils/formatting";
import { NumericStringMultiFilterProps } from "../FilterSidebar.interface";
import { filterConditionToDisplayValueMap, NumericSelectConditions } from "../FilterSidebar.constants";
import { cloneFilters, NumericFilterIsIncomplete } from "../FilterSidebar.helpers";

function NumericFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<NumericFilterField>) {
    const currentFilter = useMemo(
        () => filters.numericFilters.find(({ field }) => field === filterField),
        [filters]
    );

    const [startValue, setStartValue] = useState(currentFilter?.value[0]);
    const [endValue, setEndValue] = useState(currentFilter?.value[1]);

    const [dropdownInput, setDropdownInput] = useState(
        currentFilter?.condition
    );

    useEffect(() => {
        setDropdownInput(currentFilter?.condition);
        setStartValue(currentFilter?.value[0]);
        setEndValue(currentFilter?.value[1]);
    }, [currentFilter]);

    const dropdownItems = NumericSelectConditions.map((id) => ({
        id,
        displayValue: filterConditionToDisplayValueMap[id],
    }));

    const { placeholder } = getDisplayInfo(filterKey);

    useEffect(() => {
        if (NumericFilterIsIncomplete(startValue, endValue, dropdownInput))
            return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: NumericFilter = {
                value:
                    dropdownInput === "between"
                        ? [startValue!, endValue!]
                        : [startValue!],
                condition: dropdownInput!,
                field: filterField,
                key: filterKey,
            };

            const index = copy.numericFilters.findIndex(
                ({ field }) => field === filterField
            );
            if (index === -1) copy.numericFilters.push(newFilter);
            else copy.numericFilters[index] = newFilter;
            return copy;
        });
    }, [dropdownInput, startValue, endValue]);

    const updateStartValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === undefined || value === "") setStartValue(undefined);
        else setStartValue(Number(value));
    };

    const updateEndValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === undefined || value === "") setEndValue(undefined);
        else setEndValue(Number(value));
    };

    return (
        <div className="filter">
            <SingleSelectDropdown
                items={dropdownItems}
                onSelectItem={(item) => setDropdownInput(item.id)}
                defaultItem={dropdownItems.find(
                    ({ id }) => id === dropdownInput
                )}
                classname="filter__dropdown"
            />
            <input
                type="number"
                value={startValue || ""}
                className="filter-input"
                onChange={updateStartValue}
                placeholder={
                    dropdownInput === "between" ? "Start" : placeholder
                }
                maxLength={50}
            />
            {dropdownInput === "between" && (
                <input
                    type="numeric"
                    value={endValue || ""}
                    className="filter-input"
                    onChange={updateEndValue}
                    placeholder="End"
                    maxLength={50}
                />
            )}
        </div>
    );
}

export default NumericFilter;