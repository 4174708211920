import { useState, useEffect, useMemo, useCallback } from "react";
import { useQueryClient } from "react-query";
import classNames from "classnames";

// hooks
import { useBuildingModelConstruction } from "queries/Construction/useBuildingModelConstruction";
import { useBuildingFootprintsQuery } from "queries/useBuildingFootprintsQuery";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useUpdateBuildingModelMutation } from "mutations/useUpdateBuildingModelMutation";
import posthog from "posthog-js";

// components
import {
    EditIcon,
    FloorIcon,
    OverviewIcon,
    CloseButton,
    RoofIcon,
    WindowIcon,
    ErrorIcon,
} from "components/atoms/Icon";
import FormInput from "components/molecules/FormInput";
import BuildingTypeDropdown from "components/molecules/BuildingTypeDropdown";
import SidebarLoading from "components/molecules/SidebarLoading";
import FootprintsMap from "components/molecules/FootprintsMap";

import { Button } from "../../../components/atoms/Button";
import { Paragraph, Header } from "../../../components/atoms/Typography";

// helpers
import { getDisplayInfo } from "../../../utils/formatting";

interface BuildingConstructionProps {
    buildingModelUid: string;
}

function BuildingConstruction({ buildingModelUid }: BuildingConstructionProps) {
    const { data: buildingConstruction, isLoading } =
        useBuildingModelConstruction(buildingModelUid);

    const { selectedFootprintPolygon, setSelectedFootprintPolygon } =
        useSelectedBuildingPolygon(buildingConstruction);

    const { setLoadingState, loadingState } =
        useBuildingLoadingState(buildingModelUid);

    const { updateBuildingModel, error: updateBuildingModelError } =
        useUpdateBuildingModel(buildingModelUid);

    // properties
    const [properties, setBuildingProperties] =
        useState<BuildingProperties | null>(null);

    const defaultProperties = useMemo(() => {
        if (!buildingConstruction) return null;
        return buildBuildingPropertiesMap(buildingConstruction);
    }, [buildingConstruction]);

    useEffect(() => {
        setBuildingProperties(defaultProperties);
    }, [defaultProperties]);

    const [isEditing, setIsEditing] = useState(false);
    const [keyToValidationMap, setKeyToValidationMap] = useState(new Map());

    const { isLoading: footprintsLoading, data: buildingFootprints } =
        useBuildingFootprintsQuery(
            buildingConstruction?.location
                ? ({
                      streetAddress:
                          buildingConstruction?.location.streetAddress,
                      city: buildingConstruction?.location.city,
                      stateProvince:
                          buildingConstruction?.location.stateProvince,
                      postalZipCode:
                          buildingConstruction?.location.postalZipCode,
                      country: buildingConstruction?.location.country,
                      latitude: buildingConstruction?.location.latitude,
                      longitude: buildingConstruction?.location.longitude,
                  } as Address)
                : undefined
        );

    const updateValidationMapForKey = useCallback(
        (key: string, value: boolean) =>
            setKeyToValidationMap((prev) => {
                prev.set(key, value);
                return prev;
            }),
        [keyToValidationMap]
    );

    const localBuildingConstruction = useMemo(() => {
        if (!properties || !buildingConstruction) return null;
        return {
            ...buildingConstruction,
            ...properties,
            buildingUid: buildingConstruction.buildingUid || "",
        };
    }, [properties, buildingConstruction]);

    useEffect(() => {
        if (updateBuildingModelError) {
            setBuildingProperties(defaultProperties);
        }
    }, [updateBuildingModelError]);

    const selectedFootprintUid = useMemo(() => {
        if (buildingConstruction?.buildingFootprint)
            return buildingConstruction?.buildingFootprint.properties
                .buildingUid;

        return buildingConstruction?.buildingUid;
    }, [buildingConstruction]);

    const handleCancel = () => {
        setIsEditing(false);
        setBuildingProperties(defaultProperties);
        setSelectedFootprintPolygon(buildingConstruction?.buildingFootprint);
        posthog.capture('Edit-construction cancel');
    };

    const getFootprintForRequest = useCallback(() => {
        if (selectedFootprintPolygon) return selectedFootprintPolygon;

        if (buildingConstruction && buildingFootprints) {
            const { buildingUid } = buildingConstruction;

            return buildingFootprints.features.find(
                (footprint) => footprint.properties.buildingUid === buildingUid
            );
        }
        return undefined;
    }, [buildingConstruction, selectedFootprintPolygon, buildingFootprints]);

    const handleSave = () => {
        if (properties) {
            updateBuildingModel({
                buildingProperties: properties,
                buildingUid: selectedFootprintPolygon?.properties.buildingUid,
                buildingFootprint: getFootprintForRequest(),
            });
            setLoadingState((prev) => ({
                ...prev,
                constructionSidebarLoading: true,
                buildingReportsLoading: true,
            }));
        }
        setIsEditing(false);
    };

    if (
        isLoading ||
        footprintsLoading ||
        loadingState.constructionSidebarLoading
    )
        return <SidebarLoading />;

    if (!localBuildingConstruction || !properties) return null;

    return (
        <div
            className={classNames(
                "construction-sidebar",
                isEditing && "editing"
            )}
        >
            <ConstructionHeader
                isEditing={isEditing}
                handleCancel={handleCancel}
                handleEdit={() => setIsEditing(true)}
            />
            <div
                className={classNames(
                    "construction-sidebar__body",
                    isEditing && "editing"
                )}
            >
                <BuildingOverview
                    location={localBuildingConstruction.location}
                    buildingUid={localBuildingConstruction.buildingUid}
                    buildingConstruction={localBuildingConstruction}
                    setProperties={(value) => {
                        setBuildingProperties((prev) => ({
                            ...prev,
                            ...value,
                        }));
                    }}
                    buildingFootprints={buildingFootprints}
                    isEditing={isEditing}
                    updateValidationMapForKey={updateValidationMapForKey}
                    selectedFootprintUid={selectedFootprintUid || undefined}
                    selectedFootprintPolygon={selectedFootprintPolygon}
                    setSelectedFootprintPolygon={setSelectedFootprintPolygon}
                />
                <BuildingFloor
                    buildingConstruction={localBuildingConstruction}
                    buildingUid={localBuildingConstruction.buildingUid}
                    setProperties={(value) => {
                        setBuildingProperties((prev) => ({
                            ...prev,
                            ...value,
                        }));
                    }}
                    isEditing={isEditing}
                    updateValidationMapForKey={updateValidationMapForKey}
                />
                <BuildingRoof
                    buildingConstruction={localBuildingConstruction}
                    buildingUid={localBuildingConstruction.buildingUid}
                    setProperties={(value) => {
                        setBuildingProperties((prev) => ({
                            ...prev,
                            ...value,
                        }));
                    }}
                    isEditing={isEditing}
                    updateValidationMapForKey={updateValidationMapForKey}
                />
                <BuildingWindows
                    buildingConstruction={localBuildingConstruction}
                    buildingUid={localBuildingConstruction.buildingUid}
                    setProperties={(value) => {
                        setBuildingProperties((prev) => ({
                            ...prev,
                            ...value,
                        }));
                    }}
                    isEditing={isEditing}
                    updateValidationMapForKey={updateValidationMapForKey}
                />
            </div>
            {isEditing && (
                <ConstructionFooter
                    handleCancel={handleCancel}
                    handleSave={handleSave}
                    saveButtonIsDisabled={
                        Array.from(keyToValidationMap.values()).some(
                            (value) => value
                        ) ||
                        (buildingConstruction?.buildingFootprint !==
                            undefined &&
                            selectedFootprintPolygon === undefined)
                    }
                />
            )}
        </div>
    );
}

interface ConstructionHeaderProps {
    isEditing: boolean;
    handleCancel: () => void;
    handleEdit: () => void;
}

function ConstructionHeader({
    isEditing,
    handleCancel,
    handleEdit,
}: ConstructionHeaderProps) {
    return (
        <div
            className={classNames(
                "construction-sidebar__header",
                isEditing && "editing"
            )}
        >
            <OverviewIcon />
            <Header size="medium">
                {isEditing ? "Edit construction" : "Overview"}
            </Header>
            {isEditing ? (
                <CloseButton
                    id="edit-construction-close-button"
                    onClick={handleCancel}
                />
            ) : (
                <button
                    type="button"
                    id="edit-construction-button"
                    onClick={() => {
                        handleEdit();
                        posthog.capture('Edit-construction started');
                    }}
                >
                    <EditIcon />
                </button>
            )}
        </div>
    );
}

interface ConstructionFooterProps {
    handleCancel: () => void;
    handleSave: () => void;
    saveButtonIsDisabled: boolean;
}

function ConstructionFooter({
    handleCancel,
    handleSave,
    saveButtonIsDisabled,
}: ConstructionFooterProps) {
    return (
        <div className="construction-sidebar__footer">
            <Button
                type="primary"
                id="edit-construction-save-button"
                disabled={saveButtonIsDisabled}
                onClick={() => {
                    handleSave();
                    posthog.capture('Construction updated');
                }}
            >
                Save
            </Button>
            <Button
                type="link"
                id="edit-construction-cancel-button"
                onClick={handleCancel}
            >
                Cancel
            </Button>
        </div>
    );
}

interface BuildingSectionProps {
    location?: Location;
    buildingUid: string;
    buildingConstruction: BuildingModelConstruction;
    setProperties: (properties: Partial<CreateBuildingModelInput>) => void;
    isEditing: boolean;
    buildingFootprints?: BuildingFootprints;
    selectedFootprintUid?: string;
    updateValidationMapForKey: (key: string, value: boolean) => void;
    selectedFootprintPolygon?: FootprintPolygon;
    setSelectedFootprintPolygon?: React.Dispatch<
        React.SetStateAction<FootprintPolygon | undefined>
    >;
}

function BuildingOverview(props: BuildingSectionProps) {
    return (
        <>
            {props.isEditing && (
                <div
                    className="construction-sidebar__body__header"
                    style={{ paddingTop: "var(--size-margin-m)" }}
                >
                    <OverviewIcon />
                    <Header size="medium">Overview</Header>
                </div>
            )}
            <BuildingSectionBody {...props} fields={overviewFieldValues} />
        </>
    );
}

function BuildingFloor(props: BuildingSectionProps) {
    return (
        <>
            <div className="construction-sidebar__body__header">
                <FloorIcon />
                <Header size="medium">Floor</Header>
            </div>
            <BuildingSectionBody {...props} fields={floorFieldValues} />
        </>
    );
}

function BuildingRoof(props: BuildingSectionProps) {
    return (
        <>
            <div className="construction-sidebar__body__header">
                <RoofIcon />
                <Header size="medium">Roof</Header>
            </div>
            <BuildingSectionBody {...props} fields={roofFieldValues} />
        </>
    );
}

function BuildingWindows(props: BuildingSectionProps) {
    if (props.isEditing) return null;
    return (
        <>
            {!props.isEditing && (
                <div className="construction-sidebar__body__header">
                    <WindowIcon />
                    <Header size="medium">Window</Header>
                </div>
            )}
            <BuildingSectionBody {...props} fields={windowFieldValues} />
        </>
    );
}

interface BuildingSectionBodyProps extends BuildingSectionProps {
    fields: BuildingPropertyField[];
}

function BuildingSectionBody({
    location,
    buildingConstruction,
    buildingUid,
    setProperties,
    isEditing,
    fields,
    buildingFootprints,
    selectedFootprintUid,
    updateValidationMapForKey,
    selectedFootprintPolygon,
    setSelectedFootprintPolygon,
}: BuildingSectionBodyProps) {
    console.log(
        location,
        buildingConstruction,
        setProperties,
        isEditing,
        fields,
        buildingFootprints,
        selectedFootprintUid,
        updateValidationMapForKey,
        selectedFootprintPolygon,
        setSelectedFootprintPolygon,
    )
    return (
        <div
            className={classNames(
                "construction-sidebar__body__section",
                isEditing && "editing"
            )}
        >
            {buildingFootprints && (
                <FootprintsMap
                    key={buildingUid}
                    location={location}
                    buildingUid={selectedFootprintUid}
                    buildingFootprint={selectedFootprintPolygon}
                    buildingFootprints={buildingFootprints}
                    address={buildingConstruction.location}
                    setSelectedFootprintPolygon={setSelectedFootprintPolygon}
                    isEditing={isEditing}
                />
            )}
            {fields.map((field) => {
                if (field.type === "string" || field.type === "number")
                    return (
                        <BuildingPropertyInput
                            key={field.key}
                            value={getValueForKey(
                                field.key,
                                buildingConstruction
                            )}
                            setValue={(value) => {
                                setProperties({ [field.key]: value });
                            }}
                            attributeKey={field.key}
                            editMode={isEditing}
                            type={field.type}
                            allowEdit={field.canEdit}
                            allowNegativeNumbers={field.allowNegativeNumber}
                            setValidationError={(value) =>
                                updateValidationMapForKey(field.key, value)
                            }
                            required={field.required}
                        />
                    );
                if (
                    field.type === "select" &&
                    field.key === "buildingArchetype" &&
                    buildingConstruction[field.key]
                )
                    return (
                        <BuildingTypeInput
                            key={field.key}
                            value={buildingConstruction[field.key]!}
                            setValue={(value) =>
                                setProperties({ [field.key]: value })
                            }
                            editMode={isEditing}
                        />
                    );
                return null;
            })}
        </div>
    );
}

interface BuildingTypeInputProps {
    value: string;
    setValue: (value: string) => void;
    editMode: boolean;
}

function BuildingTypeInput({
    value,
    setValue,
    editMode,
}: BuildingTypeInputProps) {
    const { humanReadable, formatFunction } =
        getDisplayInfo("buildingArchetype");

    if (!editMode)
        return (
            <div className="construction-sidebar__body__property">
                <Header
                    size="small"
                    style={{ color: "var(--audette-gray-500)" }}
                >
                    {humanReadable}:
                </Header>
                <Paragraph>{formatFunction(value)}</Paragraph>
            </div>
        );

    return (
        <BuildingTypeDropdown
            value={value}
            setValue={setValue}
            required={true}
        />
    );
}

interface BuildingPropertyInputProps {
    value: number | string | undefined | null;
    setValue: (v: number | string | undefined) => void;
    attributeKey: string;
    editMode: boolean;
    type: "number" | "string";
    allowEdit?: boolean;
    allowNegativeNumbers?: boolean;
    required?: boolean;
    setValidationError: (isError: boolean) => void;
}

function BuildingPropertyInput({
    value,
    setValue,
    attributeKey,
    editMode,
    type,
    allowEdit = false,
    allowNegativeNumbers = true,
    setValidationError,
    required,
}: BuildingPropertyInputProps) {
    const { unit, humanReadable, formatFunction } =
        getDisplayInfo(attributeKey);
    if (!allowEdit && editMode) return null;

    if (!editMode)
        return (
            <div className="construction-sidebar__body__property">
                <Header
                    size="small"
                    style={{ color: "var(--audette-gray-500)" }}
                >
                    {humanReadable}:
                </Header>
                {valueIsValid(value) ? (
                    <Paragraph>{`${formatFunction(value)}${
                        unit === "%" ? "%" : ` ${unit}`
                    }`}</Paragraph>
                ) : (
                    <ErrorIcon color="var(--audette-gray-400)" />
                )}
            </div>
        );

    return (
        <FormInput
            value={value ?? ""}
            setValue={setValue}
            attributeKey={attributeKey}
            type={type}
            allowNegativeNumbers={allowNegativeNumbers}
            setIsError={setValidationError}
            required={required}
        />
    );
}

const buildBuildingPropertiesMap = (
    building: BuildingModelConstruction
): BuildingProperties => ({
    availableRoofAreaRatio: building.availableRoofAreaRatio ?? undefined,
    ledInstalledRatio: building.ledInstalledRatio ?? undefined,
    yearBuiltOriginal: building.yearBuiltOriginal ?? undefined,
    buildingArchetype: building.buildingArchetype ?? undefined,
    buildingHeight: building.buildingHeight ?? undefined,
    floorsAboveGrade: building.floorsAboveGrade ?? undefined,
    grossFloorArea: building.grossFloorArea ?? undefined,
});

const valueIsValid = (value: string | number | null | undefined) =>
    value !== undefined && value !== null;

const getValueForKey = (key: string, construction: BuildingModelConstruction) =>
    key in construction.location
        ? construction.location[key as keyof Location]
        : construction[key as keyof BuildingModelConstruction];

interface BuildingPropertyField {
    type: "string" | "number" | "select";
    key: string;
    canEdit: boolean;
    required?: boolean;
    allowNegativeNumber?: boolean;
}

const overviewFieldValues: BuildingPropertyField[] = [
    {
        type: "string",
        key: "city",
        canEdit: false,
    },
    {
        type: "string",
        key: "stateProvince",
        canEdit: false,
    },
    {
        type: "string",
        key: "climateZone",
        canEdit: false,
    },
    {
        type: "select",
        key: "buildingArchetype",
        canEdit: true,
        required: true,
    },
    {
        type: "number",
        key: "yearBuiltOriginal",
        canEdit: true,
        required: true,
        allowNegativeNumber: false,
    },
];

const floorFieldValues: BuildingPropertyField[] = [
    {
        type: "number",
        key: "grossFloorArea",
        canEdit: true,
    },
    {
        type: "number",
        key: "floorsAboveGrade",
        canEdit: true,
    },
    {
        type: "number",
        key: "ledInstalledRatio",
        canEdit: true,
        required: false,
    },
];

const roofFieldValues: BuildingPropertyField[] = [
    {
        type: "number",
        key: "roofInstallationYear",
        canEdit: false,
    },
    {
        type: "number",
        key: "availableRoofAreaRatio",
        canEdit: true,
        required: false,
    },
    {
        type: "number",
        key: "roofArea",
        canEdit: false,
    },
    {
        type: "number",
        key: "roofRValue",
        canEdit: false,
    },
];

const windowFieldValues: BuildingPropertyField[] = [
    {
        type: "number",
        key: "windowInstallationYear",
        canEdit: false,
    },
    {
        type: "number",
        key: "windowRValue",
        canEdit: false,
    },
    {
        type: "number",
        key: "windowSolarHeatGainCoefficient",
        canEdit: false,
    },
    {
        type: "number",
        key: "windowToWallRatio",
        canEdit: false,
    },
];

const useSelectedBuildingPolygon = (
    buildingConstruction: BuildingModelConstruction | undefined
) => {
    const [selectedFootprintPolygon, setSelectedFootprintPolygon] =
        useState<FootprintPolygon>();

    useEffect(() => {
        setSelectedFootprintPolygon(buildingConstruction?.buildingFootprint);
    }, [buildingConstruction]);

    return { selectedFootprintPolygon, setSelectedFootprintPolygon };
};

const useUpdateBuildingModel = (buildingModelUid: string) => {
    const { onSuccess, onError } = useQueryOnStatusUpdate(buildingModelUid);

    const { mutate: updateBuildingModel, error } =
        useUpdateBuildingModelMutation(buildingModelUid, onSuccess, onError);

    return { updateBuildingModel, error };
};

export const useQueryOnStatusUpdate = (buildingModelUid: string) => {
    const queryClient = useQueryClient();
    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);

    const reset = () => {
        queryClient.resetQueries({
            queryKey: [buildingModelUid, "buildingModelReport"],
        });

        queryClient.invalidateQueries({
            queryKey: ["buildingModelsTable"],
        });
        queryClient.resetQueries({
            queryKey: ["buildingModelConstruction", buildingModelUid],
        });
        setLoadingState((prev) => ({
            ...prev,
            constructionSidebarLoading: false,
            buildingReportsLoading: false,
        }));

        queryClient.invalidateQueries({
            queryKey: ["buildingModelHeader", buildingModelUid],
        });
    };

    return { onSuccess: reset, onError: reset };
};

export default BuildingConstruction;
