import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildReportsRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.reports?.baselineReport?.cumulativeEnergyConsumptionCard.error)
            throw new Error(
                data.reports.baselineReport.cumulativeEnergyConsumptionCard.error
            );
        return (
            (data.reports?.baselineReport
                ?.cumulativeEnergyConsumptionCard as CumulativeEnergyConsumptionCardData) ||
            null
        );
    });

const query = graphql(`
    query cumulativeEnergyConsumptionCard($reportFilter: ReportFilter) {
        reports(reportFilter: $reportFilter) {
            baselineReport {
                cumulativeEnergyConsumptionCard {
                    error
                    totalEnergyConsumption
                    totalEnergyConsumptionElectricity
                    totalEnergyConsumptionNaturalGas
                }
            }
        }
    }
`);

export default queryFn;
