import {
    useState,
    useContext,
    createContext,
    useMemo,
    ReactElement,
    useEffect,
} from "react";

// hooks
import { useNavigate } from "react-router-dom";

// store
import useFilters, { emptyFilters } from "recoilStore/useFilters";
import useReportsGroupByState from "recoilStore/useReportsGroupByState";

// components
import { BarChart } from "components/molecules/Charts";
import ChartContainer from "components/molecules/ChartContainer";
import { Paragraph, Header } from "components/atoms/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "components/atoms/Button";
import { ChartErrorState } from "components/molecules/ErrorStates";

// helpers
import { getDisplayInfo, semanticTermToDisplayInfo } from "utils/formatting";

import MUITheme from "components/MUITheme";

// css
import "./ByBuildingBarChart.css";
import { ChartNoDataState } from "components/molecules/NoDataState";
import useBuildingBarChartAndMapByGroup from "./hooks/useBuildingBarChartAndMapByGroup";

const DROPDOWN_ITEMS_KEYS: string[] = [
    "annualCarbonEmissionsCurrent",
    "annualCarbonEmissionIntensityCurrent",
    "annualEnergyConsumptionCurrent",
    "annualEnergyUseIntensityCurrent",
];
const NUMBER_OF_BUCKETS = 60;

function ByBuildingBarChartContextWrapper() {
    return (
        <TooltipDataContextProvider>
            <ByBuildingBarChart />
        </TooltipDataContextProvider>
    );
}

function ByBuildingBarChart() {
    const { items, onSelectItem, selectedItemId } = useDropdownItems();

    const { state: groupByMetric } = useReportsGroupByState();

    const {
        data: buildingModels,
        isLoading,
        error,
    } = useBuildingBarChartAndMapByGroup(groupByMetric);

    const data = useData(buildingModels, selectedItemId);
    const { unit, formatFunction } = useDisplayInfo(selectedItemId, data);

    if (error) return <ChartErrorState />;
    if (buildingModels?.length === 0) return <ChartNoDataState />;

    return (
        <ChartContainer
            items={items}
            onSelectItem={onSelectItem}
            loading={isLoading}
        >
            <BarChart
                data={data}
                xAxisInfo={{ hideLabels: true }}
                yAxisInfo={{
                    label: unit,
                    formatFunction: (v: string | number) =>
                        formatFunction(v, false),
                }}
                chartHeight={440}
                customLayers={[BarLayer]}
                margin={{
                    bottom: 6,
                    left: 75,
                    top: 10,
                    right: 50,
                }}
            />
        </ChartContainer>
    );
}

const useDisplayInfo = (
    selectedItemId: string,
    data: { label: number; value: number }[]
) =>
    useMemo(
        () =>
            getDisplayInfo(
                selectedItemId,
                data.map(({ value }) => value)
            ),
        [data, selectedItemId]
    );

const useDropdownItems = () => {
    const items = DROPDOWN_ITEMS_KEYS.map((key) => ({
        id: key,
        displayValue: semanticTermToDisplayInfo[key].humanReadable,
    }));

    const onSelectItem = (id: string) => {
        setSelectedItemId(id as keyof BuildingBarChartAndMap);
    };

    const [selectedItemId, setSelectedItemId] = useState(
        DROPDOWN_ITEMS_KEYS[0]
    );

    return { selectedItemId, setSelectedItemId, items, onSelectItem };
};

const useData = (
    buildingModels: BarChartAndMapData[] | undefined,
    selectedItemId: string
) => {
    const data = useMemo(() => {
        if (!buildingModels) return [];
        return DROPDOWN_ITEMS_KEYS.map((key) => {
            const { buckets, data } = buildData(
                buildingModels,
                key as keyof BarChartAndMapData
            );
            return { key, data, buckets };
        });
    }, [buildingModels]);

    const { setBuckets } = useTooltipDataContext();
    useEffect(() => {
        const buckets =
            data.find(({ key }) => key === selectedItemId)?.buckets || [];
        setBuckets(buckets);
    }, [selectedItemId, data]);

    return useMemo(
        () => data.find(({ key }) => key === selectedItemId)?.data || [],
        [selectedItemId, data]
    );
};

interface BuildingDatum {
    label: string;
    address?: string;
    value: number;
    key: string;
    selectedKey: keyof BarChartAndMapData;
}

interface ChartBucket {
    average: null | number;
    min: null | number;
    max: null | number;
    buildings: BuildingDatum[];
}

const buildData = (
    buildingModels: BarChartAndMapData[],
    selectedKey: keyof BarChartAndMapData
) => {
    if (buildingModels.length === 0) return { buckets: [], data: [] };

    const buckets = buildBucketsFromBuildingModels(buildingModels, selectedKey);
    const data = buckets.map(({ average }, index) => ({
        label: index,
        value: average,
    }));

    return { buckets, data };
};

const buildBucketsFromBuildingModels = (
    buildingModels: BarChartAndMapData[],
    selectedKey: keyof BarChartAndMapData
) => {
    const sortedBuildingData = buildingModels
        .map((model) => ({
            label: model.buildingModelUid!,
            address: model.label as string,
            value: model[selectedKey as keyof BarChartAndMapData] as number,
            selectedKey,
            key: model.buildingModelUid || model.label,
        }))
        .sort((a, b) => a.value - b.value);

    const bucketCount = Math.ceil(
        sortedBuildingData.length / NUMBER_OF_BUCKETS
    );

    const buckets: ChartBucket[] = new Array(NUMBER_OF_BUCKETS)
        .fill(null)
        .map(() => ({
            average: null,
            min: null,
            max: null,
            buildings: [],
        }));

    let bucketIndex = 0;
    sortedBuildingData.forEach((building) => {
        const currentEntries = buckets[bucketIndex].buildings.length;
        if (
            currentEntries >= bucketCount &&
            bucketIndex !== NUMBER_OF_BUCKETS - 1
        )
            bucketIndex += 1;
        buckets[bucketIndex].buildings.push(building);
    });

    return buckets
        .filter(({ buildings }: ChartBucket) => buildings.length !== 0)
        .map((bucket) => {
            const numberOfBuildings = bucket.buildings.length;
            const sum = bucket.buildings.reduce(
                (prev, curr) => prev + curr.value,
                0
            );
            const average = sum / numberOfBuildings;
            const min = bucket.buildings[0].value;
            const max = bucket.buildings[numberOfBuildings - 1].value;
            return {
                ...bucket,
                min,
                max,
                average,
            };
        });
};

function BarLayer({ bars, height }: any) {
    return (
        <>
            {bars.map(({ width, x, y, index, data }: any) => (
                <g key={`$wrapper${index}`} transform={`translate(${x}, ${y})`}>
                    <Bar
                        width={width}
                        height={height}
                        data={data}
                        index={index}
                    />
                </g>
            ))}
        </>
    );
}

function Bar({ width, height, index }: any) {
    return (
        <InteractiveTooltipWrapper index={index}>
            <rect
                width={width}
                height={height}
                rx={0}
                ry={0}
                fill="transparent"
                strokeWidth={0}
                stroke="transparent"
            />
        </InteractiveTooltipWrapper>
    );
}

interface TooltipWrapperProps {
    children: ReactElement<any, any>;
    index: number;
}

function InteractiveTooltipWrapper({ children, index }: TooltipWrapperProps) {
    return (
        <MUITheme>
            <Tooltip
                title={<ByBuildingChartTooltip currentDataIndex={index} />}
                placement="top"
                style={{ padding: 0 }}
                leaveDelay={50}
                PopperProps={tooltipPopperConfig}
            >
                {children}
            </Tooltip>
        </MUITheme>
    );
}

const tooltipPopperConfig = {
    popperOptions: {
        modifiers: [
            {
                name: "flip",
                options: {
                    fallbackPlacements: ["bottom", "left"],
                },
            },
        ],
    },
};

interface ByBuildingChartTooltipProps {
    currentDataIndex?: number;
}

function ByBuildingChartTooltip({
    currentDataIndex,
}: ByBuildingChartTooltipProps) {
    if (currentDataIndex === undefined) return null;

    const { buckets } = useTooltipDataContext();
    const { buildings, min, max, average } = buckets[currentDataIndex];
    const { min: dataMin } = buckets[0];
    const { selectedKey } = buildings[0];

    const { formatFunction, unit } = getDisplayInfo(selectedKey, dataMin || 0);

    const { setFilters } = useFilters("buildings");
    const navigate = useNavigate();

    const onClickViewAll = () => {
        setFilters({
            ...emptyFilters,
            numericFilters: [
                {
                    key: selectedKey,
                    condition: "between",
                    field: "annual_carbon_emission_intensity", // field doesn't have to match
                    value: [min || 0, max || 0],
                },
            ],
        });
        navigate("/portfolio");
    };

    return (
        <div className="bar-tooltip">
            {buildings.length > 1 && (
                <div className="bar-tooltip--title">
                    <Header
                        style={{ color: "var(--audette-gray-500)" }}
                        size="small"
                    >
                        Average:
                    </Header>
                    <Paragraph>
                        {formatFunction
                            ? formatFunction(average, false)
                            : average}{" "}
                        {unit}
                    </Paragraph>
                </div>
            )}
            {buildings.slice(0, 5).map((b) => (
                <div className="bar-tooltip--row" key={b.key}>
                    <Header size="x-small">{b.address}</Header>
                    <Paragraph size="small">
                        {`${
                            formatFunction
                                ? formatFunction(b.value, false)
                                : b.value
                        } ${unit}`}
                    </Paragraph>
                </div>
            ))}
            {buildings.length > 5 && (
                <Button type="link" onClick={onClickViewAll}>
                    View all
                </Button>
            )}
        </div>
    );
}

interface TooltipDataContextValue {
    buckets: ChartBucket[];
    setBuckets: (buckets: ChartBucket[]) => void;
}

const TooltipDataContext = createContext<TooltipDataContextValue | null>(null);

function TooltipDataContextProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [buckets, setBuckets] = useState<ChartBucket[]>([]);
    const dataContexValue = useMemo(() => ({ buckets, setBuckets }), [buckets]);

    return (
        <TooltipDataContext.Provider value={dataContexValue}>
            {children}
        </TooltipDataContext.Provider>
    );
}

export const useTooltipDataContext = () => {
    const context = useContext(TooltipDataContext);
    if (context === null)
        throw new Error(
            "TooltipDataContext must be inside a TooltipDataContextProvider"
        );
    return context;
};

export default ByBuildingBarChartContextWrapper;
