import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "./useUser";

export const usePendo = () => {
    const { data: user, isError, isLoading } = useUser();
    const { user: userInfoAuth0 } = useAuth0();

    const userEmail = userInfoAuth0?.email;

    if (isLoading || isError) return;

    (window as any).pendo.initialize({
        visitor: {
            id: userEmail,
            email: userEmail,
        },
        account: {
            name: user?.activeOrganization?.organizationName,
            id: user?.activeOrganization?.organizationUid,
        },
    });
};
