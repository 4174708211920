import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.buildingModelReports.error)
            throw new Error(data.buildingModelReports.error);
        return data.buildingModelReports.baselineReport
            ?.annualOperatingCostCard as AnnualOperatingCostCardData;
    });

const query = graphql(`
    query buildingBaselineAnnualOperatingCostCard($buildingModelUid: Uuid!) {
        buildingModelReports(buildingModelUid: $buildingModelUid) {
            error
            baselineReport {
                annualOperatingCostCard {
                    totalCarbonTaxIntensity
                    totalUtilityCostIntensity
                    totalCarbonTax
                    totalEnergyCost
                    totalUtilityCost
                    year
                }
            }
        }
    }
`);

export default queryFn;
