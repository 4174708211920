import { graphql } from "gql";
import request from "graphql-request";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationAddUsersFn = (
    accessToken: string,
    data: {
        emails: string[]
    }
) => 
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: {
            emails: data.emails,
        },
        requestHeaders: buildRequestHeaders(accessToken),   
    }).then((data) => {
        if (data) {
            return data;
        }
        throw new Error("Error adding users");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

const mutationQuery = graphql(`
    mutation addUsers ($emails: [String!]!) {
        addUsers(emails: $emails){
            userId
        }
    }
`);        

export default mutationAddUsersFn;