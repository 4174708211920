import createReportFiltersetFn from "ApiLayer/Measures/queryCreateReportFilterset";
import { ReportFilter } from "gql/graphql";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
// import { userQueryKey } from "hooks/useUser";
import { useMutation } from "react-query";
import { showMutationAlert } from "utils/helpers";

export const useCreateReportFilterset = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    // const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess:  (data) => {
            // queryClient.invalidateQueries({ queryKey: userQueryKey });
            alert.success("report filterset created");
            console.log(data);
        },
        mutationFn: async (data: { reportFilter: ReportFilter }) => (
            createReportFiltersetFn(accessToken, data)
        )
    });
}