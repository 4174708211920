import { useMemo } from "react";
import { ActiveView, DEFAULT_DOMESTIC_HOT_WATER } from "../Survey.constants";
import { buildDomesticHotWaterHeaterSurveyUIStruct, updatePageStackAndViewOnBackClick, updatePageStackOnNextClick } from "../Survey.helpers";
import { useNextButtonDisabled } from "../Survey.hooks";
import SurveyView from "../SurveyElements/Survey/SurveyView";

interface DomesticHotWaterHeaterSurveyProps {
    domesticHotWaterHeater: DomesticHotWaterHeater;
    centralPlantHeater: CentralPlantHeater;
    setDomesticHotWaterHeater: React.Dispatch<
        React.SetStateAction<DomesticHotWaterHeater>
    >;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function DomesticHotWaterHeaterSurvey({
    domesticHotWaterHeater,
    centralPlantHeater,
    setDomesticHotWaterHeater,
    setActiveView,
    activeView,
    pageStack,
    setPageStack,
}: DomesticHotWaterHeaterSurveyProps) {
    const structure = useMemo(
        () => buildDomesticHotWaterHeaterSurveyUIStruct(domesticHotWaterHeater, centralPlantHeater),
        [domesticHotWaterHeater]
    );

    const nextButtonDisabled = useNextButtonDisabled(
        structure,
        domesticHotWaterHeater
    );

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setDomesticHotWaterHeater(DEFAULT_DOMESTIC_HOT_WATER);
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        setActiveView(ActiveView.otherEquipment);
    };

    return (
        <SurveyView
            structure={structure}
            surveyPageData={domesticHotWaterHeater}
            setSurveyPageData={setDomesticHotWaterHeater}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

export default DomesticHotWaterHeaterSurvey;