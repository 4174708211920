import { useCallback, useRef, useState } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import { ConstructionIcon, DownCarrot } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import { useProperties } from "queries/useProperties";
import { useSetBuildingModelProperty } from "mutations/useSetBuildingModelProperty";

import "./PropertyDropdown.scss";
import "./BuildingSelectorOverlay.scss";
import { InlineBoldParagraph } from "components/atoms/Typography/Typography";

interface PropertyDropdownProps {
    buildingModelUid: string;
    propertyName: string | null;
}

function PropertyDropdown({
    buildingModelUid,
    propertyName,
}: PropertyDropdownProps) {
    const { data: properties } = useProperties();

    const [currentPropertyName, setCurrentPropertyName] =
        useState(propertyName);
    const [newPropertyName, setNewPropertyName] = useState(propertyName);
    const [isEditingPropertyName, setIsEditingPropertyName] = useState(false);

    const autoFocus = useCallback((el: any) => (el ? el.focus() : null), []);
    const outsideClickRef = useRef<HTMLInputElement | null>(null);

    useClickOutside(outsideClickRef, () => {
        if (isEditingPropertyName) saveAndClose();
    });

    const saveAndClose = () => {
        setIsEditingPropertyName(false);
        changeProperty(newPropertyName);
    };

    const { mutate: setBuildingModelProperty } = useSetBuildingModelProperty([
        buildingModelUid,
    ]);

    if (!properties) return null;

    const changeProperty = (newNameInput: string | null) => {
        const newName = newNameInput || null;
        setNewPropertyName(newName);
        setCurrentPropertyName(newName);
        setBuildingModelProperty(newName);
        setIsEditingPropertyName(false);
    };

    const propertyNames = properties
        .map((property) => property.propertyName)
        .sort();

    return (
        <div className="property-dropdown" ref={outsideClickRef}>
            <button
                className="property-dropdown__selector"
                type="button"
                onClick={() => setIsEditingPropertyName(true)}
                data-testid="property-dropdown-button"
            >
                <ConstructionIcon color="var(--audette-gray-600)" />
                <Paragraph dataTestId="property-dropdown-current-property-label">
                    {currentPropertyName || "No property"}
                </Paragraph>
                <DownCarrot />
            </button>
            {isEditingPropertyName && (
                <div
                    className="building-selector-overlay"
                    style={{ minWidth: "165px", width: "auto" }}
                >
                    <div
                        className="building-selector-overlay__input"
                        style={{ display: "flex" }}
                    >
                        <div>
                            <ConstructionIcon color="var(--audette-gray-600)" />
                        </div>
                        <input
                            data-testid="property-dropdown-input"
                            ref={autoFocus}
                            value={newPropertyName || ""}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    saveAndClose();
                                }
                            }}
                            onChange={(event) => {
                                const { value } = event.target;
                                setNewPropertyName(value);
                            }}
                            style={{ width: "50px" }}
                        />
                    </div>
                    <div className="building-selector-overlay__options">
                        <Paragraph
                            className="building-selector-overlay__options__select"
                            size="small"
                            style={{ color: "var(--audette-gray-600)" }}
                        >
                            Select or create one
                        </Paragraph>
                        {propertyNames.map((property) => (
                            <button
                                key={property}
                                onClick={() => changeProperty(property)}
                                type="button"
                                style={{ display: "block" }}
                            >
                                <Paragraph className="building-selector-overlay__options__select">
                                    {property}
                                </Paragraph>
                            </button>
                        ))}
                        {newPropertyName &&
                            newPropertyName !== "" &&
                            !propertyNames.includes(newPropertyName) && (
                                <button
                                    onClick={(e) => {
                                        changeProperty(newPropertyName);
                                        e.stopPropagation();
                                    }}
                                    type="button"
                                >
                                    <Paragraph
                                        className="building-selector-overlay__options__select"
                                        style={{
                                            color: "var(--audette-gray-500)",
                                        }}
                                    >
                                        Create{" "}
                                        <InlineBoldParagraph
                                            style={{
                                                color: "var(--audette-black)",
                                            }}
                                        >
                                            {newPropertyName}
                                        </InlineBoldParagraph>
                                    </Paragraph>
                                </button>
                            )}
                        {newPropertyName === "" && (
                            <button
                                onClick={(e) => {
                                    changeProperty(null);
                                    e.stopPropagation();
                                }}
                                type="button"
                            >
                                <Paragraph
                                    className="building-selector-overlay__options__select"
                                    style={{
                                        color: "var(--audette-black)",
                                    }}
                                >
                                    <InlineBoldParagraph>
                                        No Property
                                    </InlineBoldParagraph>
                                </Paragraph>
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PropertyDropdown;
