import { QueryClient } from "react-query";
import useAlert from "hooks/useAlert";
import { showMutationAlert } from "../utils/helpers";

const useQueryClient = () => {
    const alert = useAlert();

    return new QueryClient({
        defaultOptions: {
            mutations: {
                onError: (errors: any) => {
                    showMutationAlert(errors, alert);
                },
            },
        },
    });
};

export default useQueryClient;
