import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import posthog from "posthog-js";

import { useEquipmentSurvey } from "queries/Equipment/useEquipmentSurvey";
import { useBuildingModelHeaderQuery } from "queries/useBuildingModelHeaderQuery";

import classNames from "classnames";
import { AddIcon, CloseButton, EditIcon, WarningIcon } from "components/atoms/Icon";
import { Header } from "components/atoms/Typography";
import SidebarLoading from "components/molecules/SidebarLoading";
import { Button } from "components/atoms/Button";
import { message as banner } from "antd";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { getDataCoverageWarnings } from "utils/helpers";
import { ResetButton } from "components/molecules/DangerousButtons";
import { useDeleteEquipmentData } from "mutations/useDeleteEquipmentSurvey";
import EmptySidebarView from "../../../EmptySidebarView";

import { Survey } from "../../Survey";

import "./buildingEquipment.scss";
import { useQueryOnStatusUpdate } from "../../Survey.hooks";
import { DataView } from "../DataView";

function BuildingEquipment() {
    const handleAddEquipmentPrompt = () => {
        setShowSurveyView(true);
    };

    const [showSurveyView, setShowSurveyView] = useState(false);
    const { buildingModelUid } = useParams();
    if (!buildingModelUid)
        throw new Error("buildingModelUid needs to be defined");
    const {
        data: surveyData,
        isLoading,
        error,
    } = useEquipmentSurvey(buildingModelUid);

    const { loadingState } = useBuildingLoadingState(buildingModelUid);

    const { isLoading: buildingStatusLoading, data: buildingHeaderData } =
        useBuildingModelHeaderQuery(buildingModelUid);

    const surveyDataExists = useMemo(() => {
        if (!surveyData) return false;
        return true;
    }, [surveyData]);

    const dataCoverageWarnings = useMemo(
        () =>
            buildingHeaderData?.dataCoverage
                ? getDataCoverageWarnings(buildingHeaderData.dataCoverage)
                : undefined,
        [buildingHeaderData]
    );

    const showEquipmentWarnings = useMemo(
        () => dataCoverageWarnings?.equipmentWarning,
        [dataCoverageWarnings]
    );

    const hasNaturalGasWarning = useMemo(
        () => dataCoverageWarnings?.hasNaturalGasWarning,
        [dataCoverageWarnings]
    );

    if (
        isLoading ||
        loadingState.equipmentSidebarLoading ||
        buildingStatusLoading
    )

        return <SidebarLoading />;
    if (error) return <>Error</>;

    if (surveyDataExists && surveyData && !showSurveyView)
        return (
            <div className="building-equipment-sidebar">
                <BuildingEquipmentHeader
                    editSurvey={() => {
                        setShowSurveyView(true);
                        }
                    }
                    buildingName={buildingHeaderData?.buildingName ?? ""}
                />
                {showEquipmentWarnings && !hasNaturalGasWarning && (
                    <MissingNaturalGasHeader />
                )}
                <DataView
                    data={surveyData}
                    showEquipmentWarning={showEquipmentWarnings}
                    hasNaturalGasWarning={hasNaturalGasWarning}
                />
            </div>
        );

    return (
        <div
            className={classNames(
                "building-equipment-sidebar",
                showSurveyView && "building-equipment-sidebar--survey-view"
            )}
        >
            {showSurveyView ? (
                <>
                    <div className="building-equipment-sidebar--survey-view__header">
                        <Header size="medium">Add equipment</Header>
                        <CloseButton onClick={() => {
                            setShowSurveyView(false);
                            posthog.capture('Equipment closed');
                        }} />
                    </div>
                    <Survey setShowSurveyView={setShowSurveyView} />
                </>
            ) : (
                <AddEquipmentPrompt onClickAdd={handleAddEquipmentPrompt} />
            )}
        </div>
    );
}

interface AddEquipmentPromptProps {
    onClickAdd: () => void;
}

function AddEquipmentPrompt({ onClickAdd }: AddEquipmentPromptProps) {
    return (
        <>
            <EmptySidebarView />
            <div className="building-equipment-sidebar__empty-view">
                <Header size="small" style={{ lineHeight: "1.5rem" }}>
                    Add equipment information to improve baseline &
                    recommendations
                </Header>
                <Button
                    id="add-equipment-button"
                    type="primary"
                    onClick={()=>{
                        onClickAdd();
                        posthog.capture('Add-equipment started');
                    }}
                    icon={<AddIcon color="var(--audette-white)" />}
                >
                    Add equipment
                </Button>
            </div>
        </>
    );
}

export default BuildingEquipment;

interface BuildingEquipmentHeaderProps {
    editSurvey: () => void;
    buildingName: string;
}

function BuildingEquipmentHeader({ editSurvey, buildingName }: BuildingEquipmentHeaderProps) {

    const resetButtonOptions = {
        popup: {
            buttonText: "Reset survey",
            icon: <EditIcon />
        },
        modal: {
            title: "Reset survey",
            body:(<>
                This will{" "}
                <span style={{ fontWeight: "bold" }}>
                    permanently remove all equipment data associated with the{" "}
                    {buildingName} building
                </span>
                . Do you wish to proceed?
            </>),
            deleteButtonText: "Reset survey"
        }
    }

    const { buildingModelUid } = useParams();
    if (!buildingModelUid)
        throw new Error("buildingModelUid should be defined");

    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);
    const { onSuccess, onError } = useQueryOnStatusUpdate(buildingModelUid);
    const { mutate: deleteEquipmentData } = useDeleteEquipmentData(
        buildingModelUid,
        () => {
            onSuccess();
            posthog.capture('Equipment reset');
            banner.success("Equipment information reset.");
        },
        onError
    );

    const handleDeleteEquipmentData = () => {
        setLoadingState((prev) => ({
            ...prev,
            equipmentSidebarLoading: true,
            buildingReportsLoading: true,
        }));
        deleteEquipmentData();
    }

    return (
        <div className="building-equipment-header">
            <Header>
                Equipment
            </Header>
            <Button
                icon={<EditIcon color="var(--audette-black)" width="1rem" height="1rem"/>}
                onClick={()=>{
                    editSurvey();
                    posthog.capture('Edit-equipment started');
                }}
                type="secondary"
            >
                Edit
            </Button>
            <ResetButton options={resetButtonOptions} onClick={() => handleDeleteEquipmentData()}/>
        </div>
    );
}

function MissingNaturalGasHeader() {
    return (
        <div className="missing-natural-gas-header">
            <Header
                size="small"
                style={{ color: "#CA8727", display: "flex", gap: "8px" }}
            >
                Missing Natural Gas <WarningIcon />
            </Header>
        </div>
    );
}
