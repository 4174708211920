import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const mutationFn = (
    accessToken: string,
    buildingModelUid: string,
    buildingName: string
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            ...buildBuildingModelRequestVariables(buildingModelUid),
            buildingName,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.updateBuildingName.error)
            throw new Error(data.updateBuildingName.error);
        return buildingName;
    });

const query = graphql(`
    mutation updateBuildingName(
        $buildingModelUid: Uuid!
        $buildingName: String!
    ) {
        updateBuildingName(
            buildingModelUid: $buildingModelUid
            buildingName: $buildingName
        ) {
            error
            success
        }
    }
`);

export default mutationFn;
