import { useEffect, useMemo, useRef, useState } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { centroid, area } from "@turf/turf";

import {
    EventData,
    MapMouseEvent,
    MapboxGeoJSONFeature,
    Map as MapboxglMap,
} from "mapbox-gl";
import { SQUARE_METERS_TO_SQUARE_FEET_MULTIPLIER } from "utils/constants";
import { Header, Paragraph } from "components/atoms/Typography";
import { getDisplayInfo } from "utils/formatting";
import { Delete, ErrorIcon } from "components/atoms/Icon";
import { Button } from "components/atoms/Button";
import classNames from "classnames";
import "./FootprintsMap.scss";

interface FootprintsMapProps {
    address: Address;
    buildingFootprint?: FootprintPolygon;
    buildingFootprints: BuildingFootprints;
    buildingUid?: string;
    isEditing: boolean;
    location?: Location;
    setSelectedFootprintPolygon?: React.Dispatch<
        React.SetStateAction<FootprintPolygon | undefined>
    >;
}

function FootprintsMap({
    address,
    buildingUid,
    buildingFootprints,
    buildingFootprint,
    setSelectedFootprintPolygon,
    location,
    isEditing,
}: FootprintsMapProps) {
    if (buildingFootprints.features.length === 0)
        return <EmptyMap address={address} />;

    const lngLat = useBuildingLngLat(
        buildingFootprints,
        buildingFootprint,
        buildingUid,
        location
    );

    if (!lngLat) return <EmptyMap address={address} />;

    if (isEditing)
        return (
            <EditableFootprintsMap
                address={address}
                buildingUid={buildingUid}
                buildingFootprint={buildingFootprint}
                buildingFootprints={buildingFootprints}
                lngLat={lngLat}
                setSelectedFootprintPolygon={setSelectedFootprintPolygon}
            />
        );

    return (
        <FootprintMap
            buildingUid={buildingUid}
            buildingFootprints={buildingFootprints}
            lngLat={lngLat}
            address={address}
            buildingFootprint={buildingFootprint}
        />
    );
}

interface FootprintMapProps {
    buildingUid?: string;
    buildingFootprints: BuildingFootprints;
    buildingFootprint?: FootprintPolygon | null;
    lngLat: Position;
    address: Address;
}

function FootprintMap({
    buildingUid,
    buildingFootprints,
    buildingFootprint,
    lngLat,
    address,
}: FootprintMapProps) {
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const map = useRef<MapboxglMap | null>(null);

    useEffect(() => {
        if (
            buildingUid &&
            !buildingFootprint &&
            map.current?.getLayer("footprints-fill")
        ) {
            map?.current?.removeLayer("footprints-fill");
            map.current?.addLayer({
                id: "footprints-fill",
                type: "fill",
                source: "footprints",
                paint: {
                    "fill-opacity": 0.3,
                    "fill-outline-color": "#7700FF",
                    "fill-color": "#7700FF",
                },
                filter: ["==", "buildingUid", buildingUid],
            });
        }
    }, [buildingUid]);

    useEffect(() => {
        if (buildingFootprint && map.current?.getLayer("footprint")) {
            map?.current?.removeLayer("footprint");
            map.current?.addLayer({
                id: "footprint",
                type: "fill",
                source: "footprint",
                paint: {
                    "fill-opacity": 0.3,
                    "fill-outline-color": "#7700FF",
                    "fill-color": "#7700FF",
                },
            });
        }
    }, [buildingFootprint]);

    useEffect(() => {
        if (!mapContainer.current || !lngLat) return;
        if (map.current) return;

        map.current = new MapboxglMap({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/satellite-streets-v12",
            center:
                buildingFootprint?.properties.longitude &&
                buildingFootprint?.properties.latitude
                    ? [
                          buildingFootprint.properties.longitude,
                          buildingFootprint.properties.latitude,
                      ]
                    : lngLat,
            zoom: 15,
            projection: {
                name: "mercator",
            },
            minZoom: 15,
            boxZoom: false,
        });

        map.current.on("load", () => {
            map.current?.resize();
            if (buildingUid && !buildingFootprint) {
                if (!map.current?.getSource("footprints")) {
                    map.current!.addSource("footprints", {
                        type: "geojson",
                        promoteId: "buildingUid",
                        data: {
                            type: "FeatureCollection",
                            features: buildingFootprints.features,
                        },
                    });
                }
                if (!map.current?.getLayer("footprints-fill")) {
                    map.current?.addLayer({
                        id: "footprints-fill",
                        type: "fill",
                        source: "footprints",
                        paint: {
                            "fill-opacity": 0.3,
                            "fill-outline-color": "#7700FF",
                            "fill-color": "#7700FF",
                        },
                        filter: ["==", "buildingUid", buildingUid],
                    });
                }
            }
            if (buildingFootprint) {
                if (!map.current?.getSource("footprint")) {
                    map.current!.addSource("footprint", {
                        type: "geojson",
                        promoteId: "buildingUid",
                        data: {
                            type: "FeatureCollection",
                            features: [buildingFootprint],
                        },
                    });
                }
                if (!map.current?.getLayer("footprint")) {
                    map.current?.addLayer({
                        id: "footprint",
                        type: "fill",
                        source: "footprint",
                        paint: {
                            "fill-opacity": 0.3,
                            "fill-outline-color": "#7700FF",
                            "fill-color": "#7700FF",
                        },
                    });
                }
            }
        });
    }, [mapContainer.current, buildingFootprints, lngLat]);

    return (
        <div>
            <div style={{ display: "flex", gap: "8px", marginBottom: "1rem" }}>
                <Header
                    size="small"
                    style={{ color: "var(--audette-gray-500)" }}
                >
                    Address:
                </Header>
                <Paragraph>{address.streetAddress}</Paragraph>
            </div>
            <div style={{ height: "240px" }} ref={mapContainer} />
        </div>
    );
}

interface EditableFootprintsMapProps {
    address: Address;
    buildingUid?: string;
    buildingFootprint?: FootprintPolygon;
    buildingFootprints: BuildingFootprints;
    setSelectedFootprintPolygon?: React.Dispatch<
        React.SetStateAction<FootprintPolygon | undefined>
    >;
    lngLat: Position;
}

export function EditableFootprintsMap({
    buildingUid,
    buildingFootprints,
    address,
    lngLat,
    setSelectedFootprintPolygon,
    buildingFootprint,
}: EditableFootprintsMapProps) {
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const map = useRef<MapboxglMap | null>(null);

    const clickedFootprintBuildingUid = useRef(buildingUid || null);
    const defaultFootprintPolygon = useMemo(
        () => (buildingFootprint ? { ...buildingFootprint } : undefined),
        []
    );

    const [selectDraw, setSelectDraw] = useState<"select" | "draw">("select");

    const [showFootprintsLayer, setShowFootprintsLayer] = useState(true);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [footprintArea, setFootprintArea] = useState<number>();

    const drawRef = useRef<MapboxDraw | null>(null);

    const toggleTrashButtonVisibility = (visible: boolean) => {
        const elements = document.querySelectorAll("#trash-button");

        if (elements.length > 0) {
            (elements[0] as HTMLElement).style.display = visible
                ? "block"
                : "none";
        }
    };

    useEffect(() => {
        if (!showFootprintsLayer && drawRef?.current) {
            toggleTrashButtonVisibility(true);
            drawRef.current.changeMode("draw_polygon");
        } else {
            drawRef.current?.deleteAll();
            toggleTrashButtonVisibility(false);
        }
    }, [showFootprintsLayer]);

    useEffect(() => {
        const visibility = showFootprintsLayer ? "visible" : "none";

        if (mapLoaded) {
            map.current?.setLayoutProperty(
                "footprintsFill",
                "visibility",
                visibility
            );
        }
    }, [showFootprintsLayer, map?.current]);

    useEffect(() => {
        if (!mapContainer.current || map.current) return;

        map.current = intializeMap(
            mapContainer as React.MutableRefObject<HTMLDivElement>,
            lngLat
        );

        map.current.on("load", () => {
            if (!map.current?.getSource("footprints")) {
                map.current!.addSource("footprints", {
                    type: "geojson",
                    promoteId: "buildingUid",
                    data: {
                        type: "FeatureCollection",
                        features: buildingFootprints.features,
                    },
                });
            }

            if (!map.current?.getLayer("footprintsFill")) {
                map.current?.addLayer({
                    id: "footprintsFill",
                    type: "fill",
                    source: "footprints",
                    paint: {
                        "fill-opacity": 0.7,
                        "fill-outline-color": [
                            "case",
                            ["boolean", ["feature-state", "click"], false],
                            "#7700FF",
                            "#FFF",
                        ],
                        "fill-color": [
                            "case",
                            ["boolean", ["feature-state", "click"], false],
                            "#7700FF",
                            "#FFF",
                        ],
                    },
                    layout: {
                        visibility: "visible",
                    },
                });
            }

            if (buildingUid)
                map.current?.setFeatureState(
                    { source: "footprints", id: buildingUid },
                    { click: true }
                );
        });

        map.current?.on(
            "click",
            "footprintsFill",
            (
                e: MapMouseEvent & {
                    features?: MapboxGeoJSONFeature[] | undefined;
                } & EventData
            ) => {
                if (!e.features) return;
                if (e.features.length > 0) {
                    if (clickedFootprintBuildingUid.current) {
                        map.current?.setFeatureState(
                            {
                                source: "footprints",
                                id: clickedFootprintBuildingUid.current,
                            },
                            { click: false }
                        );
                    }
                    const newClickedFootprint = e.features[0];
                    const newClickedFootprintBuildingUid =
                        newClickedFootprint.properties?.buildingUid;
                    clickedFootprintBuildingUid.current =
                        newClickedFootprintBuildingUid;

                    if (setSelectedFootprintPolygon) {
                        const center = centroid(
                            JSON.parse(JSON.stringify(newClickedFootprint))
                        );
                        const centerCoordinates = center.geometry.coordinates;

                        const polygon: FootprintPolygon = {
                            type: "Feature",
                            geometry:
                                newClickedFootprint.geometry as FootprintPolygonGeometry,
                            properties: {
                                buildingUid: newClickedFootprintBuildingUid,
                                latitude: centerCoordinates[1],
                                longitude: centerCoordinates[0],
                            },
                        };
                        setSelectedFootprintPolygon(polygon);
                    }

                    map.current?.setFeatureState(
                        {
                            ...e.features[0],
                            source: "footprints",
                            id: newClickedFootprintBuildingUid || "",
                        },
                        { click: true }
                    );
                }
            }
        );

        map.current?.on("click", () => {
            if (!showFootprintsLayer) {
                drawRef.current?.changeMode("draw_polygon");
            }
        });

        map.current?.on("load", () => {
            const draw: MapboxDraw = new MapboxDraw({
                displayControlsDefault: false,
            });
            map.current?.addControl(draw as any);
            drawRef.current = draw;

            map.current?.on("draw.create", ({ features }: any) => {
                draw.deleteAll();
                draw.add(features[0]);
                if (setSelectedFootprintPolygon) {
                    const center = centroid(
                        JSON.parse(JSON.stringify(features[0]))
                    );
                    const centerCoordinates = center.geometry.coordinates;

                    const polygon: FootprintPolygon = {
                        type: "Feature",
                        geometry: features[0]
                            .geometry as FootprintPolygonGeometry,
                        properties: {
                            buildingUid: null,
                            latitude: centerCoordinates[1],
                            longitude: centerCoordinates[0],
                        },
                    };
                    setSelectedFootprintPolygon(polygon);
                }
            });

            const updateArea = () => {
                const data = draw.getAll();
                if (data.features.length > 0) {
                    const areaInSquareMeters = area(data);
                    const areaInSquareFeet =
                        areaInSquareMeters *
                        SQUARE_METERS_TO_SQUARE_FEET_MULTIPLIER;
                    setFootprintArea(areaInSquareFeet);
                } else setFootprintArea(undefined);
            };

            map.current?.on("draw.create", updateArea);
            map.current?.on("draw.update", updateArea);

            setMapLoaded(true);
        });
    }, [mapContainer.current, buildingFootprints, lngLat]);

    const onClickDeletePolygon = () => {
        drawRef.current?.deleteAll();
        drawRef.current?.changeMode("draw_polygon");
        setFootprintArea(undefined);
        if (setSelectedFootprintPolygon) setSelectedFootprintPolygon(undefined);
    };

    return (
        <div style={{ display: "grid", gap: "16px" }}>
            <MapAddress address={address} />
            <div style={{ height: "372px" }} ref={mapContainer}>
                <div className="map-widgets">
                    <div
                        id="footprint-area-container"
                        style={{ display: footprintArea ? "flex" : "none" }}
                    >
                        <Header size="small">
                            {footprintArea &&
                                Math.round(footprintArea).toLocaleString()}{" "}
                            ft²
                        </Header>
                    </div>
                    <button
                        type="button"
                        id="trash-button"
                        data-testid="map-draw-mode-trash-button"
                        onClick={onClickDeletePolygon}
                    >
                        <Delete color="var(--audette-black)" />
                    </button>
                </div>
            </div>

            <div
                style={{
                    color: "var(--audette-gray-600)",
                }}
            >
                {selectDraw === "select" ? (
                    <Paragraph
                        size="small"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        Click on the shape to select
                    </Paragraph>
                ) : (
                    <Paragraph size="small">
                        <ol
                            style={{
                                marginBlockStart: "0",
                                marginBlockEnd: "0",
                                paddingInlineStart: "1em",
                            }}
                        >
                            <li>
                                Click on the map to start drawing the footprint
                            </li>
                            <li>Double click when you draw the final point</li>
                            <li>
                                To delete and restart, click the trash can icon
                            </li>
                        </ol>
                    </Paragraph>
                )}
            </div>
            <div
                className="footprints-map-select-draw-tabs"
                style={{ display: "flex" }}
            >
                <SelectedViewTab
                    title="Select"
                    onSelect={() => {
                        setShowFootprintsLayer(true);
                        setSelectDraw("select");
                        if (setSelectedFootprintPolygon)
                            setSelectedFootprintPolygon(
                                defaultFootprintPolygon
                            );
                        setFootprintArea(undefined);
                    }}
                    selected={selectDraw === "select"}
                />
                <SelectedViewTab
                    title="Draw"
                    onSelect={() => {
                        setShowFootprintsLayer(false);
                        setSelectDraw("draw");
                        if (setSelectedFootprintPolygon)
                            setSelectedFootprintPolygon(undefined);
                    }}
                    selected={selectDraw === "draw"}
                />
            </div>
        </div>
    );
}

interface SelectedViewTabProps {
    // TO: add to design system (as well as from project page)
    selected: boolean;
    title: string;
    onSelect: () => void;
}

function SelectedViewTab({ selected, title, onSelect }: SelectedViewTabProps) {
    return (
        <Button
            className={classNames("select-view-tab", selected && "selected")}
            onClick={onSelect}
        >
            {title}
        </Button>
    );
}

const useBuildingLngLat = (
    buildingFootprints: BuildingFootprints,
    buildingFootprint?: FootprintPolygon,
    buildingUid?: string,
    location?: Location
): Position | null =>
    useMemo(() => {
        let lng: number | undefined;
        let lat: number | undefined;

        if (buildingFootprint) {
            lng = buildingFootprint.properties.longitude;
            lat = buildingFootprint.properties.latitude;
        } else {
            const selected = buildingFootprints.features.find(
                (feature) => feature.properties.buildingUid === buildingUid
            );
            if (selected) {
                lng = selected.properties.longitude;
                lat = selected.properties.latitude;
            } else if (location) {
                lng = location.longitude;
                lat = location.latitude;
            }
        }

        if (!lng || !lat) return null;
        return [lng, lat];
    }, [buildingFootprints, buildingFootprint, buildingUid, location]);

interface EmptyMapProps {
    address: Address;
}

function EmptyMap({ address }: EmptyMapProps) {
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const map = useRef<MapboxglMap | null>(null);

    const centerOfCanada: Position = [-104.94437324455755, 39.71107740126333];

    useEffect(() => {
        if (!mapContainer.current || map.current) return;

        map.current = intializeMap(
            mapContainer as React.MutableRefObject<HTMLDivElement>,
            centerOfCanada
        );
        map.current?.on("load", () => {
            map.current?.resize();
        });
    }, [mapContainer.current]);

    return (
        <div style={{ display: "grid", gap: "16px" }}>
            <MapAddress address={address} error={true} />
            <div style={{ height: "240px" }} ref={mapContainer} />
        </div>
    );
}

interface MapAddressProps {
    address: Address;
    error?: boolean;
}

export function MapAddress({ address, error = false }: MapAddressProps) {
    const { formatFunction } = getDisplayInfo("location");
    return (
        <div
            style={{
                display: "grid",
                gap: "16px",
            }}
        >
            <Header
                size="small"
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
                Address {error && <ErrorIcon color="var(--audette-gray-400)" />}
            </Header>

            <Paragraph
                style={{
                    color: "var(--audette-gray-600)",
                }}
            >
                {formatFunction(address)}
            </Paragraph>
        </div>
    );
}

const intializeMap = (
    mapContainer: React.MutableRefObject<HTMLDivElement>,
    lngLat: Position
) =>
    new MapboxglMap({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        center: lngLat,
        zoom: 15,
        projection: {
            name: "mercator",
        },
        boxZoom: false,
        minZoom: 15,
    });

export default FootprintsMap;
