import { graphql } from "gql";
import request from "graphql-request";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationCreateCustomerAccountFn = (
    accessToken: string, 
    data: {
        name: string,
        currencyCode: string
    }
) =>

    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: {
            name: data.name,
            currencyCode: data.currencyCode
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data) {
            return data;  
        }
        throw new Error("Error adding user");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

    const mutationQuery = graphql(`
        mutation createCustomerAccount ($name: String!, $currencyCode: String!) {
            createCustomerAccount(name: $name, currencyCode: $currencyCode)
        }
    `);

export default mutationCreateCustomerAccountFn;