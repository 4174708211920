import { assembleBuildingAddress } from "utils/formatting";
import { useBuildingBarChartAndMap } from "queries/Reports/Baseline/useBuildingBarChartAndMap";
import { useBuildingBarChartAndMapByProperty } from "queries/Reports/Baseline/useBuildingBarChartAndMapByProperty";
import { useMemo } from "react";

const useBuildingBarChartAndMapByGroup = (
    groupByMetric: GroupByMetric | undefined
) => {
    const {
        data: buildingData,
        isLoading: buildingIsLoading,
        error: buildingError,
    } = useBuildingBarChartAndMap();
    const {
        data: propertiesData,
        isLoading: propertiesIsLoading,
        error: propertiesError,
    } = useBuildingBarChartAndMapByProperty();

    return useMemo(() => {
        if (groupByMetric === "property") {
            return {
                data: convertByPropertyData(propertiesData),
                isLoading: propertiesIsLoading,
                error: propertiesError,
            };
        }

        return {
            data: convertByBuildingData(buildingData),
            isLoading: buildingIsLoading,
            error: buildingError,
        };
    }, [
        buildingData,
        buildingIsLoading,
        buildingError,
        propertiesData,
        propertiesIsLoading,
        propertiesError,
        groupByMetric,
    ]);
};

const convertByPropertyData = (
    data: BuildingBarChartAndMapByProperty[] | undefined
) => {
    if (data === undefined) return undefined;
    return data.map((item) => ({
        annualCarbonEmissionIntensityCurrent:
            item.averageAnnualCarbonEmissionIntensityCurrent,
        annualCarbonEmissionsCurrent: item.totalAnnualCarbonEmissionsCurrent,
        annualEnergyConsumptionCurrent:
            item.totalAnnualEnergyConsumptionCurrent,
        annualEnergyUseIntensityCurrent:
            item.averageAnnualEnergyUseIntensityCurrent,
        latitude: item.latitude,
        longitude: item.longitude,
        label: item.propertyName,
    }));
};

const convertByBuildingData = (data: BuildingBarChartAndMap[] | undefined) => {
    if (data === undefined) return undefined;
    return data.map((item) => ({
        annualCarbonEmissionIntensityCurrent:
            item.annualCarbonEmissionIntensityCurrent,
        annualCarbonEmissionsCurrent: item.annualCarbonEmissionsCurrent,
        annualEnergyConsumptionCurrent: item.annualEnergyConsumptionCurrent,
        annualEnergyUseIntensityCurrent: item.annualEnergyUseIntensityCurrent,
        buildingModelUid: item.buildingModelUid,
        latitude: item.location.latitude,
        longitude: item.location.longitude,
        label: assembleBuildingAddress(item.location),
    }));
};

export default useBuildingBarChartAndMapByGroup;
