import { Header, Paragraph } from "components/atoms/Typography";
import { getDisplayInfo } from "utils/formatting";
import { EmissionsIcon } from "components/atoms/Icon";
import { UseQueryResult } from "react-query";
import "./CumulativeEmissionSavingsCard.css";

interface CumulativeEmissionSavingsCardProps {
    query: () => UseQueryResult<CumulativeEmissionSavingsCardData>;
}

function CumulativeEmissionSavingsCard({
    query,
}: CumulativeEmissionSavingsCardProps) {
    const { data, error, isLoading } = query();
    const { formatFunction, unit } = getDisplayInfo(
        "carbonEmissions",
        data?.totalCarbonEmissionSavings || 0
    );

    if (error) return null;
    if (data === null) return null;
    if (isLoading || !data) return <>Loading...</>;

    const carbonEmissionSavings = formatFunction(
        data.totalCarbonEmissionSavings
    );
    const carbonEmissionSavingsFromMeasures = formatFunction(
        data.totalCarbonEmissionSavingsFromMeasures
    );

    const carbonEmissionSavingsFromGrid = formatFunction(
        data.totalCarbonEmissionSavingsFromGrid
    );

    return (
        <div className="cumulative-emission-savings-card">
            <div>
                <Header
                    size="small"
                    weight="bold"
                    style={{
                        color: "var(--audette-content-positive)",
                        display: "inline",
                    }}
                >
                    <EmissionsIcon /> {carbonEmissionSavings} {unit}{" "}
                </Header>
                <Paragraph
                    style={{
                        display: "inline",
                        color: "var(audette-gray-600)",
                    }}
                >
                    total cumulative carbon emissions saved{" "}
                </Paragraph>
            </div>
            <div
                className="savings-breakdown"
                style={{ color: "var(--audette-gray-500)" }}
            >
                <Paragraph size="small">
                    {carbonEmissionSavingsFromMeasures} {unit} in emissions
                    savings from decarbonization measures{" "}
                </Paragraph>
                <Paragraph size="small">
                    {carbonEmissionSavingsFromGrid} {unit} in grid savings
                </Paragraph>
            </div>
        </div>
    );
}

export default CumulativeEmissionSavingsCard;
