import Tooltip from "components/atoms/Tooltip";
import { Header, Paragraph } from "components/atoms/Typography";
import "./DataCoverageLine.scss";

interface DataCoverageLineProps {
    map: Map<DataCoverageEnum | "total", number>;
}

function DataCoverageLine({ map }: DataCoverageLineProps) {
    const getCount = (key: DataCoverageEnum) => map.get(key) || 0;

    const yellowCount =
        getCount("equipment_and_utility_warning") +
        getCount("utility_warning") +
        getCount("equipment_warning");

    const greenCount = getCount("equipment_and_utility");

    const purpleCount = getCount("utility") + getCount("equipment");

    const redCount = getCount("error");

    const total = map.get("total") || 1;

    const purplePercent = (100 * purpleCount) / total;
    const yellowPercent = (100 * yellowCount) / total;
    const redPercent = (100 * redCount) / total;
    const greenPercent = (100 * greenCount) / total;

    return (
        <Tooltip tooltipBody={<DataCoverageLineTooltip map={map} />}>
            <div className="data-coverage-line">
                <div
                    className="segment green"
                    style={{ width: `${greenPercent}%` }}
                />
                <div
                    className="segment purple"
                    style={{ width: `${purplePercent}%` }}
                />
                <div
                    className="segment yellow"
                    style={{ width: `${yellowPercent}%` }}
                />
                <div
                    className="segment red"
                    style={{ width: `${redPercent}%` }}
                />
            </div>
        </Tooltip>
    );
}

function DataCoverageLineTooltip({ map }: DataCoverageLineProps) {
    const getCount = (key: DataCoverageEnum) => map.get(key) || 0;

    const yellowCount =
        getCount("equipment_and_utility_warning") +
        getCount("utility_warning") +
        getCount("equipment_warning");

    const greenCount = getCount("equipment_and_utility");

    const purpleCount = getCount("utility") + getCount("equipment");

    const greyCount =
        getCount("no_equipment_and_no_utility") + getCount("processing");

    const redCount = getCount("error");

    return (
        <div className="data-coverage-line-tooltip">
            <Header size="x-small" style={{ color: "var(--audette-gray-500)" }}>
                Data coverage
            </Header>

            <div>
                <div className="color-block color-block--green" />
                <Header size="x-small">Energy & equipment added</Header>
                <Paragraph>{greenCount}</Paragraph>
            </div>
            <div>
                <div className="color-block color-block--purple" />
                <Header size="x-small">Energy or equipment added</Header>
                <Paragraph>{purpleCount}</Paragraph>
            </div>
            <div>
                <div className="color-block color-block--yellow" />
                <Header size="x-small">Incomplete energy or equipment</Header>
                <Paragraph>{yellowCount}</Paragraph>
            </div>
            <div>
                <div className="color-block color-block--red" />
                <Header size="x-small">Failed modelling</Header>
                <Paragraph>{redCount}</Paragraph>
            </div>
            <div>
                <div className="color-block color-block--grey" />
                <Header size="x-small">No data</Header>
                <Paragraph>{greyCount}</Paragraph>
            </div>
        </div>
    );
}

export default DataCoverageLine;
