import { useMemo } from "react";
import Card from "components/molecules/Card";
import { calculateRoundedPercentageFromRatio } from "utils/formatting";

interface FutureProjectionCardProps {
    data: any;
    cumulative: boolean;
    loading: boolean;
    error?: string;
}

export function FutureProjectionCarbonEmissionsCard({
    data,
    cumulative,
    loading,
    error,
}: FutureProjectionCardProps) {
    const cardData = useMemo(() => {
        if (!data) return undefined;

        const {
            totalCarbonEmissions,
            totalCarbonEmissionsElectricity,
            totalCarbonEmissionsNaturalGas,
        } = data;

        return {
            header: {
                title: cumulative
                    ? "Total cumulative carbon emissions"
                    : undefined,
                value: totalCarbonEmissions,
                displayInfoKey: "carbonEmissions",
            },
            sections: [
                [
                    {
                        value: totalCarbonEmissionsNaturalGas,
                        displayInfoKey: "carbonEmissions",
                        title: "Natural gas",
                        percentage: calculateRoundedPercentageFromRatio(
                            totalCarbonEmissionsNaturalGas /
                                totalCarbonEmissions
                        ),
                    },
                    {
                        value: totalCarbonEmissionsElectricity,
                        displayInfoKey: "carbonEmissions",
                        title: "Electricity",
                        percentage: calculateRoundedPercentageFromRatio(
                            totalCarbonEmissionsElectricity /
                                totalCarbonEmissions
                        ),
                    },
                ],
            ],
        };
    }, [data]);

    return <Card data={cardData} loading={loading} error={error} />;
}

export function FutureProjectionEnergyConsumptionCard({
    data,
    cumulative,
    loading,
    error,
}: FutureProjectionCardProps) {
    const cardData = useMemo(() => {
        if (!data) return undefined;

        const {
            totalEnergyConsumption,
            totalEnergyConsumptionElectricity,
            totalEnergyConsumptionNaturalGas,
        } = data;

        return {
            header: {
                title: cumulative
                    ? "Total cumulative energy consumption"
                    : undefined,
                value: totalEnergyConsumption,
                displayInfoKey: "energyConsumption",
            },
            sections: [
                [
                    {
                        value: totalEnergyConsumptionNaturalGas,
                        displayInfoKey: "energyConsumption",
                        title: "Natural gas",
                        percentage: calculateRoundedPercentageFromRatio(
                            totalEnergyConsumptionNaturalGas /
                                totalEnergyConsumption
                        ),
                    },
                    {
                        value: totalEnergyConsumptionElectricity,
                        displayInfoKey: "energyConsumption",
                        title: "Electricity",
                        percentage: calculateRoundedPercentageFromRatio(
                            totalEnergyConsumptionElectricity /
                                totalEnergyConsumption
                        ),
                    },
                ],
            ],
        };
    }, [data]);

    return <Card data={cardData} loading={loading} error={error} />;
}
