import { useMemo, useState } from "react";
import { ActiveView, DEFAULT_TERMINAL_COOLER, DEFAULT_TERMINAL_HEATER } from "../Survey.constants";
import { buildTerminalUnitSurveyUIStruct, surveyValueExists, updatePageStackAndViewOnBackClick, updatePageStackOnNextClick } from "../Survey.helpers";
import SurveyView from "../SurveyElements/Survey/SurveyView";
import { useNextButtonDisabled } from "../Survey.hooks";
import { TerminalUnits } from "../SurveyElements/Equipment/BuildingEquipment.constants";

interface TerminalUnitsSurveyViewProps {
    terminalHeater: TerminalHeater;
    terminalCooler: TerminalCooler;
    setTerminalCooler: React.Dispatch<React.SetStateAction<TerminalCooler>>;
    setTerminalHeater: React.Dispatch<React.SetStateAction<TerminalHeater>>;
    airHandlingEquipment: AirHandlingEquipment;
    centralPlantCooler: CentralPlantCooler;
    centralPlantHeater: CentralPlantHeater;
    roofTopUnit: RooftopUnit;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function TerminalUnitsSurveyView({
    terminalHeater,
    terminalCooler,
    setTerminalHeater,
    setTerminalCooler,
    airHandlingEquipment,
    centralPlantCooler,
    centralPlantHeater,
    roofTopUnit,
    setActiveView,
    activeView,
    pageStack,
    setPageStack,
}: TerminalUnitsSurveyViewProps) {
    const [terminalUnits, setTerminalUnits] = useState({
        ...terminalCooler,
        ...terminalHeater,
    });

    const structure = useMemo(
        () =>
            buildTerminalUnitSurveyUIStruct(
                airHandlingEquipment,
                centralPlantHeater,
                centralPlantCooler,
                terminalUnits as TerminalCooler,
                terminalUnits as TerminalHeater,
                roofTopUnit
            ),
        [terminalUnits]
    );

    const nextButtonDisabled = useNextButtonDisabled(structure, terminalUnits);

    const handleUpdateTerminalUnits = (
        func: (prev: TerminalUnits) => TerminalUnits
    ) => {
        setTerminalUnits((prev) => {
            const newValue = func(prev);
            const struct = buildTerminalUnitSurveyUIStruct(
                airHandlingEquipment,
                centralPlantHeater,
                centralPlantCooler,
                // note: build the struct with the current terminal units state
                terminalUnits as TerminalCooler,
                terminalUnits as TerminalHeater,
                roofTopUnit
            );
            struct.forEach((item) => {
                if (item.key !== null && !item.show) newValue[item.key] = null;
            });
            return newValue;
        });
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        setActiveView(ActiveView.domesticHotWaterHeater);
        setTerminalCooler((prev) => {
            const copy = { ...prev };
            Object.keys(terminalCooler).forEach((key) => {
                copy[key as keyof TerminalCooler] =
                    terminalUnits[key as keyof TerminalCooler];
            });
            return {
                ...copy,
                terminalCoolerExists: surveyValueExists(
                    copy.terminalCoolerUnits
                ),
            };
        });
        setTerminalHeater((prev) => {
            const copy = { ...prev };
            Object.keys(terminalHeater).forEach((key) => {
                copy[key as keyof TerminalHeater] =
                    terminalUnits[key as keyof TerminalHeater];
            });
            return {
                ...copy,
                terminalHeaterExists: surveyValueExists(
                    copy.terminalHeaterUnits
                ),
            };
        });
    };

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setTerminalCooler(DEFAULT_TERMINAL_COOLER);
        setTerminalHeater(DEFAULT_TERMINAL_HEATER);
    };

    return (
        <SurveyView
            structure={structure}
            setSurveyPageData={handleUpdateTerminalUnits}
            handleNextClick={handleNextClick}
            surveyPageData={terminalUnits}
            handleBackClick={handleBackClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

export default TerminalUnitsSurveyView;