import { createPortal } from "react-dom";
import useDownloadState from "recoilStore/useDownloadState";
import "./DownloadBanner.scss";
import { Header, Paragraph } from "components/atoms/Typography";
import { useEffect, useRef, useState } from "react";
import { Button } from "components/atoms/Button";
import { Check, CloseButton, ErrorIcon } from "components/atoms/Icon";
import { useExportBuildings } from "mutations/useExportBuildings";
import { getExportBuildingsFileName } from "./DownloadBanner.helpers";

function DownloadBanner() {
    const { state } = useDownloadState();

    if (!state.showBanner) return null;

    return createPortal(<Banner />, document.getElementById("root")!);
}

export function Banner() {
    const progress = useRef(0);

    const { state, setState, resetStateToDefault } = useDownloadState();

    const { downloadComplete } = state;

    const [width, setWidth] = useState<string>();
    const interval = useRef<NodeJS.Timer | null>(null);

    const { mutate: downloadFile } = useExportBuildings();

    const fileName = getExportBuildingsFileName();

    const handleClose = () => {
        resetStateToDefault();
    };

    useEffect(() => {
        const loadingBar =
            document.querySelector<HTMLDivElement>(".loading-bar");

        interval.current = setInterval(() => {
            if (progress.current > 90 && interval.current) return;
            if (loadingBar) setWidth(`${Math.min(progress.current, 100)}%`);
            progress.current += 2;
        }, 1000);

        return () => {
            if (interval.current !== null) clearInterval(interval.current);
        };
    }, []);

    useEffect(() => {
        let showTimeoutId: string | number | NodeJS.Timeout | undefined;
        let bannerTimeoutId: string | number | NodeJS.Timeout | undefined;

        if (downloadComplete) {
            progress.current = 100;
            setWidth(`${Math.min(progress.current, 100)}%`);

            bannerTimeoutId = setTimeout(() => {
                if (interval.current !== null) clearInterval(interval.current);
                setState((prev) => ({ ...prev, downloadSuccess: true }));
            }, 1000);

            showTimeoutId = setTimeout(() => {
                resetStateToDefault();
            }, 5000);
        }
        return () => {
            // cleanup
            if (showTimeoutId) clearTimeout(showTimeoutId);
            if (bannerTimeoutId) clearTimeout(bannerTimeoutId);
        };
    }, [downloadComplete]);

    const onCancel = () => {
        setState((prev) => ({
            ...prev,
            showBanner: false,
            downloadCancelled: true,
        }));
    };

    if (state.downloadSuccess) {
        return (
            <div className="download-banner">
                <div className="download-banner__header">
                    <Check />
                    <Header
                        size="x-small"
                        style={{ color: "var(--audette-content-positive)" }}
                    >
                        Export complete
                    </Header>
                    <CloseButton onClick={handleClose} />
                </div>
            </div>
        );
    }

    const handleTryAgain = () => {
        progress.current = 0;
        setWidth("0");
        setState((prev) => ({
            ...prev,
            showBanner: true,
            downloadInProgress: true,
            downloadError: false,
        }));
        downloadFile();
    };

    if (state.downloadError)
        return (
            <div className="download-banner">
                <div className="download-banner__header">
                    <ErrorIcon />
                    <Header size="x-small">Export failed</Header>
                    <CloseButton onClick={handleClose} />
                </div>
                <div className="download-banner__file-name">
                    <Paragraph
                        size="small"
                        style={{ color: "var(--audette-gray-500)" }}
                    >
                        {fileName}
                    </Paragraph>
                    <Button onClick={handleTryAgain} type="link">
                        Try again
                    </Button>
                </div>
            </div>
        );

    return (
        <div className="download-banner">
            {state.downloadInProgress && (
                <Header size="x-small">Export in progress...</Header>
            )}
            <div className="download-banner__file-name">
                <Paragraph
                    size="small"
                    style={{ color: "var(--audette-gray-500)" }}
                >
                    {fileName}
                </Paragraph>
                <Button onClick={onCancel} type="link">
                    Cancel
                </Button>
            </div>

            <div className="loading-bar-container">
                <div style={{ width }} className="loading-bar" />
            </div>
        </div>
    );
}

export default DownloadBanner;
