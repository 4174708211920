import { useState } from "react";

// components
import { Header } from "components/atoms/Typography";
import ChartContainer from "components/molecules/ChartContainer";
import { ChartErrorState } from "components/molecules/ErrorStates";
import CumulativeOperatingCostCard from "components/organisms/Charts/Recommendations/CumulativeOperatingCostCard";
import AnnualCashFlowChart from "components/organisms/Charts/Recommendations/AnnualCashFlowChart";
import AnnualEmissionSavingsByMeasureTable from "components/organisms/Charts/Recommendations/AnnualEmissionSavingsByMeasureTable";
import AverageMarginalAbatementCostByMeasureCategoryChart from "components/organisms/Charts/AverageMarginalAbatementCostByMeasureCategoryChart";
import CumulativeInvestmentByDecarbonizationChart from "components/organisms/Charts/Recommendations/CumulativeInvestmentByDecarbonizationChart";
import FutureProjectionCards from "components/organisms/Charts/Recommendations/FutureProjectionCards";
import AnnualCarbonEmissionsChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsChart";
import AnnualCarbonEmissionsNaturalGasChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsNaturalGasChart";
import AnnualCarbonEmissionsElectricityChart from "components/organisms/Charts/Recommendations/AnnualCarbonEmissionsElectricityChart";
// import AnnualEndUse2050Chart from "components/organisms/Charts/Recommendations/AnnualEndUse2050Chart";

// query hooks
import { useAnnualCashFlowChart } from "queries/Reports/Recommendations/useAnnualCashFlowChart";
import { useCumulativeOperatingCostCard } from "queries/Reports/Recommendations/useCumulativeOperatingCostCard";
import { useAnnualCarbonEmissionsChart } from "queries/Reports/Recommendations/useAnnualCarbonEmissionsChart";
import { useAnnualCarbonEmissionsElectricityChart } from "queries/Reports/Recommendations/useAnnualCarbonEmissionsElectricityChart";
import { useAnnualCarbonEmissionsNaturalGasChart } from "queries/Reports/Recommendations/useAnnualCarbonEmissionsNaturalGasChart";
import { useCumulativeEmissionSavingsCard } from "queries/Reports/Recommendations/useCumulativeEmissionSavingsCard";
import { useAnnualCarbonEmissionsCard } from "queries/Reports/Recommendations/useAnnualCarbonEmissionsCard";
import { useAnnualEnergyConsumptionCard } from "queries/Reports/Recommendations/useAnnualEnergyConsumptionCard";
import { useAnnualEmissionSavingsByMeasureTable } from "queries/Reports/Recommendations/useAnnualEmissionSavingsByMeasureTable";
// import { useAnnualEndUse2050Chart } from "queries/Reports/Recommendations/useAnnualEndUse2050Chart";
import { useAnnualEmissionSavingsByMeasureChart } from "queries/Reports/Recommendations/useAnnualEmissionSavingsByMeasureChart";
import { useCumulativeInvestmentByDecarbonizationChart } from "queries/Reports/Recommendations/useCumulativeInvestmentByDecarbonizationChart";
import { useAverageMarginalAbatementCostByMeasureCategoryChart } from "queries/Reports/Recommendations/useAverageMarginalAbatementCostByMeasureCategoryChart";
import { useCumulativeNetPresentValueCard } from "queries/Reports/Recommendations/useCumulativeNetPresentValueCard";
import AnnualEmissionSavingsByMeasureChart from "components/organisms/Charts/Recommendations/AnnualEmissionSavingsByMeasureChart";
import CumulativeNetPresentValueCard from "components/organisms/Charts/Recommendations/CumulativeNetPresentValueCard";

// styling
import "./DecarbonizationReport.css";
import CumulativeEmissionSavingsCard from "components/organisms/Charts/Recommendations/CumulativeEmissionSavingsCard";
import { useCumulativeCarbonEmissionsCard } from "queries/Reports/Recommendations/useCumulativeCarbonEmissionsCard";
import { useCumulativeEnergyConsumptionCard } from "queries/Reports/Recommendations/useCumulativeEnergyConsumptionCard";
import { ChartNoDataState } from "components/molecules/NoDataState";
import { CURRENT_YEAR } from "utils/constants";

function DecarbonizationReport() {
    return (
        <div className="recommendations-report">
            <Header>Reach your goal</Header>
            <AnnualEmissionSavingsByMeasures />
            <Header>Financial impact ({CURRENT_YEAR} - 2050)</Header>
            <div className="cards-and-chart-grid">
                <div className="cards">
                    <CumulativeNetPresentValueCard
                        query={useCumulativeNetPresentValueCard}
                    />
                    <CumulativeOperatingCostCard
                        query={useCumulativeOperatingCostCard}
                    />
                </div>
                <AnnualCashFlowChart query={useAnnualCashFlowChart} />
            </div>
            <div className="side-by-side-charts-grid">
                <AverageMarginalAbatementCostByMeasureCategoryChart
                    query={
                        useAverageMarginalAbatementCostByMeasureCategoryChart
                    }
                />
                <CumulativeInvestmentByDecarbonizationChart
                    query={useCumulativeInvestmentByDecarbonizationChart}
                />
            </div>
            <Header>Future projection</Header>
            <div className="cards-and-chart-grid">
                <FutureProjectionCards
                    cumulativeCarbonEmissionsCardQuery={
                        useCumulativeCarbonEmissionsCard
                    }
                    cumulativeEnergyConsumptionCardQuery={
                        useCumulativeEnergyConsumptionCard
                    }
                    annualCarbonEmissionsCardQuery={
                        useAnnualCarbonEmissionsCard
                    }
                    annualEnergyConsumptionCardQuery={
                        useAnnualEnergyConsumptionCard
                    }
                />
                <AnnualCarbonEmissionsChart
                    query={useAnnualCarbonEmissionsChart}
                />
            </div>
            <div className="side-by-side-charts-grid">
                <AnnualCarbonEmissionsNaturalGasChart
                    query={useAnnualCarbonEmissionsNaturalGasChart}
                />
                <AnnualCarbonEmissionsElectricityChart
                    query={useAnnualCarbonEmissionsElectricityChart}
                />
            </div>
            {/* <AnnualEndUse2050Chart query={useAnnualEndUse2050Chart} /> */}
        </div>
    );
}

function AnnualEmissionSavingsByMeasures() {
    const [showChart, setShowChart] = useState(true);
    const {
        isLoading: chartIsLoading,
        error: chartError,
        data: chartData,
    } = useAnnualEmissionSavingsByMeasureChart();
    const {
        isLoading: tableIsLoading,
        error: tableError,
        data: tableData,
    } = useAnnualEmissionSavingsByMeasureTable();

    if (chartError || tableError) return <ChartErrorState />;
    if (chartData === null || tableData === null) return <ChartNoDataState />;

    return (
        <ChartContainer
            title="Recommended decarbonization measure types"
            loading={chartIsLoading || tableIsLoading}
            onClickTable={() => setShowChart((show) => !show)}
        >
            {showChart ? (
                <>
                    <AnnualEmissionSavingsByMeasureChart
                        query={useAnnualEmissionSavingsByMeasureChart}
                    />
                    <CumulativeEmissionSavingsCard
                        query={useCumulativeEmissionSavingsCard}
                    />
                </>
            ) : (
                <AnnualEmissionSavingsByMeasureTable
                    query={useAnnualEmissionSavingsByMeasureTable}
                />
            )}
        </ChartContainer>
    );
}

export default DecarbonizationReport;
