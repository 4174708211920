/* eslint-disable max-len */
import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.buildingModelReports.error)
            throw new Error(data.buildingModelReports.error);
        else
            return data.buildingModelReports.recommendationsReport
                ?.annualEmissionSavingsByMeasureChart as AnnualEmissionSavingsByMeasureChartData;
    });

const query = graphql(`
    query buildingModelRecommendationsAnnualEmissionSavingsByMeasureChart(
        $buildingModelUid: Uuid!
    ) {
        buildingModelReports(buildingModelUid: $buildingModelUid) {
            error
            recommendationsReport {
                annualEmissionSavingsByMeasureChart {
                    carbonEmissionSavingsFromGrid
                    carbonReductionMeasureCategories {
                        carbonReductionMeasureCategory
                        totalCarbonEmissionSavings
                    }
                    remainingCarbonEmissions
                    year
                }
            }
        }
    }
`);

export default queryFn;
