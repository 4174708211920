import { atom, useRecoilState } from "recoil";

interface UniqueTagsState {
    tags: string[];
}

const uniqueTagsState = atom<UniqueTagsState>({
    key: "uniqueTagsState",
    default: {
        tags: [],
    },
});

const useUniqueTags = () => {
    const [tags, setTags] = useRecoilState(uniqueTagsState);
    return {
        tags,
        setTags,
    };
};

export default useUniqueTags;
