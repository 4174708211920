import { BACKEND_DOMAIN } from "utils/constants";
import { request } from "graphql-request";
import { graphql } from "gql/gql";
import { buildRequestHeaders } from "utils/helpers";
import { BatchedRemodelBuildingModelsMutation } from "gql/graphql";

const mutationFn = (
  accessToken: string, buildingModelUids: string[]
) => request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            buildingModelUids,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data: BatchedRemodelBuildingModelsMutation) => {
        const errors = data.batchedRemodelBuildingModels
            .map((response) => response.error)
            .filter((error) => error !== null && error !== undefined);

        if (errors.length > 0) {
            const errorString = errors.map((error) => `• ${error}`).join("\n");
            throw new Error(errorString);
        }
        return data.batchedRemodelBuildingModels;
    });

const query = graphql(`
    mutation batchedRemodelBuildingModels($buildingModelUids: [Uuid!]!) {
        batchedRemodelBuildingModels(buildingModelUids: $buildingModelUids) {
            error
            success
        }
    }
`);

export default mutationFn;
