import { useEffect, useMemo, useState } from "react";
import FormInput from "components/molecules/FormInput";
import { EquipmentAndInstallationYearSurveyItem } from "../../Survey.helpers";

interface SurveyNumberInputProps<T> {
    state: T;
    setState: React.Dispatch<React.SetStateAction<T>>;
    item: EquipmentAndInstallationYearSurveyItem<T>;
}

function SurveyNumberInput<T>({
    state,
    setState,
    item,
}: SurveyNumberInputProps<T>) {
    const { label, key, unit } = item;

    const [error, setError] = useState<boolean>();

    const setValue = (value: string | number | null) => {
        setState((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    useEffect(() => {
        if (error) setValue(null);
    }, [error]);

    const value = useMemo(() => {
        if (typeof state[key] === "number") {
            return state[key] as number;
        }
        return undefined;
    }, [state]);

    return (
        <FormInput
            setValue={(v) => (v ? setValue(v) : null)}
            value={value || ""}
            attributeKey={key.toString()}
            displayInfo={{
                humanReadable: label,
                unit,
                isValidFn: (value: string | number) => !!value,
                placeholder: item.placeholder,
                errorMessage: "Cannot be empty",
            }}
            type="number"
            setIsError={setError}
        />
    );
}

export default SurveyNumberInput;