import BarChart, { CHART_COLORS } from "components/molecules/Charts/BarChart";
import ChartContainer from "components/molecules/ChartContainer";
import { getDisplayInfo } from "utils/formatting";
import { useMemo } from "react";
import { ChartErrorState } from "components/molecules/ErrorStates";
import { ChartNoDataState } from "components/molecules/NoDataState";

export interface EndUseStackedBarChartDatum {
    [endUse: string]: number;
}
interface EndUseStackedBarChartProps {
    data?: EndUseStackedBarChartDatum[];
    loading?: boolean;
    error?: string;
    fuelTypeKey: "naturalGas" | "electricity";
}

function EndUseStackedBarChart({
    data = [],
    loading = false,
    fuelTypeKey,
    error,
}: EndUseStackedBarChartProps) {
    const dataAndKeys = useMemo(() => {
        if (!data) return undefined;

        const keys = new Set();
        const chartData = data.map((datum) => {
            const newMap: { [key: string]: number } = {};
            Object.keys(datum).forEach((key) => {
                if (datum[key] !== undefined && datum[key] !== null) {
                    const formattedKey =
                        key === "year"
                            ? "year"
                            : endUseValueToDisplayLabelMap[
                                  key.toLocaleLowerCase().replaceAll("_", "")
                              ];
                    if (!formattedKey) return;
                    newMap[formattedKey] = datum[key]!;
                    if (key !== "year") {
                        keys.add(formattedKey);
                    }
                }
            });
            return newMap;
        });
        return { chartData, chartKeys: Array.from(keys) as string[] };
    }, [data]);

    const { unit, formatFunction } = useMemo(
        () =>
            getDisplayInfo(
                "carbonEmissions",
                data?.flatMap(({ year, ...uses }) => Object.values(uses)) || []
            ),
        [data]
    );

    const formattedFuelType =
        endUseValueToDisplayLabelMap[fuelTypeKey.toLowerCase()];

    const xLabelsToShow = useMemo(
        () =>
            data
                ?.filter((datum) => (datum.year || 0) % 5 === 0)
                .map((datum) => datum.year || 0),
        [data]
    );

    const chartColorsToKeysMap = useMemo(
        () =>
            dataAndKeys?.chartKeys?.map((key, index) => ({
                label: key,
                color: CHART_COLORS[index],
            })),
        [dataAndKeys?.chartKeys]
    );

    if (error) return <ChartErrorState />;
    if ((data === null || data.length === 0) && !loading)
        return <ChartNoDataState />;

    return (
        <ChartContainer
            title={`${formattedFuelType} emissions`}
            loading={loading || !dataAndKeys || !chartColorsToKeysMap}
        >
            <BarChart
                data={dataAndKeys?.chartData || []}
                chartColorsMappedToKeys={chartColorsToKeysMap}
                yAxisInfo={{
                    label: unit,
                    formatFunction: (v: string | number) =>
                        formatFunction(v, false),
                }}
                xAxisInfo={{
                    xLabelsToShow,
                }}
                indexBy="year"
                chartKeys={dataAndKeys?.chartKeys || []}
                chartHeight={170}
                includeLegend={true}
            />
        </ChartContainer>
    );
}

export const endUseValueToDisplayLabelMap: { [key: string]: string } = {
    electricity: "Electricity",
    renewable: "Renewable",
    domestichotwater: "Domestic hot water",
    lighting: "Lighting",
    naturalgas: "Natural gas",
    fans: "Fans",
    pumps: "Pumps",
    outdooraircooling: "Outdoor air cooling",
    outdoorairheating: "Outdoor air heating",
    plugload: "Plug load",
    skincooling: "Skin cooling",
    skinheating: "Skin heating",
    process: "Process",
    cooling: "Cooling",
    heating: "Heating",
};

export default EndUseStackedBarChart;
