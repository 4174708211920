export const theme = {
    background: "#ffffff",
    text: {
        fontSize: 24,
        fill: "#757575",
        outlineWidth: 0,
        outlineColor: "transparent",
        fontFamily: "DM Sans",
    },
    axis: {
        domain: {
            line: {
                stroke: "#e1e1e1",
                strokeWidth: 0,
            },
        },
        legend: {
            text: {
                fontSize: 12,
                fill: "#545454",
                outlineWidth: 0,
                outlineColor: "transparent",
                fontFamily: "DM Sans",
            },
        },
        ticks: {
            line: {
                stroke: "#e1e1e1",
                strokeWidth: 1,
            },
            text: {
                fontSize: 14,
                fill: "#757575",
                fontFamily: "DM Sans",
                outlineWidth: 0,
                outlineColor: "transparent",
            },
        },
    },
    grid: {
        line: {
            stroke: "#e1e1e1",
            strokeWidth: 1,
        },
    },
    legends: {
        title: {
            text: {
                fontSize: 16,
                fill: "#757575",
                outlineWidth: 0,
                outlineColor: "transparent",
            },
        },
        text: {
            fontSize: 14,
            fill: "#757575",
            outlineWidth: 0,
            outlineColor: "transparent",
            fontFamily: "DM Sans",
        },
        ticks: {
            line: {},
            text: {
                fontSize: 14,
                fill: "#757575",
                outlineWidth: 0,
                outlineColor: "transparent",
            },
        },
    },
    annotations: {
        text: {
            fontSize: 12,
            fill: "#333333",
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
            fontFamily: "DM Sans",
        },
        link: {
            stroke: "#000000",
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
        },
        outline: {
            stroke: "#000000",
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
        },
        symbol: {
            fill: "#000000",
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
        },
    },
    tooltip: {
        container: {
            background: "#ffffff",
            fontSize: 12,
        },
        basic: {},
        chip: {},
        table: {},
        tableCell: {},
        tableCellValue: {},
    },
};
