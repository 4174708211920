import { useMemo, useState } from "react";
import { CentralPlant } from "../SurveyElements/Equipment/BuildingEquipment.constants";
import {
    ActiveView,
    DEFAULT_CENTRAL_PLANT_COOLER,
    DEFAULT_CENTRAL_PLANT_HEATER,
    DEFAULT_HEAT_PUMP,
} from "../Survey.constants";
import {
    SurveyUIStruct,
    buildCentralPlantSurveyUIStruct,
    getValidatedEquipmentState,
    surveyValueExists,
    updatePageStackAndViewOnBackClick,
    updatePageStackOnNextClick,
} from "../Survey.helpers";
import SurveyView from "../SurveyElements/Survey/SurveyView";

interface CentralPlantSurveyViewProps {
    airHandlingEquipment: AirHandlingEquipment;
    centralPlantCooler: CentralPlantCooler;
    setCentralPlantCooler: React.Dispatch<
        React.SetStateAction<CentralPlantCooler>
    >;
    centralPlantHeater: CentralPlantHeater;
    setCentralPlantHeater: React.Dispatch<
        React.SetStateAction<CentralPlantHeater>
    >;
    centralPlantHeatPump: CentralPlantHeatPump;
    setCentralPlantHeatPump: React.Dispatch<
        React.SetStateAction<CentralPlantHeatPump>
    >;
    setHeatPump: React.Dispatch<React.SetStateAction<HeatPump>>;
    setActiveView: (view: ActiveView) => void;
    centralPlantGenericEquipment: CentralPlantGenericEquipment;
    setCentralPlantGenericEquipment: React.Dispatch<
        React.SetStateAction<CentralPlantGenericEquipment>
    >;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

export function useCentralSystemNextButtonDisabled(
    struct: SurveyUIStruct<CentralPlant>,
    obj: CentralPlant
) {
    return useMemo(() => {
        const missingRequiredField = struct.some(({ key, show }) => {
            if (key !== null && show) {
                return obj[key] == null;
            }
            return false;
        });
        if (missingRequiredField) {
            return true;
        }
        const genericKeys = [
            "centralPlantGenericEquipmentName",
            "centralPlantGenericEquipmentSize",
            "centralPlantGenericEquipmentSizeUnits",
            "centralPlantGenericEquipmentCoefficientOfPerformance",
            "centralPlantGenericEquipmentFuel",
            "centralPlantGenericEquipmentEndUse",
            "centralPlantGenericEquipmentLoadRatio",
            "centralPlantGenericEquipmentInstallationYear",
        ];
        const values = genericKeys.map((key) => obj[key as keyof CentralPlant]);
        const nullCount = values.filter((value) => value === null).length;
        if (nullCount > 0 && nullCount < values.length) {
            return true;
        }
        return false;
    }, [struct, obj]);
}

function CentralPlantSurveyView({
    centralPlantCooler,
    setCentralPlantCooler,
    centralPlantHeater,
    setCentralPlantHeater,
    centralPlantHeatPump,
    setCentralPlantHeatPump,
    setHeatPump,
    centralPlantGenericEquipment,
    setCentralPlantGenericEquipment,
    setActiveView,
    activeView,
    airHandlingEquipment,
    pageStack,
    setPageStack,
}: CentralPlantSurveyViewProps) {
    const [centralPlant, setCentralPlant] = useState<CentralPlant>({
        ...centralPlantCooler,
        ...centralPlantHeater,
        ...centralPlantHeatPump,
        ...centralPlantGenericEquipment,
        centralPlantExists:
            centralPlantCooler.centralPlantCoolerExists ||
            centralPlantHeater.centralPlantHeaterExists ||
            centralPlantGenericEquipment.centralPlantGenericEquipmentExists,
    });

    const structure = useMemo(
        () =>
            buildCentralPlantSurveyUIStruct(centralPlant, airHandlingEquipment),
        [centralPlant]
    );
    const nextButtonDisabled = useCentralSystemNextButtonDisabled(
        structure,
        centralPlant
    );

    const handleUpdateCentralPlant = (
        func: (prev: CentralPlant) => CentralPlant
    ) => {
        setCentralPlant((prev) => {
            const newValue = func(prev);
            const struct = buildCentralPlantSurveyUIStruct(
                newValue,
                airHandlingEquipment
            );
            return getValidatedEquipmentState(newValue, struct);
        });
    };
    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);

        const heaterExists = surveyValueExists(
            centralPlant.centralPlantHeaterType
        );

        const heatPumpExists =
            centralPlant.centralPlantHeaterTerminalUnits ===
                "water_loop_heat_pump" ||
            centralPlant.centralPlantHeaterTerminalUnits ===
                "split_air_source_heat_pump";

        const centralHeatPumpExists = surveyValueExists(
            centralPlant.centralPlantHeatPumpType
        );

        const coolerExists = surveyValueExists(
            centralPlant.centralPlantCoolerType
        );

        const genericEquipmentExists = surveyValueExists(
            centralPlant.centralPlantGenericEquipmentName
        );

        setCentralPlantHeater((prev) => {
            const copy = { ...prev };
            Object.keys(centralPlantHeater).forEach((key) => {
                copy[key as keyof CentralPlantHeater] =
                    centralPlant[key as keyof CentralPlantHeater];
            });
            return {
                ...copy,
                centralPlantHeaterExists: heaterExists,
            };
        });

        setCentralPlantHeatPump((prev) => {
            const copy = { ...prev };
            Object.keys(centralPlantHeatPump).forEach((key) => {
                copy[key as keyof CentralPlantHeatPump] =
                    centralPlant[key as keyof CentralPlantHeatPump];
            });
            return {
                ...copy,
                centralPlantHeatPumpExists: centralHeatPumpExists,
            };
        });

        setCentralPlantCooler((prev) => {
            const copy = { ...prev };
            Object.keys(centralPlantCooler).forEach((key) => {
                copy[key as keyof CentralPlantCooler] =
                    centralPlant[key as keyof CentralPlantCooler];
            });
            return {
                ...copy,
                centralPlantCoolerExists: coolerExists,
            };
        });

        setCentralPlantGenericEquipment((prev) => {
            const copy = { ...prev };
            Object.keys(centralPlantGenericEquipment).forEach((key) => {
                copy[key as keyof CentralPlantGenericEquipment] =
                    centralPlant[key as keyof CentralPlantGenericEquipment];
            });
            return {
                ...copy,
                centralPlantGenericEquipmentExists: genericEquipmentExists,
            };
        });

        /* If water_loop_heat_pump was selected, fill in the heatpump state.
        This is an edge case where we're showing the water loop heatpump on the central plant page
        but it's actually part of the heatpump equipment state */
        if (
            centralPlant.centralPlantHeaterTerminalUnits ===
                "water_loop_heat_pump" ||
            centralPlant.centralPlantHeaterTerminalUnits ===
                "split_air_source_heat_pump"
        ) {
            setHeatPump((prev) => ({
                ...prev,
                heatPumpExists: true,
                heatPumpType: centralPlant.centralPlantHeaterTerminalUnits,
            }));
        } else {
            setHeatPump({ ...DEFAULT_HEAT_PUMP, heatPumpExists: false });
        }

        const hasCentralHeating =
            centralHeatPumpExists || heaterExists || heatPumpExists;
        const hasCentralCooling =
            centralHeatPumpExists || coolerExists || heatPumpExists;

        if (hasCentralCooling && hasCentralHeating) {
            setActiveView(ActiveView.domesticHotWaterHeater);
            return;
        }

        if (hasCentralHeating && hasCentralCooling) {
            setActiveView(ActiveView.domesticHotWaterHeater);
        } else {
            setActiveView(ActiveView.terminalUnits);
        }
    };

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setCentralPlantCooler(DEFAULT_CENTRAL_PLANT_COOLER);
        setCentralPlantHeater(DEFAULT_CENTRAL_PLANT_HEATER);
        setHeatPump(DEFAULT_HEAT_PUMP);
    };

    return (
        <SurveyView
            structure={structure}
            setSurveyPageData={handleUpdateCentralPlant}
            handleNextClick={handleNextClick}
            surveyPageData={centralPlant}
            handleBackClick={handleBackClick}
            nextButtonDisabled={nextButtonDisabled}
            activeView={activeView}
        />
    );
}

export default CentralPlantSurveyView;
