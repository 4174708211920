import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildReportsRequestVariables,
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.reports?.recommendationsReport
                .cumulativeEmissionSavingsCard as CumulativeEmissionSavingsCardData) ||
            null
    );

const query = graphql(`
    query cumulativeEmissionSavingsCard($reportFilter: ReportFilter) {
        reports(reportFilter: $reportFilter) {
            recommendationsReport {
                cumulativeEmissionSavingsCard {
                    totalCarbonEmissionSavings
                    totalCarbonEmissionSavingsFromMeasures
                    totalCarbonEmissionSavingsFromGrid
                }
            }
        }
    }
`);

export default queryFn;
