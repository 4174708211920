import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildReportsRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.reports?.baselineReport?.currentAnnualEndUseChart.error)
            throw new Error(
                data.reports.baselineReport.currentAnnualEndUseChart.error
            );
        return (
            (data.reports?.baselineReport
                ?.currentAnnualEndUseChart as CurrentAnnualEndUseChart) || null
        );
    });

const query = graphql(`
    query currentAnnualEndUseChart($reportFilter: ReportFilter) {
        reports(reportFilter: $reportFilter) {
            baselineReport {
                currentAnnualEndUseChart {
                    error
                    carbonEmissions {
                        endUse
                        fuelType
                        value
                    }
                    energyConsumption {
                        endUse
                        fuelType
                        value
                    }
                    utilityCost {
                        endUse
                        fuelType
                        value
                    }
                }
            }
        }
    }
`);

export default queryFn;
