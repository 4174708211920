import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import {
    DEFAULT_CENTRAL_PLANT_COOLER,
    DEFAULT_CENTRAL_PLANT_HEATER,
    DEFAULT_ROOFTOP_UNIT,
    DEFAULT_AIR_HANDLING_EQUIPMENT,
    DEFAULT_TERMINAL_COOLER,
    DEFAULT_TERMINAL_HEATER,
    DEFAULT_HEAT_PUMP,
    DEFAULT_DOMESTIC_HOT_WATER,
    DEFAULT_OTHER_EQUIPMENT,
    DEFAULT_CENTRAL_PLANT_HEAT_PUMP,
    DEFAULT_CENTRAL_PLANT_GENERIC_EQUIPMENT,
} from "./Survey.constants";
import { SurveyUIStruct } from "./Survey.helpers";

export const useDefaultEquipmentSurveyTypes = () => {
    const [rooftopUnit, setRooftopUnit] = useState<RooftopUnit>({
        ...DEFAULT_ROOFTOP_UNIT,
    });

    const [airHandlingEquipment, setAirHandlingEquipment] =
        useState<AirHandlingEquipment>({
            ...DEFAULT_AIR_HANDLING_EQUIPMENT,
        });

    const [centralPlantCooler, setCentralPlantCooler] =
        useState<CentralPlantCooler>({
            ...DEFAULT_CENTRAL_PLANT_COOLER,
        });

    const [centralPlantHeater, setCentralPlantHeater] =
        useState<CentralPlantHeater>({
            ...DEFAULT_CENTRAL_PLANT_HEATER,
        });
    
    const [centralPlantHeatPump, setCentralPlantHeatPump] =
        useState<CentralPlantHeatPump>({
            ...DEFAULT_CENTRAL_PLANT_HEAT_PUMP,
        });
    
    const [centralPlantGenericEquipment, setCentralPlantGenericEquipment] = 
        useState<CentralPlantGenericEquipment>({
            ...DEFAULT_CENTRAL_PLANT_GENERIC_EQUIPMENT,
        });

    const [terminalCooler, setTerminalCooler] = useState<TerminalCooler>({
        ...DEFAULT_TERMINAL_COOLER,
    });

    const [terminalHeater, setTerminalHeater] = useState<TerminalHeater>({
        ...DEFAULT_TERMINAL_HEATER,
    });

    const [heatPump, setHeatPump] = useState<HeatPump>({
        ...DEFAULT_HEAT_PUMP,
    });

    const [domesticHotWaterHeater, setDomesticHotWaterHeater] =
        useState<DomesticHotWaterHeater>({
            ...DEFAULT_DOMESTIC_HOT_WATER,
        });

    const [otherEquipment, setOtherEquipment] = useState<OtherEquipment>({
        ...DEFAULT_OTHER_EQUIPMENT,
    });

    return {
        rooftopUnit,
        setRooftopUnit,
        airHandlingEquipment,
        setAirHandlingEquipment,
        centralPlantCooler,
        setCentralPlantCooler,
        centralPlantHeater,
        setCentralPlantHeater,
        centralPlantHeatPump,
        setCentralPlantHeatPump,
        terminalCooler,
        setTerminalCooler,
        terminalHeater,
        setTerminalHeater,
        terminalHeatPump: heatPump,
        setTerminalHeatPump: setHeatPump,
        domesticHotWaterHeater,
        setDomesticHotWaterHeater,
        otherEquipment,
        setOtherEquipment,
        centralPlantGenericEquipment,
        setCentralPlantGenericEquipment
    };
};

export const useQueryOnStatusUpdate = (buildingModelUid: string) => {
    const queryClient = useQueryClient();
    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);

    const reset = () => {
        queryClient.invalidateQueries({
            queryKey: ["buildingModelHeader", buildingModelUid],
        });
        queryClient.resetQueries({
            queryKey: ["equipmentSurvey", buildingModelUid],
        });

        queryClient.resetQueries({
            queryKey: [buildingModelUid, "buildingModelReport"],
        });

        queryClient.invalidateQueries({
            queryKey: ["buildingModelsTable"],
        });

        setLoadingState((prev) => ({
            ...prev,
            equipmentSidebarLoading: false,
            buildingReportsLoading: false,
        }));
    };

    return { onSuccess: reset, onError: reset };
};

export function useNextButtonDisabled<T>(struct: SurveyUIStruct<T>, obj: T) {
    return useMemo(
        () =>
            struct.some(({ key, show }) => {
                if (key !== null && show) {
                    return obj[key] == null;
                }
                return false;
            }),
        [struct, obj]
    );
}
