import { useMemo } from "react";
import { ActiveView, DEFAULT_OTHER_EQUIPMENT } from "../Survey.constants";
import { buildOtherEquipmentSurveyUIStruct, updatePageStackAndViewOnBackClick, updatePageStackOnNextClick } from "../Survey.helpers";
import SurveyView from "../SurveyElements/Survey/SurveyView";

interface OtherEquipmentSurveyProps {
    otherEquipment: OtherEquipment;
    setOtherEquipment: React.Dispatch<React.SetStateAction<OtherEquipment>>;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function OtherEquipmentSurvey({
    otherEquipment,
    setOtherEquipment,
    setActiveView,
    activeView,
    pageStack,
    setPageStack,
}: OtherEquipmentSurveyProps) {
    const structure = useMemo(
        () => buildOtherEquipmentSurveyUIStruct(),
        [otherEquipment]
    );

    const nextButtonDisabled = useMemo(
        () =>
            (otherEquipment.clothesDryersExists === true &&
                otherEquipment.clothesDryersType === null) ||
            otherEquipment.clothesWashersExists === null ||
            otherEquipment.elevatorsExists === null ||
            otherEquipment.rooftopPhotovoltaicsExists === null ||
            otherEquipment.escalatorExists === null,
        [otherEquipment]
    );

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setOtherEquipment(DEFAULT_OTHER_EQUIPMENT);
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        setActiveView(ActiveView.sizeAndInstallationYear);
    };

    return (
        <SurveyView
            structure={structure}
            surveyPageData={otherEquipment}
            setSurveyPageData={setOtherEquipment}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

export default OtherEquipmentSurvey;