import "./Typography.css";
import classnames from "classnames";
import { CSSProperties, ReactNode } from "react";

interface HeaderProps {
    children: ReactNode;
    dataTestId?: string;
    size?: "x-small" | "small" | "medium" | "large" | "all-caps";
    style?: CSSProperties;
    weight?: "medium" | "bold";
}

function Header({
    children,
    dataTestId,
    size,
    style,
    weight = "medium",
}: HeaderProps) {
    switch (size) {
        case "large":
            return (
                <h1 style={style} className={weight} data-testid={dataTestId}>
                    {children}
                </h1>
            );
        case "medium":
            return (
                <h2 style={style} className={weight} data-testid={dataTestId}>
                    {children}
                </h2>
            );
        case "small":
            return (
                <h3
                    style={style}
                    className={weight}
                    data-testid={dataTestId}
                >
                    {children}
                </h3>
            );
        case "x-small":
            return (
                <h4 style={style} className={classnames("all-caps", weight)} data-testid={dataTestId}>
                    {children}
                </h4>
            );
        case "all-caps":
            return (
                <h4 style={style} className={weight} data-testid={dataTestId} >
                    {children}
                </h4>
            );

        default:
            return (
                <h2 style={style} className={weight} data-testid={dataTestId}>
                    {children}
                </h2>
            );
    }
}

interface ParagraphProps {
    size?: "regular" | "small" | "x-small";
    style?: CSSProperties;
    className?: string;
    children: ReactNode;
    dataTestId?: string;
}

function Paragraph({
    size = "regular",
    style,
    className,
    children,
    dataTestId,
}: ParagraphProps) {
    return (
        <p
            style={style}
            className={classnames(size, className)}
            data-testid={dataTestId}
        >
            {children}
        </p>
    );
}

interface InlineBoldParagraphProps {
    children: ReactNode;
    dataTestId?: string;
    style?: CSSProperties;
}

function InlineBoldParagraph({
    children,
    dataTestId,
    style,
}: InlineBoldParagraphProps) {
    return (
        <Header
            data-testid={dataTestId}
            size="small"
            style={{ ...style, display: "inline" }}
            weight="bold"
        >
            {children}
        </Header>
    );
}

export { Header, InlineBoldParagraph, Paragraph };
