import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserPermissions } from "utils/enums";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import { useUser } from "./useUser";

type ProtectedRouteProps = PropsWithChildren; 

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const navigate = useNavigate();
  const { data: user, isLoading } = useUser();
  const permissions = user?.permissions || [""];

  useEffect(() => {
    if (!isLoading && !permissions.includes(UserPermissions.ReadUser)) {
      navigate("/portfolio");
    }
  }, [permissions, isLoading, navigate]);

  if (isLoading) {
    return (
        <LoadingSpinner />
    );
}

  return <div>{children}</div>;
}

export default ProtectedRoute;