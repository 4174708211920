import { useMemo, useState, useEffect } from "react";

// components
import Sidebar from "components/molecules/Sidebar";
import SidebarLoading from "components/molecules/SidebarLoading";

// hooks
import { showMutationAlert } from "utils/helpers";
import useAlert from "hooks/useAlert";
import posthog from "posthog-js";
import { useCreateBuildingModelsMutation } from "mutations/useCreateBuildingModelsMutation";
import { useNavigate } from "react-router-dom";

import "./index.scss";
import BuildingFootprintSelect from "./BuildingFootprintSelect";
import inputs from "./inputs";
import BuildingDetailsForm from "./BuildingDetailsForm";

interface AddBuildingSidebarProps {
    onCancel: () => void;
    sidebarOpen: boolean;
    closeSidebar: () => void;
}

function AddBuildingSidebar({
    onCancel,
    sidebarOpen,
    closeSidebar,
}: AddBuildingSidebarProps) {
    const navigate = useNavigate();

    const [currentState, setCurrentState] = useState<
        "building-details" | "footprint"
    >("building-details");

    const [selectedFootprintPolygon, setSelectedFootprintPolygon] =
        useState<FootprintPolygon>();

    const handleCreateBuildingSuccess = (uid: string) => {
        posthog.capture("Add-building success", { building_uid: uid, added_buildings_count: 1 });
        navigate(`/building/${uid}`);
    };

    const {
        mutate: createBuilding,
        error: createBuildingModelsError,
        isLoading: createBuildingModelsIsLoading,
    } = useCreateBuildingModelsMutation((buildingModelUids: string[]) => {
        if (buildingModelUids[0])
            handleCreateBuildingSuccess(buildingModelUids[0]);
    });

    const alert = useAlert();

    useEffect(() => {
        if (createBuildingModelsError) {
            posthog.capture("Add-building failed");

            showMutationAlert(createBuildingModelsError, alert);

            closeSidebar();
        }
    }, [createBuildingModelsError]);

    const [buildingModelsInput, setBuildingModelsInput] = useState<{
        address: Partial<Location>;
        buildingProperties: Partial<BuildingProperties>;
        buildingName?: string;
    }>(defaultBuildingModelInputs);

    const inputsAreValid = useMemo(
        () =>
            inputs.every(({ key, required }) => {
                if (!required) return true;

                const { address, buildingProperties } = buildingModelsInput;

                if (key in address) return !!address[key as keyof Location];
                if (key in buildingProperties)
                    return !!buildingProperties[
                        key as keyof BuildingProperties
                    ];

                return false;
            }),
        [buildingModelsInput]
    );

    const handleAddBuilding = () => {
        createBuilding({
            buildingModels: [
                {
                    address: buildingModelsInput.address as Location,
                    buildingProperties: {
                        ...(buildingModelsInput.buildingProperties as BuildingProperties),
                        buildingUid:
                            selectedFootprintPolygon?.properties.buildingUid,
                        buildingFootprint:
                            selectedFootprintPolygon || undefined,
                    },
                    buildingName: buildingModelsInput.buildingName,
                },
            ],
        });
        setCurrentState("building-details");
    };

    const setBuildingModelValueForKey = (
        value: string | number | undefined,
        key: string
    ) => {
        setBuildingModelsInput((prev) => {
            if (key === "buildingName")
                return {
                    ...prev,
                    buildingName: value?.toString(),
                };
            if (key in buildingModelsInput.address) {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        [key]: value,
                    },
                };
            }
            return {
                ...prev,
                buildingProperties: {
                    ...prev.buildingProperties,
                    [key]: value,
                },
            };
        });
    };

    const getBuildingModelForKey = (key: string) =>
        key in Location
            ? buildingModelsInput.address[key as keyof Location]
            : buildingModelsInput.buildingProperties[
                  key as keyof BuildingProperties
              ];

    return (
        <Sidebar
            onCancel={() => {
                onCancel();
                setCurrentState("building-details");
            }}
            sidebarOpen={sidebarOpen}
            title="Add building"
        >
            {createBuildingModelsIsLoading && <SidebarLoading />}
            {currentState === "building-details" &&
                !createBuildingModelsIsLoading && (
                    <BuildingDetailsForm
                        handleNextClick={() => setCurrentState("footprint")}
                        getBuildingModelForKey={getBuildingModelForKey}
                        setBuildingModelValueForKey={
                            setBuildingModelValueForKey
                        }
                        inputsAreValid={inputsAreValid}
                        onCancel={onCancel}
                    />
                )}
            {currentState === "footprint" && !createBuildingModelsIsLoading && (
                <BuildingFootprintSelect
                    address={buildingModelsInput.address as Address}
                    selectedFootprintPolygon={selectedFootprintPolygon}
                    setSelectedFootprintPolygon={setSelectedFootprintPolygon}
                    handleAddBuilding={handleAddBuilding}
                    onCancel={() => {
                        onCancel();
                        setCurrentState("building-details");
                    }}
                />
            )}
        </Sidebar>
    );
}

const defaultBuildingModelInputs = {
    address: {
        streetAddress: undefined,
        city: undefined,
        stateProvince: undefined,
        postalZipCode: undefined,
        country: undefined,
    },
    buildingProperties: {
        buildingArchetype: undefined,
        buildingHeight: undefined,
        energyType: undefined,
        floorsAboveGrade: undefined,
        grossFloorArea: undefined,
        yearBuiltOriginal: undefined,
    },
    buildingName: undefined,
};


export default AddBuildingSidebar;
