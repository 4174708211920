import { ReactNode, useEffect } from "react";
import Checkbox from "components/atoms/Checkbox";
import classNames from "classnames";
import { Header } from "components/atoms/Typography";

interface FilterContainerProps {
    children: ReactNode;
    name: string;
    handleUncheck: () => void;
    checked: boolean;
    setChecked: (checked: boolean) => void;
}

function FilterContainer({
    children,
    name,
    handleUncheck,
    checked,
    setChecked,
}: FilterContainerProps) {
    useEffect(() => {
        if (checked === false) handleUncheck();
    }, [checked]);

    return (
        <div className="filter-container">
            <div className="filter-container__title">
                <Checkbox
                    checked={checked}
                    onChange={(checked) => {
                        setChecked(checked);
                    }}
                />
                <Header
                    size="small"
                    dataTestId={`filter-sidebar-option-label-${name}`}
                >
                    {name}
                </Header>
            </div>
            <div
                className={classNames(
                    "filter-container__body",
                    checked && "show"
                )}
            >
                {children}
            </div>
        </div>
    );
}

export default FilterContainer;