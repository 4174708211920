import { useMemo } from "react";
import { energyTypeInputOptions } from "utils/constants";
import Dropdown from "components/atoms/Dropdown";

interface EnergyTypeDropdownProps {
    value?: string;
    setValue: (value: string) => void;
    hideTitle?: boolean;
    required?: boolean;
}

function EnergyTypeDropdown({
    value,
    setValue,
    hideTitle = false,
    required = false,
}: EnergyTypeDropdownProps) {
    const EnergyTypeDropdownItems = useMemo(
        () =>
            energyTypeInputOptions.map(({ key, label }) => ({
                id: key,
                displayValue: label,
                itemId: `selenium-${key}-menu-item`,
            })),
        []
    );

    return (
        <Dropdown
            hideTitle={hideTitle}
            humanReadable="Is this building serviced by natural gas?"
            style={{ width: "100%" }}
            items={EnergyTypeDropdownItems}
            defaultItem={EnergyTypeDropdownItems.find(
                ({ id }) => id === "mixed_inferred"
            )}
            required={required}
            onSelectItem={({ id }) => setValue(id)}
            selectId="selenium-building-type-select"
        />
    );
}

export default EnergyTypeDropdown;
