import { CalendarIcon, DollarIcon } from "components/atoms/Icon";
import styles from "./OrganizationInfo.module.scss";

interface OrganizationInfoProps {
    activeOrganization?: string;
    currencyActiveOrganization?: string;
    createdYear?: string;
}

function OrganizationInfo({
    activeOrganization,
    currencyActiveOrganization,
    createdYear,
}: OrganizationInfoProps) {
    return (
        <div>
            <h2 className={styles.bottomMargin}>Organization</h2>
            <div className={styles.organizationContainer}>
                <h1>{activeOrganization}</h1>
                <div>
                    <CalendarIcon />
                    <p> Created {createdYear}</p>
                </div>
                <div>
                    <DollarIcon />
                    <p>{currencyActiveOrganization}</p>
                </div>
            </div>
        </div>
    );
}

export default OrganizationInfo;
