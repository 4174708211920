import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    buildingModelUid: string,
    utilityData: UtilityData[]
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            ...buildBuildingModelRequestVariables(buildingModelUid),
            utilityData,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.submitUtilityData.error)
            throw new Error(data.submitUtilityData.error);
        return data.submitUtilityData.buildingModelRunUid;
    });

const query = graphql(`
    mutation submitUtilityData(
        $buildingModelUid: Uuid!
        $utilityData: [UtilityData!]!
    ) {
        submitUtilityData(
            buildingModelUid: $buildingModelUid
            utilityData: $utilityData
        ) {
            error
            buildingModelRunUid
        }
    }
`);

export default queryFn;
