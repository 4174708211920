import { useCallback, useEffect, useMemo, useState } from "react";

import { useSetPropertyFund } from "mutations/useSetPropertyFund";
import { useSetBuildingModelProperty } from "mutations/useSetBuildingModelProperty";

import {
    CloseButton,
    NewFundIcon,
    NewPropertyIcon,
} from "components/atoms/Icon";
import { Header, Paragraph } from "components/atoms/Typography";
import { Button } from "components/atoms/Button";
import Modal from "components/molecules/Modal";
import FormInput from "components/molecules/FormInput";
import { BuildingsTableData } from "./helpers";

type AddEditType = "properties" | "funds";

interface AddPropertiesFundsModalProps {
    type: AddEditType;
    selectedBuildings: BuildingsTableData[];
    setSelectedBuildings: React.Dispatch<
        React.SetStateAction<BuildingsTableData[]>
    >;
    selectedProperties: Property[];
    setSelectedProperties: React.Dispatch<React.SetStateAction<Property[]>>;
}

function AddPropertiesFundsModal({
    type,
    selectedBuildings,
    setSelectedBuildings,
    selectedProperties,
    setSelectedProperties,
}: AddPropertiesFundsModalProps) {
    const [showModal, setShowModal] = useState(false);
    const nameKey = type === "funds" ? "fundName" : "propertyName";
    const selected = useMemo(
        () => (type === "funds" ? selectedProperties : selectedBuildings),
        [selectedProperties, selectedBuildings, type, showModal]
    );

    const {
        selectedBuildingsLocal,
        setSelectedBuildingsLocal,
        selectedPropertiesLocal,
        setSelectedPropertiesLocal,
        addProperty,
        addFund,
    } = useUpdateData(type, selectedBuildings, selectedProperties, showModal);

    const namesAreEqual = useMemo(() => {
        if (selected.length <= 1) return true;
        return selected
            .map((row) => row[nameKey])
            .every((name) => name === selected[0][nameKey]);
    }, [selected]);

    const isEdit = useMemo(
        () => selected.map((row) => row[nameKey]).some((name) => !!name),
        [selected]
    );

    const { newName, setNewName } = useInputValue(
        type,
        selectedProperties,
        selectedBuildings,
        namesAreEqual,
        showModal
    );

    const selectedTags = useMemo(() => {
        if (type === "properties")
            return selectedBuildingsLocal.map(
                ({ buildingModelUid, buildingName }) => ({
                    id: buildingModelUid,
                    name: buildingName,
                })
            );
        return selectedPropertiesLocal.map(({ propertyUid, propertyName }) => ({
            id: propertyUid,
            name: propertyName,
        }));
    }, [selectedBuildingsLocal, selectedPropertiesLocal]);

    const modalTitle = useMemo(() => {
        const prefix = isEdit ? "Edit" : "New";
        return `${prefix} ${type === "properties" ? "property" : "fund"}`;
    }, [isEdit]);

    return (
        <>
            <AddEditButton
                type={type}
                isEdit={isEdit}
                setShowModal={setShowModal}
            />
            <Modal
                open={showModal}
                onCancel={() => setShowModal(false)}
                title={modalTitle}
                footer={
                    <ModalFooter
                        type={type}
                        isEdit={isEdit}
                        addProperty={addProperty}
                        addFund={addFund}
                        setSelectedProperties={setSelectedProperties}
                        setSelectedBuildings={setSelectedBuildings}
                        selectedTagsAreEmpty={selectedTags.length === 0}
                        setShowModal={setShowModal}
                        name={newName}
                    />
                }
            >
                <ModalBody
                    name={newName}
                    setName={setNewName}
                    type={type}
                    namesAreEqual={namesAreEqual}
                    setBuildings={setSelectedBuildingsLocal}
                    setProperties={setSelectedPropertiesLocal}
                    selectedTags={selectedTags}
                />
            </Modal>
        </>
    );
}

interface AddEditButtonProps {
    setShowModal: (show: boolean) => void;
    isEdit: boolean;
    type: AddEditType;
}

function AddEditButton({ setShowModal, isEdit, type }: AddEditButtonProps) {
    const getTitle = useCallback(() => {
        if (isEdit) return `Edit ${type}`;
        return `New ${type === "properties" ? "property" : "fund"}`;
    }, [type, isEdit]);

    return (
        <button
            className="add-new-property__button"
            type="button"
            onClick={() => setShowModal(true)}
        >
            {type === "properties" ? <NewPropertyIcon /> : <NewFundIcon />}
            <Paragraph>{getTitle()}</Paragraph>
        </button>
    );
}

interface ModalFooterProps {
    type: AddEditType;
    isEdit: boolean;
    addProperty: (name: string | null) => void;
    addFund: (name: string | null) => void;
    setSelectedBuildings: (properties: BuildingsTableData[]) => void;
    setSelectedProperties: (properties: Property[]) => void;
    setShowModal: (show: boolean) => void;
    name: string | undefined;
    selectedTagsAreEmpty: boolean;
}

function ModalFooter({
    type,
    isEdit,
    addProperty,
    addFund,
    setSelectedBuildings,
    setSelectedProperties,
    setShowModal,
    selectedTagsAreEmpty,
    name,
}: ModalFooterProps) {
    const handleSave = () => {
        if (type === "properties") {
            addProperty(name || null);
            setSelectedBuildings([]);
        } else {
            addFund(name || null);
            setSelectedProperties([]);
        }
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleDeleteForAll = () => {
        if (type === "properties") {
            addProperty(null);
            setSelectedBuildings([]);
        } else {
            addFund(null);
            setSelectedProperties([]);
        }
        setShowModal(false);
    };

    return (
        <div className="add-new-property__modal__footer">
            <Button
                onClick={handleSave}
                type="primary"
                disabled={selectedTagsAreEmpty || !name}
            >
                {isEdit ? "Save" : "Create"}
            </Button>
            <Button onClick={handleCancel} type="secondary">
                Cancel
            </Button>
            <Button
                onClick={handleDeleteForAll}
                type="link"
                style={{ color: "var(--audette-content-negative" }}
            >
                {`Remove from all ${
                    type === "funds" ? "properties" : "buildings"
                }`}
            </Button>
        </div>
    );
}

interface ModalBodyProps {
    name: string | undefined;
    setName: (newName: string) => void;
    type: AddEditType;
    namesAreEqual: boolean;
    selectedTags: { id: string; name: string | null }[];
    setBuildings: React.Dispatch<React.SetStateAction<BuildingsTableData[]>>;
    setProperties: React.Dispatch<React.SetStateAction<Property[]>>;
}

function ModalBody({
    name,
    setName,
    type,
    namesAreEqual,
    selectedTags,
    setBuildings,
    setProperties,
}: ModalBodyProps) {
    return (
        <div className="add-new-property__modal-body">
            <div>
                <FormInput
                    attributeKey={
                        type === "properties" ? "propertyName" : "fundName"
                    }
                    setIsError={() => {}}
                    type="string"
                    value={name || undefined}
                    setValue={(v) => setName(v?.toString() || "")}
                    required={false}
                />
                {!namesAreEqual && <MixedParagraph type={type} />}
            </div>
            <Tags
                type={type}
                selectedTags={selectedTags}
                setBuildings={setBuildings}
                setProperties={setProperties}
            />
        </div>
    );
}

interface TagsProps {
    type: AddEditType;
    selectedTags: { id: string; name: string | null }[];
    setBuildings: React.Dispatch<React.SetStateAction<BuildingsTableData[]>>;
    setProperties: React.Dispatch<React.SetStateAction<Property[]>>;
}

function Tags({ type, selectedTags, setBuildings, setProperties }: TagsProps) {
    const handleCloseSingleTab = (index: number) => {
        if (type === "properties") {
            setBuildings((prev) => {
                const copy = [...prev];
                copy.splice(index, 1);
                return copy;
            });
        } else {
            setProperties((prev) => {
                const copy = [...prev];
                copy.splice(index, 1);
                return copy;
            });
        }
    };

    const handleCloseOverflow = () => {
        if (type === "properties") {
            setBuildings((prev) => {
                const copy = [...prev];
                copy.splice(2);
                return copy;
            });
        }
        setProperties((prev) => {
            const copy = [...prev];
            copy.splice(2);
            return copy;
        });
    };

    return (
        <div>
            <Header
                size="small"
                style={{ marginBottom: "var(--size-margin-s)" }}
            >
                {type === "funds" ? "Properties" : "Buildings"}
            </Header>
            <div className="add-new-property__modal-body__buildings">
                {selectedTags.slice(0, 2).map(({ id, name }, index) => (
                    <Tag
                        id={id}
                        name={name}
                        key={id}
                        onClose={() => {
                            handleCloseSingleTab(index);
                        }}
                    />
                ))}
                {selectedTags.length > 2 && (
                    <Tag
                        id="overflow"
                        name={`+ ${selectedTags.length - 2}`}
                        onClose={handleCloseOverflow}
                    />
                )}
            </div>
        </div>
    );
}

interface TagProps {
    id: string;
    name: string | null;
    onClose: () => void;
}

function Tag({ id, name, onClose }: TagProps) {
    return (
        <div className="tag" key={id}>
            <Paragraph size="small" key={id}>
                {name}
            </Paragraph>
            <CloseButton onClick={onClose} />
        </div>
    );
}

interface MixedParagraphProps {
    type: AddEditType;
}

function MixedParagraph({ type }: MixedParagraphProps) {
    const pluralNoun = type === "properties" ? "buildings" : "properties";
    const singularType = type === "properties" ? "property" : "fund";

    return (
        <Paragraph
            style={{
                color: "var(--audette-gray-500)",
                marginTop: "16px",
            }}
        >
            {`Selected ${pluralNoun} are a part of different ${type}, new ${singularType} will replace existing ones.`}
        </Paragraph>
    );
}

const useUpdateData = (
    type: "properties" | "funds",
    selectedBuildings: BuildingsTableData[],
    selectedProperties: Property[],
    showModal: boolean
) => {
    const { selectedPropertiesLocal, setSelectedPropertiesLocal } =
        useLocalProperties(selectedProperties, showModal);

    const { selectedBuildingsLocal, setSelectedBuildingsLocal } =
        useLocalBuildings(selectedBuildings, showModal);

    const buildingModelUids = useMemo(() => {
        if (type === "properties")
            return selectedBuildingsLocal.map(
                ({ buildingModelUid }) => buildingModelUid
            );
        return selectedPropertiesLocal.flatMap(({ buildingModels }) =>
            buildingModels.map(({ buildingModelUid }) => buildingModelUid)
        );
    }, [type, selectedPropertiesLocal, selectedBuildingsLocal]);

    const propertyUids = useMemo(() => {
        if (type === "properties") return [];
        return selectedPropertiesLocal.map(({ propertyUid }) => propertyUid);
    }, [selectedPropertiesLocal]);

    const { mutate: addProperty } =
        useSetBuildingModelProperty(buildingModelUids);

    const { mutate: addFund } = useSetPropertyFund(
        propertyUids,
        buildingModelUids
    );

    return {
        addProperty,
        addFund,
        selectedBuildingsLocal,
        setSelectedBuildingsLocal,
        selectedPropertiesLocal,
        setSelectedPropertiesLocal,
    };
};

const useLocalProperties = (
    selectedProperties: Property[],
    showModal: boolean
) => {
    const [selectedPropertiesLocal, setSelectedPropertiesLocal] = useState([
        ...selectedProperties,
    ]);

    useEffect(() => {
        if (showModal) setSelectedPropertiesLocal([...selectedProperties]);
    }, [selectedProperties, showModal]);

    return { selectedPropertiesLocal, setSelectedPropertiesLocal };
};

const useLocalBuildings = (
    selectedBuildings: BuildingsTableData[],
    showModal: boolean
) => {
    const [selectedBuildingsLocal, setSelectedBuildingsLocal] = useState([
        ...selectedBuildings,
    ]);

    useEffect(() => {
        if (showModal) setSelectedBuildingsLocal([...selectedBuildings]);
    }, [selectedBuildings, showModal]);

    return { selectedBuildingsLocal, setSelectedBuildingsLocal };
};

const useInputValue = (
    type: AddEditType,
    selectedProperties: Property[],
    selectedBuildings: BuildingsTableData[],
    isSame: boolean,
    showModal: boolean
) => {
    const getDefaultValue = () => {
        if (type === "funds") {
            if (selectedProperties.length < 1) return undefined;
            if (selectedProperties[0].fundName === null) return undefined;
            if (isSame) return selectedProperties[0].fundName;
            return undefined;
        }

        if (selectedBuildings.length < 1) return undefined;
        if (selectedBuildings[0].propertyName === null) return undefined;
        if (isSame) return selectedBuildings[0].propertyName;
        return undefined;
    };

    const [newName, setNewName] = useState(getDefaultValue());

    useEffect(() => {
        setNewName(getDefaultValue());
    }, [selectedBuildings, selectedProperties, showModal]);

    return { newName, setNewName };
};

export default AddPropertiesFundsModal;
