const inputs: {
    key: string;
    required: boolean;
    type: "string" | "number" | "select";
}[] = [
    {
        key: "buildingName",
        required: false,
        type: "string",
    },
    {
        key: "streetAddress",
        required: true,
        type: "string",
    },
    {
        key: "city",
        required: true,
        type: "string",
    },
    {
        key: "stateProvince",
        required: true,
        type: "string",
    },
    {
        key: "postalZipCode",
        required: true,
        type: "string",
    },
    {
        key: "country",
        required: true,
        type: "string",
    },
    {
        key: "buildingArchetype",
        required: true,
        type: "select",
    },
    {
        key: "floorsAboveGrade",
        required: true,
        type: "number",
    },
    {
        key: "grossFloorArea",
        required: true,
        type: "number",
    },
    {
        key: "buildingHeight",
        required: false,
        type: "number",
    },
    {
        key: "yearBuiltOriginal",
        required: true,
        type: "number",
    },
    {
        key: "energyType",
        required: false,
        type: "select",
    },
];

export default inputs;