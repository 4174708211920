import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildReportsRequestVariables,
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.reports?.recommendationsReport
                .annualCashFlowChart as AnnualCashFlowChartData) || null
    );

const query = graphql(`
    query annualCashFlowChart($reportFilter: ReportFilter) {
        reports(reportFilter: $reportFilter) {
            recommendationsReport {
                annualCashFlowChart {
                    cashFlowAnnual
                    cashFlowCumulative
                    presentValue
                    totalCarbonTaxSavings
                    totalIncrementalCost
                    totalUtilityCostSavings
                    year
                }
            }
        }
    }
`);

export default queryFn;
