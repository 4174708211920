function EmptySidebarView() {
    return (
        <div className="empty-sidebar-view">
            <Image />
        </div>
    );
}

function Image() {
    return (
        <svg
            width="400"
            viewBox="0 0 422 608"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_5105_9984"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="422"
                height="608"
            >
                <rect
                    x="0.755859"
                    y="0.00878906"
                    width="421"
                    height="607.6"
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_5105_9984)">
                <path
                    d="M316.358 130.503C343.952 130.503 366.321 108.134 366.321 80.5402C366.321 52.9465 343.952 30.5774 316.358 30.5774C288.765 30.5774 266.396 52.9465 266.396 80.5402C266.396 108.134 288.765 130.503 316.358 130.503Z"
                    fill="#EFEFEF"
                />
                <path
                    d="M296.661 218.587C244.055 253.119 178.365 259.851 116.927 257.802C77.6553 256.478 38.6805 251.297 0.556641 242.374V239.022C41.8522 248.737 84.1405 254.036 126.708 254.779C189.074 255.815 255.719 246.759 306.499 207.586C353.449 171.367 380.927 115.211 393.813 58.3919C394.279 56.3396 397.427 57.2134 396.963 59.2602C395.372 66.3458 393.554 73.3716 391.508 80.3374C375.534 134.748 344.862 186.947 296.661 218.587Z"
                    fill="#EFEFEF"
                />
                <path
                    d="M45.5584 421.89H28.1386C26.8397 421.889 25.5944 421.372 24.6759 420.454C23.7574 419.535 23.2407 418.29 23.2393 416.991V394.128C23.2407 392.829 23.7574 391.583 24.6759 390.665C25.5944 389.746 26.8397 389.23 28.1386 389.228H45.5584C46.8574 389.23 48.1026 389.746 49.0211 390.665C49.9396 391.583 50.4563 392.829 50.4577 394.128V416.991C50.4563 418.29 49.9396 419.535 49.0211 420.454C48.1026 421.372 46.8574 421.889 45.5584 421.89Z"
                    fill="#E1E1E1"
                />
                <path
                    d="M192.539 608.065H103.262C100.809 608.062 98.4563 607.086 96.7214 605.351C94.9864 603.616 94.0106 601.264 94.0078 598.81V425.701C94.0106 423.247 94.9864 420.895 96.7214 419.16C98.4563 417.425 100.809 416.449 103.262 416.447H192.539C194.992 416.449 197.345 417.425 199.079 419.16C200.814 420.895 201.79 423.247 201.793 425.701V598.81C201.79 601.264 200.814 603.616 199.079 605.351C197.345 607.086 194.992 608.062 192.539 608.065Z"
                    fill="#E1E1E1"
                />
                <path
                    d="M139.19 608.065H-15.4108C-17.8643 608.062 -20.2166 607.086 -21.9515 605.351C-23.6864 603.616 -24.6623 601.264 -24.665 598.81V425.701C-24.6623 423.247 -23.6864 420.895 -21.9515 419.16C-20.2166 417.425 -17.8643 416.449 -15.4108 416.447H139.19C141.644 416.449 143.996 417.425 145.731 419.16C147.466 420.895 148.442 423.247 148.445 425.701V598.81C148.442 601.264 147.466 603.616 145.731 605.351C143.996 607.086 141.644 608.062 139.19 608.065Z"
                    fill="#EFEFEF"
                />
                <path
                    d="M29.2274 421.89H11.8075C10.5086 421.889 9.2633 421.372 8.34482 420.454C7.42633 419.535 6.90967 418.29 6.9082 416.991V394.128C6.90967 392.829 7.42633 391.583 8.34482 390.665C9.2633 389.746 10.5086 389.23 11.8075 389.228H29.2274C30.5263 389.23 31.7716 389.746 32.6901 390.665C33.6086 391.583 34.1252 392.829 34.1267 394.128V416.991C34.1252 418.29 33.6086 419.535 32.6901 420.454C31.7716 421.372 30.5263 421.889 29.2274 421.89Z"
                    fill="#EFEFEF"
                />
                <path
                    d="M52.0925 501.912H31.4064C30.6848 501.912 29.993 501.625 29.4827 501.114C28.9725 500.604 28.6854 499.912 28.6846 499.191V467.617C28.6854 466.895 28.9725 466.204 29.4827 465.693C29.993 465.183 30.6848 464.896 31.4064 464.895H52.0925C52.8141 464.896 53.5059 465.183 54.0162 465.693C54.5264 466.204 54.8135 466.895 54.8143 467.617V499.191C54.8135 499.912 54.5264 500.604 54.0162 501.114C53.5059 501.625 52.8141 501.912 52.0925 501.912Z"
                    fill="white"
                />
                <path
                    d="M92.3757 501.912H71.6896C70.968 501.912 70.2762 501.625 69.7659 501.114C69.2557 500.604 68.9686 499.912 68.9678 499.191V467.617C68.9686 466.895 69.2557 466.204 69.7659 465.693C70.2762 465.183 70.968 464.896 71.6896 464.895H92.3757C93.0973 464.896 93.7891 465.183 94.2994 465.693C94.8096 466.204 95.0967 466.895 95.0975 467.617V499.191C95.0967 499.912 94.8096 500.604 94.2994 501.114C93.7891 501.625 93.0973 501.912 92.3757 501.912Z"
                    fill="white"
                />
                <path
                    d="M52.0925 559.616H31.4064C30.6848 559.615 29.993 559.328 29.4827 558.818C28.9725 558.308 28.6854 557.616 28.6846 556.894V525.321C28.6854 524.599 28.9725 523.907 29.4827 523.397C29.993 522.887 30.6848 522.6 31.4064 522.599H52.0925C52.8141 522.6 53.5059 522.887 54.0162 523.397C54.5264 523.907 54.8135 524.599 54.8143 525.321V556.894C54.8135 557.616 54.5264 558.308 54.0162 558.818C53.5059 559.328 52.8141 559.615 52.0925 559.616Z"
                    fill="white"
                />
                <path
                    d="M92.3757 559.616H71.6896C70.968 559.615 70.2762 559.328 69.7659 558.818C69.2557 558.308 68.9686 557.616 68.9678 556.894V525.321C68.9686 524.599 69.2557 523.907 69.7659 523.397C70.2762 522.887 70.968 522.6 71.6896 522.599H92.3757C93.0973 522.6 93.7891 522.887 94.2994 523.397C94.8096 523.907 95.0967 524.599 95.0975 525.321V556.894C95.0967 557.616 94.8096 558.308 94.2994 558.818C93.7891 559.328 93.0973 559.615 92.3757 559.616Z"
                    fill="white"
                />
                <path
                    d="M426.619 608.609H-711.98C-712.268 608.609 -712.545 608.494 -712.749 608.29C-712.954 608.086 -713.068 607.809 -713.068 607.52C-713.068 607.231 -712.954 606.954 -712.749 606.75C-712.545 606.546 -712.268 606.431 -711.98 606.431H426.619C426.908 606.431 427.185 606.546 427.389 606.75C427.593 606.954 427.708 607.231 427.708 607.52C427.708 607.809 427.593 608.086 427.389 608.29C427.185 608.494 426.908 608.609 426.619 608.609Z"
                    fill="#E1E1E1"
                />
                <path
                    d="M295.969 529.565C295.969 510.337 280.371 464.709 261.13 464.709C241.888 464.709 226.29 510.337 226.29 529.565C226.29 538.805 229.961 547.667 236.494 554.201C243.028 560.734 251.89 564.405 261.13 564.405C270.37 564.405 279.231 560.734 285.765 554.201C292.299 547.667 295.969 538.805 295.969 529.565Z"
                    fill="#E1E1E1"
                />
                <path
                    d="M287.259 529.565C287.259 510.337 271.661 464.709 252.42 464.709C233.178 464.709 217.58 510.337 217.58 529.565C217.58 538.805 221.251 547.667 227.784 554.201C234.318 560.734 243.18 564.405 252.42 564.405C261.66 564.405 270.521 560.734 277.055 554.201C283.589 547.667 287.259 538.805 287.259 529.565Z"
                    fill="#EFEFEF"
                />
                <path
                    d="M253.509 607.464C253.22 607.464 252.943 607.349 252.739 607.145C252.535 606.941 252.42 606.664 252.42 606.375V509.324C252.42 509.036 252.535 508.759 252.739 508.554C252.943 508.35 253.22 508.236 253.509 508.236C253.797 508.236 254.074 508.35 254.279 508.554C254.483 508.759 254.597 509.036 254.597 509.324V606.375C254.597 606.664 254.483 606.941 254.279 607.145C254.074 607.349 253.797 607.464 253.509 607.464Z"
                    fill="#AFAFAF"
                />
                <path
                    d="M253.509 525.235C253.294 525.235 253.084 525.171 252.905 525.051C252.726 524.931 252.586 524.761 252.504 524.563C252.421 524.364 252.4 524.145 252.442 523.934C252.484 523.722 252.587 523.528 252.74 523.376L266.978 509.138C267.182 508.933 267.459 508.819 267.748 508.819C268.036 508.819 268.313 508.933 268.517 509.138C268.721 509.342 268.836 509.619 268.836 509.907C268.836 510.196 268.721 510.473 268.517 510.677L254.279 524.916C254.178 525.017 254.058 525.097 253.926 525.152C253.794 525.207 253.652 525.235 253.509 525.235Z"
                    fill="#AFAFAF"
                />
                <path
                    d="M281.299 606.523C281.303 601.569 283.273 596.819 286.778 593.317C290.282 589.815 295.033 587.848 299.987 587.848C304.941 587.848 309.693 589.815 313.197 593.317C316.702 596.819 318.672 601.569 318.676 606.523"
                    fill="#EFEFEF"
                />
                <path
                    d="M299.987 607.523C299.853 607.523 299.724 607.47 299.629 607.375C299.535 607.28 299.481 607.152 299.481 607.018V597.168C299.481 597.034 299.535 596.906 299.629 596.811C299.724 596.717 299.853 596.663 299.987 596.663C300.121 596.663 300.249 596.717 300.344 596.811C300.438 596.906 300.492 597.034 300.492 597.168V607.018C300.492 607.152 300.438 607.28 300.344 607.375C300.249 607.47 300.12 607.523 299.987 607.523Z"
                    fill="#AFAFAF"
                />
                <path
                    d="M299.987 602.138C299.867 602.138 299.75 602.095 299.659 602.016L293.597 596.814C293.546 596.771 293.504 596.718 293.474 596.659C293.443 596.6 293.425 596.535 293.42 596.468C293.414 596.402 293.422 596.335 293.443 596.272C293.463 596.208 293.496 596.149 293.54 596.099C293.583 596.048 293.636 596.007 293.696 595.977C293.755 595.947 293.82 595.929 293.887 595.924C293.953 595.919 294.02 595.928 294.084 595.949C294.147 595.97 294.205 596.003 294.255 596.047L300.317 601.25C300.395 601.317 300.451 601.406 300.477 601.506C300.503 601.606 300.497 601.712 300.461 601.808C300.425 601.905 300.361 601.989 300.276 602.048C300.191 602.106 300.091 602.138 299.987 602.138Z"
                    fill="#AFAFAF"
                />
                <path
                    d="M338.88 606.523C338.884 601.569 340.854 596.819 344.359 593.317C347.863 589.815 352.614 587.848 357.569 587.848C362.523 587.848 367.274 589.815 370.778 593.317C374.283 596.819 376.253 601.569 376.257 606.523"
                    fill="#EFEFEF"
                />
                <path
                    d="M357.568 607.523C357.434 607.523 357.305 607.47 357.21 607.375C357.116 607.28 357.063 607.152 357.062 607.018V597.168C357.062 597.034 357.116 596.906 357.21 596.811C357.305 596.717 357.434 596.663 357.568 596.663C357.702 596.663 357.83 596.717 357.925 596.811C358.019 596.906 358.073 597.034 358.073 597.168V607.018C358.073 607.152 358.019 607.28 357.925 607.375C357.83 607.47 357.702 607.523 357.568 607.523Z"
                    fill="#AFAFAF"
                />
                <path
                    d="M357.569 602.138C357.448 602.138 357.331 602.095 357.24 602.016L351.179 596.814C351.128 596.771 351.086 596.718 351.055 596.659C351.025 596.6 351.006 596.535 351.001 596.469C350.995 596.402 351.003 596.335 351.024 596.272C351.044 596.208 351.077 596.149 351.121 596.099C351.164 596.048 351.217 596.007 351.277 595.977C351.337 595.947 351.402 595.929 351.468 595.924C351.535 595.919 351.602 595.928 351.665 595.949C351.728 595.97 351.787 596.004 351.837 596.048L357.898 601.25C357.976 601.317 358.032 601.407 358.058 601.507C358.084 601.607 358.079 601.712 358.043 601.809C358.007 601.905 357.942 601.989 357.858 602.048C357.773 602.107 357.672 602.138 357.569 602.138Z"
                    fill="#AFAFAF"
                />
            </g>
        </svg>
    );
}

export default EmptySidebarView;
