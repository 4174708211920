import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    buildingModelUid: string,
    carbonReductionMeasureType: string
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(
            buildingModelUid,
            carbonReductionMeasureType
        ),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            data.measureReport
                .totalCostAndSavingsCard as ProjectReportTotalCostAndSavingsCard
    );

const query = graphql(`
    query totalCostAndSavingsCard(
        $buildingModelUid: Uuid!
        $carbonReductionMeasureType: String!
    ) {
        measureReport(
            buildingModelUid: $buildingModelUid
            carbonReductionMeasureType: $carbonReductionMeasureType
        ) {
            totalCostAndSavingsCard {
                total {
                    discountRate
                    incrementalCost
                    likeForLikeCost
                    totalEnergyCostSavings
                    totalCarbonTaxSavings
                    totalMeasureCost
                    totalUtilityCostSavingsElectricity
                    totalUtilityCostSavingsNaturalGas
                }
                incremental {
                    discountRate
                    incrementalCost
                    totalEnergyCostSavings
                    totalCarbonTaxSavings
                    totalMeasureCost
                    totalUtilityCostSavingsElectricity
                    totalUtilityCostSavingsNaturalGas
                }
            }
        }
    }
`);

export default queryFn;
