import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.buildingModelReports.error)
            throw new Error(data.buildingModelReports.error);
        return data.buildingModelReports.baselineReport?.annualCarbonEmissionsElectricityChart?.map(
            (item) => {
                const endUses: { [endUseKey: string]: number } = {};
                const year = item?.year || 0;
                item?.endUseCarbonEmissions.forEach((use) => {
                    const endUseKey = use?.endUse;
                    const carbonEmissions = use?.carbonEmissions || 0;
                    if (endUseKey) endUses[endUseKey] = carbonEmissions;
                });
                return {
                    year,
                    ...endUses,
                };
            }
        ) as AnnualCarbonEmissionsElectricityChartData;
    });

const query = graphql(`
    query buildingBaselineAnnualCarbonEmissionsElectricityChart(
        $buildingModelUid: Uuid!
    ) {
        buildingModelReports(buildingModelUid: $buildingModelUid) {
            error
            baselineReport {
                annualCarbonEmissionsElectricityChart {
                    year
                    endUseCarbonEmissions {
                        endUse
                        carbonEmissions
                    }
                }
            }
        }
    }
`);

export default queryFn;
