import { request } from "graphql-request";
import { graphql } from "gql/gql";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryUser = (accessToken: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {},
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => data.user
        // TODO: ad error handling in the backend
        // if (data.user.error) throw new Error(data.user.error); 
        
    );

const query = graphql(`
    query user {
        user {
            activeOrganization {
                currencyCode
                organizationName
                organizationUid
                createdTimestamp
                members{
                    email
                    isActive
                    joinedTimestamp
                    roles
                    userId
                }
            }
            memberOrganizations {
                currencyCode
                organizationName
                organizationUid
            }
            permissions
            roles
            userId
        }
    }
`);

export default queryUser;
