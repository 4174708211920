/* eslint-disable max-len */
import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildReportsRequestVariables,
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.reports?.recommendationsReport
                .averageMarginalAbatementCostByMeasureCategoryChart as AverageMarginalAbatementCostByMeasureCategoryChartData) ||
            null
    );

const query = graphql(`
    query averageMarginalAbatementCostByMeasureCategoryChart(
        $reportFilter: ReportFilter
    ) {
        reports(reportFilter: $reportFilter) {
            recommendationsReport {
                averageMarginalAbatementCostByMeasureCategoryChart {
                    carbonReductionMeasureCategory
                    averageMarginalAbatementCost
                    totalLifetimeCarbonEmissionSavings
                }
            }
        }
    }
`);

export default queryFn;
