import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";
import { useParams } from "react-router-dom";
import queryFn from "ApiLayer/BuildingReports/Project/queryImpactCards";

export const useImpactCards = (carbonReductionMeasureType: string) => {
    const accessToken = useAccessToken();
    const { buildingModelUid } = useParams();
    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    return useQuery({
        queryKey: [
            buildingModelUid,
            "buildingModelReport",
            "projectReportImpactCards",
            carbonReductionMeasureType,
        ],
        queryFn: async () =>
            queryFn(accessToken, buildingModelUid, carbonReductionMeasureType),
        staleTime: QUERY_STALE_TIME,
    });
};
