import { atom, useRecoilState } from "recoil";

interface DownloadState {
    showBanner: boolean;
    downloadInProgress: boolean;
    downloadCancelled: boolean;
    downloadSuccess: boolean;
    downloadComplete: boolean;
    downloadError: boolean;
    controller?: AbortController;
}

export const DEFAULT_DOWNLOAD_STATE = {
    showBanner: false,
    downloadCancelled: false,
    downloadInProgress: false,
    downloadSuccess: false,
    downloadComplete: false,
    downloadError: false,
    controller: undefined,
};

const downloadStateAtom = atom<DownloadState>({
    key: "downloadState",
    default: DEFAULT_DOWNLOAD_STATE,
});

const useDownloadState = () => {
    const [state, setState] = useRecoilState(downloadStateAtom);

    const resetStateToDefault = () => {
        setState((prev) => ({
            ...DEFAULT_DOWNLOAD_STATE,
            controller: prev.controller,
        }));
    };

    return {
        state,
        setState,
        resetStateToDefault,
    };
};

export default useDownloadState;
