import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";
import { useParams } from "react-router-dom";
import queryFn from "ApiLayer/BuildingReports/Baseline/queryCurrentAnnualCarbonEmissionsCard";

export const useCurrentAnnualCarbonEmissionsCard = () => {
    const accessToken = useAccessToken();
    const { buildingModelUid } = useParams();
    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    return useQuery({
        queryKey: [
            buildingModelUid,
            "buildingModelReport",
            "buildingModelBaselineCurrentAnnualCarbonEmissionsCard",
        ],
        queryFn: async () => queryFn(accessToken, buildingModelUid),
        staleTime: QUERY_STALE_TIME,
    });
};
