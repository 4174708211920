import { Header, Paragraph } from "components/atoms/Typography";
import BooleanHeaderSelect from "../BooleanHeaderSelect";

interface HeaderProps {
    booleanSurveyResult: boolean | null;
    setBooleanSurveyResult: React.Dispatch<
        React.SetStateAction<boolean | null>
    >;
}

function EquipmentSizeAndInstallationYearSurveyHeader({
    booleanSurveyResult,
    setBooleanSurveyResult,
}: HeaderProps) {
    const onSelectYes = () => setBooleanSurveyResult(true);
    const onSelectNo = () => setBooleanSurveyResult(false);

    return (
        <>
            <Paragraph
                size="small"
                style={{
                    color: "var(--audette-gray-500)",
                }}
            >
                STEP 7/7
            </Paragraph>
            <Header size="medium">
                Do you know your equipment's full size and installation year?
            </Header>
            <BooleanHeaderSelect
                currentValue={
                    booleanSurveyResult === null
                        ? undefined
                        : booleanSurveyResult
                }
                onSelectYes={onSelectYes}
                onSelectNo={onSelectNo}
            />
        </>
    );
}

export default EquipmentSizeAndInstallationYearSurveyHeader;