import { useState } from "react";
import { Header, Paragraph } from "components/atoms/Typography";
import { useFeatureFlagEnabled } from "posthog-js/react";
import SurveyFooter from "./SurveyFooter";
import SurveySubtitle from "./SurveySubtitle";
import CheckboxWithSelect from "../CheckboxWithSelect";
import SurveyBoolean from "./SurveyBoolean";
import SurveyCheckbox from "./SurveyCheckbox";
import SurveyHint from "./SurveyHint";
import SurveyLabel from "./SurveyLabel";
import SurveySelect from "./SurveySelect";
import {
    SurveyUIStruct,
    SurveyUIStructBoolean,
    SurveyUIStructCheckbox,
    SurveyUIStructCheckboxWithSelect,
    SurveyUIStructSelect,
    SurveyUIStructText
} from "../../Survey.helpers";
import { ActiveView } from "../../Survey.constants";
import styles from "./SurveyView.module.scss";
import SurveyGenericEquipment from "./SurveyGenericEquipment";
import { SurveyText } from "./SurveyText";

interface SurveyViewProps<T> {
    structure: SurveyUIStruct<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
    nextButtonDisabled?: boolean;
    handleNextClick: () => void;
    handleBackClick?: () => void;
    activeView: ActiveView;
}

function SurveyView<T>({
    surveyPageData,
    structure,
    setSurveyPageData,
    handleNextClick,
    handleBackClick,
    nextButtonDisabled = false,
    activeView,
}: SurveyViewProps<T>) {
    const [isAddEquipmentVisible, setIsAddEquipmentVisible] =
        useState<boolean>(false);

    // const [isOtherGenericButtonVisible, setIsOtherGenericButtonVisible] =
    //     useState<boolean>(false);

    const featureEnabled = useFeatureFlagEnabled("custom-hvac-equipment-input");

    let uniqueIdCounter = 0;

    const generateUniqueId = () => {
        const id = `equipment-${uniqueIdCounter}`;
        uniqueIdCounter += 1; // Explicitly increment
        return id;
    };
    const [genericEquipmentList, setGenericEquipmentList] = useState<string[]>([
        generateUniqueId(),
    ]);

    const addCustomEquipmentHandler = () => {
        setIsAddEquipmentVisible(!isAddEquipmentVisible);
        // setIsOtherGenericButtonVisible(true);
    };

    // remove this when uncommenting
    // eslint-disable-next-line 
    const addExtraCustomEquipmentHandler = () => {
        setGenericEquipmentList((prevList) => {
            const updatedList = [...prevList, generateUniqueId()];
            return updatedList;
        });
    };

    return (
        <>
            <div className="building-equipment-survey-view">
                <Paragraph
                    size="small"
                    style={{
                        color: "var(--audette-gray-500)",
                    }}
                >
                    STEP {activeView + 1}/7
                </Paragraph>
                {structure.map((surveyQuestion) => {
                    if (surveyQuestion.show === false) return null;
                    const { label, key } = surveyQuestion;

                    return (
                        <div
                            className="building-equipment-survey-view__section"
                            key={key ? key.toString() : label}
                        >
                            {surveyQuestion.type === "label" && (
                                <SurveyLabel
                                    label={label}
                                    image={surveyQuestion.image}
                                    description={surveyQuestion.description}
                                />
                            )}
                            {surveyQuestion.type === "subtitle" && (
                                <SurveySubtitle label={label} />
                            )}
                            {surveyQuestion.type === "boolean" && (
                                <SurveyBoolean
                                    surveyBooleanStruct={
                                        surveyQuestion as SurveyUIStructBoolean<T>
                                    }
                                    surveyPageData={surveyPageData}
                                    setSurveyPageData={setSurveyPageData}
                                />
                            )}
                            {surveyQuestion.type === "checkboxWithSelect" && (
                                <CheckboxWithSelect
                                    surveyCheckboxWithSelectStruct={
                                        surveyQuestion as SurveyUIStructCheckboxWithSelect<T>
                                    }
                                    surveyPageData={surveyPageData}
                                    setSurveyPageData={setSurveyPageData}
                                />
                            )}
                            {surveyQuestion.type === "checkbox" && (
                                <SurveyCheckbox
                                    checkboxStruct={
                                        surveyQuestion as SurveyUIStructCheckbox<T>
                                    }
                                    surveyPageData={surveyPageData}
                                    setSurveyPageData={setSurveyPageData}
                                />
                            )}
                            {surveyQuestion.type === "select" && (
                                <SurveySelect
                                    selectStruct={
                                        surveyQuestion as SurveyUIStructSelect<T>
                                    }
                                    surveyPageData={surveyPageData}
                                    setSurveyPageData={setSurveyPageData}
                                />
                            )}
                            {surveyQuestion.type === "text" && (
                                <SurveyText
                                    textStruct={
                                        surveyQuestion as SurveyUIStructText<T>
                                    }
                                    label={surveyQuestion.label}
                                />
                            )}
                            { (surveyQuestion.type === "genericEquipment" && featureEnabled) && (
                                <>
                                    <Header size="small">{label}</Header>
                                    {isAddEquipmentVisible && (
                                        <>
                                            {genericEquipmentList.map(
                                                (_, index) => (
                                                    <SurveyGenericEquipment
                                                        key={key?.toString()}
                                                        surveyQuestion={
                                                            surveyQuestion
                                                        }
                                                        surveyPageData={
                                                            surveyPageData
                                                        }
                                                        setSurveyPageData={
                                                            setSurveyPageData
                                                        }
                                                    />
                                                )
                                            )}
                                        </>
                                    )}

                                    {(!isAddEquipmentVisible && activeView===2) && (
                                        <button
                                            type="button"
                                            className={
                                                styles.addEquipmentButton
                                            }
                                            onClick={addCustomEquipmentHandler}
                                        >
                                            + Add custom equipment
                                        </button>
                                    )}

                                    {/* {isOtherGenericButtonVisible && (
                                        <button
                                            type="button"
                                            className={
                                                styles.addEquipmentButton
                                            }
                                            onClick={
                                                addExtraCustomEquipmentHandler
                                            }
                                        >
                                            + Add other custom equipment
                                        </button>
                                    )} */}

                                    {/* <SurveyText
                                    textStruct={
                                        surveyQuestion as SurveyUIStructText<T>
                                    }
                                    label={surveyQuestion.label}
                                    /> */}
                                </>
                            )}
                            {surveyQuestion.hint && (
                                <SurveyHint hint={surveyQuestion.hint} />
                            )}
                        </div>
                    );
                })}
            </div>
            <SurveyFooter
                nextButtonDisabled={nextButtonDisabled}
                handleBackClick={handleBackClick}
                handleNextClick={handleNextClick}
            />
        </>
    );
}

export default SurveyView;
