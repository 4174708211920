import "./SidebarLoading.scss";

function SidebarLoading() {
    return (
        <div className="sidebar-loading">
            <SidebarLoadingSection />
            <SidebarLoadingSection />
        </div>
    );
}

function SidebarLoadingSection() {
    return (
        <div className="sidebar-loading__section">
            <SidebarLoadingSectionHeader />
            <SidebarLoadingSectionBody />
        </div>
    );
}

function SidebarLoadingSectionHeader() {
    return (
        <div className="sidebar-loading__section__header">
            <div>
                <div
                    className="loading"
                    style={{
                        width: "105px",
                        height: "16px",
                        borderRadius: "25px",
                    }}
                />
                <Circle />
            </div>
            <div>
                <Circle />
                <div
                    className="loading"
                    style={{
                        width: "46px",
                        height: "16px",
                        borderRadius: "25px",
                    }}
                />
            </div>
        </div>
    );
}

function Circle() {
    return (
        <div
            className="loading"
            style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
            }}
        />
    );
}

function SidebarLoadingSectionBody() {
    const sectionLength = Math.ceil(
        bodyLoadingBarWidths.length / numberOfLoadingBarsInRow
    );

    const mapFn = (_: number, i: number) =>
        bodyLoadingBarWidths.slice(
            i * numberOfLoadingBarsInRow,
            i * numberOfLoadingBarsInRow + numberOfLoadingBarsInRow
        );

    const sections = Array.from(
        {
            length: sectionLength,
        },
        mapFn
    );

    return (
        <div className="sidebar-loading__section__body">
            {sections.map((arr, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                    {arr.map((width, i) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${width}${i}`}
                            className="loading"
                            style={{
                                width: `${width}px`,
                                height: "16px",
                                borderRadius: "25px",
                            }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

const numberOfLoadingBarsInRow = 2;
const bodyLoadingBarWidths = [
    104, 168, 99, 82, 132, 98, 67, 70, 80, 117, 71, 117,
];

export default SidebarLoading;
