import { useState, useMemo } from "react";
import { CURRENT_YEAR } from "utils/constants";
import Card from "components/molecules/Card";
import { UseQueryResult } from "react-query";
import { ChartErrorState } from "components/molecules/ErrorStates";
import AnnualCumulativePicker, {
    RadioButtonSelectMode,
} from "../AnnualCumulativePicker";

interface FutureProjectionCardsProps {
    cumulativeCarbonEmissionsCardQuery: () => UseQueryResult<CumulativeCarbonEmissionsCardData>;
    cumulativeEnergyConsumptionCardQuery: () => UseQueryResult<CumulativeEnergyConsumptionCardData>;
    annualCarbonEmissionsCardQuery: () => UseQueryResult<AnnualCarbonEmissionsCardData>;
    annualEnergyConsumptionCardQuery: () => UseQueryResult<AnnualEnergyConsumptionCardData>;
}

function FutureProjectionCards({
    cumulativeCarbonEmissionsCardQuery,
    cumulativeEnergyConsumptionCardQuery,
    annualCarbonEmissionsCardQuery,
    annualEnergyConsumptionCardQuery,
}: FutureProjectionCardsProps) {
    const [selected, setSelected] =
        useState<RadioButtonSelectMode>("cumulative");
    const [sliderCurrentYear, setSliderCurrentYear] = useState(CURRENT_YEAR);

    const onChangeSelect = () => {
        setSelected((prev) => {
            if (prev === "cumulative") return "annual";
            return "cumulative";
        });
    };

    return (
        <div>
            <AnnualCumulativePicker
                selected={selected}
                onChangeSelect={onChangeSelect}
                currentSliderYear={sliderCurrentYear}
                setCurrentSliderYear={setSliderCurrentYear}
            />
            <div className="cards">
                {selected === "cumulative" ? (
                    <>
                        <CumulativeCarbonEmissionsCard
                            query={cumulativeCarbonEmissionsCardQuery}
                        />
                        <CumulativeEnergyConsumptionCard
                            query={cumulativeEnergyConsumptionCardQuery}
                        />
                    </>
                ) : (
                    <>
                        <AnnualCarbonEmissionsCard
                            year={sliderCurrentYear}
                            query={annualCarbonEmissionsCardQuery}
                        />
                        <AnnualEnergyConsumptionCard
                            year={sliderCurrentYear}
                            query={annualEnergyConsumptionCardQuery}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

interface CumulativeCarbonEmissionsCardProps {
    query: () => UseQueryResult<CumulativeCarbonEmissionsCardData>;
}

function CumulativeCarbonEmissionsCard({
    query,
}: CumulativeCarbonEmissionsCardProps) {
    const { data, isLoading, error } = query();
    return (
        <CarbonEmissionsCard
            data={data}
            error={error?.toString()}
            loading={isLoading}
            isCumulative={true}
        />
    );
}

interface AnnualCarbonEmissionsCardProps {
    year: number;
    query: () => UseQueryResult<AnnualCarbonEmissionsCardData>;
}

function AnnualCarbonEmissionsCard({
    year,
    query,
}: AnnualCarbonEmissionsCardProps) {
    const { data, isLoading, error } = query();

    const dataForYear = useMemo(
        () => data?.find((datum) => datum?.year === year),
        [year, data]
    );

    return (
        <CarbonEmissionsCard
            data={dataForYear}
            error={error?.toString()}
            loading={isLoading}
        />
    );
}

interface CarbonEmissionsCardProps {
    data?:
        | CumulativeCarbonEmissionsCardData
        | AnnualCarbonEmissionsCardDatum
        | null;
    loading?: boolean;
    error?: string;
    isCumulative?: boolean;
}

function CarbonEmissionsCard({
    data,
    loading = false,
    error,
    isCumulative,
}: CarbonEmissionsCardProps) {
    const cardData = useMemo(() => {
        if (!data) return undefined;

        return {
            header: {
                displayInfoKey: "carbonEmissions",
                value: data.totalCarbonEmissions,
                title: isCumulative
                    ? "Total cumulative carbon emissions"
                    : undefined,
            },
            sections: [
                [
                    {
                        displayInfoKey: "totalCarbonEmissionSavings",
                        value: data.totalCarbonEmissionSavings,
                        increaseDecreasePercentage: Number(
                            data?.totalCarbonEmissionsPercentChange
                        ),
                    },
                    {
                        displayInfoKey: "carbonEmissionIntensity",
                        value: (data.averageCarbonEmissionIntensity ||
                            data.totalCarbonEmissionIntensity)!,
                    },
                ],
                [
                    {
                        title: "Natural gas",
                        displayInfoKey: "carbonEmissions",
                        value: data.totalCarbonEmissionsNaturalGas,
                    },
                    {
                        title: "Electricity",
                        displayInfoKey: "carbonEmissions",
                        value: data.totalCarbonEmissionsElectricity,
                    },
                ],
            ],
        };
    }, [data]);

    return <Card loading={loading} data={cardData} error={error} />;
}

interface CumulativeEnergyConsumptionCardProps {
    query: () => UseQueryResult<CumulativeEnergyConsumptionCardData>;
}

function CumulativeEnergyConsumptionCard({
    query,
}: CumulativeEnergyConsumptionCardProps) {
    const { data, isLoading, error } = query();

    return (
        <EnergyConsumptionCard
            data={data}
            loading={isLoading}
            error={error?.toString()}
            isCumulative={true}
        />
    );
}

interface EnergyConsumptionCardProps {
    data?:
        | CumulativeEnergyConsumptionCardData
        | AnnualEnergyConsumptionCardDatum
        | null;
    loading?: boolean;
    error?: string;
    isCumulative?: boolean;
}

function EnergyConsumptionCard({
    data,
    loading = false,
    error,
    isCumulative,
}: EnergyConsumptionCardProps) {
    const cardData = useMemo(() => {
        if (!data) return undefined;

        return {
            header: {
                displayInfoKey: "energyConsumption",
                value: data?.totalEnergyConsumption,
                title: isCumulative
                    ? "Total cumulative energy consumption"
                    : undefined,
            },
            sections: [
                [
                    {
                        displayInfoKey: "totalEnergyConsumptionSavings",
                        value: data?.totalEnergyConsumptionSavings,
                        increaseDecreasePercentage: Number(
                            data.totalEnergyConsumptionPercentChange
                        ),
                    },
                    {
                        displayInfoKey: "energyUseIntensity",
                        value: (data.averageEnergyIntensity ||
                            data.totalEnergyIntensity)!,
                    },
                ],
                [
                    {
                        title: "Natural gas",
                        displayInfoKey: "energyConsumption",
                        value: data.totalEnergyConsumptionNaturalGas,
                    },
                    {
                        title: "Electricity",
                        displayInfoKey: "energyConsumption",
                        value: data.totalEnergyConsumptionElectricity,
                    },
                ],
            ],
        };
    }, [data]);

    if (error) return <ChartErrorState />;

    return <Card loading={loading} data={cardData} />;
}

interface AnnualEnergyConsumptionCardProps {
    year: number;
    query: () => UseQueryResult<AnnualEnergyConsumptionCardData>;
}

function AnnualEnergyConsumptionCard({
    year,
    query,
}: AnnualEnergyConsumptionCardProps) {
    const { data, isLoading, error } = query();

    const dataForYear = useMemo(
        () => data?.find((datum) => datum?.year === year),
        [year, data]
    );

    return (
        <EnergyConsumptionCard
            data={dataForYear}
            error={error?.toString()}
            loading={isLoading}
        />
    );
}

export default FutureProjectionCards;
