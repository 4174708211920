import { Header } from "components/atoms/Typography";
import { ReactNode } from "react";
import { CentralPlantIcon } from "components/atoms/Icon";
import ImageAndDescription from "../ImageAndDescription";

interface SurveyLabelProps {
    label: string;
    image?: ReactNode;
    description?: string;
}

function SurveyLabel({ label, image, description }: SurveyLabelProps) {
    const isCentralPlant = (label === "Central plant")
    
    return (
        <>
            <Header size="medium">
                {isCentralPlant && <CentralPlantIcon  /> }
                <span>{label}</span>
            </Header>
            {image && description && (
                <ImageAndDescription description={description} image={image} />
            )}
        </>
    );
}

export default SurveyLabel;