import { semanticTermToDisplayInfo } from "utils/formatting";
import { uniqueBuildingTypes, uniqueEnergyTypes } from "utils/constants";

export const NumericFilterIsIncomplete = (
    start: number | string | undefined,
    end: number | undefined | string,
    dropdownInput: NumericFilterCondition | StringFilterCondition | undefined
) =>
    dropdownInput === undefined ||
    start === undefined ||
    start === "" ||
    (dropdownInput === "between" && (end === undefined || end === ""));

export const buildMultiSelectDropdownItems = (key: string, page: Page) => {
    const { formatFunction } = semanticTermToDisplayInfo[key];

    const multiSelectKeyToItemsMap = buildMultiSelectKeyToItemsMap(page);

    return multiSelectKeyToItemsMap[key].map((item) => ({
        key: item,
        displayValue: formatFunction ? formatFunction(item) : item,
    }));
};

export const buildMultiSelectKeyToItemsMap = (
    page: Page
): { [key: string]: string[] } => {
    const items = {
        buildingArchetype: uniqueBuildingTypes,
        energyType: uniqueEnergyTypes,
    };

    return {
        ...items,
    };
};

export const getFilterNameFromKey = (key: string) =>
    `${semanticTermToDisplayInfo[key]?.humanReadable} ${
        semanticTermToDisplayInfo[key]?.unit || ""
    }` || "";

export const buildIsFieldActiveMap = (filters: Filters) => {
    const map = new Map();

    const { numericFilters, stringFilters, dateFilters, dataCoverageFilter } =
        filters;

    numericFilters.forEach(({ field }) => {
        map.set(field, true);
    });

    stringFilters.forEach(({ field }) => {
        map.set(field, true);
    });

    dateFilters.forEach(({ field }) => {
        map.set(field, true);
    });

    if (dataCoverageFilter.length > 0) map.set("data_coverage", true);

    return map;
};

export const removeFilter = (filters: Filters, filterField: string) => {
    const copy = cloneFilters(filters);
    if (isNumericFilterFieldType(filterField)) {
        const index = copy.numericFilters.findIndex(
            ({ field }) => filterField === field
        );
        if (index !== -1) copy.numericFilters.splice(index, 1);
    } else if (isStringFilterFieldType(filterField)) {
        const index = copy.stringFilters.findIndex(
            ({ field }) => filterField === field
        );
        if (index !== -1) copy.stringFilters.splice(index, 1);
    } else if (isDateFilterFieldType(filterField)) {
        const index = copy.dateFilters.findIndex(
            ({ field }) => filterField === field
        );
        if (index !== -1) copy.dateFilters.splice(index, 1);
    } else if (filterField === "data_coverage") {
        copy.dataCoverageFilter = [];
    }
    return copy;
};

const isStringFilterFieldType = (key: string): boolean =>
    [
        "building_archetype",
        "building_name",
        "building_name",
        "city",
        "country",
        "property_name",
        "fund_name",
        "state_province",
        "street_address",
        "tag",
    ].includes(key);

const isNumericFilterFieldType = (key: string): boolean =>
    [
        "annual_carbon_emissions",
        "annual_carbon_emission_intensity",
        "annual_energy_consumption",
        "gross_floor_area",
        "year_built_original",
    ].includes(key);

const isDateFilterFieldType = (key: string): boolean => key === "created_at";

export const cloneFilters = (filters: Filters) => ({
    booleanFilters: [...filters.booleanFilters],
    dataCoverageFilter: [...filters.dataCoverageFilter],
    dateFilters: [...filters.dateFilters],
    numericFilters: [...filters.numericFilters],
    stringFilters: [...filters.stringFilters],
});
