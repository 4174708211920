import { UseQueryResult } from "react-query";
import CashFlowChart from "../AnnualCashFlowChart";

interface AnnualCashFlowChartProps {
    query: () => UseQueryResult<AnnualCashFlowChartData>;
}

function AnnualCashFlowChart({ query }: AnnualCashFlowChartProps) {
    return (
        <CashFlowChart
            query={query as () => UseQueryResult<{ [key: string]: number }[]>}
            tableColummnKeys={cashFlowTableColumns}
            barKeys={cashFlowChartBarKeys}
            height={430}
        />
    );
}

const cashFlowTableColumns: (keyof AnnualCashFlowChartDatum)[] = [
    "year",
    "totalIncrementalCost",
    "totalUtilityCostSavings",
    "totalCarbonTaxSavings",
    "cashFlowAnnual",
    "presentValue",
];

const cashFlowChartBarKeys: (keyof AnnualCashFlowChartDatum)[] = [
    "totalIncrementalCost",
    "totalUtilityCostSavings",
    "totalCarbonTaxSavings",
];

export default AnnualCashFlowChart;
