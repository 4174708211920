import { Modal } from "antd";
import { Button } from "components/atoms/Button";
import { RoleActions } from "utils/enums";
import styles from "./DeleteUserModal.module.scss";

interface DeleteUserModalProps {
    modalIsOpen: boolean;
    email: string;
    handleMenuItemClick: (action: string) => void;
    onCancel: () => void;
}

function DeleteUserModal({
    modalIsOpen,
    email,
    onCancel,
    handleMenuItemClick,
}: DeleteUserModalProps) {
    const modalSubmitHandler = () => {
        handleMenuItemClick(RoleActions.Remove);
        onCancel();
    };

    return (
        <Modal
            open={modalIsOpen}
            onCancel={onCancel}
            title="Remove member?"
            className={styles.modalContainer}
            footer={
                <DeleteUserModalFooter
                    onSave={() => modalSubmitHandler()}
                    onCancel={onCancel}
                />
            }
        >
            <div className={styles.firstHeader}>
                <p>
                    {email}
                    <b> will no longer have access to this account.</b> 
                </p>
            </div>
        </Modal>
    );
}

interface DeleteUserModalFooterProps {
    onSave: () => void;
    onCancel: () => void;
}

function DeleteUserModalFooter({
    onSave,
    onCancel,
}: DeleteUserModalFooterProps) {
    return (
        <div className="bulk-edit-tags__footer" style={{ marginTop: "40px" }}>
            <Button
                type="primary"
                onClick={onSave}
                className={styles.warningButton}
            >
                Remove member
            </Button>
            <Button
                type="link"
                onClick={onCancel}
                style={{ color: "var(--audette-black)" }}
            >
                Cancel
            </Button>
        </div>
    );
}

export default DeleteUserModal;
