import { useEffect, useMemo, useState } from "react";
import FormInput from "components/molecules/FormInput";
import { EquipmentAndInstallationYearSurveyItem } from "../../Survey.helpers";

interface SurveyCustomElementProps<T> {
    item: EquipmentAndInstallationYearSurveyItem<T>;
    type: "number" | "string";
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveyCustomElement<T>({ 
    item,
    type,
    surveyPageData,
    setSurveyPageData,
}: SurveyCustomElementProps<T>) {
    const { label, key } = item;

    const [state, setState] = useState<Record<string, string | number | null>>({});
    
    const [error, setError] = useState<boolean>(false);

    const setValue = (value: string | number | null) => {
        setState((prev) => ({
            ...prev,
            [key]: value,
        }));

        setSurveyPageData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    useEffect(() => {
        if (error) setValue(null);
    }, [error]);

    const value = useMemo(() => {
        if (typeof state[key as string] === "number") {
            return state[key as string] as number;
        }
        if (typeof state[key as string] === "string") {
            return state[key as string] as string;
        }
        return undefined;
    }, [state]);

    const isValidFn = (value: string | number): boolean => {
        if (label === "Load Ratio") {
            const numericValue = Number(value);
            return numericValue >= 0 && numericValue <= 1;
        }
        return !!value;
    };

    const errorMessage = label === "Load Ratio" ? "Must be between 0 and 1" : "Cannot be empty"
    
    return (
        <FormInput
            setValue={(v) => setValue(v || null)}
            value={value || ""}
            attributeKey={key.toString()}
            displayInfo={{
                humanReadable: label,
                isValidFn,
                placeholder: item.placeholder,
                errorMessage,
            }}
            type={type}
            setIsError={setError} 
        />
    );
}

export default SurveyCustomElement;
