import { useMemo, useState } from "react";
import { Paragraph } from "components/atoms/Typography";
import {
    endUseValueToDisplayLabelMap,
    endUseValueToColorMap,
    getDisplayInfo,
    displayValueWithUnit,
} from "utils/formatting";
import ChartContainer from "components/molecules/ChartContainer";
import { ResponsiveSankey } from "@nivo/sankey";
import { ChartErrorState } from "components/molecules/ErrorStates";
import "./EndUseSankey.css";
import { Item } from "components/atoms/Dropdown";
import { ChartNoDataState } from "components/molecules/NoDataState";

interface EndUseSankeyProps {
    data: {
        [key: string]: {
            nodes: Nodes[];
            links: Link[];
        };
    } | null;
    loading?: boolean;
    error?: string;
}

interface Nodes {
    id: string;
}

interface Link {
    source: string;
    target: string;
    value: number;
}

function EndUseSankey({ data, loading, error }: EndUseSankeyProps) {
    const [selectedKey, setSelectedKey] = useState(dropdownItems[0].id);

    const sankeyData = useMemo(() => {
        if (!data) return null;
        return data[selectedKey];
    }, [data, selectedKey]);

    if (
        error ||
        sankeyData?.links.length === 0 ||
        sankeyData?.nodes.length === 0
    )
        return <ChartErrorState />;

    if (data === null && !loading) return <ChartNoDataState />;

    return (
        <ChartContainer
            items={dropdownItems}
            onSelectItem={setSelectedKey}
            loading={loading || !sankeyData}
        >
            <div style={{ height: "350px", width: "100%" }}>
                <ResponsiveSankey
                    data={sankeyData!}
                    margin={{ top: 40, right: 125, bottom: 40, left: 80 }}
                    align="justify"
                    nodeOpacity={1}
                    nodeHoverOthersOpacity={0.35}
                    nodeBorderWidth={0}
                    nodeBorderColor={{
                        from: "color",
                        modifiers: [["darker", 0.8]],
                    }}
                    nodeBorderRadius={3}
                    linkOpacity={0.5}
                    colors={(node: any) => node.nodeColor}
                    linkHoverOthersOpacity={0.1}
                    enableLinkGradient={true}
                    labelPosition="outside"
                    labelOrientation="horizontal"
                    labelPadding={24}
                    labelTextColor={{
                        from: "color",
                        modifiers: [["darker", 1]],
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    nodeTooltip={({ node }) => (
                        <SankeyTooltip
                            links={sankeyData!.links}
                            selectedKey={selectedKey}
                            activeSource={
                                node.targetLinks[0]?.source.id || node.id
                            }
                            activeTarget={node.id}
                        />
                    )}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    linkTooltip={({ link }) => (
                        <SankeyTooltip
                            links={sankeyData!.links}
                            selectedKey={selectedKey}
                            activeSource={link.source.id}
                            activeTarget={link.target.id}
                        />
                    )}
                />
            </div>
        </ChartContainer>
    );
}

const dropdownItems: Item[] = [
    {
        displayValue: "Carbon emissions breakdown",
        id: "carbonEmissions",
    },
    {
        displayValue: "Energy usage breakdown",
        id: "energyConsumption",
    },
    {
        displayValue: "Utility cost breakdown",
        id: "utilityCost",
    },
];

interface SankeyTooltipProps {
    links: Link[];
    activeSource: string;
    activeTarget: string;
    selectedKey: string;
}

function SankeyTooltip({
    links,
    activeSource,
    activeTarget,
    selectedKey,
}: SankeyTooltipProps) {
    const dataFilteredBySource = links.filter(
        ({ source }) => source === activeSource
    );

    const totalForFuelType = dataFilteredBySource.reduce(
        (prev, curr) => prev + curr.value,
        0
    );

    const { formatFunction, unit } = getDisplayInfo(
        selectedKey,
        dataFilteredBySource.map((l) => l.value)
    );
    return (
        <div className="sankey-tooltip">
            <div className="sankey-tooltip--rows">
                <Paragraph style={{ color: "var(--audette-gray-500" }}>
                    {activeSource}:
                </Paragraph>
                <Paragraph>
                    {displayValueWithUnit(totalForFuelType, unit, (v) =>
                        formatFunction(v, false)
                    )}
                </Paragraph>
            </div>
            <div className="sankey-tooltip--rows">
                {dataFilteredBySource.map(({ source, target, value }) => {
                    const targetIsActive = target === activeTarget;
                    return (
                        <div
                            key={`${source} ${target}`}
                            className="sankey-tooltip--row"
                        >
                            <Paragraph
                                style={{
                                    color: targetIsActive
                                        ? "var(--audette-purple)"
                                        : "var(--audette-gray-500)",
                                }}
                            >
                                {target}:{" "}
                            </Paragraph>
                            <Paragraph
                                style={{
                                    color: targetIsActive
                                        ? "var(--audette-purple)"
                                        : "var(--audette-black)",
                                }}
                            >
                                {displayValueWithUnit(value, unit, (v) =>
                                    formatFunction(v, false)
                                )}
                            </Paragraph>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export const buildSankeyData = (
    data: {
        fuelType: string;
        endUse: string;
        value: number;
    }[]
) => {
    const uniqueNodes = new Set<string>();
    const links: { source: string; target: string; value: number }[] = [];
    data.forEach(({ fuelType, endUse, value }) => {
        uniqueNodes.add(fuelType);
        uniqueNodes.add(endUse);
        links.push({
            source: endUseValueToDisplayLabelMap[fuelType],
            target: endUseValueToDisplayLabelMap[endUse],
            value,
        });
    });
    const nodes = Array.from(uniqueNodes).map((node) => ({
        id: endUseValueToDisplayLabelMap[node],
        nodeColor: endUseValueToColorMap[node],
    }));
    return { links, nodes };
};

export default EndUseSankey;
