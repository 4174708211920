import { useParams } from "react-router-dom";

export const useBuildingModelUid = () => {
    const { buildingModelUid } = useParams();

    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    return buildingModelUid;
};
