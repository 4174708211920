import { ReactNode, useState } from "react";
import styles from "./HoverableDropdown.module.scss";
import { Paragraph, Header as HeaderText } from "../Typography";
import { DownChevronIcon } from "../Icon";

interface Props {
    dropdownElements: ReactNode;
    userEmail: string;
    customerAccounts: CustomerAccount[];
    activeOrganization: string;
}
function HoverableDropdown({ 
        dropdownElements, 
        userEmail, 
        customerAccounts, 
        activeOrganization 
    }: Props) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className={styles.hoverableDropdown}>
            <button
                onClick={toggleDropdown}
                onKeyDown={toggleDropdown}
                type="button"
            >
                <div className="user-settings">
                    <Paragraph size="small">
                        {userEmail ? userEmail[0].toUpperCase() : ""}
                    </Paragraph>
                </div>
                {customerAccounts.length > 1 && (
                    <HeaderText size="small">
                        <span className={`tab ${styles.tab}`}>
                            {activeOrganization}
                        </span>
                    </HeaderText>
                )}
                <DownChevronIcon />
            </button>
            {isDropdownOpen && (
                <div>
                    <div className={styles.menuContainer}>
                        <div className={styles.dropdown}>
                            {dropdownElements}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default HoverableDropdown;
