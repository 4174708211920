import { Header } from "components/atoms/Typography";
import { SurveyUIStructBoolean } from "../../Survey.helpers";
import ImageAndDescription from "../ImageAndDescription";
import BooleanHeaderSelect from "../BooleanHeaderSelect";

interface SurveyBooleanProps<T> {
    surveyBooleanStruct: SurveyUIStructBoolean<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveyBoolean<T>({
    surveyBooleanStruct,
    surveyPageData,
    setSurveyPageData,
}: SurveyBooleanProps<T>) {
    const { image, description, key, label } = surveyBooleanStruct;

    return (
        <>
            <Header size="medium">{label}</Header>
            {image && description && (
                <ImageAndDescription description={description} image={image} />
            )}
            <BooleanHeaderSelect
                onSelectYes={() => {
                    setSurveyPageData((prev) => ({
                        ...prev,
                        [key]: true,
                    }));
                }}
                onSelectNo={() => {
                    setSurveyPageData((prev) => ({
                        ...prev,
                        [key]: false,
                    }));
                }}
                currentValue={
                    surveyPageData[key] === null
                        ? undefined
                        : !!surveyPageData[key]
                }
            />
        </>
    );
}

export default SurveyBoolean;