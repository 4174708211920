import { ChangeEvent, useEffect, useMemo, useState } from "react";
import SingleSelectDropdown from "components/atoms/Dropdown";
import { getDisplayInfo } from "utils/formatting";
import { NumericStringMultiFilterProps } from "../FilterSidebar.interface";
import { filterConditionToDisplayValueMap, StringSelectConditions } from "../FilterSidebar.constants";
import { cloneFilters } from "../FilterSidebar.helpers";


function StringFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<StringFilterField>) {
    const currentFilter = useMemo(
        () => filters.stringFilters.find(({ field }) => field === filterField),
        [filters]
    );

    const [value, setValue] = useState(currentFilter?.value[0]);

    const [dropdownInput, setDropdownInput] = useState(
        currentFilter?.condition
    );

    useEffect(() => {
        setDropdownInput(currentFilter?.condition);
        setValue(currentFilter?.value[0]);
    }, [currentFilter]);

    const dropdownItems = StringSelectConditions.map((id) => ({
        id,
        displayValue: filterConditionToDisplayValueMap[id],
    }));

    const { placeholder } = getDisplayInfo(filterKey);

    useEffect(() => {
        if (value === "" || value === undefined || dropdownInput === undefined)
            return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: StringFilter = {
                value: [value],
                condition: dropdownInput,
                field: filterField,
                key: filterKey,
            };

            const index = copy.stringFilters.findIndex(
                ({ field }) => field === filterField
            );
            if (index === -1) copy.stringFilters.push(newFilter);
            else copy.stringFilters[index] = newFilter;
            return copy;
        });
    }, [dropdownInput, value]);

    const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === undefined || value === "") setValue(undefined);
        else setValue(value);
    };

    return (
        <div className="filter">
            <SingleSelectDropdown
                items={dropdownItems}
                onSelectItem={(item) => setDropdownInput(item.id)}
                defaultItem={dropdownItems.find(
                    ({ id }) => id === dropdownInput
                )}
                classname="filter__dropdown"
            />
            <input
                value={value || ""}
                className="filter-input"
                onChange={updateValue}
                placeholder={placeholder}
                maxLength={50}
            />
        </div>
    );
}

export default StringFilter;