import { Button } from "components/atoms/Button";
import CancelButton from "components/atoms/CancelButton/CancelButton";
import { Header, Paragraph } from "components/atoms/Typography";
import FootprintsMap, { MapAddress } from "components/molecules/FootprintsMap";
import SidebarLoading from "components/molecules/SidebarLoading";
import useAlert from "hooks/useAlert";
import { useBuildingFootprintsQuery } from "queries/useBuildingFootprintsQuery";
import { useEffect } from "react";
import { showMutationAlert } from "utils/helpers";

interface BuildingFootprintSelectProps {
    address: Address;
    handleAddBuilding: () => void;
    onCancel: () => void;
    selectedFootprintPolygon: FootprintPolygon | undefined;
    setSelectedFootprintPolygon: React.Dispatch<
        React.SetStateAction<FootprintPolygon | undefined>
    >;
}

function BuildingFootprintSelect({
    address,
    handleAddBuilding,
    onCancel,
    selectedFootprintPolygon,
    setSelectedFootprintPolygon,
}: BuildingFootprintSelectProps) {
    const {
        data: footprints,
        isLoading: footprintsLoading,
        error,
    } = useBuildingFootprintsQuery(address as Address);

    const alert = useAlert();


    useEffect(() => {
        if (footprints?.closestBuildingUid && footprints?.features.length > 0) {
            const closestMatchingBuildingPolygonIndex =
                footprints?.features.findIndex(
                    ({ properties }) =>
                        properties.buildingUid ===
                        footprints?.closestBuildingUid
                );
            setSelectedFootprintPolygon(
                footprints?.features[
                    closestMatchingBuildingPolygonIndex
                ] as FootprintPolygon
            );
        }
    }, [footprints]);

    if (footprintsLoading) return <SidebarLoading />;
    if (error || !footprints) {
        showMutationAlert(error, alert);

        return <>Error...</>;
    }

    return (
        <>
            <div className="add-building-sidebar">
                <div style={{ display: "grid", gap: "16px" }}>
                    <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                        STEP 2/2
                    </Paragraph>
                    <Header size="medium">Select footprint</Header>
                </div>
                {!footprints.closestBuildingUid ||
                footprints.features.length === 0 ? (
                    <>
                        <MapAddress address={address} />
                        <Paragraph>
                            No footprint found, please enter valid address
                        </Paragraph>
                    </>
                ) : (
                    <FootprintsMap
                        buildingFootprints={footprints}
                        buildingUid={footprints.closestBuildingUid}
                        address={address}
                        setSelectedFootprintPolygon={
                            setSelectedFootprintPolygon
                        }
                        isEditing={true}
                    />
                )}
            </div>
            <div className="add-building-sidebar--footer">
                <Button
                    style={{ width: "76px" }}
                    type="primary"
                    disabled={selectedFootprintPolygon === undefined}
                    onClick={handleAddBuilding}
                >
                    Add
                </Button>
                <CancelButton onCancel={onCancel} />
            </div>
        </>
    );
}

export default BuildingFootprintSelect;