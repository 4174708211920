import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildReportsRequestVariables,
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.reports?.recommendationsReport?.annualCarbonEmissionsElectricityChart.map(
                (item) => {
                    const endUses: { [endUseKey: string]: number } = {};
                    const year = item?.year || 0;
                    item?.endUseCarbonEmissions.forEach((use) => {
                        const endUseKey = use?.endUse;
                        const carbonEmissions = use?.carbonEmissions || 0;
                        if (endUseKey) endUses[endUseKey] = carbonEmissions;
                    });
                    return {
                        year,
                        ...endUses,
                    };
                }
            ) as AnnualCarbonEmissionsElectricityChartData) || null
    );

const query = graphql(`
    query queryAnnualCarbonEmissionsElectricityChart(
        $reportFilter: ReportFilter
    ) {
        reports(reportFilter: $reportFilter) {
            recommendationsReport {
                annualCarbonEmissionsElectricityChart {
                    year
                    endUseCarbonEmissions {
                        endUse
                        carbonEmissions
                    }
                }
            }
        }
    }
`);

export default queryFn;
