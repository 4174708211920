import DateFilter from "components/organisms/FiltersSidebar/Filter/DateFilter";
import MultiSelectFilter from "components/organisms/FiltersSidebar/Filter/MultiSelectFilter";
import NumericFilter from "components/organisms/FiltersSidebar/Filter/NumericFilter";
import StringFilter from "components/organisms/FiltersSidebar/Filter/StringFilter";

interface FilterProps {
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    filterField:
        | StringFilterField
        | NumericFilterField
        | DateFilterField
        | "data_coverage";
    filterKey: string;
    type: "multi-select" | "text" | "numeric" | "date";
}

function Filter({
    filters,
    setFilters,
    filterField,
    filterKey,
    type,
}: FilterProps) {
    if (type === "date")
        return (
            <DateFilter
                filters={filters}
                setFilters={setFilters}
                filterField={filterField as DateFilterField}
                filterKey={filterKey}
            />
        );

    if (type === "numeric")
        return (
            <NumericFilter
                filters={filters}
                setFilters={setFilters}
                filterField={filterField as NumericFilterField}
                filterKey={filterKey}
            />
        );

    if (type === "multi-select")
        return (
            <MultiSelectFilter
                filters={filters}
                setFilters={setFilters}
                filterField={filterField as StringFilterField}
                filterKey={filterKey}
            />
        );

    if (type === "text")
        return (
            <StringFilter
                filters={filters}
                setFilters={setFilters}
                filterField={filterField as StringFilterField}
                filterKey={filterKey}
            />
        );

    return null;
}

export default Filter;