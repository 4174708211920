import { Button } from "components/atoms/Button";

interface SurveyFooterProps {
    handleNextClick?: () => void;
    handleSaveClick?: () => void;
    handleBackClick?: () => void;
    nextButtonDisabled?: boolean;
    saveButtonDisabled?: boolean;
}

function SurveyFooter({
    handleNextClick,
    handleBackClick,
    handleSaveClick,
    nextButtonDisabled = false,
    saveButtonDisabled = false,
}: SurveyFooterProps) {
    return (
        <div className="building-equipment-sidebar__footer">
            <Button
                type="primary"
                onClick={handleSaveClick || handleNextClick}
                disabled={saveButtonDisabled || nextButtonDisabled}
            >
                {handleSaveClick ? "Save" : "Next"}
            </Button>
            {handleBackClick && (
                <Button type="link" onClick={handleBackClick}>
                    Back
                </Button>
            )}
        </div>
    );
}

export default SurveyFooter;