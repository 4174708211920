import { Header, Paragraph } from "components/atoms/Typography";
import { WarningIcon } from "components/atoms/Icon";
import {
    buildRooftopUnitDisplayStruct,
    buildAirHandlingEquipmentDisplayStruct,
    buildCentralPlantDisplayStruct,
    buildTerminalUnitsDisplayStruct,
    buildDomesticHotWaterDisplayStruct,
    buildOtherEquipmentDisplayStruct,
    DataViewStruct,
} from "./DataView.helpers";

interface DataViewProps {
    data: EquipmentSurvey;
    showEquipmentWarning?: boolean;
    hasNaturalGasWarning?: boolean;
}

export function DataView({
    data,
    showEquipmentWarning,
    hasNaturalGasWarning,
}: DataViewProps) {
    const rooftopUnitStructure = buildRooftopUnitDisplayStruct(
        data.rooftopUnit,
        showEquipmentWarning,
        hasNaturalGasWarning
    );
    const airHandlingEquipmentStructure =
        buildAirHandlingEquipmentDisplayStruct(
            data.airHandlingEquipment,
            showEquipmentWarning,
            hasNaturalGasWarning
        );

    const centralPlantStructure = buildCentralPlantDisplayStruct(
        data.centralPlantHeater,
        data.centralPlantCooler,
        data.centralPlantHeatPump,
        data.genericHVACEquipment,
        showEquipmentWarning,
        hasNaturalGasWarning
    );

    const terminalUnitsStructure = buildTerminalUnitsDisplayStruct(
        {
            ...data.terminalHeater,
            ...data.terminalCooler,
        },
        { ...data.heatPump },
        showEquipmentWarning,
        hasNaturalGasWarning
    );

    const domesticHotWaterStructure = buildDomesticHotWaterDisplayStruct(
        data.domesticHotWaterHeater,
        showEquipmentWarning,
        hasNaturalGasWarning
    );

    const otherEquipmentStructure = buildOtherEquipmentDisplayStruct(
        data.otherEquipment,
        showEquipmentWarning,
        hasNaturalGasWarning
    );

    return (
        <div>
            <EquipmentSection structure={rooftopUnitStructure} />
            <EquipmentSection structure={airHandlingEquipmentStructure} />
            <EquipmentSection structure={centralPlantStructure} />
            <EquipmentSection structure={terminalUnitsStructure} />
            <EquipmentSection structure={domesticHotWaterStructure} />
            <EquipmentSection structure={otherEquipmentStructure} />
        </div>
    );
}

interface EquipmentSectionProps {
    structure: DataViewStruct;
}

function EquipmentSection({ structure }: EquipmentSectionProps) {
    return (
        <div className="equipment-section">
            <Header size="small">{structure.title}</Header>
            {structure.items.filter(({ show }) => show).length === 0 ? (
                <Paragraph style={{ color: "var(--audette-gray-500" }}>
                    Not defined
                </Paragraph>
            ) : (
                <div className="equipment-section__items">
                    {structure.items.map(
                        ({
                            label,
                            value,
                            show,
                            unit,
                            icon,
                            hasMissingSizeAndYear,
                            showNaturalGasWarning,
                        }, index) => {
                            if (label === "separator") {
                                return (
                                    <hr
                                        key={label}
                                        style={{
                                            width: "100%",
                                            borderTop: "1px solid var(--audette-gray-200)",
                                            margin: "12px 0",
                                        }}
                                    />
                                );
                            }
                            if (show)
                                return (
                                    <div
                                    key={label + value}
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            alignItems: "center",
                                        }}
                                    >
                                        {icon && icon}
                                        <Paragraph
                                            style={{
                                                color: "var(--audette-gray-500",
                                                paddingLeft: icon
                                                    ? "0"
                                                    : "24px",
                                            }}
                                        >
                                            {label}:
                                        </Paragraph>
                                        <Paragraph
                                            style={{
                                                display: "flex",
                                                gap: "4px",
                                                alignItems: "center",
                                            }}
                                        >
                                            {value} {unit}{" "}
                                            {showNaturalGasWarning && (
                                                <WarningIcon />
                                            )}
                                        </Paragraph>
                                    </div>
                                );
                            if (!show && hasMissingSizeAndYear)
                                return (
                                    <div
                                        key={label}
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                            alignItems: "center",
                                        }}
                                    >
                                        {icon && icon}
                                        <Paragraph
                                            style={{
                                                color: "var(--audette-gray-500",
                                                paddingLeft: icon
                                                    ? "0"
                                                    : "24px",
                                            }}
                                        >
                                            {label}:
                                        </Paragraph>
                                        <Paragraph>
                                            {hasMissingSizeAndYear && (
                                                <WarningIcon />
                                            )}
                                        </Paragraph>
                                    </div>
                                );
                            return null;
                        }
                    )}
                </div>
            )}
        </div>
    );
}
