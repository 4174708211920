import classNames from "classnames";
import { Paragraph } from "components/atoms/Typography";

interface BooleanHeaderSelectProps {
    onSelectYes: () => void;
    onSelectNo: () => void;
    currentValue?: boolean;
}

function BooleanHeaderSelect({
    onSelectYes,
    onSelectNo,
    currentValue,
}: BooleanHeaderSelectProps) {
    return (
        <Paragraph className="boolean-header-select" size="small">
            <button
                className={classNames(currentValue === true && "focus")}
                type="button"
                onClick={onSelectYes}
            >
                Yes
            </button>
            <button
                className={classNames(currentValue === false && "focus")}
                type="button"
                onClick={onSelectNo}
            >
                No
            </button>
        </Paragraph>
    );
}

export default BooleanHeaderSelect;