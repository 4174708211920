// csv uploader
import { IDeveloperField } from "dromo-uploader-react";

// components
import DromoCsvUploader from "components/molecules/DromoCsvUploader";

// hooks
import posthog from "posthog-js";
import { useCreateBuildingModelsMutation } from "mutations/useCreateBuildingModelsMutation";

// constants
import { buildingTypeOptions, uniqueBuildingTypes } from "utils/constants";
import useAlert from "hooks/useAlert";

function BuildingsCsvUploader() {
    const { mutate: createBuildingModels } = useCreateBuildingModelsMutation();
    const alert = useAlert();

    const onResults = (response: any) => {
        const buildingModelsInput = buildBuildingModelsInput(response);
        createBuildingModels({ buildingModels: buildingModelsInput });
        alert.success(
            "Your buildings have been submitted. Please allow up to several minutes."
        );
        posthog.capture("Buildings imported", { added_buildings_count: buildingModelsInput.length });
    };

    return (
        <DromoCsvUploader
            onResults={onResults}
            validatorWorkflowStep="user_adds_a_building"
            fieldValues={fieldValues}
            importIdentifier="buildings"
            importButtonStyle="secondary"
        />
    );
}
const buildingTypeSelectOptions = uniqueBuildingTypes.map((type) => ({
    label: buildingTypeOptions.find(({ key }) => key === type)?.label!,
    value: type,
}));

const energyTypeOptions = [
    {
        label: "Yes",
        value: "mixed",
    },
    {
        label: "No",
        value: "electric",
    },
    {
        label: "I don't know",
        value: "mixed_inferred",
    },
];

export const fieldValues: IDeveloperField[] = [
    {
        label: "Street address",
        key: "street_address",
        validators: [{ validate: "required" }],
    },
    {
        label: "City",
        key: "city",
        validators: [{ validate: "required" }],
    },
    {
        label: "State/Province",
        key: "state_province",
        validators: [{ validate: "required" }],
    },
    {
        label: "Postal code",
        key: "postal_code",
        validators: [{ validate: "required" }],
    },
    {
        label: "Country",
        key: "country",
        validators: [{ validate: "required" }],
    },
    {
        label: "Latitude",
        key: "latitude",
        type: "number",
    },
    {
        label: "Longitude",
        key: "longitude",
        type: "number",
    },
    {
        label: "Building name",
        key: "building_name",
    },
    {
        label: "Building type",
        key: "building_type",
        validators: [{ validate: "required" }],
        type: "select",
        selectOptions: buildingTypeSelectOptions,
    },
    {
        label: "Is this building serviced by natural gas?",
        key: "energy_type",
        type: "select",
        selectOptions: energyTypeOptions,
    },
    {
        label: "Number of floors",
        key: "floors_above_grade",
        validators: [{ validate: "required" }],
        type: "number",
    },
    {
        label: "Gross floor area ft²",
        key: "gross_floor_area",
        validators: [{ validate: "required" }],
        type: "number",
    },
    {
        label: "Building Height ft",
        key: "building_height",
        type: "number",
    },
    {
        label: "Year built",
        key: "year_built_original",
        validators: [{ validate: "required" }],
        alternateMatches: ["vintage", "age"],
        type: [
            "number",
            {
                outputFormat: {
                    thousandSeparated: false,
                },
                round: 0,
            },
        ],
    },
    {
        label: "Tags",
        key: "tags",
    },
    {
        label: "Property",
        key: "property",
    },
];

const buildBuildingModelsInput = (response: any) =>
    response.map((row: any) => {
        const input = {
            address: {
                streetAddress: row.street_address,
                city: row.city,
                stateProvince: row.state_province,
                postalZipCode: row.postal_code,
                country: row.country,
                latitude: row.latitude === "" ? null : row.latitude,
                longitude: row.longitude === "" ? null : row.longitude,
            },
            buildingProperties: {
                buildingArchetype: row.building_type,
                buildingHeight:
                    row.building_height === "" ? null : row.building_height, // have to convert optional field to null before graphQL
                energyType: row.energy_type,
                floorsAboveGrade: row.floors_above_grade,
                grossFloorArea: row.gross_floor_area,
                yearBuiltOriginal: Number(row.year_built_original), // have to convert to number, bug in dromo?
            },
        };
        if (row.building_name)
            Object.assign(input, { buildingName: row.building_name });
        if (row.tags) Object.assign(input, { tags: row.tags.split(",") });
        if (row.property) Object.assign(input, { propertyName: row.property });
        return input;
    });

export default BuildingsCsvUploader;
