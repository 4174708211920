import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildReportsRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            data.reports?.baselineReport
                ?.buildingBarChartAndMap as BuildingBarChartAndMap[]
    );

const query = graphql(`
    query buildingBarChartAndMap($reportFilter: ReportFilter) {
        reports(reportFilter: $reportFilter) {
            baselineReport {
                buildingBarChartAndMap {
                    annualCarbonEmissionIntensityCurrent
                    annualCarbonEmissionsCurrent
                    annualEnergyConsumptionCurrent
                    annualEnergyUseIntensityCurrent
                    buildingModelUid
                    location {
                        streetAddress
                        city
                        stateProvince
                        postalZipCode
                        country
                        latitude
                        longitude
                        climateZone
                    }
                }
            }
        }
    }
`);

export default queryFn;
