import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.buildingModelUtilityData.error)
            throw new Error(data.buildingModelUtilityData.error);
        return data.buildingModelUtilityData
            .buildingModelUtilityData as BuildingModelUtilityData[];
    });

const query = graphql(`
    query buildingModelUtilityData($buildingModelUid: Uuid!) {
        buildingModelUtilityData(buildingModelUid: $buildingModelUid) {
            error
            buildingModelUtilityData {
                accountNumber
                currency
                utilityRate
                endDate
                energyConsumption
                energyConsumptionUnit
                insertTime
                startDate
                utilityCost
                utilityProvider
                utilityType
            }
        }
    }
`);

export default queryFn;
