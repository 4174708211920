import { useQueryClient } from "react-query";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useUpdateBuildingModelMutation } from "mutations/useUpdateBuildingModelMutation";

export const useQueryOnStatusUpdate = (buildingModelUid: string) => {
    const queryClient = useQueryClient();
    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);

    const reset = () => {
        setLoadingState((prev) => ({
            ...prev,
            energySidebarLoading: false,
            buildingReportsLoading: false,
        }));
        queryClient.resetQueries({
            queryKey: ["energyAggregation", buildingModelUid],
        });
        queryClient.resetQueries({
            queryKey: ["buildingModelUtilityData", buildingModelUid],
        });

        queryClient.resetQueries({
            queryKey: [buildingModelUid, "buildingModelReport"],
        });

        queryClient.invalidateQueries({
            queryKey: ["buildingModelHeader", buildingModelUid],
        });

        queryClient.invalidateQueries({
            queryKey: ["buildingModelsTable"],
        });
    };

    return { onSuccess: reset, onError: reset };
};

export const useUpdateBuildingModel = (buildingModelUid: string) => {
    const { onSuccess, onError } =
        useEnergyTypeOnStatusUpdate(buildingModelUid);

    const { mutate: updateBuildingModel, error } =
        useUpdateBuildingModelMutation(buildingModelUid, onSuccess, onError);

    return { updateBuildingModel, error };
};

const useEnergyTypeOnStatusUpdate = (buildingModelUid: string) => {
    const queryClient = useQueryClient();
    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);

    const reset = () => {
        setLoadingState((prev) => ({
            ...prev,
            constructionSidebarLoading: false,
            buildingReportsLoading: false,
        }));
        queryClient.resetQueries({
            queryKey: [buildingModelUid, "buildingModelReport"],
        });

        queryClient.resetQueries({
            queryKey: [buildingModelUid, "buildingModelConstruction"],
        });

        queryClient.invalidateQueries({
            queryKey: ["buildingModelHeader", buildingModelUid],
        });

        queryClient.invalidateQueries({
            queryKey: ["buildingModelsTable"],
        });
    };

    return { onSuccess: reset, onError: reset };
};
