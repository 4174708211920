import RadioButton from "components/atoms/RadioButton";
import Slider from "@mui/material/Slider";
import { Paragraph } from "components/atoms/Typography";
import { CURRENT_YEAR } from "utils/constants";

export type RadioButtonSelectMode = "annual" | "cumulative";

interface AnnualCumulativePickerProps {
    selected: RadioButtonSelectMode;
    onChangeSelect: (value: RadioButtonSelectMode) => void;
    currentSliderYear: number;
    setCurrentSliderYear: (year: number) => void;
}

function AnnualCumulativePicker({
    selected,
    onChangeSelect,
    currentSliderYear,
    setCurrentSliderYear,
}: AnnualCumulativePickerProps) {
    const onUpdateSlider = (event: Event) => {
        const { value } = event.target as any;
        if (value) setCurrentSliderYear(value);
    };

    return (
        <>
            <RadioButton
                checked={selected === "cumulative"}
                onChange={(v) => onChangeSelect(v as RadioButtonSelectMode)}
                label={`Cumulative (${CURRENT_YEAR} - 2050)`}
                value="cumulative"
            />
            <RadioButton
                checked={selected === "annual"}
                onChange={(v) => onChangeSelect(v as RadioButtonSelectMode)}
                label="Annual"
                value="annual"
            >
                {selected === "annual" && (
                    <>
                        <Paragraph style={{ color: "var(--audette-pink)" }}>
                            {currentSliderYear}
                        </Paragraph>
                        <br />
                        <Slider
                            defaultValue={CURRENT_YEAR}
                            value={currentSliderYear}
                            min={CURRENT_YEAR}
                            max={2050}
                            step={1}
                            onChange={onUpdateSlider}
                        />
                    </>
                )}
            </RadioButton>
        </>
    );
}

export default AnnualCumulativePicker;
