import { useMemo } from "react";
import { getDisplayInfo } from "utils/formatting";
import { ChartErrorState } from "components/molecules/ErrorStates";
import ChartContainer from "components/molecules/ChartContainer";
import { AreaChart } from "components/molecules/Charts";
import { AreaChartDatum } from "components/molecules/Charts/AreaChart";
import { UseQueryResult } from "react-query";
import { CURRENT_YEAR, END_YEAR } from "utils/constants";
import { ChartNoDataState } from "components/molecules/NoDataState";

interface AnnualCarbonEmissionsChartProps {
    query: () => UseQueryResult<AnnualCarbonEmissionsChartData | null>;
}

function AnnualCarbonEmissionsChart({
    query,
}: AnnualCarbonEmissionsChartProps) {
    const { data, isLoading, error } = query();

    const dataWithCalculatedGoalLine = useMemo((): AreaChartDatum[] => {
        if (!data || data?.length === 0) return [];

        // calculate goal line (from current year emissions to zero emissions in 2050)
        const y1 = 0;
        const y0 =
            data.find(({ year }) => year === CURRENT_YEAR)
                ?.totalCarbonEmissionsBaseline || 0;
        const m = (y1 - y0) / (END_YEAR - CURRENT_YEAR);
        const c = 0 - m * END_YEAR;

        const baselineData: { x: number; y: number }[] = [];
        const goalData: { x: number; y: number }[] = [];
        const recommendationsData: { x: number; y: number }[] = [];

        data.forEach(
            ({
                year: x,
                totalCarbonEmissionsBaseline,
                totalCarbonEmissionsGoal,
                totalCarbonEmissionsRecommendations,
            }) => {
                baselineData.push({
                    x,
                    y: totalCarbonEmissionsBaseline || 0,
                });
                recommendationsData.push({
                    x,
                    y:
                        totalCarbonEmissionsGoal ||
                        totalCarbonEmissionsRecommendations ||
                        0,
                });
                goalData.push({
                    x,
                    y: m * x + c,
                });
            }
        );

        return [
            {
                type: "line",
                id: "Baseline",
                color: "var(--audette-chart-baseline)",
                data: baselineData,
                formatKey: "annualCarbonEmissionsCurrent",
            },
            {
                type: "line",
                id: "Goal",
                color: "var(--audette-chart-goal)",
                data: goalData,
                formatKey: "annualCarbonEmissionsCurrent",
            },
            {
                type: "area",
                id: "Recommendations",
                color: "var(--audette-chart-recommendations)",
                data: recommendationsData,
                formatKey: "annualCarbonEmissionsCurrent",
            },
        ];
    }, [data]);

    const { unit, formatFunction, humanReadable } = useMemo(
        () =>
            getDisplayInfo(
                "annualCarbonEmissionsCurrent",
                dataWithCalculatedGoalLine.length > 0
                    ? dataWithCalculatedGoalLine[1].data.map(({ y }) => y)
                    : 0
            ),
        [dataWithCalculatedGoalLine]
    );

    if (error) return <ChartErrorState />;
    if (data === null) return <ChartNoDataState />;

    return (
        <ChartContainer title={humanReadable} loading={isLoading}>
            <AreaChart
                data={dataWithCalculatedGoalLine}
                yAxisInfo={{
                    formatFunction: (v: string | number) =>
                        formatFunction(v, false),
                    unit: unit || "",
                }}
            />
        </ChartContainer>
    );
}

export default AnnualCarbonEmissionsChart;
