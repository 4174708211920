import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildReportsRequestVariables,
    buildRequestHeaders,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.reports?.recommendationsReport
                .cumulativeNetPresentValueCard as CumulativeNetPresentValueCardData) ||
            null
    );

const query = graphql(`
    query recommendationsCumulativeNetPresentValueCard(
        $reportFilter: ReportFilter
    ) {
        reports(reportFilter: $reportFilter) {
            recommendationsReport {
                cumulativeNetPresentValueCard {
                    netPresentValue
                    discountRate
                    years
                    totalMeasureCost
                    totalLikeForLikeCost
                    totalIncrementalCost
                    totalEnergyCostSavings
                    returnOnInvestment
                    averageMarginalAbatementCost
                }
            }
        }
    }
`);

export default queryFn;
