import React, {  useState } from "react";
import {
    useDefaultEquipmentSurveyTypes,
} from "./Survey.hooks";

import {
    ActiveView,
} from "./Survey.constants";

import EquipmentSizeAndInstallationYearSurvey from "./Steps/EquipmentSizeAndInstallationYearSurvey";
import OtherEquipmentSurvey from "./Steps/OtherEquipmentSurvey";
import DomesticHotWaterHeaterSurvey from "./Steps/DomesticHotWaterHeaterSurvey";
import TerminalUnitsSurveyView from "./Steps/TerminalUnitsSurveyView";
import CentralPlantSurveyView from "./Steps/CentralPlantSurveyView";
import AirHandlingEquipmentSurveyView from "./Steps/AirHandlingEquipmentSurveyView";
import RoofTopUnitsSurveyView from "./Steps/RoofTopUnitsSurveyView";

interface SurveyProps {
    setShowSurveyView: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Survey({ setShowSurveyView }: SurveyProps) {
    const [currentActiveView, setCurrentActiveView] = useState<ActiveView>(
        ActiveView.rooftopUnit
    );
    const [pageStack, setPageStack] = useState<ActiveView[]>([]);

    const [
        booleanQuestionResultSizeAndYearSurvey,
        setBooleanQuestionResultSizeAndYearSurvey,
    ] = useState<boolean | null>(null);

    const {
        rooftopUnit,
        setRooftopUnit,
        airHandlingEquipment,
        setAirHandlingEquipment,
        centralPlantCooler,
        setCentralPlantCooler,
        centralPlantHeater,
        setCentralPlantHeater,
        centralPlantHeatPump,
        setCentralPlantHeatPump,
        centralPlantGenericEquipment,
        setCentralPlantGenericEquipment,
        terminalCooler,
        setTerminalCooler,
        terminalHeater,
        setTerminalHeater,
        terminalHeatPump,
        setTerminalHeatPump,
        domesticHotWaterHeater,
        setDomesticHotWaterHeater,
        otherEquipment,
        setOtherEquipment,
    } = useDefaultEquipmentSurveyTypes();

    return (
        <>
            {currentActiveView === ActiveView.rooftopUnit && (
                <RoofTopUnitsSurveyView
                    roofTopUnit={rooftopUnit}
                    setRooftopUnit={setRooftopUnit}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    airHandlingEquipment={airHandlingEquipment}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.airHandlingEquipment && (
                <AirHandlingEquipmentSurveyView
                    airHandlingEquipment={airHandlingEquipment}
                    setAirHandlingEquipment={setAirHandlingEquipment}
                    rooftopUnit={rooftopUnit}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.centralPlantHeaterCooler && (
                <CentralPlantSurveyView
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    centralPlantCooler={centralPlantCooler}
                    setCentralPlantCooler={setCentralPlantCooler}
                    centralPlantHeater={centralPlantHeater}
                    setCentralPlantHeater={setCentralPlantHeater}
                    centralPlantHeatPump={centralPlantHeatPump}
                    setCentralPlantHeatPump={setCentralPlantHeatPump}
                    setHeatPump={setTerminalHeatPump}
                    airHandlingEquipment={airHandlingEquipment} 
                    centralPlantGenericEquipment={centralPlantGenericEquipment}
                    setCentralPlantGenericEquipment={setCentralPlantGenericEquipment}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.terminalUnits && (
                <TerminalUnitsSurveyView
                    terminalCooler={terminalCooler}
                    setTerminalCooler={setTerminalCooler}
                    terminalHeater={terminalHeater}
                    setTerminalHeater={setTerminalHeater}
                    airHandlingEquipment={airHandlingEquipment}
                    roofTopUnit={rooftopUnit}
                    centralPlantCooler={centralPlantCooler}
                    centralPlantHeater={centralPlantHeater}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.domesticHotWaterHeater && (
                <DomesticHotWaterHeaterSurvey
                    domesticHotWaterHeater={domesticHotWaterHeater}
                    centralPlantHeater={centralPlantHeater}
                    setDomesticHotWaterHeater={setDomesticHotWaterHeater}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.otherEquipment && (
                <OtherEquipmentSurvey
                    otherEquipment={otherEquipment}
                    setOtherEquipment={setOtherEquipment}
                    setActiveView={setCurrentActiveView}
                    activeView={currentActiveView}
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
            {currentActiveView === ActiveView.sizeAndInstallationYear && (
                <EquipmentSizeAndInstallationYearSurvey
                    rooftopUnit={rooftopUnit}
                    setRooftopUnit={setRooftopUnit}
                    airHandlingEquipment={airHandlingEquipment}
                    setAirHandlingEqipment={setAirHandlingEquipment}
                    centralPlantCooler={centralPlantCooler}
                    setCentralPlantCooler={setCentralPlantCooler}
                    centralPlantHeater={centralPlantHeater}
                    setCentralPlantHeater={setCentralPlantHeater}
                    centralPlantHeatPump={centralPlantHeatPump}
                    setCentralPlantHeatPump={setCentralPlantHeatPump}
                    centralPlantGenericEquipment={centralPlantGenericEquipment}
                    setCentralPlantGenericEquipment={setCentralPlantGenericEquipment}
                    terminalCooler={terminalCooler}
                    setTerminalCooler={setTerminalCooler}
                    terminalHeater={terminalHeater}
                    setTerminalHeater={setTerminalHeater}
                    terminalHeatPump={terminalHeatPump}
                    setTerminalHeatPump={setTerminalHeatPump}
                    domesticHotWaterHeater={domesticHotWaterHeater}
                    setDomesticHotWaterHeater={setDomesticHotWaterHeater}
                    otherEquipment={otherEquipment}
                    setOtherEquipment={setOtherEquipment}
                    setActiveView={setCurrentActiveView}
                    setShowSurveyView={setShowSurveyView}
                    booleanQuestionResult={
                        booleanQuestionResultSizeAndYearSurvey
                    }
                    setBooleanQuestionResult={
                        setBooleanQuestionResultSizeAndYearSurvey
                    }
                    pageStack={pageStack}
                    setPageStack={setPageStack}
                />
            )}
        </>
    );
}
