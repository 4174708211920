import { Header, Paragraph } from "components/atoms/Typography";
import { SurveyUIStructText } from "../../Survey.helpers";
import { equipmentKeyToLabelMap } from "../Equipment/BuildingEquipment.constants";

interface SurveyTextProps<T> {
    label: string;
    textStruct: SurveyUIStructText<T>;
}

export function SurveyText<T>({ label, textStruct }: SurveyTextProps<T>) {
    return (
        <div className="building-equipment-survey-view__section">
            <Header size="small">{label}</Header>
            <Paragraph>{equipmentKeyToLabelMap[textStruct.value]}</Paragraph>
        </div>
    );
}