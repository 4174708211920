import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildReportsRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            (data.reports?.recommendationsReport
                .annualCarbonEmissionsChart as AnnualCarbonEmissionsChartData) ||
            null
    );

const query = graphql(`
    query recommendationsAnnualCarbonEmissionsChart(
        $reportFilter: ReportFilter
    ) {
        reports(reportFilter: $reportFilter) {
            recommendationsReport {
                annualCarbonEmissionsChart {
                    totalCarbonEmissionsBaseline
                    totalCarbonEmissionsGoal
                    year
                }
            }
        }
    }
`);

export default queryFn;
