import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationFn = (
    accessToken: string,
    propertyUids: string[],
    fundName: string | null
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            propertyUids,
            fundName,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.setPropertyFund.error)
            throw new Error(data.setPropertyFund.error);
        return data.setPropertyFund.success;
    });

const query = graphql(`
    mutation setPropertyFund($propertyUids: [Uuid!]!, $fundName: String) {
        setPropertyFund(propertyUids: $propertyUids, fundName: $fundName) {
            error
            success
        }
    }
`);

export default mutationFn;
