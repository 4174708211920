import mutationCreateCustomerAccountFn from "ApiLayer/AccountManagement/createCustomerAccount";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useMutation, useQueryClient } from "react-query";
import { showMutationAlert } from "utils/helpers";

export const useCreateCustomerAccount = () => {
    const accessToken = useAccessToken();

    const alert = useAlert();

    const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: async () => {
            alert.success("customer account created.");

            await queryClient.invalidateQueries({ queryKey: ["user"] });

        },
        mutationFn: async (
            data: {
                name: string;
                currencyCode: string;
            }
        ) => (
            mutationCreateCustomerAccountFn(accessToken, data)
        )
    });
}