import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useUserIdentity = () => {
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();

    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [error, setError] = useState<string>();

    if (!user)
        throw new Error(`User object is ${user} when it should be defined`);

    useEffect(() => {
        const fetchAccessToken = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getAccessTokenSilently();
                    setAccessToken(token);
                } catch (error) {
                    console.error("Error getting access token:", error);
                    setError(`Error getting access token:: ${error}`);
                }
            }
        };

        fetchAccessToken();
    }, [getAccessTokenSilently, isAuthenticated]);

    return { accessToken, user, error, isAuthenticated };
};
