import classNames from "classnames";
import { Radio } from "@mui/material";
import { Paragraph } from "components/atoms/Typography";
import { SurveyUIStructCheckboxWithSelect } from "../Survey.helpers";
import CheckboxAndLabel from "./CheckboxAndLabel";
import { equipmentKeyToLabelMap } from "./Equipment/BuildingEquipment.constants";

interface CheckboxWithSelectProps<T> {
    surveyCheckboxWithSelectStruct: SurveyUIStructCheckboxWithSelect<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function CheckboxWithSelect<T>({
    surveyCheckboxWithSelectStruct,
    surveyPageData,
    setSurveyPageData,
}: CheckboxWithSelectProps<T>) {
    const { key, label, options } = surveyCheckboxWithSelectStruct;

    const handleSetChecked = (val: boolean) => {
        setSurveyPageData((prev) => ({
            ...prev,
            [key]: val,
            [options.key]: val === false ? null : prev[options.key],
        }));
    };

    const handleOnChangeRadio = (value: T[keyof T]) =>
        setSurveyPageData((prev) => {
            const copy = {
                ...prev,
            };
            copy[options.key] = value;
            return copy;
        });

    return (
        <div
            className={classNames(
                "check-box-with-select",
                surveyPageData[key] === true &&
                    "check-box-with-select__selected"
            )}
        >
            <CheckboxAndLabel
                setChecked={handleSetChecked}
                currentValue={
                    surveyPageData[key] === null
                        ? undefined
                        : !!surveyPageData[key]
                }
                label={label}
            />
            {surveyPageData[key] === true && (
                <>
                    {options.values.map((value) => (
                        <div
                            key={options.key.toString()}
                            className="check-box-with-select__radio"
                        >
                            <Radio
                                style={{
                                    color: "var(--audette-pink)",
                                }}
                                checked={surveyPageData[options.key] === value}
                                onChange={() => handleOnChangeRadio(value)}
                            />
                            <Paragraph
                                style={{
                                    display: "inline-block",
                                }}
                            >
                                {equipmentKeyToLabelMap[value]}
                            </Paragraph>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default CheckboxWithSelect;