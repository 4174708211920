import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import queryFn from "ApiLayer/Funds/queryFunds";
import { QUERY_STALE_TIME } from "utils/constants";

export const useFunds = () => {
    const accessToken = useAccessToken();
    return useQuery({
        queryKey: ["funds"],
        queryFn: async () => queryFn(accessToken),
        staleTime: QUERY_STALE_TIME,
    });
};
