import { IconButton, Tooltip } from "@mui/material";
import { QuestionMarkIcon } from "components/atoms/Icon";
import { TableColumn } from "components/molecules/Table";
import styles from "./SettingsPage.module.scss";
import UserActions from "./UserActions";

export const formattedTableViewColumns: TableColumn[] = [
    {
        title: "Email",
        key: "email",
        render: (text: string) => text,
    },
    {
        title: (
            <div style={{ display: "flex", alignItems: "center" }}>
                Roles
                <Tooltip
                    title={
                        <div className={styles.tooltip}>
                            <p>
                                <b>Admin</b>: can use all of Audette and manage
                                members.
                            </p>
                            <p>
                                <b>Member</b>: can use all of Audette.
                            </p>
                        </div>
                    }
                >
                    <IconButton>
                        <QuestionMarkIcon />
                    </IconButton>
                </Tooltip>
            </div>
        ),        key: "role",
        render: (text: string) => text,
    },
    {
        title: "Status",
        key: "status",
        render: (text: string) => text,
    },
    {
        title: "Date joined",
        key: "dateJoined",
        render: (text: string) => text,
    },
    {
        title: "",
        key: "action",
        render: ({ userId, roles, email, isActive }: 
            { userId: string; roles: string[], email:string, isActive: boolean }) => (

            <UserActions userId={userId} roles={roles} email={email} isActive={isActive} />
        ),
    },
];

export const tableDefaultData = [
    {
        key: "1",
        email: "No member found",
        role: "",
        status: "",
        dateJoined: "",
    },
];
