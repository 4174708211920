import { useMemo } from "react";
import { getDisplayInfo } from "utils/formatting";
import { ChartErrorState } from "components/molecules/ErrorStates";
import ChartContainer from "components/molecules/ChartContainer";
import VariableWidthBarChart from "components/molecules/Charts/VariableWidthBarChart";
import { UseQueryResult } from "react-query";
import { ChartNoDataState } from "components/molecules/NoDataState";

interface AverageMarginalAbatementCostByMeasureCategoryChartProps {
    query: () => UseQueryResult<AverageMarginalAbatementCostByMeasureCategoryChartData>;
}

function AverageMarginalAbatementCostByMeasureCategoryChart({
    query,
}: AverageMarginalAbatementCostByMeasureCategoryChartProps) {
    const { data, isLoading, error } = query();

    const { formatFunction: formatAbatementCost, unit: abatementCostUnit } =
        getDisplayInfo("marginalAbatementCost");

    const { formatFunction: formatEmissions, unit: carbonEmissionsUnit } =
        useMemo(() => {
            const values =
                data?.map((d) => d.totalLifetimeCarbonEmissionSavings) || [];

            return getDisplayInfo("carbonEmissions", values);
        }, [data]);

    const chartData = useMemo(() => {
        if (!data) return [];
        let cumulativeX = 0;
        const formatted = data
            .sort(
                (a, b) =>
                    a.averageMarginalAbatementCost -
                    b.averageMarginalAbatementCost
            )
            .map((datum, index) => {
                const formattedAbatementCost = formatAbatementCost(
                    datum.averageMarginalAbatementCost
                );

                const formattedLifetimeCarbonSavings = formatEmissions(
                    datum.totalLifetimeCarbonEmissionSavings,
                    false
                );

                const value = {
                    y: datum.averageMarginalAbatementCost,
                    x: cumulativeX || 0,
                    width: datum.totalLifetimeCarbonEmissionSavings,
                    color: chartColors[index],
                    key: datum.carbonReductionMeasureCategory,
                    yLabel: `Cost of abatement: ${formattedAbatementCost} ${abatementCostUnit}`,
                    xLabel: `Lifetime carbon emission savings: ${formattedLifetimeCarbonSavings} ${carbonEmissionsUnit}`,
                };
                cumulativeX += datum.totalLifetimeCarbonEmissionSavings;
                return value;
            });
        return formatted;
    }, [data]);

    if (data === null) return <ChartNoDataState />;
    if (error) return <ChartErrorState />;

    return (
        <ChartContainer
            title="Average marginal abatement cost per measure type"
            loading={isLoading}
        >
            <VariableWidthBarChart
                data={chartData}
                yAxisFormatFunction={(v) => formatAbatementCost(v)}
                xAxisFormatFunction={(v) => formatEmissions(Number(v), false)}
                xAxisLabel="Cost of abatement  $/tCO₂e"
                yAxisLabel={`Lifetime carbon emission savings ${carbonEmissionsUnit}`}
            />
        </ChartContainer>
    );
}

const chartColors = [
    "#EF81DA",
    "#EB03AD",
    "#D5BBF9",
    "#7A2AEB",
    "#7CB7F2",
    "#066ECC",
    "#4BF7B5",
    "#00BC98",
    "#3B814B",
    "#F9BF73",
    "#F7931E",
    "#BB6E00",
    "#F94646",
    "#CC303C",
];

export default AverageMarginalAbatementCostByMeasureCategoryChart;
