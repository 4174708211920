import { useMutation } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import posthog from "posthog-js";
import mutationFn from "ApiLayer/Equipment/submitEquipmentSurvey";
import { EquipmentSurveyInput } from "gql/graphql";
import { showMutationAlert } from "utils/helpers";
import useAlert from "hooks/useAlert";

export const useSubmitEquipmentData = (
    buildingModelUid: string,
    onSuccess: () => void,
    onError: () => void
) => {
    const accessToken = useAccessToken();
    const alert = useAlert();

    return useMutation({
        mutationFn: async (equipmentData: EquipmentSurveyInput) =>
            mutationFn(accessToken, buildingModelUid, equipmentData),
        onSuccess: () => {
            onSuccess();
            posthog.capture('Update-equipment success');
            alert.success("Equipment information updated.");
        },
        onError: (errors) => {
            onError();
            showMutationAlert(errors, alert);
            posthog.capture('Add-equipment success');
        },
    });
};
