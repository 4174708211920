import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationFn = (accessToken: string, signal?: AbortSignal) =>
    fetch(`${BACKEND_DOMAIN}/export-buildings`, {
        method: "GET",
        headers: buildRequestHeaders(accessToken),
        signal,
    }).then((res) => {
        if (!res.ok) throw new Error(`Error: status: ${res.status}`);
        return res.blob().then((blob) => new Blob([blob]));
    });

export default mutationFn;
