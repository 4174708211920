export enum UserPermissions {
    LogIn = "log_in",
    CreateBuilding = "create_building",
    ReadUser = "read_user",
    UpdateBuilding = "update_building",
    UseInternalTools = "use_internal_tools",
    ReadBuilding = "read_building",
    CreateCustomerAccount = "create_customer_account",
    DeleteBuilding = "delete_building",
    RemoveUser = "remove_user",
    AddUser = "add_user",
    GrantAudetteAdmin = "grant_audette_admin",
    GrantUser = "grant_user",
}

export enum RoleActions {
    MakeAudetteAdmin = "Make Audette Admin",
    RemoveAudetteAdmin = "Remove Audette Admin",
    MakeMember = "Make Member",
    MakeAdmin = "Make Admin",
    Remove = "Remove",
    Reinvite = "Reinvite",
}

export enum Roles {
    AudetteAdmin = "Audette Admin",
    Admin = "Admin",
    Member = "Member"
}


