import { ReactNode } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface MUIThemeProps {
    children: ReactNode;
    primaryColor?: string;
}

function MUITheme({ children, primaryColor = "#7700FF" }: MUIThemeProps) {
    const theme = createTheme({
        palette: {
            primary: {
                main: primaryColor,
            },
        },
        typography: {
            fontFamily: "DM Sans",
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: "white",
                        color: "black",
                        boxShadow: "5px 5px 16px 0px rgba(168, 143, 197, 0.26)",
                    },
                },
            },
        },
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default MUITheme;
