import { Paragraph } from "components/atoms/Typography";
import "./ChartLegend.css";

interface ChartLegendProps {
    keys: { color: string; label: string }[];
}

function ChartLegend({ keys }: ChartLegendProps) {
    return (
        <div className="chart-legend">
            {keys.map(({ color, label }) => (
                <div key={`${color}$${label}`} className="chart-legend__key">
                    <div
                        className="chart-legend__color"
                        style={{
                            backgroundColor: color,
                        }}
                    />
                    <Paragraph
                        style={{ color: "var(--audette-gray-600" }}
                        key={color}
                    >
                        {label}
                    </Paragraph>
                </div>
            ))}
        </div>
    );
}

export default ChartLegend;
