import { useMemo } from "react";
import Card from "components/molecules/Card";
import { UseQueryResult } from "react-query";

interface CumulativeOperatingCostCardProps {
    query: () => UseQueryResult<CumulativeOperatingCostCardData>;
}

function CumulativeOperatingCostCard({
    query,
}: CumulativeOperatingCostCardProps) {
    const { data, isLoading, error } = query();

    const formattedData = useMemo(() => {
        if (!data) return undefined;
        const formatted = {
            header: {
                displayInfoKey: "operatingCost",
                value: data.totalEnergyCost,
                increaseDecreasePercentage: Number(
                    data.totalEnergyCostPercentChange
                ),
                valueColor: "var(--audette-content-positive)",
            },
            sections: [
                [
                    {
                        displayInfoKey: "utilityCost",
                        value: data.totalUtilityCost,
                        tooltipText: "Excluding carbon taxes",
                        increaseDecreasePercentage:
                            data.totalUtilityCostPercentChange,
                    },
                    {
                        displayInfoKey: "utilityCostIntensity",
                        value: (data.averageUtilityCostIntensity ||
                            data.totalUtilityCostIntensity)!,
                        increaseDecreasePercentage:
                            data.totalUtilityCostPercentChange,
                    },
                ],
            ],
        };

        if (data.totalCarbonTaxPercentChange !== null) {
            // only show carbon tax when carbon tax applies (isn't zero)
            formatted.sections[0].push(
                {
                    displayInfoKey: "carbonTax",
                    value: data.totalCarbonTax,
                    increaseDecreasePercentage:
                        data.totalCarbonTaxPercentChange,
                },
                {
                    displayInfoKey: "carbonTaxIntensity",
                    value: (data.averageCarbonTaxIntensity ||
                        data.totalCarbonTaxIntensity)!,
                    increaseDecreasePercentage:
                        data.totalCarbonTaxPercentChange,
                }
            );
        }

        return formatted;
    }, [data]);

    return (
        <Card
            loading={isLoading}
            data={formattedData}
            error={error?.toString()}
        />
    );
}

export default CumulativeOperatingCostCard;
