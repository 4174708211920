import { useQuery, useQueryClient } from "react-query";
import queryFn from "ApiLayer/queryBuildingModelHeader";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";

export const useBuildingModelHeaderQuery = (buildingModelUid: string) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ["buildingModelHeader", buildingModelUid],
        queryFn: async () => queryFn(accessToken, buildingModelUid),
        onSuccess: () => {
            // Note: this is a hack so that the building model table data gets refetched when a single building is added through the "Add building" flow
            queryClient.invalidateQueries({
                queryKey: ["buildingModelsTable"],
            });
        },
        staleTime: QUERY_STALE_TIME,
        retry: 25,
    });
};
