import { CloseButton } from "components/atoms/Icon";
import useAlert from "hooks/useAlert";
import classNames from "classnames";
import { Paragraph } from "components/atoms/Typography";
import { useEffect, useState } from "react";
import { AlertStatus } from "context";
import "./GlobalAlert.scss";

function GlobalAlert() {
    const alert = useAlert();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (alert.status !== AlertStatus.None) setVisible(true);
    }, [alert.status]);

    if (alert.status === AlertStatus.None) return null;

    const onClose = () => {
        alert.clear();
        setVisible(false);
    };

    return (
        <div
            className={classNames(
                "alert",
                alert.status,
                visible ? "show" : "hide"
            )}
        >
            {alert.status === AlertStatus.Success ? (
                <SuccessIcon />
            ) : (
                <ErrorIcon />
            )}
            <Paragraph>{alert.message}</Paragraph>
            <CloseButton onClick={onClose} />
        </div>
    );
}

function SuccessIcon() {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.94141 0.512695C4.54141 0.512695 0.941406 4.1127 0.941406 8.5127C0.941406 12.9127 4.54141 16.5127 8.94141 16.5127C13.3414 16.5127 16.9414 12.9127 16.9414 8.5127C16.9414 4.1127 13.3414 0.512695 8.94141 0.512695ZM7.34141 12.5127L3.34141 8.5127L4.46941 7.3847L7.34141 10.2487L13.4134 4.1767L14.5414 5.3127L7.34141 12.5127Z"
                fill="#3B814B"
            />
        </svg>
    );
}

function ErrorIcon() {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.74141 9.3127H8.14141V4.5127H9.74141M9.74141 12.5127H8.14141V10.9127H9.74141M8.94141 0.512695C7.89083 0.512695 6.85054 0.719621 5.87994 1.12166C4.90933 1.5237 4.02742 2.11297 3.28455 2.85584C1.78426 4.35613 0.941406 6.39096 0.941406 8.5127C0.941406 10.6344 1.78426 12.6693 3.28455 14.1695C4.02742 14.9124 4.90933 15.5017 5.87994 15.9037C6.85054 16.3058 7.89083 16.5127 8.94141 16.5127C11.0631 16.5127 13.098 15.6698 14.5983 14.1695C16.0986 12.6693 16.9414 10.6344 16.9414 8.5127C16.9414 7.46212 16.7345 6.42183 16.3324 5.45123C15.9304 4.48062 15.3411 3.59871 14.5983 2.85584C13.8554 2.11297 12.9735 1.5237 12.0029 1.12166C11.0323 0.719621 9.99198 0.512695 8.94141 0.512695Z"
                fill="#CC303C"
            />
        </svg>
    );
}

export default GlobalAlert;
