import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";
import queryFn from "ApiLayer/Reports/Baseline/queryBuildingBarChartAndMap";
import useFilters from "recoilStore/useFilters";

export const useBuildingBarChartAndMap = () => {
    const accessToken = useAccessToken();
    const { filters } = useFilters("reports");
    return useQuery({
        queryKey: ["buildingBarChartAndMap", filters],
        queryFn: async () => queryFn(accessToken, filters),
        staleTime: QUERY_STALE_TIME,
    });
};
