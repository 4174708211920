import { Button } from "components/atoms/Button";

interface FiltersSidebarFooterProps {
    onClickApply: () => void;
    onClickCancel: () => void;
    onClickClearAll: () => void;
    page: Page;
    localFilters: Filters;
}

function FiltersSidebarFooter({
    onClickApply,
    onClickCancel,
    onClickClearAll,
    page,
    localFilters,
}: FiltersSidebarFooterProps) {
    return (
        <div className="filters-sidebar__footer">
            <div className="filters-sidebar__footer__buttons">
                <Button
                    type="primary"
                    onClick={onClickApply}
                    id={`${page}-apply-filters-button`}
                    data-ph-capture-attribute-filters={JSON.stringify(
                        localFilters
                    )}
                >
                    Apply
                </Button>
                <Button
                    onClick={onClickCancel}
                    type="link"
                    id={`${page}-cancel-filters-button`}
                >
                    Cancel
                </Button>
            </div>
            <Button
                onClick={onClickClearAll}
                type="link"
                id={`${page}-reset-filters-button`}
            >
                Reset filters
            </Button>
        </div>
    );
}

export default FiltersSidebarFooter;