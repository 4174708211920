import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.buildingModelReports.error)
            throw new Error(data.buildingModelReports.error);
        return data.buildingModelReports.measuresReport
            ?.carbonReductionMeasuresTable as ProjectsReportTableRow[];
    });

const query = graphql(`
    query carbonReductionMeasuresTable($buildingModelUid: Uuid!) {
        buildingModelReports(buildingModelUid: $buildingModelUid) {
            error
            measuresReport {
                carbonReductionMeasuresTable {
                    annualCarbonEmissionSavings
                    annualCarbonTaxSavings
                    annualEnergyConsumptionSavings
                    annualEnergyCostSavings
                    carbonReductionMeasureCategory
                    carbonReductionMeasureType
                    incrementalCost
                    internalRateOfReturn
                    likeForLikeCost
                    marginalAbatementCost
                    netPresentValue
                    returnOnInvestment
                    simplePayback
                    totalMeasureCost
                    totalMeasureCostIntensity
                    yearApplied
                }
            }
        }
    }
`);

export default queryFn;
