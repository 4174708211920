import { useMemo, useState } from "react";
import { Header, Paragraph } from "components/atoms/Typography";
import FiltersSidebar from "components/organisms/FiltersSidebar";
import { FilterLabelCards } from "components/organisms/FiltersSidebar/FilterLabelCards";
import { FilterIcon } from "components/atoms/Icon";
import { useBuildingModelsTableQuery } from "queries/useBuildingModelsTable";
import { useBuildingBarChartAndMapByProperty } from "queries/Reports/Baseline/useBuildingBarChartAndMapByProperty";
import { Button } from "components/atoms/Button";
import Dropdown from "components/atoms/Dropdown";
import useReportsGroupByState from "recoilStore/useReportsGroupByState";
import posthog from "posthog-js";
import ViewTab from "../../components/atoms/ViewTab";
import "./ReportsHeader.scss";

const groupByItems = [
    {
        id: "building",
        displayValue: "Group by Buildings",
    },
    {
        id: "property",
        displayValue: "Group by Properties",
    },
];

function ReportsHeader({
    currentActiveTab,
    setCurrentActiveTab,
}: ReportHeaderProps) {
    const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);

    const { state: groupByMetric, setState: setGroupByMetric } =
        useReportsGroupByState();

    const handleFilterButtonClick = () => {
        posthog.capture("Open filters");
        setIsFilterSidebarOpen(true);
    };

    const closeFilterSidebar = () => {
        setIsFilterSidebarOpen(false);
    };

    return (
        <>
            <FiltersSidebar
                sidebarOpen={isFilterSidebarOpen}
                closeSidebar={closeFilterSidebar}
                page="reports"
            />
            <div className="reports-header">
                <div className="title">
                    <Header size="large">Reports</Header>
                    <Dropdown
                        items={groupByItems}
                        onSelectItem={(item) => setGroupByMetric(item.id)}
                        defaultItem={groupByItems.find(
                            (item) => item.id === groupByMetric
                        )}
                    />
                    <Button
                        type="secondary"
                        onClick={handleFilterButtonClick}
                        icon={<FilterIcon />}
                    >
                        Filters
                    </Button>
                </div>
                <NumberOfBuildings groupByMetric={groupByMetric} />
                <div className="filters">
                    <FilterLabelCards page="reports" />
                </div>
                <ReportTabs
                    reportView={currentActiveTab}
                    setReportView={setCurrentActiveTab}
                />
            </div>
        </>
    );
}

interface ReportHeaderProps {
    currentActiveTab: ReportTab;
    setCurrentActiveTab: (tab: ReportTab) => void;
}

interface ReportTabsProps {
    reportView: ReportTab;
    setReportView: (view: ReportTab) => void;
}

function ReportTabs({ reportView, setReportView }: ReportTabsProps) {
    return (
        <div className="report-tabs">
            <ViewTab
                name="Baseline"
                onClick={() => setReportView("currentState")}
                selected={reportView === "currentState"}
            />
            <ViewTab
                name="Recommendations"
                onClick={() => setReportView("decarbonization")}
                selected={reportView === "decarbonization"}
            />
        </div>
    );
}

interface NumberOfBuildingsProps {
    groupByMetric: GroupByMetric;
}

function NumberOfBuildings({ groupByMetric }: NumberOfBuildingsProps) {
    const { data: buildingModels } = useBuildingModelsTableQuery();
    const { data: properties } = useBuildingBarChartAndMapByProperty();

    const numberOfGroups = useMemo(() => {
        if (groupByMetric === "property") return properties?.length;
        return buildingModels?.length;
    }, [buildingModels, groupByMetric, properties]);

    return (
        <div className="number-of-buildings">
            <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                {numberOfGroups === undefined ? "-" : numberOfGroups}{" "}
                {groupByMetric === "property" ? "properties" : "buildings"}
            </Paragraph>
        </div>
    );
}

export default ReportsHeader;
