import { useState, useMemo } from "react";

// helpers
import classNames from "classnames";

import { useBuildingModelHeaderQuery } from "queries/useBuildingModelHeaderQuery";
import { getDataCoverageWarnings } from "utils/helpers";

// components
import {
    EnergyIcon,
    ConstructionIcon,
    BackArrow,
    EquipmentIcon,
    WarningCountIcon,
} from "components/atoms/Icon";
import { Paragraph, Header } from "components/atoms/Typography";
import BetaTag from "components/atoms/BetaTag";
import BuildingEnergy from "./BuildingEnergy";
import BuildingConstruction from "./BuildingConstruction";
import BuildingEquipment from "./BuildingEquipment";
// styles
import "./BuildingSidebar.scss";

interface BuildingSidebarProps {
    show: boolean;
    onClose: () => void;
    buildingName: string;
    buildingModelUid: string;
}

enum BuildingSidebarTab {
    energy = "energy",
    construction = "construction",
    equipment = "equipment",
}

function BuildingSidebar({
    show,
    onClose,
    buildingName,
    buildingModelUid,
}: BuildingSidebarProps) {
    const [view, setView] = useState<BuildingSidebarTab>(
        BuildingSidebarTab.energy
    );
    const onClickView = (view: BuildingSidebarTab) => setView(view);

    const { data: buildingHeaderData } =
        useBuildingModelHeaderQuery(buildingModelUid);

    const dataCoverageWarnings = useMemo(
        () =>
            buildingHeaderData?.dataCoverage
                ? getDataCoverageWarnings(buildingHeaderData.dataCoverage)
                : undefined,
        [buildingHeaderData]
    );

    return (
        <div className={classNames("building-sidebar", show && "show")}>
            <div className="building-sidebar__header">
                <Header>Building profile</Header>
                <button
                    type="button"
                    id="building-profile-close-button"
                    onClick={onClose}
                    style={{ paddingLeft: "12px" }}
                >
                    <BackArrow rotate180Degrees />
                </button>
            </div>
            <BuildingSidebarTabs
                onClick={onClickView}
                view={view}
                dataCoverageWarnings={dataCoverageWarnings}
                hasEquipmentSizeAndYear={
                    buildingHeaderData?.dataCoverage.equipmentDataHasSizeAndYear
                }
                equipmentDataUploaded={
                    buildingHeaderData?.dataCoverage.equipmentDataUploaded
                }
            />
            {view === BuildingSidebarTab.construction && (
                <BuildingConstruction buildingModelUid={buildingModelUid} />
            )}
            {view === BuildingSidebarTab.energy && (
                <BuildingEnergy buildingName={buildingName} />
            )}
            {view === BuildingSidebarTab.equipment && <BuildingEquipment />}
        </div>
    );
}

interface BuildingSidebarTabsProps {
    onClick: (view: BuildingSidebarTab) => void;
    view: BuildingSidebarTab;
    dataCoverageWarnings?: DataCoverageWarningsList;
    hasEquipmentSizeAndYear?: boolean;
    equipmentDataUploaded?: boolean;
}

function BuildingSidebarTabs({
    onClick,
    view,
    dataCoverageWarnings,
    hasEquipmentSizeAndYear,
    equipmentDataUploaded,
}: BuildingSidebarTabsProps) {
    return (
        <div className="building-sidebar-tabs">
            <button
                type="button"
                id="building-profile-energy-tab"
                className="building-sidebar-tabs__button"
                onClick={() => onClick(BuildingSidebarTab.energy)}
                style={
                    view === BuildingSidebarTab.energy
                        ? { color: "#EB03AD", fill: "#EB03AD" }
                        : undefined
                }
            >
                <div className="energy-icon" style={{ position: "relative" }}>
                    <EnergyIcon />
                    {dataCoverageWarnings?.energyWarning && (
                        <Warning type="energy" />
                    )}
                </div>
                <div className="building-sidebar-tabs__title-with-tag">
                    <Paragraph>Energy</Paragraph>
                    {view === BuildingSidebarTab.energy && <BetaTag />}
                </div>
            </button>
            <button
                id="building-profile-equipment-tab"
                type="button"
                className="building-sidebar-tabs__button"
                onClick={() => onClick(BuildingSidebarTab.equipment)}
                style={
                    view === BuildingSidebarTab.equipment
                        ? { color: "#EB03AD", fill: "#EB03AD" }
                        : undefined
                }
            >
                <div
                    className="equipment-icon"
                    style={{ position: "relative" }}
                >
                    <EquipmentIcon />
                    {(dataCoverageWarnings?.equipmentWarning ||
                        (!hasEquipmentSizeAndYear &&
                            equipmentDataUploaded)) && (
                        <Warning type="equipment" />
                    )}
                </div>
                <div className="building-sidebar-tabs__title-with-tag">
                    <Paragraph>Equipment</Paragraph>
                    {view === BuildingSidebarTab.equipment && <BetaTag />}
                </div>
            </button>
            <button
                type="button"
                id="building-profile-construction-tab"
                className="building-sidebar-tabs__button"
                onClick={() => onClick(BuildingSidebarTab.construction)}
                style={
                    view === BuildingSidebarTab.construction
                        ? { color: "#EB03AD", fill: "#EB03AD" }
                        : undefined
                }
            >
                <ConstructionIcon
                    color={
                        view === BuildingSidebarTab.construction
                            ? "var(--audette-pink)"
                            : undefined
                    }
                />
                <Paragraph>Construction</Paragraph>
            </button>
        </div>
    );
}

interface WarningProps {
    type: "energy" | "equipment";
}

function Warning({ type }: WarningProps) {
    return (
        <div data-testid={`warning-icon-${type}`}>
            <WarningCountIcon
                style={{
                    position: "absolute",
                    top: "-8.48px",
                    left: "11px",
                }}
            />
        </div>
    );
}

export default BuildingSidebar;
