import posthog from "posthog-js";
// csv uploader
import { IDeveloperField } from "dromo-uploader-react";
import { useSubmitUtilityData } from "queries/Utility/useSubmitUtilityData";

// components
import DromoCsvUploader from "components/molecules/DromoCsvUploader";
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useQueryOnStatusUpdate } from "./BuildingEnergy.hooks";

interface UtilityCsvUploaderProps {
    buildingModelUid: string;
}

function UtilityCsvUploader({ buildingModelUid }: UtilityCsvUploaderProps) {
    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);
    const { onSuccess, onError } = useQueryOnStatusUpdate(buildingModelUid);

    const { mutate: submitUtilityData } = useSubmitUtilityData(
        buildingModelUid,
        () => {
            onSuccess();
            posthog.capture("import_energy_completed");
        },
        () => {
            onError();
            posthog.capture("import_energy_failed");
        }
    );

    const onResults = (results: any) => {
        const utilityData = results.map((row: any) => ({
            utilityProvider: row.utility_provider,
            accountNumber: row.account_number,
            utilityType: row.utility_type,
            startDate: row.start_date,
            endDate: row.end_date,
            energyConsumption: row.energy_consumption,
            energyConsumptionUnit: row.energy_consumption_unit,
            utilityCost: row.utility_cost !== '' ? row.utility_cost : null,
            currency: row.currency,
        }));
        setLoadingState((prev) => ({
            ...prev,
            energySidebarLoading: true,
            buildingReportsLoading: true,
        }));
        submitUtilityData(utilityData);
    };

    return (
        <DromoCsvUploader
            onResults={onResults}
            validatorWorkflowStep="user_submits_utility_data"
            fieldValues={fieldValues}
            importIdentifier="utilities"
        />
    );
}

const buildSelectOptions = (keys: string[]) =>
    keys.map((key) => ({
        value: key,
        label: key,
    }));

const energyTypeOptions = [
    {
        value: "natural_gas",
        label: "Natural gas",
    },
    {
        value: "electricity",
        label: "Electricity",
    },
];

const unitOptions = [
    {
        value: "ft3",
        label: "ft³",
    },
    {
        value: "GJ",
        label: "GJ",
    },
    {
        value: "kWh",
        label: "kWh",
    },
    {
        value: "m3",
        label: "m³",
    },
];

const fieldValues: IDeveloperField[] = [
    {
        label: "Supplier",
        key: "utility_provider",
        type: "string",
    },
    {
        label: "Account number",
        key: "account_number",
        type: "string",
    },
    {
        label: "Energy type",
        key: "utility_type",
        type: "select",
        selectOptions: energyTypeOptions,
    },
    {
        label: "Start date",
        key: "start_date",
        validators: [{ validate: "required" }],
        type: "date",
    },
    {
        label: "End date",
        key: "end_date",
        validators: [{ validate: "required" }],
        type: "date",
    },
    {
        label: "Consumption",
        key: "energy_consumption",
        validators: [{ validate: "required" }],
        type: "number",
    },
    {
        label: "Unit",
        key: "energy_consumption_unit",
        validators: [{ validate: "required" }],
        type: "select",
        selectOptions: unitOptions,
    },
    {
        label: "Cost",
        key: "utility_cost",
        validators: [],
        type: "number",
    },
    {
        label: "Currency",
        key: "currency",
        validators: [{ validate: "required" }],
        type: "select",
        selectOptions: buildSelectOptions(["CAD", "USD"]),
    },
];

export default UtilityCsvUploader;
