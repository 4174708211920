import posthog from "posthog-js";
import React, { Component, ErrorInfo } from "react";

interface Props {
    children: React.ReactNode;
    fallback: React.ReactNode;
    originComponent?: string;
}

interface State {
    hasError: boolean;
}

export class ComponentErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }
    
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ hasError: true });
        
        const originComponent = this.props.originComponent || "Unwatched Component";
        
        posthog.capture(`${originComponent} Error`, {
            error: error.toString()
        })

        console.error("ErrorBoundary", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }
        return this.props.children;
    }
}


