import { useMemo, useState } from "react";

// components
import { Header } from "components/atoms/Typography";
import {
    BaselineAreaChart,
    EndUseStackedBarChart,
    EndUseSankey,
    OperatingCostCard,
    FutureProjectionEnergyConsumptionCard,
    FutureProjectionCarbonEmissionsCard,
    AnnualCumulativePicker,
} from "components/organisms/Charts";
import { RadioButtonSelectMode } from "components/organisms/Charts/AnnualCumulativePicker";
import Card from "components/molecules/Card";

// hooks
import { useCurrentAnnualCarbonEmissionsCard } from "queries/BuildingReport/Baseline/useCurrentAnnualCarbonEmissionsCard";
import { useCurrentAnnualEnergyConsumptionCard } from "queries/BuildingReport/Baseline/useCurrentAnnualEnergyConsumptionCard";
import { useCurrentAnnualOperatingCostCard } from "queries/BuildingReport/Baseline/useCurrentAnnualOperatingCostCard";
import { useCurrentAnnualEndUseChart } from "queries/BuildingReport/Baseline/useCurrentAnnualEndUseChart";
import { buildSankeyData } from "components/organisms/Charts/EndUseSankey";
import { useCumulativeCarbonEmissionsCard } from "queries/BuildingReport/Baseline/useCumulativeCarbonEmissionsCard";
import { useCumulativeEnergyConsumptionCard } from "queries/BuildingReport/Baseline/useCumulativeEnergyConsumptionCard";
import { useCumulativeOperatingCostCard } from "queries/BuildingReport/Baseline/useCumulativeOperatingCostCard";
import { useAnnualCarbonEmissionsChart } from "queries/BuildingReport/Baseline/useAnnualCarbonEmissionsChart";
import { useAnnualCarbonEmissionsNaturalGasChart } from "queries/BuildingReport/Baseline/useAnnualCarbonEmissionsNaturalGasChart";
import { useAnnualCarbonEmissionsElectricityChart } from "queries/BuildingReport/Baseline/useAnnualCarbonEmissionsElectricityChart";
import { useAnnualCarbonEmissionsCard } from "queries/BuildingReport/Baseline/useAnnualCarbonEmissionsCard";
import { useAnnualEnergyConsumptionCard } from "queries/BuildingReport/Baseline/useAnnualEnergyConsumptionCard";
import { useAnnualOperatingCostCard } from "queries/BuildingReport/Baseline/useAnnualOperatingCostCard";

// constants
import { CURRENT_YEAR } from "utils/constants";

// styling
import "./BaselineReport.css";

function BaselineReport() {
    return (
        <div className="baseline-building-report">
            <Header size="medium">
                Your buildings at a glance - {CURRENT_YEAR}
            </Header>
            <div className="cards-and-chart-grid">
                <div className="cards">
                    <CurrentAnnualCarbonEmissionsCard />
                    <CurrentAnnualEnergyConsumptionCard />
                    <CurrentAnnualOperatingCostCard />
                </div>
                <CurrentAnnualEndUseChart />
            </div>
            <Header size="medium">Future projection</Header>
            <div className="cards-and-chart-grid">
                <FutureProjectionCards />
                <AnnualCarbonEmissionsChart />
            </div>
            <div className="side-by-side-charts-grid">
                <AnnualCarbonEmissionsNaturalGasChart />
                <AnnualCarbonEmissionsElectricityChart />
            </div>
        </div>
    );
}

function CurrentAnnualCarbonEmissionsCard() {
    const { data, isLoading, error } = useCurrentAnnualCarbonEmissionsCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;
        const { totalCarbonEmissions, totalEmissionIntensity } = data;

        return {
            header: {
                value: totalCarbonEmissions,
                displayInfoKey: "carbonEmissions",
            },
            sections: [
                [
                    {
                        value: totalEmissionIntensity,
                        displayInfoKey: "carbonEmissionIntensity",
                    },
                ],
            ],
        };
    }, [data]);

    return (
        <Card
            data={cardData}
            loading={isLoading || !cardData}
            error={error?.toString()}
        />
    );
}

function CurrentAnnualEnergyConsumptionCard() {
    const { data, isLoading, error } = useCurrentAnnualEnergyConsumptionCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;
        const { totalEnergyConsumption, totalEnergyIntensity } = data;

        return {
            header: {
                value: totalEnergyConsumption,
                displayInfoKey: "annualEnergyConsumptionCurrent",
            },
            sections: [
                [
                    {
                        value: totalEnergyIntensity,
                        displayInfoKey: "annualEnergyUseIntensityCurrent",
                    },
                ],
            ],
        };
    }, [data]);

    return (
        <Card
            data={cardData}
            loading={isLoading || !data}
            error={error?.toString()}
        />
    );
}

function CurrentAnnualOperatingCostCard() {
    const { data, isLoading, error } = useCurrentAnnualOperatingCostCard();

    return (
        <OperatingCostCard
            data={data}
            loading={isLoading || !data}
            error={error?.toString()}
        />
    );
}

function CurrentAnnualEndUseChart() {
    const { data, isLoading, error } = useCurrentAnnualEndUseChart();

    const sankeyData = useMemo(() => {
        if (!data) return null;
        const { carbonEmissions, energyConsumption, utilityCost } = data;
        return {
            carbonEmissions: buildSankeyData(carbonEmissions),
            energyConsumption: buildSankeyData(energyConsumption),
            utilityCost: buildSankeyData(utilityCost),
        };
    }, [data]);

    return (
        <EndUseSankey
            loading={isLoading || !sankeyData}
            data={sankeyData}
            error={error?.toString()}
        />
    );
}

function FutureProjectionCards() {
    const [selected, setSelected] =
        useState<RadioButtonSelectMode>("cumulative");
    const [sliderCurrentYear, setSliderCurrentYear] = useState(CURRENT_YEAR);

    const onChangeSelect = () => {
        setSelected((prev) => {
            if (prev === "cumulative") return "annual";
            return "cumulative";
        });
    };

    return (
        <div>
            <AnnualCumulativePicker
                selected={selected}
                onChangeSelect={onChangeSelect}
                currentSliderYear={sliderCurrentYear}
                setCurrentSliderYear={setSliderCurrentYear}
            />
            <div className="cards">
                {selected === "cumulative" ? (
                    <>
                        <CumulativeCarbonEmissionsCard />
                        <CumulativeEnergyConsumptionCard />
                        <CumulativeOperatingCostCard />
                    </>
                ) : (
                    <>
                        <AnnualCarbonEmissionsCard year={sliderCurrentYear} />
                        <AnnualEnergyConsumptionCard year={sliderCurrentYear} />
                        <AnnualOperatingCostCard year={sliderCurrentYear} />
                    </>
                )}
            </div>
        </div>
    );
}

function CumulativeCarbonEmissionsCard() {
    const { data, isLoading, error } = useCumulativeCarbonEmissionsCard();

    return (
        <FutureProjectionCarbonEmissionsCard
            loading={isLoading || !data}
            cumulative={true}
            data={data}
            error={error?.toString()}
        />
    );
}

function CumulativeEnergyConsumptionCard() {
    const { data, isLoading, error } = useCumulativeEnergyConsumptionCard();

    return (
        <FutureProjectionEnergyConsumptionCard
            loading={isLoading || !data}
            cumulative={true}
            data={data}
            error={error?.toString()}
        />
    );
}

function CumulativeOperatingCostCard() {
    const { data, isLoading, error } = useCumulativeOperatingCostCard();

    return (
        <OperatingCostCard
            data={data}
            loading={isLoading || !data}
            error={error?.toString()}
        />
    );
}

function AnnualCarbonEmissionsCard({ year }: { year: number }) {
    const { data, isLoading, error } = useAnnualCarbonEmissionsCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => year === datum.year);
    }, [data, year]);

    return (
        <FutureProjectionCarbonEmissionsCard
            loading={isLoading || !cardData}
            cumulative={false}
            data={cardData}
            error={error?.toString()}
        />
    );
}

function AnnualEnergyConsumptionCard({ year }: { year: number }) {
    const { data, isLoading, error } = useAnnualEnergyConsumptionCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => year === datum.year);
    }, [data, year]);

    return (
        <FutureProjectionEnergyConsumptionCard
            loading={isLoading || !data}
            cumulative={false}
            data={cardData}
            error={error?.toString()}
        />
    );
}

function AnnualOperatingCostCard({ year }: { year: number }) {
    const { data, isLoading, error } = useAnnualOperatingCostCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => year === datum.year);
    }, [data, year]);

    return (
        <OperatingCostCard
            data={cardData}
            loading={isLoading || !data}
            cumulative={false}
            error={error?.toString()}
        />
    );
}

function AnnualCarbonEmissionsChart() {
    const { data, isLoading, error } = useAnnualCarbonEmissionsChart();

    const areaChartData = useMemo(
        () =>
            data?.map((datum) => ({
                areaY: datum?.totalCarbonEmissions || 0,
                x: datum?.year || 0,
            })),
        [data]
    );

    return (
        <BaselineAreaChart
            data={areaChartData}
            loading={isLoading || !areaChartData}
            error={error?.toString()}
        />
    );
}

function AnnualCarbonEmissionsNaturalGasChart() {
    const { data, isLoading, error } =
        useAnnualCarbonEmissionsNaturalGasChart();

    return (
        <EndUseStackedBarChart
            data={data as any}
            loading={isLoading || !data}
            fuelTypeKey="naturalGas"
            error={error?.toString()}
        />
    );
}

function AnnualCarbonEmissionsElectricityChart() {
    const { data, isLoading, error } =
        useAnnualCarbonEmissionsElectricityChart();

    return (
        <EndUseStackedBarChart
            data={data}
            loading={isLoading || !data}
            fuelTypeKey="electricity"
            error={error?.toString()}
        />
    );
}

export default BaselineReport;
