import { useMemo } from "react";
import { ActiveView, DEFAULT_AIR_HANDLING_EQUIPMENT } from "../Survey.constants";
import { buildAirHandlingEquipmentSurveyUIStruct, getValidatedEquipmentState, surveyValueExists, updatePageStackAndViewOnBackClick, updatePageStackOnNextClick } from "../Survey.helpers";
import { useNextButtonDisabled } from "../Survey.hooks";
import SurveyView from "../SurveyElements/Survey/SurveyView";

interface AirHandlingEquipmentSurveyViewProps {
    airHandlingEquipment: AirHandlingEquipment;
    setAirHandlingEquipment: React.Dispatch<
        React.SetStateAction<AirHandlingEquipment>
    >;
    rooftopUnit: RooftopUnit;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    pageStack: ActiveView[];
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function AirHandlingEquipmentSurveyView({
    airHandlingEquipment,
    setAirHandlingEquipment,
    rooftopUnit,
    setActiveView,
    activeView,
    pageStack,
    setPageStack,
}: AirHandlingEquipmentSurveyViewProps) {
    const structure = useMemo(
        () =>
            buildAirHandlingEquipmentSurveyUIStruct(
                airHandlingEquipment,
                rooftopUnit
            ),
        [airHandlingEquipment]
    );

    const nextButtonDisabled = useNextButtonDisabled(
        structure,
        airHandlingEquipment
    );

    const handleUpdateEquipment = (
        func: (prev: AirHandlingEquipment) => AirHandlingEquipment
    ) => {
        setAirHandlingEquipment((prev) => {
            const newValue = func(prev);
            const struct = buildAirHandlingEquipmentSurveyUIStruct(
                newValue,
                rooftopUnit
            );
            return getValidatedEquipmentState(newValue, struct);
        });
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        if (
            airHandlingEquipment.airHandlingEquipmentType ===
            "suite_air_exchangers"
        )
            setActiveView(ActiveView.terminalUnits);
        else setActiveView(ActiveView.centralPlantHeaterCooler);

        setAirHandlingEquipment((prev) => ({
            ...prev,
            // /* It's possible for the airhandling equipment to be mandatory.
            // In this case the default of the airHandling equipment exists key should be true */
            airHandlingEquipmentExists:
                prev.airHandlingEquipmentExists ||
                surveyValueExists(prev.airHandlingEquipmentType),
        }));
    };

    const handleBackClick = () => {
        updatePageStackAndViewOnBackClick(
            setActiveView,
            pageStack,
            setPageStack
        );
        setAirHandlingEquipment(DEFAULT_AIR_HANDLING_EQUIPMENT);
    };

    return (
        <SurveyView
            structure={structure}
            setSurveyPageData={handleUpdateEquipment}
            handleNextClick={handleNextClick}
            surveyPageData={airHandlingEquipment}
            handleBackClick={handleBackClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

export default AirHandlingEquipmentSurveyView;