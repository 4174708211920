import { useState } from "react";
import { Header } from "components/atoms/Typography";
import { Table } from "components/molecules/Table";
import ViewTab from "components/atoms/ViewTab";
// import TableAdmin from "components/molecules/TableAdmin/TableAdmin";
import { Box } from "@mui/material";
import { useUser } from "hooks/useUser";
import { formatIsoToDateString } from "utils/formatting";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import { Button } from "components/atoms/Button";
import { useAddUsers } from "mutations/Users/useAddUsers";
import { showMutationAlert } from "utils/helpers";
import useAlert from "hooks/useAlert";
import OrganizationInfo from "./OrganizationInfo";
import styles from "./SettingsPage.module.scss";
import { formattedTableViewColumns, tableDefaultData } from "./formattedTableViewColumns";

function SettingsPage() {
    const { data: user, isFetching } = useUser();
    const { mutate: mutateAddUsers, isLoading } = useAddUsers();

    const [emails, setEmails] = useState<string>("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const alert = useAlert();

    
    if (isFetching) {
        return (
            <LoadingSpinner />
        );
    }

    if (!user) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <p>User data could not be loaded.</p>
            </Box>
        );
    }


    
    const activeOrganization = user.activeOrganization?.organizationName;
    const currencyActiveOrganization = user.activeOrganization?.currencyCode;
    const createdYear = user.activeOrganization?.createdTimestamp.split(" ")[0];
    const members = user.activeOrganization?.members;
    const membersFormatted = members?.map((member) => (
        {
            key: member.userId,
            email: member.email,
            role: member.roles.join(", "),
            isActive: member.isActive,
            status: member.isActive ? "Active" : "Invited",
            dateJoined: formatIsoToDateString(member.joinedTimestamp ?? ""),
            action: { userId: member.userId, roles: member.roles, email: member.email, isActive: member.isActive },
        }
    ));

    const handleAddusers = () => {
        const emailsArray = emails.split(",").map((email) => email.trim()).filter((email) => email);    
        const invalidEmails = emailsArray.filter((email) => !emailRegex.test(email));

        if (invalidEmails.length > 0) {
            const errorMessage = `Invalid email(s): ${invalidEmails.join(", ")}`;
            showMutationAlert(errorMessage, alert);
            return;
        }
        mutateAddUsers(
            { emails: emailsArray },
            { onSuccess: () => { setEmails("")} } 
        );
        
    }
        
    // test
    
    return (
        <div>
            <div className={styles.settingsPage}>
                <div className={styles.pageHeader}>
                    <Header size="large">Settings</Header>
                    <div className={`report-tabs ${styles.settingsTabs}`}>
                        <ViewTab
                            name="Organization"
                            onClick={() => {}}
                            selected={true}
                        />
                    </div>
                </div>
                <div className={styles.settingsContent}>
                    <div className="horizontal-form">
                        <h2 className={styles.bottomMargin}>
                            Members ({members?.length || "No member found"})
                        </h2>

                        <form 
                            className={styles.formInputWrapper}
                            onSubmit={(e) => e.preventDefault()}    
                        >
                            <input
                                className={styles.formInput}
                                type="text"
                                placeholder="Enter emails, comma separated"
                                value={emails}
                                onChange={(e) => setEmails(e.target.value)}
                            />
                            <Button
                                style={{ width: "76px" }}
                                type="primary"
                                disabled={!emails || isLoading}
                                onClick={() => handleAddusers()}
                            >
                                {isLoading ? "Loading..." : "Invite"}
                            </Button>
                        </form>

                        <div className="settingsTable">
                            <Table
                                columns={formattedTableViewColumns}
                                dataSource={membersFormatted || tableDefaultData}
                                className={styles.settingsTable}
                                pagination={false}
                                defaultOrderBy={{
                                    key: "email",
                                    order: "asc"
                                }}
                            />
                        </div>
                    </div>

                    <OrganizationInfo
                        activeOrganization={activeOrganization}
                        currencyActiveOrganization={currencyActiveOrganization}
                        createdYear={createdYear}
                    />
                </div>
            </div>

        </div>
    );
}

export default SettingsPage;
