import "./index.scss";

interface TableLoadingProps {
    includeHeader?: boolean;
}

function TableLoading({ includeHeader = true }: TableLoadingProps) {
    return (
        <div className="table-loading">
            {includeHeader && (
                <>
                    <div className="table-loading__header">
                        <div className="loading" style={{ width: "86px" }} />
                        <div className="loading" style={{ width: "16px" }} />
                        <div className="loading" style={{ width: "45px" }} />
                    </div>
                    <div className="table-loading__header">
                        <div className="loading" style={{ width: "86px" }} />
                        <div className="loading" style={{ width: "16px" }} />
                        <div className="loading" style={{ width: "45px" }} />
                    </div>
                </>
            )}
            <div className="table-loading__table">
                {Array.from({ length: 8 }, (_, i) => i + 1).map((i) => (
                    <div className="table-loading__column" key={i}>
                        {Array.from({ length: 11 }, (_, j) => j + 1).map(
                            (j) => (
                                <div
                                    key={`${i}$${j}`}
                                    className="table-loading__column__cell"
                                >
                                    <div
                                        className="loading"
                                        style={{
                                            width: `${
                                                Math.floor(Math.random() * 81) +
                                                10
                                            }%`,
                                        }}
                                    />
                                </div>
                            )
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TableLoading;
