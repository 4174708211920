import posthog from "posthog-js";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "./useUser";

export const usePosthog = () => {
    const { data: user, isError, isLoading } = useUser();
    const { user: userInfoAuth0 } = useAuth0();

    const userEmail = userInfoAuth0?.email;

    if (isLoading || isError) return;

    posthog.identify(userEmail, {
        email: userEmail,
        company: user?.activeOrganization?.organizationName,
        companyID: user?.activeOrganization?.organizationUid,
    });
};
