import { useMemo } from "react";
import { buildingTypeOptions } from "utils/constants";
import { getDisplayInfo } from "utils/formatting";
import Dropdown from "components/atoms/Dropdown";

interface BuildingTypeDropdownProps {
    value?: string;
    setValue: (value: string) => void;
    hideTitle?: boolean;
    required?: boolean;
}

function BuildingTypeDropdown({
    value,
    setValue,
    hideTitle = false,
    required = false,
}: BuildingTypeDropdownProps) {
    const { humanReadable, formatFunction } =
        getDisplayInfo("buildingArchetype");

    const buildingTypeDropdownItems = useMemo(
        () =>
            buildingTypeOptions.map(({ key }) => ({
                id: key,
                displayValue: formatFunction(key),
                itemId: `selenium-${key}-menu-item`,
            })),
        []
    );

    return (
        <Dropdown
            hideTitle={hideTitle}
            humanReadable={humanReadable}
            style={{ width: "100%" }}
            items={buildingTypeDropdownItems}
            defaultItem={buildingTypeDropdownItems.find(
                ({ id }) => id === value
            )}
            required={required}
            onSelectItem={({ id }) => setValue(id)}
            selectId="selenium-building-type-select"
        />
    );
}

export default BuildingTypeDropdown;
