import { Table } from "components/molecules/Table";
import { ChartErrorState } from "components/molecules/ErrorStates";
import { ChartNoDataState } from "components/molecules/NoDataState";
import { useMemo } from "react";
import { UseQueryResult } from "react-query";
import { getDisplayInfo } from "utils/formatting";

export enum MeasureDrillDown {
    Category,
    Type,
}

interface AnnualEmissionSavingsByMeasureTableProps {
    query: () => UseQueryResult<
        | AnnualEmissionSavingsByMeasureCategoryTableData
        | AnnualEmissionSavingsByMeasureTypeTableData
    >;
    drillDown?: MeasureDrillDown;
}

function AnnualEmissionSavingsByMeasureTable({
    query,
    drillDown = MeasureDrillDown.Category,
}: AnnualEmissionSavingsByMeasureTableProps) {
    const { data, error } = query();

    const dataSource = useMemo(() => {
        if (!data) return [];
        return data.map((datum) => ({
            ...datum,
            key:
                "carbonReductionMeasureType" in datum
                    ? datum.carbonReductionMeasureType
                    : datum.carbonReductionMeasureCategory,
        }));
    }, [data]);

    const tableColumns = useMemo(() => {
        let columns;

        if (drillDown === MeasureDrillDown.Category)
            columns = MeasureCategoryTableColumns;
        else columns = MeasureCategoryTypeTableColumns;

        return columns.map((col) => {
            const { formatFunction, humanReadable, unit } =
                dataSource[0] && typeof (dataSource[0] as any)[col] === "number"
                    ? getDisplayInfo(
                          col,
                          dataSource.map((d) => (d as any)[col]) as number[]
                      )
                    : getDisplayInfo(col);

            return {
                key: col,
                render: formatFunction,
                title: `${humanReadable} ${unit && `(${unit})`}`,
            };
        });
    }, [drillDown, dataSource]);

    if (error) return <ChartErrorState />;
    if (data === null) return <ChartNoDataState />;

    return (
        <div style={{ height: "530px", width: "100%" }}>
            <Table dataSource={dataSource} columns={tableColumns} />
        </div>
    );
}

const MeasureCategoryTableColumns = [
    "carbonReductionMeasureCategory",
    "measureCount",
    "totalMeasureCost",
    "impactPercentage",
    "totalAnnualCarbonEmissionSavings",
    "totalAnnualEnergyConsumptionSavings",
    "totalAnnualEnergyCostSavings",
];

const MeasureCategoryTypeTableColumns = [
    "carbonReductionMeasureType",
    "carbonReductionMeasureCategory",
    "impactPercentage",
    "incrementalCost",
    "likeForLikeCost",
    "annualCarbonEmissionSavings",
    "annualEnergyConsumptionSavings",
    "annualEnergyCostSavings",
    "yearApplied",
];

export default AnnualEmissionSavingsByMeasureTable;
