import { useMemo } from "react";
import Card from "components/molecules/Card";
import { UseQueryResult } from "react-query";
import { useLocation } from "react-router-dom";

interface CumulativeNetPresentValueCardProps {
    query: () => UseQueryResult<CumulativeNetPresentValueCardData>;
}

function CumulativeNetPresentValueCard({
    query,
}: CumulativeNetPresentValueCardProps) {
    const { data, isLoading, error } = query();

    const { pathname } = useLocation();

    const isBuildingReport = useMemo(
        () => pathname.includes("building"),
        [pathname]
    );

    const formattedData = useMemo(() => {
        if (!data) return undefined;
        return {
            header: {
                displayInfoKey: isBuildingReport
                    ? "netPresentValueIncremental"
                    : "netPresentValue",
                value: data.netPresentValue,
                valueColor:
                    data.netPresentValue > 0
                        ? "var(--audette-content-positive)"
                        : "var(--audette-content-negative)",
            },
            sections: [
                [
                    {
                        displayInfoKey: "discountRate",
                        value: data.discountRate,
                    },
                    {
                        displayInfoKey: "years",
                        value: data.years,
                    },
                    {
                        displayInfoKey: "totalMeasureCost",
                        value: -data.totalMeasureCost,
                        valueColor: getCardValueColor(-data.totalMeasureCost),
                    },
                    {
                        displayInfoKey: "totalLikeForLikeCost",
                        value: -data.totalLikeForLikeCost,
                        titleIcon: <>• </>,
                    },
                    {
                        displayInfoKey: "incrementalCost",
                        value: -data.totalIncrementalCost,
                        titleIcon: <>• </>,
                    },
                    {
                        displayInfoKey: "totalCostSavings",
                        value: data.totalEnergyCostSavings,
                        valueColor: getCardValueColor(
                            data.totalEnergyCostSavings
                        ),
                    },
                ],
                [
                    {
                        displayInfoKey: "returnOnInvestment",
                        value: data.returnOnInvestment,
                    },

                    {
                        displayInfoKey: "marginalAbatementCost",
                        value: data.averageMarginalAbatementCost,
                    },
                ],
            ],
        };
    }, [data]);

    return (
        <Card
            loading={isLoading}
            data={formattedData}
            error={error?.toString()}
        />
    );
}

const getCardValueColor = (value: number) => {
    if (value < 0) return "var(--audette-content-negative)";
    if (value > 0) return "var(--audette-content-positive)";
    return "var(audette-gray-500)";
};

export default CumulativeNetPresentValueCard;
