import { Paragraph } from "components/atoms/Typography";
import { ReactNode } from "react";

function ImageAndDescription({
    description,
    image,
}: {
    description: string;
    image: ReactNode;
}) {
    return (
        <>
            {image}
            <Paragraph
                size="small"
                style={{
                    color: "var(--audette-gray-600)",
                    textAlign: "center",
                    marginBottom: "8px",
                    lineHeight: "1.25rem",
                }}
            >
                {description}
            </Paragraph>
        </>
    );
}

export default ImageAndDescription;