import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";



const mutationActiveAccountFn = (accessToken: string, userInput: UserInput) =>

    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: { 
            userInput 
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data) {
            return data;  
        }
        throw new Error("Error updating user");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });


const mutationQuery = graphql(`
    mutation updateUser($userInput: UserInput!) {
        user (userInput: $userInput)
    }
`);

export default mutationActiveAccountFn;