import Dropdown from "components/atoms/Dropdown";
import { Header } from "components/atoms/Typography";
import { useMemo } from "react";
import { equipmentKeyToLabelMap } from "../Equipment/BuildingEquipment.constants";
import { EquipmentAndInstallationYearSurveyItem, SurveyUIStructSelect } from "../../Survey.helpers";

interface SurveyCustomElementSelectProps<T> {
    item: EquipmentAndInstallationYearSurveyItem<T>;
    selectStruct: SurveyUIStructSelect<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveyCustomElementSelect<T>({
    item,
    selectStruct,
    surveyPageData,
    setSurveyPageData,
}: SurveyCustomElementSelectProps<T>) {
    const { label } = selectStruct;
    const { key } = item

    const selectOptions = item.options
    
    const handleSelectItem = ({ id }: { displayValue: string; id: string }) => {
        setSurveyPageData((prev) => ({
            ...prev,
            [key]: id,
        }));
    };

    const items = useMemo(
        () =>
            selectOptions?.map((id) => ({
                id,
                displayValue: equipmentKeyToLabelMap[id] || id,
            })) || [],
        [selectOptions]
    );

    const defaultItem = useMemo(
        () =>
            surveyPageData[key] !== null
                ? {
                      id: surveyPageData[key],
                      displayValue: equipmentKeyToLabelMap[surveyPageData[key]],
                  }
                : undefined,
        [surveyPageData]
    );

    return (
        <>
            <Header size="small" style={{ marginBottom: "10px" }}>{item.label}</Header>
            <Dropdown
                items={items}
                selectorDataTestId={`test-survey-dropdown-${label}`}
                onSelectItem={handleSelectItem}
                defaultItem={defaultItem}
            />
        </>
    );
}

export default SurveyCustomElementSelect;