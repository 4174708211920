import classNames from "classnames";
import { Paragraph } from "../Typography";
import "./index.css";

function ViewTab({
    name,
    selected,
    onClick,
    id,
}: {
    id?: string;
    name: string;
    selected: boolean;
    onClick: () => void;
}) {
    return (
        <Paragraph>
            <button
                id={id}
                type="button"
                className={classNames("view-tab", selected && "selected")}
                onClick={onClick}
            >
                {name}
            </button>
        </Paragraph>
    );
}

export default ViewTab;
