import { useMemo } from "react";
import { getDisplayInfo } from "utils/formatting";
import { ResponsiveStream } from "@nivo/stream";
import { UseQueryResult } from "react-query";
import { theme } from "components/molecules/Charts/ChartTheme";
import { CURRENT_YEAR } from "utils/constants";
import { Paragraph, Header } from "components/atoms/Typography";
import "./AnnualEmissionSavingsByMeasureChart.css";

interface AnnualEmissionSavingsByMeasureChartProps {
    query: () => UseQueryResult<AnnualEmissionSavingsByMeasureChartData | null>;
}

function AnnualEmissionSavingsByMeasureChart({
    query,
}: AnnualEmissionSavingsByMeasureChartProps) {
    const { data } = query();

    const streamChartData = useMemo(() => {
        if (!data) return null;

        const uniqueKeys = new Set<string>();
        data.forEach((datum) => {
            datum?.carbonReductionMeasureCategories.forEach((category) => {
                if (category?.carbonReductionMeasureCategory)
                    uniqueKeys.add(category?.carbonReductionMeasureCategory);
            });
        });

        let dataMax = 0;
        const formatted = data.map((datum) => {
            let measureTotal = 0;
            const measuresToCarbonEmissions: { [measure: string]: number } = {};
            datum.carbonReductionMeasureCategories.forEach((category) => {
                if (category.carbonReductionMeasureCategory) {
                    measuresToCarbonEmissions[
                        category.carbonReductionMeasureCategory
                    ] = category.totalCarbonEmissionSavings;
                    measureTotal += category.totalCarbonEmissionSavings;
                }
            });

            const arr = Array.from(uniqueKeys).reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr]: 0,
                }),
                {}
            );

            const gridSavings = datum.carbonEmissionSavingsFromGrid;
            measureTotal += datum.carbonEmissionSavingsFromGrid;
            measureTotal += datum.remainingCarbonEmissions;

            if (measureTotal > dataMax) dataMax = measureTotal;

            return {
                year: datum.year,
                "Grid savings": gridSavings,
                ...arr,
                ...measuresToCarbonEmissions,
                "Carbon emissions": datum.remainingCarbonEmissions,
            };
        });
        return {
            formatted,
            max: dataMax,
            chartKeys: [
                "Carbon emissions",
                ...Array.from(uniqueKeys),
                "Grid savings",
            ],
        };
    }, [data]);

    const { unit, formatFunction } = useMemo(
        () => getDisplayInfo("carbonEmissions", streamChartData?.max || 0),
        [streamChartData]
    );

    const chartColors = useMemo(() => {
        if (!streamChartData || streamChartData?.formatted?.length === 0)
            return [];
        const numberOfMeasures =
            Object.keys(streamChartData.formatted[0]).length - 3;
        const colors = STREAM_CHART_COLORS.slice(0, numberOfMeasures);
        colors.push(GRID_SAVINGS_COLOR);
        colors.unshift(CARBON_EMISSIONS_COLOR);
        return colors;
    }, [streamChartData]);

    const margin = {
        top: 20,
        right: 26,
        bottom: 25,
        left: 95,
    };

    return (
        <div className="stream-chart-container">
            <div
                className="stream-chart"
                style={{
                    height: "520px",
                }}
            >
                <ResponsiveStream
                    data={
                        (streamChartData?.formatted as {
                            [key: string]: number;
                        }[]) || []
                    }
                    fill={[{ match: "*", id: "gradient" }]}
                    theme={theme}
                    keys={streamChartData?.chartKeys || []}
                    valueFormat={(v) => formatFunction(v, false)}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 3,
                        tickPadding: 5,
                        legendPosition: "middle",
                        legendOffset: 36,
                        format: (v) => {
                            if (v === 0) return CURRENT_YEAR;
                            if (
                                v ===
                                (streamChartData?.formatted.length || 0) - 1
                            )
                                return "2050";
                            return "";
                        },
                    }}
                    axisLeft={{
                        tickSize: 3,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legend: unit,
                        legendOffset: margin ? margin.left * -1 + 5 : -50,
                        format: (v) => formatFunction(v, false),
                    }}
                    colors={chartColors}
                    offsetType="none"
                    margin={margin}
                    enableStackTooltip={false}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tooltip={(data) => (
                        <div className="stream-chart-tooltip">
                            <div className="stream-chart-tooltip--row">
                                <LegendColor color={data.layer.color} />
                                <Paragraph>{data.layer.label}</Paragraph>
                            </div>
                            <div className="stream-chart-tooltip--row">
                                <Header
                                    size="small"
                                    style={{
                                        color: "var(--audette-gray-500)",
                                    }}
                                >
                                    {data.layer.label === "Carbon emissions"
                                        ? "Total emissions:"
                                        : "Emissions saved:"}
                                </Header>
                                <Paragraph>
                                    {formatFunction(
                                        data.layer.data.reduce(
                                            (prev, curr) => prev + curr.value,
                                            0
                                        ),
                                        false
                                    )}{" "}
                                    {unit}
                                </Paragraph>
                            </div>
                        </div>
                    )}
                />
            </div>
            <AnnualEmissionSavingsByMeasureLegend
                measureCategories={streamChartData?.chartKeys || []}
                colors={chartColors.slice(1, chartColors.length - 1)}
            />
        </div>
    );
}

interface AnnualEmissionSavingsByMeasureLegendProps {
    measureCategories: string[];
    colors: string[];
}

function AnnualEmissionSavingsByMeasureLegend({
    measureCategories,
    colors,
}: AnnualEmissionSavingsByMeasureLegendProps) {
    return (
        <div className="annual-emission-savings-by-measure-legend">
            <Header
                style={{ color: "var(--audette-gray-600)" }}
                size="x-small"
                weight="medium"
            >
                Measure types by impact
            </Header>
            {measureCategories
                .filter(
                    (name) =>
                        name !== "Grid savings" && name !== "Carbon emissions"
                )
                .map((name, index) => (
                    <div className="row" key={colors[index]}>
                        <LegendColor color={colors[index]} />
                        <Paragraph
                            size="small"
                            style={{ color: "var(--audette-gray-600)" }}
                        >
                            {name}
                        </Paragraph>
                    </div>
                ))}
            <div className="row">
                <LegendColor color="var(--audette-gray-300)" />
                <Paragraph
                    size="small"
                    style={{ color: "var(--audette-gray-600)" }}
                >
                    Grid savings
                </Paragraph>
            </div>
            <div className="row">
                <LegendColor color="var(--audette-gray-600)" />
                <Paragraph
                    size="small"
                    style={{ color: "var(--audette-gray-600)" }}
                >
                    Carbon emissions
                </Paragraph>
            </div>
        </div>
    );
}

function LegendColor({ color }: { color: string }) {
    return (
        <div
            style={{
                backgroundColor: color,
                width: "16px",
                height: "16px",
                borderRadius: "4px",
            }}
        />
    );
}

const STREAM_CHART_COLORS = [
    "#EF81DA",
    "#EB03AD",
    "#D5BBF9",
    "#7A2AEB",
    "#7CB7F2",
    "#066ECC",
    "#4BF7B5",
    "#00BC98",
    "#3B814B",
    "#F9BF73",
    "#F7931E",
    "#BB6E00",
    "#F94646",
    "#CC303C",
];

const CARBON_EMISSIONS_COLOR = "#545454";
const GRID_SAVINGS_COLOR = "#E1E1E1";

export default AnnualEmissionSavingsByMeasureChart;
