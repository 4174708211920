import { Paragraph } from "../Typography";
import "./BetaTag.css";

function BetaTag() {
    return (
        <div className="beta-tag">
            <Paragraph size="x-small" style={{ color: "var(--audette-white" }}>
                Beta
            </Paragraph>
        </div>
    );
}

export default BetaTag;
