import { WarningIcon } from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import { Header, Paragraph } from "components/atoms/Typography";
import { useState } from "react";
import { getDisplayInfo } from "utils/formatting";
import "./DataCoverageHeader.scss";

interface DataCoverageHeaderProps {
    dataCoverages: DataCoverage[];
}

function DataCoverageHeader({ dataCoverages }: DataCoverageHeaderProps) {
    const [selectedItem, setSelectedItem] = useState<string>();

    return (
        <div className="data-coverage-header">
            <div className="data-coverage-header__items">
                {Object.keys(dataCoveragesMap).map((key) => (
                    <DataCoverageHeaderItem
                        key={key}
                        dataCoverages={dataCoverages}
                        dataCoverageItemKey={key}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                    />
                ))}
            </div>
        </div>
    );
}

interface DataCoverageHeaderItemProps {
    dataCoverages: DataCoverage[];
    selectedItem: string | undefined;
    setSelectedItem: (item: string | undefined) => void;
    dataCoverageItemKey: string;
}

function DataCoverageHeaderItem({
    dataCoverages,
    selectedItem,
    setSelectedItem,
    dataCoverageItemKey,
}: DataCoverageHeaderItemProps) {
    const handleClick = () => {
        if (selectedItem === dataCoverageItemKey) setSelectedItem(undefined);
        else setSelectedItem(dataCoverageItemKey);
    };

    return (
        <button
            className="data-coverage-header__item-wrapper"
            data-testid={`data-coverage-header-${dataCoverageItemKey}`}
            type="button"
            key={dataCoverageItemKey}
            onClick={handleClick}
        >
            <DataCoverageButtonTitle coverageTypeKey={dataCoverageItemKey} />
            <DataCoverageItemBody
                coverageTypeKey={dataCoverageItemKey}
                dataCoverages={dataCoverages}
            />
        </button>
    );
}

interface DataCoverageButtonTitleProps {
    coverageTypeKey: string;
}

function DataCoverageButtonTitle({
    coverageTypeKey: key,
}: DataCoverageButtonTitleProps) {
    return (
        <div className="data-coverage-header__item-title">
            <Paragraph style={{ color: "var(--audette-gray-600)" }}>
                {key[0].toUpperCase() + key.slice(1)}
            </Paragraph>
            <Tooltip
                tooltipBody={<DataCoverageTooltipBody coverageTypeKey={key} />}
            />
        </div>
    );
}

function DataCoverageTooltipBody({
    coverageTypeKey: key,
}: DataCoverageButtonTitleProps) {
    const tooltipText = dataCoveragesKeyToTooltipTextMap[key];
    const showTextAsList = typeof tooltipText === "string";

    return (
        <Paragraph size="small">
            {showTextAsList ? (
                tooltipText
            ) : (
                <>
                    <Paragraph size="small" style={{ marginBottom: "8px" }}>
                        One of the following occurred:
                    </Paragraph>
                    {tooltipText.map((text, index) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                        >{`\u2022 ${text}`}</div>
                    ))}
                </>
            )}
        </Paragraph>
    );
}

interface DataCoverageItemBodyProps {
    coverageTypeKey: string;
    dataCoverages: DataCoverage[];
}

function DataCoverageItemBody({
    coverageTypeKey: key,
    dataCoverages,
}: DataCoverageItemBodyProps) {
    const { formatFunction } = getDisplayInfo("dataCoverage");
    return (
        <div className="data-coverage-header__item">
            <Header size="small">
                {dataCoverages.reduce(
                    (acc, curr) =>
                        dataCoveragesMap[key].includes(curr.dataCoverage)
                            ? acc + 1
                            : acc,
                    0
                )}
            </Header>
            {key === "warning" ? (
                <div className="data-coverage-header__warning-icon">
                    <WarningIcon />
                </div>
            ) : (
                dataCoveragesMap[key].map((badge) => formatFunction(badge))
            )}
        </div>
    );
}

const dataCoveragesMap: { [key: string]: DataCoverageEnum[] } = {
    low: ["no_equipment_and_no_utility"],
    error: ["error"],
    warning: [
        "equipment_warning",
        "equipment_and_utility_warning",
        "utility_warning",
    ],
    medium: ["equipment", "utility"],
    high: ["equipment_and_utility"],
};

const dataCoveragesKeyToTooltipTextMap: { [key: string]: string | string[] } = {
    low: "Add energy and equipment data to the building profile.",
    error: "Couldn't create energy and emissions profile. Try editing the building. If the problem persists, contact Audette.",
    warning: [
        "Energy data must match the building energy type.",
        "Equipment data must match the building energy type.",
        "Equipment size and installation years must be defined.",
    ],
    medium: "Energy or equipment data added.",
    high: "Energy and equipment data added.",
};

export default DataCoverageHeader;
