import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";

import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import Modal from "@mui/material/Modal";
import { Item } from "components/atoms/Dropdown";
import ChartHeader from "./ChartHeader";
import "./ChartContainer.css";

interface ChartContainerProps {
    children: ReactNode;
    items?: Item[];
    onSelectItem?: (id: string) => void;
    title?: string;
    loading?: boolean;
    onClickTable?: () => void;
}

function ChartContainerWrapper({ ...props }: ChartContainerProps) {
    const { buildingModelUid } = useParams();

    if (buildingModelUid)
        return (
            <BuildingChartContainer
                buildingModelUid={buildingModelUid}
                {...props}
            />
        );

    return <ChartContainer {...props} />;
}

interface BuildingChartContainerProps extends ChartContainerProps {
    buildingModelUid: string;
}

function BuildingChartContainer({
    buildingModelUid,
    loading,
    ...props
}: BuildingChartContainerProps) {
    const { loadingState } = useBuildingLoadingState(buildingModelUid);

    return (
        <ChartContainer
            loading={loadingState.buildingReportsLoading || loading}
            {...props}
        />
    );
}

function ChartContainer({
    children,
    items,
    onSelectItem,
    title,
    loading = false,
    onClickTable,
}: ChartContainerProps) {
    const [fullWidthModalIsOpen, setFullWidthModalIsOpen] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const handleExpand = () => setFullWidthModalIsOpen(true);
    const handleCollapse = () => setFullWidthModalIsOpen(false);

    if (loading) return <ChartLoadingSkeleton />;

    return (
        <div className="chart-container">
            <ChartHeader
                items={items}
                onSelectItem={onSelectItem}
                onClickExpandCollapse={handleExpand}
                expand={true}
                title={title}
                onClickTable={onClickTable}
                showTable={showTable}
                setShowTable={setShowTable}
            />
            <FullWidthChartModal
                open={fullWidthModalIsOpen}
                onClose={handleCollapse}
            >
                <ChartHeader
                    items={items}
                    onSelectItem={onSelectItem}
                    onClickExpandCollapse={handleCollapse}
                    expand={false}
                    title={title}
                    onClickTable={onClickTable}
                    showTable={showTable}
                    setShowTable={setShowTable}
                />
                {children}
            </FullWidthChartModal>
            {children}
        </div>
    );
}

function ChartLoadingSkeleton() {
    return (
        <div
            className="chart-container chart-loading-skeleton"
            data-testid="chart-loading"
        >
            <div className="chart-loading-skeleton--title loading" />
            <div className="chart-loading-skeleton--body">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 768 222"
                    fill="none"
                >
                    <path d={barChartSkeletonSVGPath} fill="#E1E1E1" />
                </svg>
            </div>
        </div>
    );
}

const barChartSkeletonSVGPath =
    "M0 222H767.999C767.999 137.647 768 23.1707 768 3.07333C768 1.61974 766.704 0.580892 765.372 1.16327C733.566 15.0713 416.502 149.022 2.01501 149.456C0.910461 149.457 0 150.352 0 151.457V222Z";

interface FullWidthChartModalProps {
    children?: ReactNode;
    open: boolean;
    onClose: () => void;
}

function FullWidthChartModal({
    children,
    onClose,
    open,
}: FullWidthChartModalProps) {
    return (
        <Modal open={open} onClose={onClose}>
            <div className="full-width-chart-modal">{children}</div>
        </Modal>
    );
}

export default ChartContainerWrapper;
