import { Button } from "components/atoms/Button";
import CancelButton from "components/atoms/CancelButton/CancelButton";
import { Header, Paragraph } from "components/atoms/Typography";
import Input from "./Input";
import inputs from "./inputs";

interface BuildingDetailsFormProps {
    handleNextClick: () => void;
    getBuildingModelForKey: (key: string) => any;
    setBuildingModelValueForKey: any;
    inputsAreValid: boolean;
    onCancel: () => void;
}

function BuildingDetailsForm({
    handleNextClick,
    getBuildingModelForKey,
    setBuildingModelValueForKey,
    inputsAreValid,
    onCancel,
}: BuildingDetailsFormProps) {
    return (
        <>
            <div className="add-building-sidebar">
                <div style={{ display: "grid", gap: "16px" }}>
                    <Paragraph style={{ color: "var(--audette-gray-500)" }}>
                        STEP 1/2
                    </Paragraph>
                    <Header size="medium">Enter building details</Header>
                </div>
                {inputs.map(({ key, required, type }) => (
                    <Input
                        key={key}
                        attributeKey={key}
                        required={required}
                        type={type}
                        value={getBuildingModelForKey(key)}
                        setValue={(value) =>
                            setBuildingModelValueForKey(value, key)
                        }
                    />
                ))}
            </div>
            <div className="add-building-sidebar--footer">
                <Button
                    style={{ width: "76px" }}
                    type="primary"
                    disabled={!inputsAreValid}
                    onClick={handleNextClick}
                >
                    Next
                </Button>
                <CancelButton onCancel={onCancel} />
            </div>
        </>
    );
}

export default BuildingDetailsForm;