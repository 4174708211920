import mutationSetRolesFn from "ApiLayer/User/querySetRole";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { userQueryKey } from "hooks/useUser";
import { useMutation, useQueryClient } from "react-query";
import { showMutationAlert } from "utils/helpers";

export const useSetRoles = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess:  () => {
            queryClient.invalidateQueries({ queryKey: userQueryKey });
            alert.success("Role changed.");
        },
        mutationFn: async (data: { userIds: string[], roles: string[] }) => (
            mutationSetRolesFn(accessToken, data)
        )
    });
}