import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";
import "./index.scss";

interface ButtonProps {
    children?: any;
    type?: "primary" | "secondary" | "link" | "primary-negative";
    onClick?: (value: any) => void;
    disabled?: boolean;
    icon?: ReactNode;
    style?: CSSProperties;
    id?: string;
    className?: string;
    dataTestId?: string
}

export function Button({
    children,
    type,
    onClick,
    disabled,
    icon,
    style,
    id,
    className,
    dataTestId,
    ...props
}: ButtonProps) {
    return (
        <button
            {...props}
            id={id}
            type="button"
            className={classNames(
                "audette-button",
                type,
                disabled,
                className && className
            )}
            onClick={onClick}
            disabled={disabled}
            style={style}
            data-testid={dataTestId}
        >
            {icon && icon}
            {children}
        </button>
    );
}
