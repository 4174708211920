import { EnergyTypeIcon } from "components/atoms/Icon";
import "./EnergyTypeInferredIcon.css";

interface EnergyTypeInferredIconProps {
    label?: string;
}

export function EnergyTypeInferredIcon({ label }: EnergyTypeInferredIconProps) {
    return (
        <div className="energy-type-inferred-icon">
            {label && label}
            <EnergyTypeIcon />
        </div>
    );
}
