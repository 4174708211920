import { NumericStringMultiFilterProps } from "../FilterSidebar.interface";
import DataCoverageMultiSelectFilter from "./DataCoverageMultiSelectFilter";
import GenericMultiSelectFilter from "./GenericMultiSelectFilter";
import TagMultiSelectFilter from "./TagMultiSelectFilter";

function MultiSelectFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<StringFilterField>) {
    if (filterKey === "tags") {
        return (
            <TagMultiSelectFilter filters={filters} setFilters={setFilters} />
        );
    }

    if (filterKey === "dataCoverage") {
        return (
            <DataCoverageMultiSelectFilter
                filters={filters}
                setFilters={setFilters}
            />
        );
    }

    return (
        <GenericMultiSelectFilter
            filters={filters}
            setFilters={setFilters}
            filterField={filterField}
            filterKey={filterKey}
        />
    );
}

export default MultiSelectFilter;