import { atom, useRecoilState } from "recoil";

const reportsGroupByStateAtom = atom<GroupByMetric>({
    key: "reportsGroupByState",
    default: "building",
});

const useReportsGroupByState = () => {
    const [state, setState] = useRecoilState(reportsGroupByStateAtom);

    return {
        state,
        setState,
    };
};

export default useReportsGroupByState;
