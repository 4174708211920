import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    buildingModelUid: string,
    carbonReductionMeasureType: string
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(
            buildingModelUid,
            carbonReductionMeasureType
        ),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            data.measureReport
                .annualCashFlowChart as ProjectReportAnnualCashFlowChart
    );

const query = graphql(`
    query annualCashFlowChartProjectReport(
        $buildingModelUid: Uuid!
        $carbonReductionMeasureType: String!
    ) {
        measureReport(
            buildingModelUid: $buildingModelUid
            carbonReductionMeasureType: $carbonReductionMeasureType
        ) {
            annualCashFlowChart {
                total {
                    carbonTaxSavings
                    calendarYear
                    cashFlowAnnual
                    cashFlowCumulative
                    incrementalCost
                    utilityCostSavingsElectricity
                    utilityCostSavingsNaturalGas
                    likeForLikeCost
                }
                incremental {
                    carbonTaxSavings
                    calendarYear
                    cashFlowAnnual
                    cashFlowCumulative
                    incrementalCost
                    utilityCostSavingsElectricity
                    utilityCostSavingsNaturalGas
                }
            }
        }
    }
`);

export default queryFn;
