import { useState, useMemo } from "react";
import { useBuildingModelsTableQuery } from "queries/useBuildingModelsTable";
import useUniqueTags from "recoilStore/useUniqueTags";
import {
    BuildingsTableData,
    buildBuildingTableColumns,
    buildTableData,
} from "./helpers";
import { BuildingsData } from "./constants";

export const useBuildingsData = (): BuildingsData => {
    const {
        data: buildingModels,
        error,
        isLoading,
    } = useBuildingModelsTableQuery();

    const { tags } = useUniqueTags();
    const tableViewTableColumns = useMemo(
        () => buildBuildingTableColumns(tags.tags.length !== 0),
        [tags]
    );

    const dataCoverages = useMemo(
        () => buildingModels?.map(({ dataCoverage }) => dataCoverage) || [],
        [buildingModels]
    );

    const {
        dataSource: tableViewTableData,
        columns: formattedTableViewColumns,
    } = useMemo(
        () => buildTableData(buildingModels || [], tableViewTableColumns),
        [buildingModels, tableViewTableColumns]
    );

    const [selectedBuildingRows, setSelectedBuildingRows] = useState<
        BuildingsTableData[]
    >([]);

    const numberOfBuildings = useMemo(
        () => buildingModels?.length,
        [buildingModels]
    );

    const buildingName = useMemo(() => {
        if (selectedBuildingRows.length === 1)
            return selectedBuildingRows[0].buildingName || undefined;
        return undefined;
    }, [selectedBuildingRows]);

    const { selectedTags, tagsAreMixed } = useMemo(() => {
        let totalTagCount = 0;
        const tags = selectedBuildingRows.flatMap(({ tags }) => {
            totalTagCount += tags.length;
            return tags;
        });

        const uniqueTags = Array.from(new Set(tags));
        const averageTagCount = totalTagCount / selectedBuildingRows.length;

        return {
            selectedTags: uniqueTags,
            tagsAreMixed: uniqueTags.length !== averageTagCount,
        };
    }, [selectedBuildingRows, buildingModels]);

    return {
        buildingName,
        dataCoverages,
        numberOfBuildings,
        selectedBuildingRows,
        setSelectedBuildingRows,
        tableViewTableData,
        formattedTableViewColumns,
        selectedTags,
        tagsAreMixed,
        error,
        isLoading,
    };
};
