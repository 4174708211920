import "./NoDataState.scss";
import { Paragraph } from "components/atoms/Typography";

interface NoDataStateProps {
    height?: number;
}

function NoDataState({ height }: NoDataStateProps) {
    return (
        <div
            className="no-data-state"
            style={height ? { height: `${height}px` } : undefined}
        >
            <Paragraph
                size="small"
                style={{ color: "var(--audette-gray-500)" }}
            >
                No data
            </Paragraph>
        </div>
    );
}

export function ChartNoDataState() {
    return <NoDataState height={450} />;
}

export function CardNoDataState() {
    return <NoDataState height={150} />;
}
