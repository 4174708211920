import { graphql } from "gql/gql";
import { request } from "graphql-request";
import {
    buildBuildingModelRequestVariables,
    buildRequestHeaders,
} from "utils/helpers";
import { BACKEND_DOMAIN } from "utils/constants";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.buildingModelReports.error)
            throw new Error(data.buildingModelReports.error);

        const dataCoverage = data?.buildingModelReports.header?.dataCoverage;
        if (dataCoverage?.modellingStatus === "processing") {
            throw new Error("Building isn't ready yet");
        }
        return data.buildingModelReports.header as BuildingModelHeader;
    });
const query = graphql(`
    query buildingModelHeader($buildingModelUid: Uuid!) {
        buildingModelReports(buildingModelUid: $buildingModelUid) {
            error
            header {
                buildingName
                dataCoverage {
                    dataCoverage
                    energyType
                    energyTypeFromEquipmentData
                    energyTypeFromUtilityData
                    equipmentDataHasSizeAndYear
                    equipmentDataUploaded
                    modellingStatus
                    utilityDataUploaded
                }
                fundName
                location {
                    streetAddress
                    city
                    stateProvince
                    postalZipCode
                    country
                    latitude
                    longitude
                    climateZone
                }
                propertyName
                tags
            }
        }
    }
`);

export default queryFn;
