import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    buildingModelUid: string,
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            ...buildBuildingModelRequestVariables(buildingModelUid),
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.deleteEquipmentSurvey.error)
            throw new Error(data.deleteEquipmentSurvey.error);
        return data.deleteEquipmentSurvey.buildingModelRunUid;
    });

const query = graphql(`
    mutation deleteEquipmentSurvey(
        $buildingModelUid: Uuid!
    ) {
        deleteEquipmentSurvey(
            buildingModelUid: $buildingModelUid
        ) {
            error
            buildingModelRunUid
            buildingModelUid
        }
    }
`);

export default queryFn;
