import { useMutation } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { showMutationAlert } from "utils/helpers";
import mutationFn from "../ApiLayer/Utility/deleteBuildingModelUtilityData";

export const useDeleteBuildingModelUtilityData = (
    buildingModelUid: string,
    onSuccess: () => void,
    onError: () => void
) => {
    const accessToken = useAccessToken();
    const alert = useAlert();

    return useMutation({
        onSuccess: () => {
            onSuccess();
            alert.success("Energy deleted.");
        },
        onError: (errors) => {
            onError();
            showMutationAlert(errors, alert);
        },
        mutationFn: async () => mutationFn(accessToken, buildingModelUid),
    });
};
