import { useMemo } from "react";
import Card from "components/molecules/Card";

interface OperatingCostCardProps {
    cumulative?: boolean;
    data: any;
    loading: boolean;
    error?: string;
}

function OperatingCostCard({
    data,
    loading,
    cumulative = false,
    error,
}: OperatingCostCardProps) {
    const cardData = useMemo(() => {
        if (!data) return undefined;

        const dataIsAveraged =
            "averageCarbonTaxCostIntensity" in data &&
            "averageUtilityCostIntensity" in data;

        const { totalCarbonTax, totalUtilityCost } = data;

        const totalOperatingCost =
            "totalEnergyCost" in data
                ? data.totalEnergyCost
                : data.totalOperatingCost;

        const carbonTaxIntensity = dataIsAveraged
            ? data.averageCarbonTaxCostIntensity
            : data.totalCarbonTaxIntensity;

        const utilityCostIntensity = dataIsAveraged
            ? data.averageUtilityCostIntensity
            : data.totalUtilityCostIntensity;

        return {
            header: {
                title: cumulative
                    ? "Total cumulative operating cost"
                    : undefined,
                value: totalOperatingCost,
                displayInfoKey: "totalAnnualOperatingCost",
            },
            sections: [
                [
                    {
                        value: utilityCostIntensity,
                        displayInfoKey: "utilityCostIntensity",
                    },
                    {
                        value: carbonTaxIntensity,
                        displayInfoKey: "carbonTaxIntensity",
                    },
                ],
                [
                    {
                        value: totalUtilityCost,
                        displayInfoKey: "utilityCost",
                        tooltipText: "Excluding carbon taxes",
                        percentage: Math.round(
                            (totalUtilityCost / totalOperatingCost) * 100
                        ),
                    },
                    {
                        value: totalCarbonTax,
                        displayInfoKey: "carbonTax",
                        percentage: Math.round(
                            (totalCarbonTax / totalOperatingCost) * 100
                        ),
                    },
                ],
            ],
        };
    }, [data]);

    return <Card data={cardData} loading={loading} error={error} />;
}

export default OperatingCostCard;
