import { Box, CircularProgress } from "@mui/material";

function LoadingSpinner() {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop:"50px" }}>
            <CircularProgress style={{ 'color': 'var(--audette-purple-300)' }} />
        </Box>
    );
}

export default LoadingSpinner