import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import {
    Delete,
    MoreOptionsIcon,
    ReinviteIcon,
    UserIcon,
} from "components/atoms/Icon";
import LoadingSpinner from "components/atoms/LoadingSpinner/LoadingSpinner";
import { useUser } from "hooks/useUser";
import { RoleActions, UserPermissions } from "utils/enums";
import DeleteUserModal from "./DeleteUserModal";
import styles from "./UserManagementMenu.module.scss";

interface UserManagementMenuProps {
    userId: string;
    email: string;
    roles: string[];
    isActive: boolean;
    setRolesIsLoading: boolean;
    removeUserIsLoading: boolean;
    onOptionClick: (action: string) => void;
}

function UserManagementMenu({
    userId,
    email,
    roles,
    isActive,
    onOptionClick,
    setRolesIsLoading,
    removeUserIsLoading
}: UserManagementMenuProps) {
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);


    const { data: user, isFetching } = useUser(); 

    if (isFetching) {
        return (
            <LoadingSpinner />
        );
    }

    const permissions = user?.permissions || [""];
    
    const handleCancel = () => {
        setModalIsOpen(false);
    }    

    const handleMoreOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const handleMenuItemClick = (action: string) => {
        onOptionClick(action);
        handleClose();
    };

    const changeRoleText = (roles.includes("Admin") || (roles.length === 0) ) ? RoleActions.MakeMember : RoleActions.MakeAdmin;
    const changeAudetteAdminRoleText = roles.includes("Audette Admin") ? RoleActions.RemoveAudetteAdmin : RoleActions.MakeAudetteAdmin;
    
    return (
        <>
        <div>
            <IconButton onClick={handleMoreOptionsClick}>
                <MoreOptionsIcon />
            </IconButton>
            <Menu
                anchorEl={anchorElement}
                open={Boolean(anchorElement)}
                onClose={handleClose}
            >
                { !isActive && (
                    <MenuItem 
                        onClick={() => handleMenuItemClick(RoleActions.Reinvite)}
                        disabled={removeUserIsLoading}    
                    >
                        <div className={styles.buttonContainer}>
                            <ReinviteIcon />
                            Reinvite
                        </div>
                    </MenuItem>
                )}

                <MenuItem 
                    onClick={() => handleMenuItemClick(changeRoleText)}
                    disabled={setRolesIsLoading}    
                >
                    <div className={styles.buttonContainer}>
                        <UserIcon />
                        {changeRoleText} {setRolesIsLoading && "(role change in progress)"}
                    </div>
                </MenuItem>
                
                {permissions.includes(UserPermissions.GrantAudetteAdmin) && (
                    <MenuItem 
                        onClick={() => handleMenuItemClick(changeAudetteAdminRoleText)}
                        disabled={setRolesIsLoading}    
                    >
                        <div className={styles.buttonContainer}>
                            <UserIcon />
                            {changeAudetteAdminRoleText} {setRolesIsLoading && "(role change in progress)"}
                        </div>
                    </MenuItem>
                )}
                    
                <MenuItem
                    onClick={() => { 
                        setModalIsOpen(true); 
                        handleClose(); 
                    }}
                    disabled={removeUserIsLoading}
                >
                    <div className={`${styles.buttonContainer} ${styles.deleteButton}`}>
                        <Delete />
                        Remove
                    </div>
                </MenuItem>
            </Menu>
        </div>
            <DeleteUserModal
                modalIsOpen={modalIsOpen}
                email={email}
                onCancel={handleCancel}
                handleMenuItemClick={handleMenuItemClick}
            />
        </>
    );
}

export default UserManagementMenu;
