import { graphql } from "gql";
import request from "graphql-request";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationSetRolesFn = (
    accessToken: string, 
    data: {
        userIds: string[],
        roles: string[]
    }
) =>

    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: {
            userIds: data.userIds,
            roles: data.roles
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data) {
            return data;  
        }
        throw new Error("Error setting role");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });

    const mutationQuery = graphql(`
        mutation setRoles ($userIds: [String!]!, $roles: [String!]!) {
            setRoles(userIds: $userIds, roles: $roles){
                userId
                roles
            }
        }
    `);

export default mutationSetRolesFn;