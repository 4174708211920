import React from "react";
import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./context";

const posthogOptions = {
    api_host: "https://us.i.posthog.com",
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={posthogOptions}
        >
            <AuthProvider>
                <App />
            </AuthProvider>
        </PostHogProvider>
    </React.StrictMode>
);
