import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildReportsRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.reports?.baselineReport?.annualOperatingCostCard.error)
            throw new Error(
                data.reports.baselineReport.annualOperatingCostCard.error
            );
        return (
            data.reports?.baselineReport?.annualOperatingCostCard.data || null
        );
    });

const query = graphql(`
    query annualOperatingCostCard($reportFilter: ReportFilter) {
        reports(reportFilter: $reportFilter) {
            baselineReport {
                annualOperatingCostCard {
                    error
                    data {
                        averageCarbonTaxCostIntensity
                        averageUtilityCostIntensity
                        totalCarbonTax
                        totalOperatingCost
                        totalUtilityCost
                        year
                    }
                }
            }
        }
    }
`);

export default queryFn;
