import { graphql } from "gql/gql";
import { request } from "graphql-request";
import {
    buildRequestHeaders,
    buildFootprintRequestVariables,
} from "utils/helpers";
import { BACKEND_DOMAIN } from "utils/constants";

const queryFn = (accessToken: string, address: Address) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildFootprintRequestVariables(address),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => data.buildingFootprints as BuildingFootprints);

const query = graphql(`
    query buildingFootprints($address: Address!) {
        buildingFootprints(address: $address) {
            error
            closestBuildingUid
            features {
                type
                geometry {
                    type
                    coordinates
                }
                properties {
                    latitude
                    longitude
                    buildingUid
                }
            }
        }
    }
`);

export default queryFn;
