import { useQuery } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";
import queryFn from "ApiLayer/Equipment/queryEquipmentSurvey";

export const useEquipmentSurvey = (buildingModelUid: string) => {
    const accessToken = useAccessToken();
    return useQuery({
        queryKey: ["equipmentSurvey", buildingModelUid],
        queryFn: async () => queryFn(accessToken, buildingModelUid),
        staleTime: QUERY_STALE_TIME,
    });
};
