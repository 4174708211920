import { DownArrow, UpArrow } from "components/atoms/Icon";
import { Header, Paragraph } from "components/atoms/Typography";
import { formatPercentage } from "utils/formatting";

interface IncreaseDecreasePercentageProps {
    value: number;
    isHeader?: boolean;
}

export function IncreaseDecreasePercentage({
    value,
    isHeader = false,
}: IncreaseDecreasePercentageProps) {
    const isNegative = value > 0;
    const isNeutral = Math.round(value) === 0;
    const isPositive = value < 0;

    let color;
    if (isNegative) color = "var(--audette-content-negative)";
    if (isPositive) color = "var(--audette-content-positive)";
    if (isNeutral) color = "var(--audette-gray-500)";

    const formattedValue = `${formatPercentage(Math.abs(value))}%`;

    return (
        <div
            style={{ color, textAlign: "center" }}
            className="card__increase-decrease-percentage"
        >
            {isPositive || isNeutral ? (
                <DownArrow color={color} />
            ) : (
                <UpArrow color={color} />
            )}
            {/* Conditionally rendering Header or Paragraph based on isHeader */}
            {isHeader ? (
                <Header size="small" style={{ color }}>
                    {formattedValue}
                </Header>
            ) : (
                <Paragraph size="small" style={{ color }}>
                    {formattedValue}
                </Paragraph>
            )}
        </div>
    );
}