import { graphql } from "gql/gql";
import { request } from "graphql-request";
import {
    buildBuildingModelRequestVariables,
    buildRequestHeaders,
} from "utils/helpers";
import { BACKEND_DOMAIN } from "utils/constants";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.equipmentSurvey === null) return null;
        return data.equipmentSurvey as EquipmentSurvey;
    });

const query = graphql(`
    query equipmentSurvey($buildingModelUid: Uuid!) {
        equipmentSurvey(buildingModelUid: $buildingModelUid) {
            error
            airHandlingEquipment {
                airHandlingEquipmentExists
                airHandlingEquipmentType
                airHandlingEquipmentHeatingType
                airHandlingEquipmentCoolingType
                airHandlingEquipmentSupplyAirRate
                airHandlingEquipmentAverageInstallationYear
            }
            centralPlantCooler {
                centralPlantCoolerExists
                centralPlantCoolerType
                centralPlantCoolerTerminalUnits
                centralPlantCoolerSize
                centralPlantCoolerAverageInstallationYear
            }
            centralPlantHeater {
                centralPlantHeaterExists
                centralPlantHeaterType
                centralPlantHeaterTerminalUnits
                centralPlantHeaterSize
                centralPlantHeaterAverageInstallationYear
            }
            centralPlantHeatPump {
                centralPlantHeatPumpExists
                centralPlantHeatPumpType
                centralPlantHeatPumpSize
                centralPlantHeatPumpAverageInstallationYear
            }
            genericHVACEquipment {
                name
                size
                coefficientOfPerformance
                endUse
                fuel
                installationYear
                lifeSpan
                loadRatio
                sizeUnits
            }
            domesticHotWaterHeater {
                domesticHotWaterHeaterExists
                domesticHotWaterHeaterType
                domesticHotWaterHeaterSize
                domesticHotWaterHeaterAverageInstallationYear
            }
            heatPump {
                heatPumpExists
                heatPumpType
                heatPumpSize
                heatPumpInstallationYear
            }
            otherEquipment {
                clothesDryersExists
                clothesDryersType
                clothesDryersEnergyDensity
                clothesDryersAverageInstallationYear
                clothesWashersExists
                clothesWashersEnergyDensity
                clothesWashersAverageInstallationYear
                escalatorExists
                escalatorQuantity
                escalatorInstallationYear
                elevatorsExists
                elevatorsQuantity
                elevatorsAverageInstallationYear
                rooftopPhotovoltaicsExists
                rooftopPhotovoltaicsSize
                rooftopPhotovoltaicsAverageInstallationYear
            }
            rooftopUnit {
                rooftopUnitExists
                rooftopUnitHeatingType
                rooftopUnitCoolingType
                rooftopUnitSupplyAirRate
                rooftopUnitAverageInstallationYear
            }
            terminalCooler {
                terminalCoolerExists
                terminalCoolerUnits
                terminalCoolerSize
                terminalCoolerAverageInstallationYear
            }
            terminalHeater {
                terminalHeaterCoolerSize
                terminalHeaterExists
                terminalHeaterUnits
                terminalHeaterSize
                terminalHeaterAverageInstallationYear
            }
        }
    }
`);

export default queryFn;
