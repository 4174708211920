import "./ChartHeader.css";
import Dropdown, { Item } from "components/atoms/Dropdown";
import { Expand, Collapse, ChartIcon } from "components/atoms/Icon";
import { Header } from "components/atoms/Typography";

interface ChartHeaderProps {
    items?: Item[];
    title?: string;
    onClickExpandCollapse: () => void;
    onSelectItem?: (id: string) => void;
    expand: boolean;
    onClickTable?: () => void;
    showTable: boolean;
    setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
}

function ChartHeader({
    items,
    onClickExpandCollapse,
    onSelectItem,
    expand,
    title,
    onClickTable,
    showTable,
    setShowTable,
}: ChartHeaderProps) {
    if (!title && !items)
        throw new Error("Please define either a title or dropdown items");

    if (items && !onSelectItem)
        throw new Error("Please define onSelectItem when adding prop items");

    return (
        <div className="chart-header">
            {title && <Header size="small">{title}</Header>}
            {items && onSelectItem && (
                <Dropdown
                    items={items}
                    onSelectItem={({ id }) => onSelectItem(id)}
                    defaultItem={items[0]}
                />
            )}
            <div className="chart-header--icons">
                {onClickTable && (
                    <button
                        type="button"
                        onClick={() => {
                            setShowTable((prev) => !prev);
                            onClickTable();
                        }}
                    >
                        {showTable ? <ChartIcon /> : <ChartIcon />}
                    </button>
                )}
                <button type="button" onClick={onClickExpandCollapse}>
                    {expand ? <Expand /> : <Collapse />}
                </button>
            </div>
        </div>
    );
}

export default ChartHeader;
