import { SurveyUIStructSelect } from "../../Survey.helpers";
import SurveyCustomElement from "./SurveyCustomElement";
import SurveyCustomElementSelect from "./SurveyCustomElementSelect";
import styles from "./SurveyView.module.scss";

interface SurveyGenericEquipmentProps<T> {
    surveyQuestion: any;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveyGenericEquipment<T>({ 
    surveyQuestion, 
    surveyPageData, 
    setSurveyPageData 
}: SurveyGenericEquipmentProps<T>) {
    return(
        <div>
        {surveyQuestion.items?.map(
            (item: any, index: number) => (
                <div
                    key={
                        item.key
                            ? item.key.toString()
                            : item.label
                    }
                    className={`${index !== 0 ? styles.surveyItemWitMargin : ''}`}

                    style={{ marginBottom: "20px" }}
                >
                    {item.type ===
                        "text" && (
                        <SurveyCustomElement
                            item={item}
                            type="string"
                            surveyPageData={surveyPageData}
                            setSurveyPageData={setSurveyPageData}
                        />
                    )}

                    {
                        item.type ===
                            "number" && (
                            <SurveyCustomElement
                                item={item}
                                type="number"
                                surveyPageData={surveyPageData}
                                setSurveyPageData={setSurveyPageData}
                            />
                        )

                    }
                    {
                        item.type ===
                            "select" && (
                            <SurveyCustomElementSelect
                                item={item}
                                selectStruct={
                                    surveyQuestion as SurveyUIStructSelect<T>
                                }
                                surveyPageData={surveyPageData}
                                setSurveyPageData={setSurveyPageData}
                            />
                        )

                    }
                </div>
            )
        )}
    </div>
    )
}

export default SurveyGenericEquipment;