import { ReactNode } from "react";
import classNames from "classnames";
import { Header } from "components/atoms/Typography";
import { CloseButton } from "components/atoms/Icon";
import "./index.css";

interface SidebarProps {
    onCancel: () => void;
    sidebarOpen: boolean;
    title: string;
    children: ReactNode;
}

function Sidebar({ onCancel, sidebarOpen, title, children }: SidebarProps) {
    return (
        <div className={classNames("sidebar", sidebarOpen ? "show" : "hidden")}>
            <div className="sidebar--header">
                <Header size="small" weight="bold">
                    {title}
                </Header>
                <CloseButton onClick={onCancel} />
            </div>
            {children}
        </div>
    );
}

export default Sidebar;
