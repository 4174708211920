import { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// hooks
import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useBuildingModelHeaderQuery } from "queries/useBuildingModelHeaderQuery";
import { useDeleteBuildingModelUtilityData } from "mutations/useDeleteBuildingModelUtilityData";
import { useEnergyAggregationQuery } from "queries/Utility/useEnergyAggregationQuery";
import posthog from "posthog-js";

// components
import { Button } from "components/atoms/Button";
import { DeleteButton } from "components/molecules/DangerousButtons";
import Dropdown from "components/atoms/Dropdown";
import {
    ElectricityIcon,
    NaturalGasIcon,
    WarningIcon,
} from "components/atoms/Icon";
import { Paragraph, Header } from "components/atoms/Typography";
import SidebarLoading from "components/molecules/SidebarLoading";

// helpers
import { getDataCoverageWarnings } from "utils/helpers";
import { getDisplayInfo } from "utils/formatting";
import { InlineBoldParagraph } from "components/atoms/Typography/Typography";
import UtilityCsvUploader from "./UtilityCsvUploader";
import {
    useQueryOnStatusUpdate,
    useUpdateBuildingModel,
} from "./BuildingEnergy.hooks";
import EmptySidebarView from "./EmptySidebarView";

interface BuildingEnergyProps {
    buildingName: string;
}

function BuildingEnergy({ buildingName }: BuildingEnergyProps) {
    const { buildingModelUid } = useParams();
    if (!buildingModelUid)
        throw new Error("BuildingModelUid param needs to be defined");

    const {
        isLoading,
        error,
        data: energyData,
    } = useEnergyAggregationQuery(buildingModelUid);

    const { isLoading: dataCoverageLoading, data: buildingHeaderData } =
        useBuildingModelHeaderQuery(buildingModelUid);

    const { loadingState } = useBuildingLoadingState(buildingModelUid);

    const dataCoverageWarnings = useMemo(
        () =>
            buildingHeaderData?.dataCoverage
                ? getDataCoverageWarnings(buildingHeaderData.dataCoverage)
                : undefined,
        [buildingHeaderData]
    );

    const showEnergyWarning = useMemo(
        () => dataCoverageWarnings?.energyWarning,
        [dataCoverageWarnings]
    );

    if (isLoading || loadingState.energySidebarLoading || dataCoverageLoading)
        return <SidebarLoading />;
    if (error) return <>error</>;

    return (
        <div className="building-energy-sidebar">
            <BuildingEnergyHeader
                buildingName={buildingName}
                buildingModelUid={buildingModelUid}
                energyDataExists={
                    !!energyData?.electricity || !!energyData?.naturalGas
                }
                energyType={buildingHeaderData?.dataCoverage.energyType || null}
            />
            {!energyData?.electricity && !energyData?.naturalGas && (
                <>
                    <EmptySidebarView />
                    <div className="building-energy-sidebar__empty-content">
                        <Header size="small" style={{ lineHeight: "1.5rem" }}>
                            Import energy data to improve baseline &
                            recommendations
                        </Header>
                    </div>
                </>
            )}
            {energyData?.electricity && buildingHeaderData && (
                <>
                    <BuildingEnergyElectricitySection
                        data={energyData!.electricity}
                        buildingModelUid={buildingModelUid}
                        buildingName={buildingName}
                        energyType={buildingHeaderData?.dataCoverage.energyType}
                    />
                    {(energyData?.naturalGas || showEnergyWarning) && (
                        <BuildingEnergyNaturalGasSection
                            data={energyData?.naturalGas || null}
                            buildingModelUid={buildingModelUid}
                            buildingName={buildingName}
                            showEnergyWarning={showEnergyWarning}
                        />
                    )}
                    <BuildingEnergyFooter buildingModelUid={buildingModelUid} />
                </>
            )}
        </div>
    );
}

interface BuildingEnergyHeaderProps {
    buildingModelUid: string;
    buildingName: string;
    energyDataExists: boolean;
    energyType: EnergyTypeEnum | null;
}

function BuildingEnergyHeader({
    buildingModelUid,
    buildingName,
    energyDataExists,
    energyType,
}: BuildingEnergyHeaderProps) {
    const { onSuccess, onError } = useQueryOnStatusUpdate(buildingModelUid);

    const { mutate: deleteEnergy } = useDeleteBuildingModelUtilityData(
        buildingModelUid,
        onSuccess,
        onError
    );

    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);

    const handleDelete = () => {
        deleteEnergy();
        posthog.capture('Energy deleted');
        setLoadingState((prev) => ({
            ...prev,
            energySidebarLoading: true,
            buildingReportsLoading: true,
        }));
    };

    const modalOptions = useMemo(
        () => buildModalOptions(buildingName),
        [buildingName]
    );

    return (
        <div className="building-energy-header">
            <EnergyDropdown
                buildingModelUid={buildingModelUid}
                energyType={energyType}
            />
            <UtilityCsvUploader buildingModelUid={buildingModelUid} />
            {energyDataExists && <DeleteButton onClick={handleDelete} options={modalOptions} />}
        </div>
    );
}

const buildModalOptions = (buildingName: string) => ({
    popup: {
        buttonText: "Delete energy",
    },
    modal: {
        title: "Delete energy?",
        body: (
            <>
                This will{" "}
                <InlineBoldParagraph>
                    permanently remove all energy data associated with{" "}
                    {buildingName} building
                </InlineBoldParagraph>
                . Do you wish to proceed?
            </>
        ),
        deleteButtonText: "Delete energy",
        id: "delete-energy",
    },
});

interface BuildingEnergyFooterProps {
    buildingModelUid: string;
}

function BuildingEnergyFooter({ buildingModelUid }: BuildingEnergyFooterProps) {
    const navigate = useNavigate();

    const onClickViewDetails = () => {
        navigate(`/building/${buildingModelUid}/energy`);
    };

    return (
        <div className="building-energy-footer">
            <Button type="link" onClick={onClickViewDetails}>
                View details
            </Button>
        </div>
    );
}

interface BuildingEnergySectionProps {
    data: EnergyAggregation | null;
    buildingModelUid: string;
    buildingName: string;
    showEnergyWarning?: boolean;
}

interface BuildingEnergySectionElectricity {
    data: EnergyAggregation;
    buildingModelUid: string;
    buildingName: string;
    energyType: EnergyTypeEnum;
}

function BuildingEnergyElectricitySection({
    data,
    buildingModelUid,
    buildingName,
    energyType,
}: BuildingEnergySectionElectricity) {
    return (
        <div
            className="building-energy-section building-energy-section--electricity"
            style={
                energyType?.includes("electric")
                    ? { borderBottom: "none" }
                    : undefined
            }
        >
            <div className="building-energy-section__header">
                <div className="building-energy-section__header__title">
                    <ElectricityIcon />
                    <Header size="small" style={{ display: "inline" }}>
                        Electricity{" "}
                    </Header>
                </div>
            </div>
            {data !== null && <SectionBody data={data} />}
        </div>
    );
}

function BuildingEnergyNaturalGasSection({
    data,
    buildingModelUid,
    buildingName,
    showEnergyWarning,
}: BuildingEnergySectionProps) {
    return (
        <div className="building-energy-section">
            <div className="building-energy-section__header">
                <div className="building-energy-section__header__title">
                    <NaturalGasIcon />
                    <Header size="small" style={{ display: "inline" }}>
                        Natural gas{" "}
                    </Header>
                    {showEnergyWarning && <WarningIcon />}
                </div>
            </div>
            {data !== null && <SectionBody data={data} />}
        </div>
    );
}

interface SectionBodyProps {
    data: EnergyAggregation;
}

function SectionBody({ data }: SectionBodyProps) {
    const {
        formatFunction: formatEnergy,
        unit: energyUnit,
        humanReadable,
    } = getDisplayInfo("totalEnergyConsumption");

    const { formatFunction: formatDate } = getDisplayInfo("endDate");
    const { formatFunction: formatLastUpdated } = getDisplayInfo("lastUpdated");

    const { formatFunction: formatCost } = getDisplayInfo("utilityCost");
    const { formatFunction: formatRate } = getDisplayInfo("averageRate");

    return (
        <div className="section-body">
            <Row
                title="Last updated"
                value={formatLastUpdated(data.lastUpdated)}
            />
            <Row
                title={`${humanReadable} (all time)`}
                value={`${formatEnergy(
                    data.totalEnergyConsumption
                )} ${energyUnit}`}
            />
            <Row
                title="Total cost (all time)"
                value={`$${formatCost(data.totalUtilityCost)}`}
            />

            <Row
                title="Average rate"
                value={`$${formatRate(data.averageUtilityRate)}/kWh`}
            />
            <Row title="Start date" value={formatDate(data.startDate)} />
            <Row title="End date" value={formatDate(data.endDate)} />
            <Row title="Total entries" value={data.totalEntries.toString()} />
        </div>
    );
}

interface RowProps {
    title: string;
    value: string;
}

function Row({ title, value }: RowProps) {
    return (
        <div className="building-sidebar__row">
            <Header size="small" style={{ color: "var(--audette-gray-500)" }}>
                {title}:
            </Header>
            <Paragraph>{value}</Paragraph>
        </div>
    );
}

interface EnergyDropdownProps {
    buildingModelUid: string;
    energyType: EnergyTypeEnum | null;
}

interface EnergyDropdownItem {
    id: EnergyTypeEnum;
    displayValue: string;
    element?: React.ReactNode;
}

function EnergyDropdown({ energyType, buildingModelUid }: EnergyDropdownProps) {
    if (energyType === null) return null;

    const [energyTypeItem, setEnergyTypeItem] = useState<EnergyDropdownItem>({
        id: energyType,
        displayValue: energyType.toString(),
        element: <EnergyType energyType={energyType} />,
    });

    const { formatFunction } = getDisplayInfo("energyType");
    const energyTypeDropdownItems = useMemo(
        () =>
            ["mixed", "electric"].map((type) => ({
                id: type,
                displayValue: formatFunction(type),
                element: <EnergyType energyType={type as EnergyTypeEnum} />,
            })),
        []
    );

    const { updateBuildingModel } = useUpdateBuildingModel(buildingModelUid);
    const { setLoadingState } = useBuildingLoadingState(buildingModelUid);

    useEffect(() => {
        setEnergyTypeItem({
            id: energyType,
            displayValue: energyType.toString(),
            element: <EnergyType energyType={energyType} />,
        });
    }, [energyType]);

    const onSelectItem = (item: EnergyDropdownItem) => {
        setEnergyTypeItem(item);
        setLoadingState((prev) => ({
            ...prev,
            constructionSidebarLoading: true,
            buildingReportsLoading: true,
        }));
        updateBuildingModel({
            buildingProperties: { energyType: item.id },
        });
        posthog.capture('Energy type updated',{ energy_type:item.displayValue });
    };

    return (
        <Dropdown
            items={energyTypeDropdownItems}
            onSelectItem={onSelectItem}
            classname="energy-type-dropdown"
            defaultItem={energyTypeItem}
        />
    );
}

interface EnergyTypeProps {
    energyType: EnergyTypeEnum;
}

function EnergyType({ energyType }: EnergyTypeProps) {
    const { formatFunction } = getDisplayInfo("energyType");

    return (
        <div className="select-energy-type">
            <Header> {formatFunction(energyType)} </Header>
        </div>
    );
}

export default BuildingEnergy;
