import { useContext } from "react";
import { AlertContext } from "../context";

const useAlert = () => {
    const alert = useContext(AlertContext);
    if (alert === null) throw new Error("Alert cannot be null");
    return alert;
};

export default useAlert;
