import Checkbox from "components/atoms/Checkbox";
import { Paragraph } from "components/atoms/Typography";

interface CheckboxAndLabelProps {
    currentValue?: boolean;
    setChecked: (value: boolean) => void;
    label: string;
}

function CheckboxAndLabel({
    setChecked,
    currentValue,
    label,
}: CheckboxAndLabelProps) {
    return (
        <div>
            <div className="building-equipment-survey-view__section__radio-button">
                <Checkbox
                    checked={currentValue === true}
                    onChange={setChecked}
                />
                <Paragraph size="regular">{label}</Paragraph>
            </div>
        </div>
    );
}

export default CheckboxAndLabel;