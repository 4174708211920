import { useCallback, useMemo, useEffect } from "react";
import { atomFamily, useRecoilState } from "recoil";
import useReportsGroupByState from "./useReportsGroupByState";

export const emptyFilters: Filters = {
    booleanFilters: [],
    dataCoverageFilter: [],
    dateFilters: [],
    numericFilters: [],
    stringFilters: [],
};

const buildingModelFiltersAtomFamily = atomFamily<Filters, Page>({
    key: "buildingModelsFilters",
    default: emptyFilters,
});

const useFilters = (page: Page) => {
    const [filters, setFiltersInternal] = useRecoilState(
        buildingModelFiltersAtomFamily(page)
    );

    const { state: groupByMetric } = useReportsGroupByState();

    useEffect(() => {
        if (groupByMetric === "property") {
            setFiltersInternal((prev) => {
                const copy = { ...prev };
                copy.booleanFilters = [
                    { condition: "has", field: "property_name" },
                ];
                return copy;
            });
        } else
            setFiltersInternal((prev) => {
                const copy = { ...prev };
                copy.booleanFilters = [];
                return copy;
            });
    }, [groupByMetric]);

    const setFilters = (newFilters: Filters) => {
        const hasEnergyTypeFilter = newFilters.stringFilters.some(
            (filter) => filter.field === "energy_type"
        );
        if (hasEnergyTypeFilter) {
            const modifiedFilters = JSON.parse(JSON.stringify(newFilters));
            for (let i = 0; i < modifiedFilters.stringFilters.length; i++) {
                const currentStringFilter = modifiedFilters.stringFilters[i];
                if (currentStringFilter.field === "energy_type") {
                    if (currentStringFilter.value.includes("electric")) {
                        currentStringFilter.value.push("electric_inferred");
                    }
                    if (currentStringFilter.value.includes("mixed")) {
                        currentStringFilter.value.push("mixed_inferred");
                    }
                    break;
                }
            }
            setFiltersInternal(modifiedFilters);
        } else {
            setFiltersInternal(newFilters);
        }
    };

    const removeFilters = useCallback(() => {
        setFilters(emptyFilters);
    }, []);

    const removeFilter = (
        filterField:
            | StringFilterField
            | NumericFilterField
            | DateFilterField
            | "data_coverage"
    ) => {
        const copy = {
            booleanFilters: [...filters.booleanFilters],
            dateFilters: [...filters.dateFilters],
            dataCoverageFilter: [...filters.dataCoverageFilter],
            numericFilters: [...filters.numericFilters],
            stringFilters: [...filters.stringFilters],
        };
        if (filterField === "data_coverage") {
            copy.dataCoverageFilter = [];
        }

        const numericIndex = copy.numericFilters.findIndex(
            ({ field }) => field === filterField
        );

        if (numericIndex !== -1) {
            copy.numericFilters.splice(numericIndex, 1);
        }

        const stringIndex = copy.stringFilters.findIndex(
            ({ field }) => field === filterField
        );

        if (stringIndex !== -1) {
            copy.stringFilters.splice(stringIndex, 1);
        }

        const dateIndex = copy.dateFilters.findIndex(
            ({ field }) => field === filterField
        );

        if (dateIndex !== -1) {
            copy.dateFilters.splice(dateIndex, 1);
        }

        setFilters(copy);
    };

    const numberOfFilters = useMemo(() => {
        const {
            dataCoverageFilter,
            numericFilters,
            stringFilters,
            dateFilters,
        } = filters;
        return (
            dataCoverageFilter.length +
            numericFilters.length +
            stringFilters.length +
            dateFilters.length
        );
    }, [filters]);

    return {
        filters,
        setFilters,
        removeFilters,
        removeFilter,
        numberOfFilters,
    };
};

export default useFilters;
