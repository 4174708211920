/* eslint-disable no-restricted-syntax */
import { useRef, useEffect, useState, useMemo } from "react";
import { createRoot } from "react-dom/client";
import mapboxgl, {
    Marker,
    LngLatLike,
    Map as MapboxglMap,
    GeoJSONSource,
} from "mapbox-gl";

// css
import "./EmissionIntensityMap.css";
import "mapbox-gl/dist/mapbox-gl.css";

// constants
import { MAPBOX_TOKEN } from "utils/constants";
import MUITheme from "components/MUITheme";

// hooks
import { useNavigate, NavigateFunction } from "react-router-dom";
import useReportsGroupByState from "recoilStore/useReportsGroupByState";

// helpers
import { getDisplayInfo } from "utils/formatting";

// components
import { Header, Paragraph } from "components/atoms/Typography";
import { ChartErrorState } from "components/molecules/ErrorStates";
import { OpenInNew } from "components/atoms/Icon";
import { Tooltip } from "@mui/material";

import useBuildingBarChartAndMapByGroup from "./hooks/useBuildingBarChartAndMapByGroup";
import BlankMap from "./BlankMap.png";

mapboxgl.accessToken = MAPBOX_TOKEN;

interface EmissionIntensityMapProps {
    includeHeader?: boolean;
}

function EmissionIntensityMap({
    includeHeader = true,
}: EmissionIntensityMapProps) {
    const { state: groupByMetric } = useReportsGroupByState();

    const {
        data: buildingModels,
        isLoading,
        error,
    } = useBuildingBarChartAndMapByGroup(groupByMetric);

    if (isLoading || !buildingModels) return <MapLoading />;
    if (error) return <ChartErrorState />;

    return (
        <div className="emission-intensity-map">
            {includeHeader && (
                <div className="title">
                    <Header size="small">Emission intensity</Header>
                </div>
            )}
            <Map buildingModels={buildingModels} />
        </div>
    );
}

interface MapProps {
    buildingModels: BarChartAndMapData[];
}

export function Map({ buildingModels }: MapProps) {
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const map = useRef<MapboxglMap | null>(null);
    const [mapHasLoaded, setMapHasLoaded] = useState(false);
    const navigate = useNavigate();

    const { minEmissionIntensity, maxEmissionIntensity } = useMemo(() => {
        const intensities = buildingModels.map(
            ({ annualCarbonEmissionIntensityCurrent }) =>
                annualCarbonEmissionIntensityCurrent
        );
        const minEmissionIntensity = Math.min(...intensities);
        const maxEmissionIntensity = Math.max(...intensities);
        return { minEmissionIntensity, maxEmissionIntensity };
    }, [buildingModels]);

    useEffect(() => {
        if (!map.current || !buildingModels) return;
        (map.current.getSource("buildings") as GeoJSONSource)?.setData(
            createGeoJsonData(buildingModels)
        );
    }, [buildingModels]);

    useMapLayers(
        buildingModels,
        map,
        mapContainer,
        setMapHasLoaded,
        minEmissionIntensity,
        maxEmissionIntensity,
        navigate
    );

    return (
        <div ref={mapContainer} className="map-container">
            {!mapHasLoaded && <MapLoading />}
            {mapHasLoaded && (
                <MapLegend
                    scaleMin={minEmissionIntensity}
                    scaleMax={maxEmissionIntensity}
                />
            )}
        </div>
    );
}

const useMapLayers = (
    buildingModels: BarChartAndMapData[],
    map: React.MutableRefObject<mapboxgl.Map | null>,
    mapContainer: React.MutableRefObject<HTMLDivElement | null>,
    setMapHasLoaded: (loaded: boolean) => void,
    minEmissionIntensity: number,
    maxEmissionIntensity: number,
    navigate: NavigateFunction
) => {
    useEffect(() => {
        if (!mapContainer.current) return;

        // eslint-disable-next-line no-param-reassign
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/light-v11",
            center: [-99, 47],
            zoom: 4,
            projection: {
                name: "mercator",
            },
            minZoom: 2,
            boxZoom: false,
        });

        const bounds = createBoundingBox(buildingModels);
        if (bounds !== null) {
            map.current.fitBounds(bounds);
        }

        map.current.on("load", () => {
            setMapHasLoaded(true);
            const buildingFeatures = createGeoJsonData(buildingModels);

            if (!map.current?.getSource("buildings"))
                map.current!.addSource("buildings", {
                    type: "geojson",
                    data: buildingFeatures,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                    clusterProperties: {
                        clusterTotal: ["+", ["get", "colorValue"]],
                    },
                });

            buildingFeatures.features.forEach((feature) => {
                const coords = feature.geometry.coordinates as LngLatLike;
                const building = feature.properties
                    ?.building as BarChartAndMapData;

                const popupNode = document.createElement("div");
                popupNode.className = "popup-node";
                createRoot(popupNode).render(
                    <MapPopup buildingModel={building} navigate={navigate} />
                );
                const marker = new Marker(popupNode).setLngLat(coords);
                marker.addTo(map.current!);
            });

            if (!map.current!.getLayer("cluster-circle"))
                map.current!.addLayer({
                    id: "cluster-circle",
                    type: "circle",
                    source: "buildings",
                    filter: ["has", "point_count"],
                    paint: {
                        "circle-color": [
                            "interpolate",
                            ["linear"],
                            [
                                "/",
                                ["number", ["get", "clusterTotal"]],
                                ["number", ["get", "point_count"]],
                            ],
                            minEmissionIntensity,
                            "#00BC98",
                            (minEmissionIntensity + maxEmissionIntensity) / 2,
                            "#F7931E",
                            maxEmissionIntensity,
                            "#CC303C",
                        ],
                        "circle-radius": ["number", 14],
                        "circle-opacity": 1,
                        "circle-stroke-width": 0,
                    },
                });

            if (!map.current!.getLayer("cluster-number"))
                map.current!.addLayer({
                    id: "cluster-number",
                    type: "symbol",
                    source: "buildings",
                    filter: ["has", "point_count"],
                    layout: {
                        "text-field": ["get", "point_count"],
                        "text-font": [
                            "DIN Offc Pro Medium",
                            "Arial Unicode MS Bold",
                        ],
                        "text-size": 16,
                        "text-allow-overlap": true,
                    },
                    paint: {
                        "text-color": "#FFF",
                    },
                });

            if (!map.current!.getLayer("building-points"))
                map.current!.addLayer({
                    id: "building-points",
                    type: "circle",
                    source: "buildings",
                    filter: ["!", ["has", "point_count"]],
                    paint: {
                        "circle-color": [
                            "interpolate",
                            ["linear"],
                            ["get", "colorValue"],
                            minEmissionIntensity,
                            "#00BC98",
                            (minEmissionIntensity + maxEmissionIntensity) / 2,
                            "#F7931E",
                            maxEmissionIntensity,
                            "#CC303C",
                        ],
                        "circle-radius": 10,
                        "circle-opacity": 1,
                        "circle-stroke-width": 0,
                    },
                });
        });
        setMapHasLoaded(true);
    }, [buildingModels]);
};

const createBoundingBox = (buildings: BarChartAndMapData[]) => {
    if (buildings.length === 0) return null;

    let north = buildings[0].latitude;
    let south = buildings[0].latitude;
    let west = buildings[0].longitude;
    let east = buildings[0].longitude;

    for (let i = 1; i < buildings.length; i++) {
        north = Math.max(north, buildings[i].latitude);
        south = Math.min(south, buildings[i].latitude);
        west = Math.min(west, buildings[i].longitude);
        east = Math.max(east, buildings[i].longitude);
    }

    return new mapboxgl.LngLatBounds([west, south, east, north]);
};

const createGeoJsonData = (
    buildings: BarChartAndMapData[]
): GeoJSON.FeatureCollection<any> => ({
    type: "FeatureCollection",
    features: buildings
        // filter out buildings that haven't been modelled
        .filter(
            (building) =>
                building.annualCarbonEmissionIntensityCurrent !== null &&
                building.annualCarbonEmissionIntensityCurrent !== undefined
        )
        .map((building) => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [building.longitude, building.latitude],
            },
            properties: {
                building,
                colorValue: building.annualCarbonEmissionIntensityCurrent,
            },
        })),
});

interface MapPopupProps {
    buildingModel: BarChartAndMapData;
    navigate: NavigateFunction;
}

function MapPopup({ buildingModel, navigate }: MapPopupProps) {
    return (
        <MUITheme>
            <Tooltip
                title={
                    <MapTooltip
                        buildingModel={buildingModel}
                        navigate={navigate}
                    />
                }
                placement="top"
                style={{ padding: 0, margin: 0 }}
                leaveDelay={60}
            >
                <div className="popup-node" />
            </Tooltip>
        </MUITheme>
    );
}

interface MapTooltipProps {
    buildingModel: BarChartAndMapData;
    navigate: NavigateFunction;
}

function MapTooltip({ buildingModel, navigate }: MapTooltipProps) {
    const {
        annualCarbonEmissionIntensityCurrent: intensity,
        buildingModelUid,
        label,
    } = buildingModel;

    const { formatFunction, unit } = getDisplayInfo(
        "annualCarbonEmissionIntensityCurrent"
    );

    const goToBuildingPage = () => {
        const e = document.getElementById("map-popup");
        e?.remove();
        navigate(`/building/${buildingModelUid}`);
    };
    return (
        <div id="map-popup" className="map-popup">
            {buildingModelUid ? (
                <button
                    className="map-popup--title"
                    type="button"
                    onClick={goToBuildingPage}
                >
                    <Header size="small">{label}</Header>
                    <OpenInNew fontSize="1.5em" />
                </button>
            ) : (
                <div className="map-popup--title">
                    <Header size="small">{label}</Header>
                </div>
            )}
            <div className="map-popup--body">
                <Paragraph size="small">Emission intensity: </Paragraph>
                <Paragraph
                    size="small"
                    style={{ color: "var(--audette-gray-500)" }}
                >
                    {formatFunction(intensity)} {unit}
                </Paragraph>
            </div>
        </div>
    );
}

export function MapLoading() {
    return (
        <div className="emission-intensity-map map-loading">
            <img
                src={BlankMap}
                alt="Map loading..."
                height="100%"
                width="100%"
            />
            <div className="map-legend-loading">
                <div className="map-legend-loading--text__title loading" />
                <div className="map-legend-loading--text__body loading" />
            </div>
        </div>
    );
}

function MapLegend({
    scaleMin,
    scaleMax,
}: {
    scaleMin: number;
    scaleMax: number;
}) {
    const { unit, formatFunction } = getDisplayInfo(
        "annualCarbonEmissionIntensityCurrent"
    );

    return (
        <div className="map-legend">
            <Paragraph size="small">{unit}</Paragraph>
            <div className="map-legend--scale" />
            <div className="map-legend--values">
                <Paragraph size="small">{formatFunction(scaleMin)}</Paragraph>
                <Paragraph size="small">
                    {formatFunction((scaleMin + scaleMax) / 2)}
                </Paragraph>
                <Paragraph size="small">{formatFunction(scaleMax)}</Paragraph>
            </div>
        </div>
    );
}

export default EmissionIntensityMap;
