import mutationAddUsersFn from "ApiLayer/User/queryAddUsers";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { userQueryKey } from "hooks/useUser";
import { useMutation, useQueryClient } from "react-query";
import { showMutationAlert } from "utils/helpers";

export const useAddUsers = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: (data, { userIsReinvited }) => {
            queryClient.invalidateQueries({ queryKey: userQueryKey });

            if (userIsReinvited) {
                alert.success("User(s) reinvited.");
            } else {
                alert.success("User(s) added.");
            }

        },
        mutationFn: async (data: { emails: string[], userIsReinvited?:boolean }) => (
            mutationAddUsersFn(accessToken, data)
        )
    })
}