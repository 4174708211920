import Dropdown from "components/atoms/Dropdown";
import { Header } from "components/atoms/Typography";
import { useMemo } from "react";
import { equipmentKeyToLabelMap } from "../Equipment/BuildingEquipment.constants";
import { SurveyUIStructSelect } from "../../Survey.helpers";

interface SurveySelectProps<T> {
    selectStruct: SurveyUIStructSelect<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveySelect<T>({
    selectStruct,
    surveyPageData,
    setSurveyPageData,
}: SurveySelectProps<T>) {
    // console.log(selectStruct)
    // console.log(surveyPageData)
    // console.log(surveyPageData)
    const { label, key, options } = selectStruct;
    
    const handleSelectItem = ({ id }: { displayValue: string; id: string }) => {
        setSurveyPageData((prev) => ({
            ...prev,
            [key]: id,
        }));
    };
    
    const items = useMemo(
        () =>
            options?.map((id) => ({
                id,
                displayValue: equipmentKeyToLabelMap[id] || id,
            })) || [],
            [options]
        );

    const defaultItem = useMemo(
        () =>
            surveyPageData[key] !== null
                ? {
                      id: surveyPageData[key],
                      displayValue: equipmentKeyToLabelMap[surveyPageData[key]],
                  }
                : undefined,
        [surveyPageData]
    );

    return (
        <>
            <Header size="small">{label}</Header>
            <Dropdown
                items={items}
                selectorDataTestId={`test-survey-dropdown-${label}`}
                onSelectItem={handleSelectItem}
                defaultItem={defaultItem}
            />
        </>
    );
}

export default SurveySelect;