import classNames from "classnames";
import {
    WarningIcon,
    EquipmentIcon,
    EnergyIcon,
    ErrorIcon,
} from "components/atoms/Icon";
import "./DataCoverage.scss";
import Tooltip from "components/atoms/Tooltip";
import { Paragraph } from "components/atoms/Typography";

interface DataCoverageProps {
    dataCoverage: DataCoverage | DataCoverageEnum;
}

function DataCoverage({ dataCoverage }: DataCoverageProps) {
    const showTooltip = typeof dataCoverage === "object";
    const identifier = showTooltip ? dataCoverage.dataCoverage : dataCoverage;

    if (showTooltip)
        return (
            <Tooltip
                tooltipBody={
                    <DataCoverageTooltip
                        identifier={identifier}
                        dataCoverage={dataCoverage}
                    />
                }
            >
                <DataCoverageIcon identifier={identifier} />
            </Tooltip>
        );
    return <DataCoverageIcon identifier={identifier} />;
}

interface DataCoverageTooltipProps extends DataCoverageIconProps {
    dataCoverage: DataCoverage;
}

function DataCoverageTooltip({
    identifier,
    dataCoverage,
}: DataCoverageTooltipProps) {
    const map = buildDataCoverageKeyToTooltipTextMap(dataCoverage);
    const tooltipBodyContent = map[identifier];

    return (
        <Paragraph size="small" style={{ lineHeight: "25px" }}>
            <div style={{ display: "inline-block", marginRight: "8px" }}>
                <DataCoverageIcon identifier={identifier} />
            </div>
            <span data-testid="data-coverage-tooltip--text">
                {typeof tooltipBodyContent === "string" ||
                tooltipBodyContent.length === 1
                    ? tooltipBodyContent
                    : tooltipBodyContent.map((text, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={index}>{`\u2022 ${text}`}</div>
                      ))}
            </span>
        </Paragraph>
    );
}

interface DataCoverageIconProps {
    identifier: DataCoverageEnum;
}

export function DataCoverageIcon({ identifier }: DataCoverageIconProps) {
    return (
        <div data-testid="data-coverage">
            {identifier === "utility" && <Energy warning={false} />}
            {identifier === "utility_warning" && <Energy warning={true} />}
            {identifier === "equipment" && <Equipment warning={false} />}
            {identifier === "equipment_warning" && <Equipment warning={true} />}
            {identifier === "equipment_and_utility" && (
                <EnergyAndEquipment warning={false} />
            )}
            {identifier === "equipment_and_utility_warning" && (
                <EnergyAndEquipment warning={true} />
            )}
            {identifier === "no_equipment_and_no_utility" && (
                <NoEnergyAndEquipment />
            )}
            {identifier === "error" && <Error />}
            {identifier === "processing" && <Processing />}
        </div>
    );
}

interface IconsProps {
    warning?: boolean;
}

function Processing() {
    return (
        <div className="status-tag status-tag--grey">
            <ProcessingIcon />
        </div>
    );
}

function Energy({ warning = false }: IconsProps) {
    return (
        <div
            className={classNames(
                "status-tag",
                warning ? "status-tag--yellow" : "status-tag--purple"
            )}
        >
            <EnergyIcon size="medium" />
            {warning && <WarningIcon />}
        </div>
    );
}

function NoEnergyAndEquipment() {
    return (
        <div className={classNames("status-tag", "status-tag--grey")}>
            <EnergyIcon size="medium" />
            <EquipmentIcon size="medium" />
        </div>
    );
}

function EnergyAndEquipment({ warning = false }: IconsProps) {
    return (
        <div
            className={classNames(
                "status-tag",
                warning ? "status-tag--yellow" : "status-tag--green"
            )}
        >
            <EnergyIcon size="medium" />
            <EquipmentIcon size="medium" />
            {warning && <WarningIcon />}
        </div>
    );
}

function Equipment({ warning = false }: IconsProps) {
    return (
        <div
            className={classNames(
                "status-tag",
                warning ? "status-tag--yellow" : "status-tag--purple"
            )}
        >
            <EquipmentIcon size="medium" />
            {warning && <WarningIcon />}
        </div>
    );
}

function Error() {
    return (
        <div className="status-tag status-tag--negative">
            <ErrorIcon />
        </div>
    );
}

function ProcessingIcon() {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.71457 0.794922C13.5043 1.17149 16.5248 4.17599 16.8853 7.9817C17.326 12.3883 14.1212 16.2982 9.71457 16.7388V15.1364C12.6309 14.7919 14.9224 12.4764 15.2909 9.56007C15.7236 6.04279 13.2318 2.846 9.71457 2.41335V0.810946V0.794922ZM8.11216 0.818958V2.42136C6.96644 2.5816 5.88482 3.0463 4.98748 3.76738L3.84176 2.5816C5.05958 1.57209 6.54982 0.971186 8.11216 0.818958ZM2.71206 3.7113L3.85778 4.85701C3.14471 5.76237 2.69604 6.844 2.54381 7.9817H0.941406C1.10165 6.41135 1.70255 4.92912 2.71206 3.7113ZM0.949418 9.58411H2.55182C2.69604 10.7218 3.15272 11.8034 3.85778 12.7088L2.72007 13.8545C1.72658 12.6367 1.10966 11.1545 0.949418 9.58411ZM4.98748 13.8866C5.89283 14.5916 6.97446 15.0483 8.11216 15.1925V16.7949C6.54181 16.6267 5.05157 15.9937 3.84176 14.9842L4.98748 13.8866ZM8.91336 12.3883L5.30796 8.7829H8.11216V5.5781H9.71457V8.7829H12.5188L8.91336 12.3883Z"
                fill="#757575"
            />
        </svg>
    );
}

const buildDataCoverageKeyToTooltipTextMap = (dataCoverage: DataCoverage) => ({
    processing: "Processing...",
    error: "Couldn't create energy and emissions profile. Try editing the building. If the problem persists, contact Audette.",
    utility: "Energy data added. Next, add equipment information.",
    utility_warning: "Energy data must match the building energy type.",
    equipment: "Equipment information added. Next, add energy data.",
    equipment_warning: getEquipmentWarnings(dataCoverage),
    equipment_and_utility: "Energy and equipment data added.",
    equipment_and_utility_warning: getEquipmentAndUtilityWarnings(dataCoverage),
    no_equipment_and_no_utility:
        "Add energy and equipment data to the building profile.",
});

const getEquipmentWarnings = (dataCoverage: DataCoverage) => {
    const warnings = [];
    if (dataCoverage.dataCoverage === "equipment_warning") {
        if (
            dataCoverage.energyType.replace("_inferred", "") !==
            dataCoverage.energyTypeFromEquipmentData
        )
            warnings.push(
                "Equipment data must match the building energy type."
            );

        if (!dataCoverage.equipmentDataHasSizeAndYear)
            warnings.push(
                "Equipment size and installation years must be defined."
            );
    }
    return warnings;
};

const getEquipmentAndUtilityWarnings = (dataCoverage: DataCoverage) => {
    const warnings = [];
    if (dataCoverage.dataCoverage === "equipment_and_utility_warning") {
        if (
            dataCoverage.energyType.replace("_inferred", "") !==
            dataCoverage.energyTypeFromEquipmentData
        )
            warnings.push(
                "Equipment data must match the building energy type."
            );

        if (
            dataCoverage.energyType.replace("_inferred", "") !==
            dataCoverage.energyTypeFromUtilityData
        )
            warnings.push("Energy data must match the building energy type.");

        if (!dataCoverage.equipmentDataHasSizeAndYear)
            warnings.push(
                "Equipment size and installation years must be defined."
            );
    }
    return warnings;
};

export default DataCoverage;
