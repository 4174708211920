import { request } from "graphql-request";

// csv uploader
import DromoUploader, { IDeveloperField } from "dromo-uploader-react";

// components
import { Button } from "components/atoms/Button";
import { ImportIcon } from "components/atoms/Icon";

// hooks
import { useUserIdentity } from "hooks/useUserIdentity";
import posthog from "posthog-js";

// constants
import { VALIDATION_SERVICE_DOMAIN } from "utils/constants";

interface DromoCsvUploaderProps {
    onResults: (results: any) => void;
    importIdentifier: string;
    validatorWorkflowStep: string;
    fieldValues: IDeveloperField[];
    importButtonStyle?: "primary" | "secondary";
}

function DromoCsvUploader({
    onResults,
    importIdentifier,
    validatorWorkflowStep,
    fieldValues,
    importButtonStyle = "primary",
}: DromoCsvUploaderProps) {
    const { user } = useUserIdentity();

    const { name, email } = user;
    if (!email) throw new Error("user email should be defined!");

    const userInfo = {
        id: email,
        name: name || email,
        email,
        companyId: email,
        companyName: email,
    };

    const secret = process.env.REACT_APP_DROMO_SECRET;
    if (!secret)
        throw new Error(
            "Please define REACT_APP_DROMO_SECRET in your .env file. You can find the secret at https://dashboard.dromo.io/"
        );

    const isDevelopmentMode =
        process.env.REACT_APP_DEPLOY_ENV === "dev" ||
        process.env.REACT_APP_DEPLOY_ENV === "local";
    const settings = {
        importIdentifier,
        ...styles,
        developmentMode: isDevelopmentMode,
        templateDownloadFilename: importIdentifier,
    };

    const validateRow = async (record: any) => {
        const newRecord = { ...record };

        const requestData = Object.entries(record.row)
            .filter(([key, { value }]: any) => key !== "rowId" && value !== "")
            .map(([key, { resultValue }]: any) => ({
                key,
                value:
                    key === "year_built_original"
                        ? Number(resultValue) // workaround for a bug in Dromo
                        : resultValue,
            }));

        return request({
            url: `${VALIDATION_SERVICE_DOMAIN}/graphql`,
            document: query,
            variables: {
                workflowStep: validatorWorkflowStep,
                data: requestData,
                level: "error",
            },
            requestHeaders: { "Content-Type": "application/json" },
        }).then((data: any) => {
            data?.validateData?.resultsByField?.forEach((obj: any) => {
                if (obj.allRulesPassed) {
                    newRecord.row[obj.key].info = [];
                } else {
                    newRecord.row[obj.key].info.push({
                        message: errorMessages[obj.key],
                        level: "error",
                    });
                }
            });
            return newRecord;
        });
    };

    return (
        <DromoUploader
            licenseKey={secret}
            fields={fieldValues}
            settings={settings}
            user={userInfo}
            onResults={onResults}
            rowHooks={[validateRow]}
        >
            <ImportButton type={importButtonStyle} />
        </DromoUploader>
    );
}

interface ImportButtonProps {
    type: "primary" | "secondary";
}

function ImportButton({ type }: ImportButtonProps) {
    return (
        <Button type={type} icon={<ImportIcon />} onClick={()=>{posthog.capture('Import-buildings started');}}>
            Import
        </Button>
    );
}

const styles = {
    styleOverrides: {
        global: {
            textColor: "#000000",
            backgroundColor: "#F6F6F6",
            customFontURL: "https://fonts.googleapis.com/css?family=DM Sans",
            customFontFamily: "'DM Sans', sans-serif",
        },
        primaryButton: {
            borderRadius: "0.25rem",
            backgroundColor: "#7700FF",
            textColor: "#ffffff",
            border: "1px solid #7700FF",
            hoverBackgroundColor: "#7700FF",
            hoverTextColor: "#ffffff",
            hoverBorder: "1px solid #7700FF",
        },
        secondaryButton: {
            borderRadius: "0.25rem",
            backgroundColor: "#ffffff",
            textColor: "#7700FF",
            border: "1px solid #7700FF",
        },
        helpText: {
            textColor: "#545454",
        },
        stepperBar: {
            currentColor: "#7700FF",
            incompleteColor: "#545454",
        },
    },
};

const query = `
query validateData(
    $workflowStep: String!, $data: [InputKeyValue]!, $level: RuleLevel
) {
    validateData (
        workflowStep: $workflowStep, data: $data, level: $level
    ) {
        allRulesPassed
        resultsByField {
            key
            allRulesPassed
        }
    }
}`;

const errorMessages: { [key: string]: string } = {
    account_number: "Must be a number",
    building_height: "Must be between 2.5 and 800",
    city: "Cannot contain numbers or be empty",
    country: "Cannot contain numbers or be empty",
    currency: "Cannot be empty",
    end_date: "Enter a valid date in the following format DD/MM/YYYY",
    energy_consumption: "Must be a number",
    energy_consumption_unit: "Cannot be empty",
    gross_floor_area: "Must be a number",
    latitude: "Must be a number",
    longitude: "Must be a number",
    postal_code:
        "Must follow A1B 2C3 format for Canada and 12345 or 12345-1234 for the United States",
    start_date: "Enter a valid date in the following format DD/MM/YYYY",
    state_province: "Cannot contain numbers or be empty",
    street_address: "Must start with a number, cannot contain &, /, * or ^",
    utility_cost: "Must be a number",
    utility_type: "Cannot be empty",
    utility_provider: "Must be a valid name",
    number_of_floors: "Must be a number",
    year_built_original: "Cannot be empty",
};

export default DromoCsvUploader;
