import { useMutation, useQueryClient } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import mutationFn from "../ApiLayer/Properties/setBuildingModelProperty";

export const useSetBuildingModelProperty = (buildingModelUids: string[]) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();

    return useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["properties"],
            });

            buildingModelUids.forEach((buildingModelUid) => {
                queryClient.invalidateQueries({
                    queryKey: ["buildingModelHeader", buildingModelUid],
                });
            });

            queryClient.invalidateQueries({
                queryKey: ["buildingModelsTable"],
            });
        },
        mutationFn: async (propertyName: string | null) =>
            mutationFn(accessToken, buildingModelUids, propertyName),
    });
};
