import ChartContainer from "components/molecules/ChartContainer";
import AreaChart, {
    AreaChartDatum,
} from "components/molecules/Charts/AreaChart";
import { useMemo } from "react";
import { CURRENT_YEAR, END_YEAR } from "utils/constants";
import { getDisplayInfo } from "utils/formatting";
import { ChartErrorState } from "components/molecules/ErrorStates";
import { ChartNoDataState } from "components/molecules/NoDataState";

interface AnnualCarbonEmissionsChartProps {
    data: { x: number; areaY: number }[] | undefined;
    loading?: boolean;
    error?: string;
}

function AnnualCarbonEmissionsChart({
    data = [],
    loading = false,
    error,
}: AnnualCarbonEmissionsChartProps) {
    const dataWithCalculatedGoalLine = useMemo((): AreaChartDatum[] => {
        if (data.length === 0) return [];

        // calculate goal line (from current year emissions to zero emissions in 2050)
        const y1 = 0;
        const y0 = data.find(({ x }) => x === CURRENT_YEAR)?.areaY || 0;
        const m = (y1 - y0) / (END_YEAR - CURRENT_YEAR);
        const c = 0 - m * 2050;

        const areaData: { x: number; y: number }[] = [];
        const lineData: { x: number; y: number }[] = [];

        data.forEach(({ x, areaY }) => {
            areaData.push({
                x,
                y: areaY,
            });
            lineData.push({
                x,
                y: m * x + c,
            });
        });

        return [
            {
                type: "area",
                id: "Baseline",
                color: "var(--audette-chart-baseline)",
                data: areaData,
                formatKey: "annualCarbonEmissionsCurrent",
            },
            {
                type: "line",
                id: "Goal",
                color: "var(--audette-chart-goal)",
                data: lineData,
                formatKey: "annualCarbonEmissionsCurrent",
            },
        ];
    }, [data]);

    const { unit, formatFunction, humanReadable } = useMemo(
        () =>
            getDisplayInfo(
                "annualCarbonEmissionsCurrent",
                dataWithCalculatedGoalLine.length > 0
                    ? dataWithCalculatedGoalLine[1].data.map(({ y }) => y)
                    : 0
            ),
        [dataWithCalculatedGoalLine]
    );

    if (error) return <ChartErrorState />;
    if (data.length === 0 && !loading) return <ChartNoDataState />;

    return (
        <ChartContainer title={humanReadable} loading={loading}>
            <AreaChart
                data={dataWithCalculatedGoalLine}
                yAxisInfo={{
                    formatFunction: (v: string | number) =>
                        formatFunction(v, false),
                    unit,
                }}
            />
        </ChartContainer>
    );
}

export default AnnualCarbonEmissionsChart;
