import { useAccessToken } from "hooks/useAccessToken";
import mutationFn from "ApiLayer/queryExportBuildings";
import useDownloadState from "recoilStore/useDownloadState";
import { useRef } from "react";
import { getExportBuildingsFileName } from "components/molecules/DownloadBanner/DownloadBanner.helpers";

export const useExportBuildings = () => {
    const { setState } = useDownloadState();

    const controller = useRef<AbortController>();
    const accessToken = useAccessToken();
    const retries = useRef(0);

    const reset = () => {
        retries.current = 0;
    };

    const mutate = () => {
        controller.current = new AbortController();
        setState((prev) => ({
            ...prev,
            controller: controller.current,
        }));

        mutationFn(accessToken, controller.current?.signal)
            .then((data) => {
                setState((prev) => ({ ...prev, downloadComplete: true }));
                const fileName = getExportBuildingsFileName();

                const url = window.URL.createObjectURL(new Blob([data]));
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                if (retries.current < 3) {
                    if (!controller.current?.signal.aborted) {
                        retries.current += 1;
                        mutate();
                    }
                } else {
                    setState((prev) => ({ ...prev, downloadError: true }));
                    retries.current = 0;
                }
            });
    };

    return { mutate, reset };
};
