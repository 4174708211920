import { Delete, Menu, ResetIcon } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import { useClickOutside } from "hooks/useClickOutside";
import { ReactNode, useRef, useState } from "react";
import "./DangerousButtons.scss";
import DangerousModal from "components/molecules/DangerousModal";
import classNames from "classnames";

interface ConvenientDangerousButtonOptions {
    popup: {
        buttonText?: string;
        buttonColor?: string;
    };
    modal: {
        title?: string;
        body?: string | JSX.Element;
        deleteButtonText?: string;
        modalId?: string;
    };
}

interface ConvenientDangerousButtonProps {
    onClick: () => void;
    options?: ConvenientDangerousButtonOptions;
}

export function DeleteButton({ onClick, options }: ConvenientDangerousButtonProps) {
    return <DangerousButton
        onClick={onClick}
        options={options}
        styleOptions={{
            popupIcon: <Delete />,
            type: "primary-negative"
        }}
    />;
}

export function ResetButton({ onClick, options }: ConvenientDangerousButtonProps) {
    return <DangerousButton
        onClick={onClick}
        options={options}
        styleOptions={{
            popupIcon: <ResetIcon />,
            type: "primary"
        }}
    />;
}

interface StyleOptions {
    popupIcon: ReactNode;
    type: "primary" | "primary-negative";
}

interface DangerousButtonProps extends ConvenientDangerousButtonProps {
    styleOptions: StyleOptions
}

function DangerousButton({ onClick, options, styleOptions }: DangerousButtonProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="dangerous-button">
            <button
                style={{ padding: "12px" }}
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                }}
                data-testid="dangerous-button"
            >
                <Menu />
            </button>
            {isOpen && (
                <DangerousPopup
                    onCancel={() => setIsOpen(false)}
                    onClick={onClick}
                    options={options}
                    styleOptions={styleOptions}
                />
            )}
        </div>
    );
}

interface DangerousPopupProps {
    onCancel: () => void;
    onClick: () => void;
    options?: ConvenientDangerousButtonOptions;
    styleOptions: StyleOptions
}

function DangerousPopup({ onCancel, onClick, options, styleOptions }: DangerousPopupProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const deleteButtonText = options?.popup.buttonText || "Delete";

    useClickOutside(ref, onCancel);

    const handleDelete = () => {
        onClick();
    };

    return (
        <div className={classNames("popup", `popup--${styleOptions.type}`)} ref={ref}>
            <button
                type="button"
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
                onClick={() => setShowDeleteConfirmation(true)}
                data-testid="dangerous-popup"
            >
                {styleOptions.popupIcon}
                <Paragraph>
                    {deleteButtonText}
                </Paragraph>
            </button>
            <DangerousModal
                open={showDeleteConfirmation}
                onCancel={() => setShowDeleteConfirmation(false)}
                options={options?.modal}
                onDelete={handleDelete}
                styleType={styleOptions.type}
            />
        </div>
    );
}
