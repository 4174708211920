import { useMemo } from "react";
import { UseQueryResult } from "react-query";
import { getDisplayInfo } from "utils/formatting";
import ChartContainer from "components/molecules/ChartContainer";
import { ChartErrorState } from "components/molecules/ErrorStates";
import ChartTooltip from "components/molecules/Charts/ChartTooltip";
import { ResponsiveLine } from "@nivo/line";
import { theme } from "components/molecules/Charts/ChartTheme";
import { ChartNoDataState } from "components/molecules/NoDataState";

interface CumulativeInvestmentByDecarbonizationChartProps {
    query: () => UseQueryResult<CumulativeInvestmentByDecarbonizationChartData | null>;
}

function CumulativeInvestmentByDecarbonizationChart({
    query,
}: CumulativeInvestmentByDecarbonizationChartProps) {
    const { data, isLoading, error } = query();

    const { formatFunction: formatFunctionY, unit: unitY } = useMemo(
        () =>
            getDisplayInfo(
                "totalMeasureCost",
                data?.map((d) => d.totalMeasureCost) || []
            ),
        [data]
    );

    const { formatFunction: formatFunctionX } = getDisplayInfo(
        "decarbonizationPercentage"
    );

    const barData = useMemo(() => {
        if (!data) return [];

        const newData = data.map(
            ({ totalMeasureCost, decarbonizationPercentage }) => ({
                x: decarbonizationPercentage,
                y: totalMeasureCost,
            })
        );

        return [
            {
                id: "id",
                data: newData,
            },
        ];
    }, [data]);

    if (error) return <ChartErrorState />;
    if (data === null) return <ChartNoDataState />;

    return (
        <ChartContainer
            title="Cumulative investments vs decarbonization"
            loading={isLoading}
        >
            <div style={{ height: "450px", width: "100%" }}>
                <ResponsiveLine
                    theme={theme}
                    data={barData}
                    margin={{ top: 50, right: 110, bottom: 50, left: 100 }}
                    xScale={{ type: "linear" }}
                    curve="monotoneX"
                    layers={["grid", "axes", "mesh", DashedLine]}
                    yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: false,
                        reverse: false,
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tooltip={(data) => (
                        <ChartTooltip
                            title={`${data.point.data.xFormatted}%`}
                            rows={[
                                {
                                    label: "Cumulative investments",
                                    unit: "",
                                    value: data.point.data.yFormatted,
                                },
                            ]}
                        />
                    )}
                    enableGridX={false}
                    lineWidth={2}
                    yFormat={(v) => formatFunctionY(v, false)}
                    xFormat={(v) => formatFunctionX(v)}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickValues: 5,
                        tickRotation: 0,
                        legendOffset: 36,
                        legendPosition: "middle",
                        format: (v) => `${v}%`,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -75,
                        legendPosition: "middle",
                        legend: unitY,
                        format: (v) => formatFunctionY(v, false),
                    }}
                    pointSize={0}
                    useMesh={true}
                />
            </div>
        </ChartContainer>
    );
}

const DashedLine = ({ series, lineGenerator, xScale, yScale }: any) =>
    series.map(({ id, data }: any, index: number) => (
        <path
            key={id}
            d={lineGenerator(
                data.map((d: any) => ({
                    x: xScale(d.data.x),
                    y: yScale(d.data.y),
                }))
            )}
            fill="none"
            stroke="#00BC98"
            style={{
                strokeDasharray: "10, 10",
                strokeWidth: 2,
            }}
        />
    ));

export default CumulativeInvestmentByDecarbonizationChart;
