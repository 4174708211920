import { request } from "graphql-request";
import { graphql } from "gql/gql";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryFn = (accessToken: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => data.properties as Property[]);

const query = graphql(`
    query properties {
        properties {
            annualCarbonEmissionIntensityCurrent
            annualCarbonTaxIntensityCurrent
            annualEnergyUseIntensityCurrent
            annualUtilityCostIntensityCurrent
            averageMarginalAbatementCost
            buildingModels {
                buildingArchetype
                buildingModelUid
                buildingName
                dataCoverage {
                    dataCoverage
                    energyType
                    energyTypeFromEquipmentData
                    energyTypeFromUtilityData
                    equipmentDataHasSizeAndYear
                    equipmentDataUploaded
                    modellingStatus
                    utilityDataUploaded
                }
                location {
                    streetAddress
                    city
                    stateProvince
                    postalZipCode
                    country
                    latitude
                    longitude
                    climateZone
                }
                tags
            }
            carbonEmissionReductionPotential
            fundName
            grossFloorArea
            netPresentValueIntensity
            propertyName
            propertyUid
        }
    }
`);

export default queryFn;
