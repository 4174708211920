import { SurveyUIStructCheckbox } from "../../Survey.helpers";
import CheckboxAndLabel from "../CheckboxAndLabel";

interface SurveyCheckboxProps<T> {
    checkboxStruct: SurveyUIStructCheckbox<T>;
    surveyPageData: T;
    setSurveyPageData: (func: (prev: T) => T) => void;
}

function SurveyCheckbox<T>({
    checkboxStruct,
    surveyPageData,
    setSurveyPageData,
}: SurveyCheckboxProps<T>) {
    const { key, label } = checkboxStruct;

    const handleSetChecked = (val: boolean) => {
        setSurveyPageData((prev) => ({
            ...prev,
            [key]: val,
        }));
    };

    return (
        <CheckboxAndLabel
            setChecked={handleSetChecked}
            currentValue={
                surveyPageData[key] === null ? undefined : !!surveyPageData[key]
            }
            label={label}
        />
    );
}

export default SurveyCheckbox;