import useBuildingLoadingState from "recoilStore/useBuildingLoadingState";
import { useParams } from "react-router-dom";
import {
    getDisplayInfo,
} from "utils/formatting";
import { CardErrorState } from "../ErrorStates";
import "./Card.css";
import { CardNoDataState } from "../NoDataState";
import errorFallbackUI from "../ErrorFallbackUI.tsx/ErrorFallbackUI";
import { CardHeader } from "./CardHeader";
import { DataSection, StatisticInfo } from "./CardTypes";
import CardBody from "./CardBody";
import { ComponentErrorBoundary } from "../ErrorStates/ErrorBoundary";

interface CardData {
    header: StatisticInfo;
    sections: DataSection[];
}

interface CardProps {
    data?: CardData;
    loading: boolean;
    error?: string;
}

function CardWrapper({ ...props }: CardProps) {
    const { buildingModelUid } = useParams();

    if (buildingModelUid)
        return <BuildingCard buildingModelUid={buildingModelUid} {...props} />;

    return <Card {...props} />;
}

interface BuildingCardProps extends CardProps {
    buildingModelUid: string;
}

function BuildingCard({
    buildingModelUid,
    loading,
    ...props
}: BuildingCardProps) {
    const { loadingState } = useBuildingLoadingState(buildingModelUid);
    return (
        <Card
            {...props}
            loading={loading || loadingState.buildingReportsLoading}
        />
    );
}

function Card({ data, error, loading }: CardProps) {
    if (loading) return <CardLoadingSkeleton />;
    if (error) return <CardErrorState />;
    if (!data) return <CardNoDataState />;

    const {
        value,
        displayInfoKey,
        title,
        valueColor,
        increaseDecreasePercentage,
    } = data.header;
    const { sections } = data;
    const { humanReadable, unit, formatFunction } =
        typeof value === "number"
            ? getDisplayInfo(displayInfoKey, value)
            : getDisplayInfo(displayInfoKey);

    return (
        <div className="card">
            <ComponentErrorBoundary fallback={errorFallbackUI("cardHeaderError")} originComponent="Card">
                <CardHeader
                    title={title || humanReadable}
                    value={value}
                    unit={unit}
                    valueColor={valueColor}
                    increaseDecreasePercentage={increaseDecreasePercentage}
                    formatFunction={(v) => formatFunction(v)}
                />
            </ComponentErrorBoundary >
            <ComponentErrorBoundary fallback={errorFallbackUI("cardBodyError")} originComponent="Card">
                <CardBody sections={sections} />
            </ComponentErrorBoundary>
        </div>
    );
}

function CardLoadingSkeleton() {
    return (
        <div className="card loading">
            <div className="loading-title loading" />
            <div className="loading-body loading" />
        </div>
    );
}


export default CardWrapper;
