import React, { useEffect } from "react";

export const useClickOutside = (
    ref: React.MutableRefObject<Node | null>,
    callback: () => void
) => {
    const handleClick = (e: any) => {
        if (!ref.current || ref.current.contains(e.target)) {
            return;
        }
        callback();
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};
