/* eslint-disable max-len */
import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.buildingModelReports.error)
            throw new Error(data.buildingModelReports.error);
        else
            return data.buildingModelReports.recommendationsReport?.annualCarbonEmissionsNaturalGasChart.map(
                (item) => {
                    const endUses: { [endUseKey: string]: number } = {};
                    const year = item?.year || 0;
                    item?.endUseCarbonEmissions.forEach((use) => {
                        const endUseKey = use?.endUse;
                        const carbonEmissions = use?.carbonEmissions || 0;
                        if (endUseKey) endUses[endUseKey] = carbonEmissions;
                    });
                    return {
                        year,
                        ...endUses,
                    };
                }
            ) as AnnualCarbonEmissionsNaturalGasChartData;
    });

const query = graphql(`
    query buildingRecommendationsAnnualCarbonEmissionsNaturalGasChart(
        $buildingModelUid: Uuid!
    ) {
        buildingModelReports(buildingModelUid: $buildingModelUid) {
            error
            recommendationsReport {
                annualCarbonEmissionsNaturalGasChart {
                    year
                    endUseCarbonEmissions {
                        endUse
                        carbonEmissions
                    }
                }
            }
        }
    }
`);

export default queryFn;
