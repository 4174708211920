import { graphql } from "gql";
import request from "graphql-request";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationRemoveUsersFn = (
    accessToken: string,
    data: {
        userIds: string[]
    }
) => 
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: mutationQuery,
        variables: {
            userIds: data.userIds,
        },
        requestHeaders: buildRequestHeaders(accessToken),   
    }).then((data) => {
        if (data) {
            return data;
        }
        throw new Error("Error deleting user");
    })
    .catch((error) => {
        console.error("GraphQL Error:", error);
        throw error; 
    });



const mutationQuery = graphql(`
    mutation removeUsers ($userIds: [String!]!) {
        removeUsers(userIds: $userIds){
            userId
        }
    }
`);        

export default mutationRemoveUsersFn;