// eslint-disable-next-line import/no-named-default
import { default as CheckboxMUI } from "@mui/material/Checkbox";
import { Paragraph } from "components/atoms/Typography";
import MUITheme from "components/MUITheme";
import "./index.css";

interface CheckboxProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label?: string;
}

function Checkbox({ checked, onChange, label }: CheckboxProps) {
    const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        return onChange(checked);
    };

    return (
        <MUITheme primaryColor="#eb03ad">
            <div className="checkbox">
                <CheckboxMUI
                    checked={checked}
                    disableRipple={true}
                    onChange={onChangeEvent}
                    style={{
                        padding: 0,
                        margin: 0,
                    }}
                />
                {label && <Paragraph className="label">{label}</Paragraph>}
            </div>
        </MUITheme>
    );
}

export default Checkbox;
