import MultiselectDropdown from "components/molecules/MultiselectDropdown";
import { useEffect, useMemo, useState } from "react";
import useUniqueTags from "recoilStore/useUniqueTags";
import { getDisplayInfo } from "utils/formatting";
import { MultiSelectFilterProps } from "../FilterSidebar.interface";
import { cloneFilters } from "../FilterSidebar.helpers";

function TagMultiSelectFilter({ filters, setFilters }: MultiSelectFilterProps) {
    const currentFilter = useMemo(
        () => filters.stringFilters.find(({ field }) => field === "tag"),
        [filters]
    );

    const [selectedItemsMultiSelect, setSelectedItemsMultiSelect] = useState(
        currentFilter?.value
    );

    const { tags } = useUniqueTags();
    const multiSelectDropdownItems = useMemo(() => {
        const { formatFunction } = getDisplayInfo("tags");
        return tags.tags.map((tag) => ({
            key: tag,
            displayValue: formatFunction(tag),
        }));
    }, [tags]);

    useEffect(() => {
        if (!selectedItemsMultiSelect) return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: StringFilter = {
                value: selectedItemsMultiSelect,
                condition: "equal",
                field: "tag",
                key: "tags",
            };

            const index = copy.stringFilters.findIndex(
                ({ field }) => field === "tag"
            );
            if (index === -1) copy.stringFilters.push(newFilter);
            else copy.stringFilters[index] = newFilter;
            return copy;
        });
    }, [selectedItemsMultiSelect]);

    useEffect(() => {
        setSelectedItemsMultiSelect(currentFilter?.value);
    }, [currentFilter]);

    return (
        <div className="filter">
            <MultiselectDropdown
                items={multiSelectDropdownItems}
                selectedKeys={selectedItemsMultiSelect || []}
                handleChange={(keys) => setSelectedItemsMultiSelect(keys)}
                fixedHeight={false}
            />
        </div>
    );
}

export default TagMultiSelectFilter;