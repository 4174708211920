import { useMemo, ReactNode, CSSProperties } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MUITheme from "components/MUITheme";

interface MultiselectDropdownProps {
    items: Item[];
    selectedKeys: string[];
    handleChange: (keys: string[]) => void;
    fixedHeight?: boolean;
}

function MultiselectDropdown({
    items,
    handleChange,
    selectedKeys,
    fixedHeight = true,
}: MultiselectDropdownProps) {
    const onChange = (event: SelectChangeEvent<Item[]>) => {
        const items = event.target.value as Item[];
        handleChange(items.map(({ key }) => key));
    };
    const selectedItems = useMemo(
        () => items.filter(({ key }) => selectedKeys.includes(key)),
        [selectedKeys, items]
    );

    const renderValue = (selected: Item[]) => (
        <div style={wrapStyle}>
            {selected.map(({ displayValue, key }) => (
                <div key={key}>{displayValue}</div>
            ))}
        </div>
    );

    return (
        <MUITheme primaryColor="#EB03AD">
            <Select
                multiple
                value={selectedItems}
                onChange={onChange}
                style={{
                    width: "100%",
                    height: fixedHeight ? "35px" : undefined,
                    minWidth: 0,
                }}
                renderValue={fixedHeight ? undefined : renderValue}
            >
                {items.map((item) => (
                    <MenuItem key={item.key} value={item as any}>
                        {item.displayValue}
                    </MenuItem>
                ))}
            </Select>
        </MUITheme>
    );
}

const wrapStyle: CSSProperties = {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
};

interface Item {
    key: string;
    displayValue: string | ReactNode;
}

export default MultiselectDropdown;
