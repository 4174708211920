import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DataCoverageIcon } from "components/molecules/DataCoverage/DataCoverage";
import MultiselectDropdown from "components/molecules/MultiselectDropdown";
import { MultiSelectFilterProps } from "../FilterSidebar.interface";
import { DataCoverageEnums } from "../FilterSidebar.constants";
import { cloneFilters } from "../FilterSidebar.helpers";

function DataCoverageMultiSelectFilter({
    filters,
    setFilters,
}: MultiSelectFilterProps) {
    const { pathname } = useLocation();
    const page = pathname.includes("reports") ? "reports" : "buildings";

    const currentFilter = filters.dataCoverageFilter;
    const [selectedItemsMultiSelect, setSelectedItemsMultiSelect] =
        useState(currentFilter);

    let dropdownItems = DataCoverageEnums.slice();
    if (page === "reports") {
        dropdownItems = dropdownItems.filter(
            (item) => !["error", "processing"].includes(item)
        );
    }
    const multiSelectDropdownItems = dropdownItems.map((item) => ({
        key: item,
        displayValue: <DataCoverageIcon identifier={item} />,
    }));

    useEffect(() => {
        if (!selectedItemsMultiSelect) return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);
            copy.dataCoverageFilter =
                selectedItemsMultiSelect as DataCoverageEnum[];
            return copy;
        });
    }, [selectedItemsMultiSelect]);

    useEffect(() => {
        setSelectedItemsMultiSelect(currentFilter);
    }, [currentFilter]);

    return (
        <div className="filter">
            <MultiselectDropdown
                items={multiSelectDropdownItems}
                selectedKeys={selectedItemsMultiSelect || []}
                handleChange={(keys) =>
                    setSelectedItemsMultiSelect(keys as DataCoverageEnum[])
                }
                fixedHeight={false}
            />
        </div>
    );
}

export default DataCoverageMultiSelectFilter;