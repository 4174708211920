import mutationActiveAccountFn from "ApiLayer/ActiveOrganization/updateActiveOrganization";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { showMutationAlert } from "utils/helpers";

export const useUpdateActiveOrganization = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["user"] });
            
            navigate("/portfolio");
            window.location.reload();
        },
        mutationFn: async (activeOrganization: UserInput) => (
            mutationActiveAccountFn(accessToken, activeOrganization)
        )
    });
};
