import { useMemo } from "react";
import { useTooltip } from "@nivo/tooltip";
import { Header, Paragraph } from "components/atoms/Typography";
import { ResponsiveLine } from "@nivo/line";
import "./VariableWidthBarChart.css";
import { theme } from "components/molecules/Charts/ChartTheme";
import ChartLegend from "./ChartLegend";

interface VariableWidthBarChartProps {
    data: any;
    yAxisFormatFunction: (v: number | string) => string;
    xAxisFormatFunction: (v: number | string) => string;
    yAxisLabel: string;
    xAxisLabel: string;
}

function VariableWidthBarChart({
    data,
    yAxisFormatFunction,
    xAxisFormatFunction,
    yAxisLabel,
    xAxisLabel,
}: VariableWidthBarChartProps) {
    const lineData = useMemo(
        () => [
            {
                id: "id",
                data: data || [],
            },
        ],
        [data]
    );

    return (
        <>
            <ChartLegend
                keys={data?.map(({ color, key }: any) => ({
                    color,
                    label: key,
                }))}
            />
            <div style={{ height: "350px", width: "100%" }}>
                <ResponsiveLine
                    theme={theme}
                    data={lineData}
                    margin={{ top: 50, right: 110, bottom: 50, left: 100 }}
                    xScale={{ type: "linear" }}
                    curve="monotoneX"
                    layers={["grid", "axes", CustomBarLayers]}
                    yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: false,
                        reverse: false,
                    }}
                    enableGridX={false}
                    lineWidth={2}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickValues: 5,
                        legendOffset: 40,
                        legendPosition: "middle",
                        format: (v) => xAxisFormatFunction(v),
                        legend: yAxisLabel,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: -70,
                        legendPosition: "middle",
                        format: (v) => yAxisFormatFunction(v),
                        legend: xAxisLabel,
                    }}
                    pointSize={0}
                />
            </div>
        </>
    );
}

function CustomBarLayers({ series, lineGenerator, xScale, yScale }: any) {
    const bars = series[0].data;

    return bars.map(({ data, position }: any, index: number) => (
        <g key={`$${position.x}${position.y}`}>
            <Bar
                data={data}
                index={index}
                xScale={xScale}
                yScale={yScale}
                x={position.x}
                y={position.y}
            />
        </g>
    ));
}

function Bar({ data, xScale, yScale, x, y }: any) {
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const showChartTooltip = (event: React.MouseEvent<Element, MouseEvent>) => {
        showTooltipFromEvent(
            <VariableWidthChartTooltip data={data} />,
            event,
            "right"
        );
    };

    const handleMouseEnter = (event: React.MouseEvent<Element, MouseEvent>) => {
        showChartTooltip(event);
    };

    const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>) => {
        showChartTooltip(event);
    };

    const handleMouseLeave = () => {
        hideTooltip();
    };

    const isNegativeY = data.y < 0;
    const yPos = isNegativeY ? yScale(0) : y;

    let HEIGHT = yScale(0) - y;
    if (isNegativeY) {
        HEIGHT = Math.abs(y - yPos);
    }
    const WIDTH = xScale(data.width);

    return (
        <rect
            width={WIDTH}
            x={x}
            y={yPos}
            height={HEIGHT < 3 ? 3 : HEIGHT}
            rx={3}
            ry={3}
            fill={data.color}
            strokeWidth={1}
            stroke="white"
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        />
    );
}

interface VariableWidthChartTooltipProps {
    data: any;
}

function VariableWidthChartTooltip({ data }: VariableWidthChartTooltipProps) {
    return (
        <div className="variable-width-chart-tooltip">
            <div className="variable-width-chart-tooltip--title">
                <div
                    className="color-block"
                    style={{ backgroundColor: data.color }}
                />
                <Header size="small">{data.key}</Header>
            </div>
            <Paragraph>{data.xLabel}</Paragraph>
            <Paragraph>{data.yLabel}</Paragraph>
        </div>
    );
}

export default VariableWidthBarChart;
