import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationFn = (
    accessToken: string,
    buildingModelUids: string[],
    propertyName: string | null
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            buildingModelUids,
            propertyName,
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.setBuildingModelProperty.error)
            throw new Error(data.setBuildingModelProperty.error);
        return data.setBuildingModelProperty.success;
    });

const query = graphql(`
    mutation setBuildingModelProperty(
        $buildingModelUids: [Uuid!]!
        $propertyName: String
    ) {
        setBuildingModelProperty(
            buildingModelUids: $buildingModelUids
            propertyName: $propertyName
        ) {
            error
            success
        }
    }
`);

export default mutationFn;
