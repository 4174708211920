import { Header } from "components/atoms/Typography";

interface SurveySubtitleProps {
    label: string;
}

function SurveySubtitle({ label }: SurveySubtitleProps) {
    return <Header size="small">{label}</Header>;
}

export default SurveySubtitle;