import queryUser from "ApiLayer/User/queryUser";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { useQuery } from "react-query";
import { QUERY_STALE_TIME } from "utils/constants";
import { showMutationAlert } from "utils/helpers";

export const userQueryKey = ["user"];

export const useUser = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();

    return useQuery({
        queryKey: userQueryKey,

        queryFn: async () => queryUser(accessToken),
        staleTime: QUERY_STALE_TIME,
        onError: (error) => {
            showMutationAlert(error, alert);
        },
    });
};
