import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationFn = (
    accessToken: string,
    data: {
        tags: string[];
        buildingModelUid: string;
    }[]
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            data: data.map(({ tags, buildingModelUid }) => ({
                tags,
                building_model_uid: buildingModelUid,
            })),
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.deleteBuildingModelTags.error)
            throw new Error(data.deleteBuildingModelTags.error);
        return data.deleteBuildingModelTags.success;
    });

const query = graphql(`
    mutation deleteBuildingModelTags($data: [BuildingModelTagInput!]!) {
        deleteBuildingModelTags(data: $data) {
            error
            success
        }
    }
`);

export default mutationFn;
