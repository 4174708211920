import { UseQueryResult } from "react-query";
import EndUseStackedBarChart from "../EndUseStackedBarChart";

interface AnnualCarbonEmissionsElectricityChartProps {
    query: () => UseQueryResult<AnnualCarbonEmissionsElectricityChartData>;
}

function AnnualCarbonEmissionsElectricityChart({
    query,
}: AnnualCarbonEmissionsElectricityChartProps) {
    const { data, isLoading, error } = query();

    return (
        <EndUseStackedBarChart
            data={data}
            fuelTypeKey="electricity"
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default AnnualCarbonEmissionsElectricityChart;
