import { request } from "graphql-request";
import { graphql } from "gql/gql";
import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const queryFn = (accessToken: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => data.funds as Fund[]);

const query = graphql(`
    query funds {
        funds {
            annualCarbonEmissionIntensityCurrent
            annualCarbonTaxIntensityCurrent
            annualEnergyUseIntensityCurrent
            annualUtilityCostIntensityCurrent
            averageMarginalAbatementCost
            carbonEmissionReductionPotential
            fundId
            fundName
            grossFloorArea
            netPresentValueIntensity
            properties {
                propertyUid
                buildingModels {
                    buildingModelUid
                    dataCoverage {
                        dataCoverage
                        energyType
                        energyTypeFromEquipmentData
                        energyTypeFromUtilityData
                        equipmentDataHasSizeAndYear
                        equipmentDataUploaded
                        modellingStatus
                        utilityDataUploaded
                    }
                    tags
                }
            }
        }
    }
`);

export default queryFn;
