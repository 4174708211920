import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import { buildRequestHeaders } from "utils/helpers";

const mutationFn = (
    accessToken: string,
    data: {
        buildingModelUid: string;
        tags: string[];
    }[]
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: {
            data: data.map(({ tags, buildingModelUid }) => ({
                tags,
                building_model_uid: buildingModelUid,
            })),
        },
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.createBuildingModelTags.error)
            throw new Error(data.createBuildingModelTags.error);
        return data.createBuildingModelTags.success;
    });

const query = graphql(`
    mutation createBuildingModelTags($data: [BuildingModelTagInput!]!) {
        createBuildingModelTags(data: $data) {
            error
            success
        }
    }
`);

export default mutationFn;
