import Modal from "components/molecules/Modal";
import { Button } from "components/atoms/Button";
import { Paragraph } from "components/atoms/Typography";

interface DangerousModalOptions {
    title?: string;
    body?: string | JSX.Element;
    modalId?: string; // unique modal identifier used to set ids of elements within modal
    deleteButtonText?: string;
}
interface DangerousModalProps {
    open: boolean;
    onCancel: () => void;
    onDelete: () => void;
    loading?: boolean;
    options?: DangerousModalOptions;
    styleType?: "primary" | "primary-negative";
}

function DangerousModal({
    open,
    onCancel,
    onDelete,
    loading,
    options,
    styleType,
}: DangerousModalProps) {
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            title={options?.title || "Delete"}
            footer={
                <Footer
                    id={options?.modalId}
                    deleteButtonText={options?.deleteButtonText || "Delete"}
                    onDelete={onDelete}
                    onCancel={onCancel}
                    loading={loading}
                    styleType={styleType}
                />
            }
        >
            <Paragraph style={{ lineHeight: "1.5rem" }}>
                {options?.body || "Do you wish to proceed?"}
            </Paragraph>
        </Modal>
    );
}

interface FooterProps {
    id?: string;
    deleteButtonText: string;
    onDelete: () => void;
    onCancel: () => void;
    loading?: boolean;
    styleType?: "primary" | "primary-negative";
}

function Footer({
    id,
    deleteButtonText,
    onDelete,
    onCancel,
    loading,
    styleType,
}: FooterProps) {
    return (
        <>
            <Button
                id={`${id}-delete-button`}
                type={styleType}
                onClick={onDelete}
                dataTestId="dangerous-modal-submit-button"
                disabled={loading}
            >
                {deleteButtonText}
            </Button>
            <Button id={`${id}-cancel-button`} onClick={onCancel}>
                Cancel
            </Button>
        </>
    );
}

export default DangerousModal;
