import { Paragraph } from "components/atoms/Typography";

function SurveyHint({ hint }: { hint: string }) {
    return (
        <Paragraph
            size="x-small"
            style={{
                textAlign: "left",
                marginBottom: "8px",
                lineHeight: "1.25rem",
            }}
        >
            <b>Hint</b>: {hint}
        </Paragraph>
    );
}

export default SurveyHint;
