import { ResponsiveBar, BarLayer } from "@nivo/bar";
import { useMemo } from "react";
import { theme } from "./ChartTheme";
import ChartTooltip from "./ChartTooltip";
import ChartLegend from "./ChartLegend";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface xAxisInfo {
    unit?: string;
    formatFunction?: (value: string) => string | null;
    hideLabels?: boolean;
    xLabelsToShow?: (string | number)[];
    tickValues?: number[];
}
// eslint-disable-next-line @typescript-eslint/naming-convention
interface yAxisInfo {
    label: string;
    formatFunction?: (value: number | string) => string | number;
}

type BarChartDatum = { [key: string]: string | number };

type BarChartData = BarChartDatum[];

interface BarChartProps {
    data: BarChartData;
    chartKeys?: string[];
    indexBy?: string;
    includeLegend?: boolean;
    margin?: { bottom: number; left: number; top: number; right: number };
    xAxisInfo?: xAxisInfo;
    yAxisInfo?: yAxisInfo;
    chartHeight?: number;
    tooltip?: (id: string) => JSX.Element | null;
    customLayers?: BarLayer<BarChartDatum>[];
    showBarsWithCustomLayers?: boolean;
    valueScale?: any;
    chartColorsMappedToKeys?: { label: string; color: string }[];
}

function BarChart({
    data,
    chartKeys = ["value"],
    indexBy = "label",
    includeLegend,
    customLayers,
    margin = {
        bottom: 60,
        left: 95,
        top: 10,
        right: 50,
    },
    valueScale,
    xAxisInfo,
    yAxisInfo,
    chartHeight = 250,
    showBarsWithCustomLayers = true,
    chartColorsMappedToKeys,
    tooltip,
}: BarChartProps) {
    if (chartKeys.length !== 1 && !chartColorsMappedToKeys)
        throw new Error(
            "Please define chartColorsMappedToKeys when using the BarChart component for stacked data"
        );

    const formatFunctionYAxis = yAxisInfo?.formatFunction;
    const unitYAxis = yAxisInfo?.label;

    const layers = useMemo(() => {
        const newLayers = customLayers
            ? ["grid", "axes", "legends", ...customLayers]
            : undefined;
        if (newLayers && showBarsWithCustomLayers)
            newLayers.splice(2, 0, "bars");
        return newLayers;
    }, [customLayers]);

    const colors = useMemo(
        () =>
            chartKeys.length === 1
                ? [SINGLE_BAR_COLOR]
                : chartKeys.map(
                      (key, index) =>
                          chartColorsMappedToKeys?.find(
                              ({ label }) => label === key
                          )?.color ||
                          CHART_COLORS[index] ||
                          SINGLE_BAR_COLOR
                  ),
        [chartKeys, chartColorsMappedToKeys]
    );

    return (
        <>
            {includeLegend && data.length > 0 && (
                <ChartLegend keys={chartColorsMappedToKeys || []} />
            )}
            <div style={{ height: `${chartHeight}px`, width: "100%" }}>
                <ResponsiveBar
                    data={data}
                    indexBy={indexBy}
                    keys={chartKeys}
                    colors={colors}
                    valueScale={valueScale}
                    indexScale={{ type: "band", round: false }} // workaround large left and right band | caused by a D3 calculation https://github.com/plouc/nivo/issues/315
                    theme={theme}
                    labelSkipWidth={10}
                    axisBottom={
                        xAxisInfo?.hideLabels
                            ? null
                            : {
                                  tickRotation: 0,
                                  tickSize: 0,
                                  tickValues: xAxisInfo?.tickValues,
                                  legend: xAxisInfo?.unit,
                                  legendPosition: "middle",
                                  legendOffset: 50,
                                  format: (v) => {
                                      const labelsToShow =
                                          xAxisInfo?.xLabelsToShow;
                                      let value = v;
                                      if (labelsToShow) {
                                          value =
                                              xAxisInfo?.xLabelsToShow?.find(
                                                  (vts) => vts === v
                                              );
                                      }
                                      if (value && xAxisInfo?.formatFunction)
                                          return xAxisInfo?.formatFunction(
                                              value
                                          );
                                      if (value) return value;
                                      return "";
                                  },
                              }
                    }
                    axisLeft={{
                        tickSize: 3,
                        tickPadding: 5,
                        tickRotation: 0,
                        tickValues: chartHeight < 200 ? 4 : undefined,
                        legendPosition: "middle",
                        legend: unitYAxis,
                        legendOffset: margin ? margin.left * -1 + 5 : -50,
                        format: formatFunctionYAxis,
                    }}
                    margin={margin}
                    enableLabel={false}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tooltip={({ indexValue, index }) => {
                        if (tooltip) return tooltip(indexValue as string);
                        return (
                            <ChartTooltip
                                title={data[index][indexBy]}
                                rows={buildChartTooltipRows(
                                    index,
                                    data,
                                    indexBy,
                                    unitYAxis || "",
                                    chartColorsMappedToKeys,
                                    formatFunctionYAxis
                                )}
                            />
                        );
                    }}
                    padding={0.5}
                    borderColor="#fff"
                    layers={layers as BarLayer<BarChartDatum>[]}
                />
            </div>
        </>
    );
}

const buildChartTooltipRows = (
    dataIndex: number,
    data: BarChartData,
    indexBy: string,
    unitYAxis: string,
    chartKeyToColorMap?: { label: string; color: string }[],
    formatFunction?: (v: string | number) => string | number
) =>
    Object.keys(data[dataIndex])
        .filter((k) => k !== indexBy)
        .map((key) => ({
            value: formatFunction
                ? formatFunction(Number(data[dataIndex][key]))
                : data[dataIndex][key],
            label: key,
            unit: unitYAxis || "",
            color:
                chartKeyToColorMap?.find(({ label }) => label === key)?.color ||
                SINGLE_BAR_COLOR,
        }));

export const CHART_COLORS = [
    "#4BF7B5",
    "#00BC98",
    "#066ECC",
    "#7CB7F2",
    "#7A2AEB",
    "#EB03AD",
    "#EF81DA",
    "#F7931E",
    "#BB6E00",
    "#F94646",
    "#CC303C",
];

const SINGLE_BAR_COLOR = "#00BC98";

export default BarChart;
