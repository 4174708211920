import { useMutation, useQueryClient } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import mutationFn from "../ApiLayer/deleteBuildingModels";

export const useDeleteBuildingModels = (onSuccess?: () => void) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();

    return useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["report"],
            });
            queryClient.invalidateQueries({
                queryKey: ["buildingModelsTable"],
            });
            if (onSuccess) onSuccess();
        },
        mutationFn: async (buildingModelUids: string[]) =>
            mutationFn(accessToken, buildingModelUids),
    });
};
