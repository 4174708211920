import { Button } from "../Button";

function CancelButton({ onCancel }: { onCancel: () => void }) {
    return (
        <Button id="add-building-cancel-button" type="link" onClick={onCancel}>
            Cancel
        </Button>
    );
}


export default CancelButton;