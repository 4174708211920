import { useMutation } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import mutationFn from "ApiLayer/Equipment/deleteEquipmentSurvey";
import useAlert from "hooks/useAlert";
import { showMutationAlert } from "utils/helpers";

export const useDeleteEquipmentData = (
    buildingModelUid: string,
    onSuccess: () => void,
    onError: () => void
) => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    return useMutation({
        mutationFn: async () =>
            mutationFn(accessToken, buildingModelUid),
        onSuccess,
        onError: (errors) => {
            onError();
            showMutationAlert(errors, alert);
        },
    });
};
