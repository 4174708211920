import { atom, useRecoilState } from "recoil";

interface BuildingModelsAggregatedState {
    minEmissionIntensity: number | null;
    maxEmissionIntensity: number | null;
}

const buildingsPageStateAtom = atom<BuildingModelsAggregatedState>({
    key: "buildingModelsAggregatedState",
    default: {
        minEmissionIntensity: null,
        maxEmissionIntensity: null,
    },
});

const useBuildingModelsAggregatedState = () => {
    const [state, setState] = useRecoilState(buildingsPageStateAtom);

    return {
        state,
        setState,
    };
};

export default useBuildingModelsAggregatedState;
