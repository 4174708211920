import { Paragraph } from "components/atoms/Typography";
import Tooltip from "components/atoms/Tooltip";
import { displayValueWithUnit, getDisplayInfo } from "utils/formatting";
import { IncreaseDecreasePercentage } from "./CardFunctions";
import { DataSection, StatisticInfo } from "./CardTypes";

interface CardBodyProps {
    sections: DataSection[];
}

function CardBody({ sections }: CardBodyProps) {
    return (
        <>
            {sections.map((section: DataSection, index: number) => (
                <div
                    className="card__body"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}${section[0].displayInfoKey}`}
                >
                    <CardBodySection section={section} sectionIndex={index} />
                </div>
            ))}
        </>
    );
}

interface CardBodySectionProps {
    section: DataSection;
    sectionIndex: number;
}

function CardBodySection({ section, sectionIndex }: CardBodySectionProps) {
    return (
        <>
            {addDividerToCardSection(sectionIndex) && (
                <div className="card__divider" />
            )}
            {section.map((info: StatisticInfo) => (
                <CardRow
                    info={info}
                    key={`${info.displayInfoKey}${sectionIndex}${info.title}`}
                />
            ))}
        </>
    );
}

const addDividerToCardSection = (sectionIndex: number): boolean =>
    (sectionIndex + 1) % 2 === 0;

interface CardRowProps {
    info: StatisticInfo;
}

function CardRow({ info }: CardRowProps) {
    const {
        value,
        percentage,
        tooltipText,
        displayInfoKey,
        title,
        increaseDecreasePercentage,
        valueColor,
        titleIcon,
    } = info;

    const { humanReadable, unit, formatFunction } =
        typeof value === "number"
            ? getDisplayInfo(displayInfoKey, value)
            : getDisplayInfo(displayInfoKey);

    return (
        <div className="card__row">
            <div className="card__title-tooltip">
                <Paragraph size="small">
                    {titleIcon && titleIcon}
                    {title || humanReadable}
                </Paragraph>
                {tooltipText && <Tooltip tooltipBody={tooltipText} />}
            </div>
            <div className="card__value-and-percentage">
                <Paragraph size="small" style={{ color: valueColor }}>
                    {displayValueWithUnit(value, unit, (v) =>
                        formatFunction(v)
                    )}
                    {percentage !== undefined && (
                        <span className="percentage"> {percentage}%</span>
                    )}
                </Paragraph>
                {increaseDecreasePercentage !== undefined && (
                    <IncreaseDecreasePercentage
                        value={increaseDecreasePercentage}
                    />
                )}
            </div>
        </div>
    );
}

export default CardBody;