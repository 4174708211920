import { useMutation, useQueryClient } from "react-query";
import { useAccessToken } from "hooks/useAccessToken";
import mutationFn from "../ApiLayer/Tags/deleteBuildingModelTags";

export const useDeleteBuildingModelTags = (buildingModelUids: string[]) => {
    const accessToken = useAccessToken();
    const queryClient = useQueryClient();

    return useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["buildingModelsTable"],
            });

            buildingModelUids.forEach((uid) =>
                queryClient.invalidateQueries({
                    queryKey: ["buildingModelHeader", uid],
                })
            );
        },
        mutationFn: async (
            data: {
                tags: string[];
                buildingModelUid: string;
            }[]
        ) => mutationFn(accessToken, data),
    });
};
