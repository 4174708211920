import { EquipmentAndInstallationYearSurveyItem } from "../../Survey.helpers";
import SurveyNumberInput from "../Survey/SurveyNumberInput";

interface BodyItemProps<T> {
    item: EquipmentAndInstallationYearSurveyItem<T>;
    state: T;
    setState: React.Dispatch<React.SetStateAction<T>>;
}

function EquipmentSizeAndInstallationYearSurveyBodyItem<T>({
    item,
    state,
    setState,
}: BodyItemProps<T>) {
    const { type, key } = item;

    if (item.show === false) return null;

    if (type === "number") {
        return (
            <SurveyNumberInput
                key={key.toString()}
                item={item}
                state={state}
                setState={setState}
            />
        );
    }
    return null;
}

export default EquipmentSizeAndInstallationYearSurveyBodyItem;