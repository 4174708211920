import { UseQueryResult } from "react-query";

import EndUseStackedBarChart from "../EndUseStackedBarChart";

interface AnnualCarbonEmissionsNaturalGasChartProps {
    query: () => UseQueryResult<any>;
}

function AnnualCarbonEmissionsNaturalGasChart({
    query,
}: AnnualCarbonEmissionsNaturalGasChartProps) {
    const { data, isLoading, error } = query();

    return (
        <EndUseStackedBarChart
            data={data}
            fuelTypeKey="naturalGas"
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

export default AnnualCarbonEmissionsNaturalGasChart;
