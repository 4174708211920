import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildBuildingModelRequestVariables,
} from "utils/helpers";

const queryFn = (
    accessToken: string,
    buildingModelUid: string,
    carbonReductionMeasureType: string
) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(
            buildingModelUid,
            carbonReductionMeasureType
        ),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            data.measureReport
                .totalEnergyConsumptionCard as MeasureReportTotalEnergyConsumptionCard
    );

const query = graphql(`
    query totalEnergyConsumptionCard(
        $buildingModelUid: Uuid!
        $carbonReductionMeasureType: String!
    ) {
        measureReport(
            buildingModelUid: $buildingModelUid
            carbonReductionMeasureType: $carbonReductionMeasureType
        ) {
            totalEnergyConsumptionCard {
                totalEnergyConsumptionSavings
                totalEnergyConsumptionSavingsElectricity
                totalEnergyConsumptionSavingsNaturalGas
                totalEnergyConsumption
                totalEnergyConsumptionElectricity
                totalEnergyConsumptionNaturalGas
                totalEnergyUseIntensity
            }
        }
    }
`);

export default queryFn;
