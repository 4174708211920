import { useMemo } from "react";
import { ActiveView } from "../Survey.constants";
import { buildRooftopUnitsSurveyUIStruct, getValidatedEquipmentState, updatePageStackOnNextClick } from "../Survey.helpers";
import SurveyView from "../SurveyElements/Survey/SurveyView";
import { useNextButtonDisabled } from "../Survey.hooks";

interface RoofTopSurveyViewProps {
    roofTopUnit: RooftopUnit;
    setRooftopUnit: React.Dispatch<React.SetStateAction<RooftopUnit>>;
    airHandlingEquipment: AirHandlingEquipment;
    setActiveView: (view: ActiveView) => void;
    activeView: ActiveView;
    setPageStack: React.Dispatch<React.SetStateAction<ActiveView[]>>;
}

function RoofTopUnitsSurveyView({
    roofTopUnit,
    setRooftopUnit,
    setActiveView,
    activeView,
    airHandlingEquipment,
    setPageStack,
}: RoofTopSurveyViewProps) {
    const structure = useMemo(
        () =>
            buildRooftopUnitsSurveyUIStruct(roofTopUnit, airHandlingEquipment),
        [roofTopUnit]
    );

    const nextButtonDisabled = useNextButtonDisabled(structure, roofTopUnit);

    const handleUpdateRooftopUnit = (
        func: (prev: RooftopUnit) => RooftopUnit
    ) => {
        setRooftopUnit((prev) => {
            const newValue = func(prev);
            const struct = buildRooftopUnitsSurveyUIStruct(
                newValue,
                airHandlingEquipment
            );
            return {
                ...getValidatedEquipmentState(newValue, struct),
                rooftopUnitCoolingType: newValue.rooftopUnitExists
                    ? "direct_expansion"
                    : null,
            };
        });
    };

    const handleNextClick = () => {
        updatePageStackOnNextClick(activeView, setPageStack);
        setActiveView(ActiveView.airHandlingEquipment);
    };

    return (
        <SurveyView
            structure={structure}
            surveyPageData={roofTopUnit}
            setSurveyPageData={handleUpdateRooftopUnit}
            handleNextClick={handleNextClick}
            activeView={activeView}
            nextButtonDisabled={nextButtonDisabled}
        />
    );
}

export default RoofTopUnitsSurveyView;