import { request } from "graphql-request";
import { graphql } from "gql/gql";

import { BACKEND_DOMAIN } from "utils/constants";
import {
    buildRequestHeaders,
    buildReportsRequestVariables,
} from "utils/helpers";

const queryFn = (accessToken: string, filters: Filters) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildReportsRequestVariables(filters),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then(
        (data) =>
            data.reports?.baselineReport
                ?.buildingBarChartAndMapByProperty as BuildingBarChartAndMapByProperty[]
    );

const query = graphql(`
    query buildingBarChartAndMapByProperty($reportFilter: ReportFilter) {
        reports(reportFilter: $reportFilter) {
            baselineReport {
                buildingBarChartAndMapByProperty {
                    averageAnnualCarbonEmissionIntensityCurrent
                    averageAnnualEnergyUseIntensityCurrent
                    latitude
                    longitude
                    propertyName
                    totalAnnualCarbonEmissionsCurrent
                    totalAnnualEnergyConsumptionCurrent
                }
            }
        }
    }
`);

export default queryFn;
