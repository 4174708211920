import CardBody from "../Card/CardBody";
import { CardHeader } from "../Card/CardHeader";
import { Table } from "../Table";

const errorFallbackUI = (caseType: string): JSX.Element => { 
    if (caseType === "cardHeaderError") {
        return (
            <CardHeader 
                title="Error with this data"
                value="N/A"
                unit=""
                valueColor={undefined}
                increaseDecreasePercentage={undefined}
                formatFunction={() => "N/A"}
            />
        )
    } 
    
    if (caseType === "cardBodyError") {
        return (
            <CardBody sections={errorSection}/>
        )
    } 

    if (caseType === "tableError") {
        return (
            <Table 
                dataSource={errorTable}
                columns={errorTableColumns}
                className="projects-report-table"
                pagination={false}
            />
        )
    }
    
    return (  
        <>
            <h1>Undefined Error</h1>
            <div>An undefined error has occurred</div>
        </> 
    )
}

const errorSection = [
    [
        {
            "value": 0,
            "displayInfoKey": "energyConsumption",
            "title": "Error with this data",
            "percentage": 0
        },
    ]
]

const errorTable = [
    {
        "carbonReductionMeasureType": "There was an error with the data ",
        "key": "There was an error with the data "
    }
]

const errorTableColumns = [
    {
        "key": "carbonReductionMeasureType",
        "title": "Error with Table Data",
        "render": (value: any) => value
    }
]

export default errorFallbackUI;