import { CloseButton } from "components/atoms/Icon";
import { Modal as MUIModal } from "@mui/material";
import { Header } from "components/atoms/Typography";
import "./Modal.scss";
import { ReactNode } from "react";

interface ModalProps {
    open: boolean;
    onCancel: () => void;
    title: string;
    children: ReactNode;
    footer: ReactNode;
}

function Modal({ open, title, children, footer, onCancel }: ModalProps) {
    return (
        <MUIModal open={open} onClose={onCancel}>
            <div className="modal">
                <div className="modal__header">
                    <Header>{title}</Header>
                    <CloseButton onClick={onCancel} />
                </div>
                <div className="modal__body">{children}</div>
                <div className="modal__footer">{footer}</div>
            </div>
        </MUIModal>
    );
}

export default Modal;
