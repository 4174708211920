import { Header, Paragraph } from "components/atoms/Typography";
import { displayValueWithUnit } from "utils/formatting";
import { IncreaseDecreasePercentage } from "./CardFunctions";
import { ValueType } from "./CardTypes";


interface CardHeaderProps {
    title: string;
    value: ValueType;
    valueColor?: string;
    unit: string;
    increaseDecreasePercentage?: number;
    formatFunction: (value: ValueType) => string;
}


export function CardHeader({
    value,
    title,
    unit,
    valueColor = "var(--audette-black)",
    increaseDecreasePercentage,
    formatFunction,
}: CardHeaderProps) {
    return (
        <div className="card__header">
            <Paragraph style={{ color: "var(--audette-gray-600)" }}>
                {title}
            </Paragraph>
            <div className="card__value-and-percentage">
                <Header style={{ color: valueColor }}>
                    {displayValueWithUnit(
                        value, 
                        unit, 
                        (v: ValueType) => formatFunction(v) ?? String(v) 
                    )}
                </Header>
                {increaseDecreasePercentage && (
                    <IncreaseDecreasePercentage
                        isHeader={true}
                        value={increaseDecreasePercentage}
                    />
                )}
            </div>
        </div>
    );
}


