import { Header } from "components/atoms/Typography";
import { EquipmentAndInstallationYearSurveySection } from "../../Survey.helpers";
import EquipmentSizeAndInstallationYearSurveyBodyItem from "./EquipmentSizeAndInstallationYearSurveyBodyItem";

interface EquipmentSizeAndInstallationYearSurveyBodyProps<T> {
    section: EquipmentAndInstallationYearSurveySection<T>;
    booleanQuestionResult: boolean | null;
    state: T;
    setState: React.Dispatch<React.SetStateAction<T>>;
}

function EquipmentSizeAndInstallationYearSurveyBody<T>({
    section,
    booleanQuestionResult,
    state,
    setState,
}: EquipmentSizeAndInstallationYearSurveyBodyProps<T>) {
    if (section.show === false || booleanQuestionResult !== true) return null;
    return (
        <div
            className="building-equipment-survey-view__section building-equipment-survey-view__section--size-and-year-survey"
            key={section.sectionTitle}
        >
            <Header size="medium">{section.sectionTitle}</Header>
            {section.items.map((item) => (
                <EquipmentSizeAndInstallationYearSurveyBodyItem
                    state={state}
                    setState={setState}
                    item={item}
                    key={item.key.toString()}
                />
            ))}
        </div>
    );
}

export default EquipmentSizeAndInstallationYearSurveyBody;