import { useContext } from "react";
import { AccessTokenContext } from "context";

const useAccessTokenContext = () => useContext(AccessTokenContext);

export const useAccessToken = () => {
    const accessToken = useAccessTokenContext();
    if (accessToken === null)
        throw new Error("Access Token is null when it should be defined");

    return accessToken;
};
