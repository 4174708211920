import { FilterIcon } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import FiltersSidebar from "components/organisms/FiltersSidebar";
import posthog from "posthog-js";
import { useState } from "react";
import useFilters from "recoilStore/useFilters";

function Filters() {
    const { numberOfFilters } = useFilters("buildings");
    const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);

    const onClickHandler = () => {
        posthog.capture('Open filters');
        setFilterSidebarIsOpen(true);
    }
    
    return (
        <>
            <button
                id="buildings-filter-button"
                type="button"
                onClick={onClickHandler}
            >
                <FilterIcon />
                <Paragraph>
                    Filters
                    {numberOfFilters > 0 && ` (${numberOfFilters})`}
                </Paragraph>
            </button>
            <FiltersSidebar
                closeSidebar={() => setFilterSidebarIsOpen(false)}
                sidebarOpen={filterSidebarIsOpen}
                page="buildings"
            />
        </>
    );
}

export default Filters;