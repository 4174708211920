import { useQuery } from "react-query";
import { QUERY_STALE_TIME } from "utils/constants";
import { useAccessToken } from "hooks/useAccessToken";
import { useCallback } from "react";
import useFilters from "recoilStore/useFilters";
import queryFn from "ApiLayer/queryBuildingModelsTable";
import useBuildingModelsAggregatedState from "recoilStore/useBuildingModelsAggregatedState";
import { useLocation } from "react-router-dom";
import { filterTableData } from "utils/helpers";
import useUniqueTags from "recoilStore/useUniqueTags";

export const useBuildingModelsTableQuery = () => {
    const accessToken = useAccessToken();

    const { pathname } = useLocation();
    let page: Page = "buildings";
    if (pathname.includes("reports")) page = "reports";

    const { filters: clientFilters, numberOfFilters } = useFilters(page);
    const { setState } = useBuildingModelsAggregatedState();
    const { setTags } = useUniqueTags();

    const onSuccess = (datum: BuildingModelsTableDatum[]) => {
        const emissionIntensities = datum.map(
            ({ annualCarbonEmissionIntensityCurrent: d }) => d
        );

        const emissionIntensitiesWithNullFiltered = emissionIntensities.filter(
            (intensity) => intensity !== null
        ) as number[];

        const min = Math.min(...emissionIntensitiesWithNullFiltered);
        const max = Math.max(...emissionIntensitiesWithNullFiltered);

        setState((prev) => ({
            ...prev,
            minEmissionIntensity: min,
            maxEmissionIntensity: max,
        }));
        const uniqueTags = new Set(datum.flatMap(({ tags }) => tags));
        setTags({ tags: Array.from(uniqueTags) });
    };

    const selectBuildingModels = useCallback(
        (datum: BuildingModelsTableDatum[]) =>
            filterTableData(datum, clientFilters, numberOfFilters),
        [clientFilters]
    );

    return useQuery({
        queryKey: ["buildingModelsTable"],
        queryFn: async () => queryFn(accessToken, onSuccess),
        staleTime: QUERY_STALE_TIME,
        select: selectBuildingModels,
    });
}
