import { ExportIcon } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";
import { useExportBuildings } from "mutations/useExportBuildings";
import posthog from "posthog-js";
import { useEffect } from "react";
import useDownloadState from "recoilStore/useDownloadState";

function Export() {
    const { state, setState, resetStateToDefault } = useDownloadState();
    const { mutate: downloadFile, reset } = useExportBuildings();

    const handleClick = () => {
        if (state.downloadSuccess || state.downloadInProgress) return;
        setState((prev) => ({
            ...prev,
            showBanner: true,
            downloadInProgress: true,
        }));
        downloadFile();
        posthog.capture('Export buildings')
    };

    useEffect(() => {
        if (state.downloadCancelled) {
            state.controller?.abort("abort");
            reset();
            resetStateToDefault();
        }
    }, [state]);

    return (
        <button
            id="portfolio-export-button"
            type="button"
            onClick={handleClick}
        >
            <ExportIcon />
            <Paragraph>Export</Paragraph>
        </button>
    );
}

export default Export;