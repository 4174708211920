import { useState } from "react";

import ViewTab from "components/atoms/ViewTab";
import { Header, Paragraph } from "components/atoms/Typography";
import { FilterIcon } from "components/atoms/Icon";
import FiltersSidebar from "components/organisms/FiltersSidebar";
import { useCreateReportFilterset } from "mutations/Measures/useCreateReportFiltersetMutation";
import { useUser } from "hooks/useUser";
import { UserPermissions } from "utils/enums";
import MeasuresProjectsReport from "./MeasuresProjectsReport";

import "./MeasuresPage.scss";

function MeasuresPage() {
    const [currentActiveTab, setCurrentActiveTab] =
        useState<ReportTab>("projects");

    const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);

    const { data: user } = useUser();
    const { mutate } = useCreateReportFilterset();
    const permissions = user?.permissions || [""];
    console.log(permissions)
    
    const mockReportFiltersetData = {
        boolean_filters: [],
        data_coverage_filters: [],
        numeric_filters: [],
        string_filters: [],
    };

    const handleClick = () => {
        mutate({ reportFilter: mockReportFiltersetData });
    }
    
    return (
        <div className="measures-page">
        {permissions.includes(UserPermissions.UseInternalTools) && (
                        <button onClick={handleClick} type="button"> Mutation test (dev only)</button>
        )}
            <div className="measures-page__header">
                <Header size="large">Measures</Header>
            </div>
            <div className="measures-page--view-tabs">
                <ViewTab
                    id="measures-projects-tab"
                    name="Projects"
                    selected={currentActiveTab === "projects"}
                    onClick={() => setCurrentActiveTab("projects")}
                />
                <button
                    id="filter-button"
                    type="button"
                    onClick={() => setFilterSidebarIsOpen(true)}
                >
                    <FilterIcon />
                    <Paragraph>Filters</Paragraph>
                </button>
            </div>
            <FiltersSidebar
                closeSidebar={() => setFilterSidebarIsOpen(false)}
                sidebarOpen={filterSidebarIsOpen}
                page="measures"
            />
            {currentActiveTab === "projects" && <MeasuresProjectsReport />}

        </div>
    );
}

export default MeasuresPage;
