import BuildingTypeDropdown from "components/molecules/BuildingTypeDropdown";
import EnergyTypeDropdown from "components/molecules/EnergyTypeDropdown";
import FormInput from "components/molecules/FormInput";

interface InputProps {
    attributeKey: string;
    required: boolean;
    type: "string" | "number" | "select";
    value: string | number | undefined;
    setValue: (value: string | number | undefined) => void;
}

function Input({ attributeKey, required, type, value, setValue }: InputProps) {
    if (type === "select" && attributeKey === "buildingArchetype")
        return (
            <BuildingTypeDropdown
                value={value?.toString()}
                setValue={setValue}
                required={true}
            />
        );
    if (type === "select" && attributeKey === "energyType")
        return (
            <EnergyTypeDropdown
                value={value?.toString()}
                setValue={setValue}
                required={true}
            />
        );
    if (type === "string" || type === "number")
        return (
            <FormInput
                value={value || ""}
                setValue={(v) => {
                    if (type === "number") setValue(Number(v));
                    else setValue(v);
                }}
                attributeKey={attributeKey}
                type={type}
                setIsError={() => {}}
                required={required}
            />
        );

    return null;
}

export default Input;