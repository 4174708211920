import { graphql } from "gql/gql";
import { request } from "graphql-request";
import {
    buildBuildingModelRequestVariables,
    buildRequestHeaders,
} from "utils/helpers";
import { BACKEND_DOMAIN } from "utils/constants";

const queryFn = (accessToken: string, buildingModelUid: string) =>
    request({
        url: `${BACKEND_DOMAIN}/graphql`,
        document: query,
        variables: buildBuildingModelRequestVariables(buildingModelUid),
        requestHeaders: buildRequestHeaders(accessToken),
    }).then((data) => {
        if (data.buildingModelConstruction.error)
            throw new Error(data.buildingModelConstruction.error);
        return data.buildingModelConstruction as BuildingModelConstruction;
    });

const query = graphql(`
    query buildingModelConstruction($buildingModelUid: Uuid!) {
        buildingModelConstruction(buildingModelUid: $buildingModelUid) {
            error
            location {
                streetAddress
                city
                stateProvince
                postalZipCode
                country
                latitude
                longitude
                climateZone
            }
            buildingArchetype
            buildingFootprint {
                type
                geometry {
                    type
                    coordinates
                }
                properties {
                    latitude
                    longitude
                    buildingUid
                }
            }
            yearBuiltOriginal
            grossFloorArea
            floorsAboveGrade
            ledInstalledRatio
            buildingHeight
            buildingUid
            footprintArea
            wallRValue
            roofArea
            availableRoofAreaRatio
            roofRValue
            roofInstallationYear
            windowSolarHeatGainCoefficient
            windowToWallRatio
            windowRValue
            windowInstallationYear
        }
    }
`);

export default queryFn;
