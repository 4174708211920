import { ReactNode } from "react";
import MUITheme from "components/MUITheme";
import Radio from "@mui/material/Radio";
import { Paragraph } from "components/atoms/Typography";
import "./RadioButton.css";

interface RadioButtonProps {
    children?: ReactNode;
    checked: boolean;
    value: string;
    label: string;
    onChange: (value: string) => void;
}

function RadioButton({
    children,
    checked,
    value,
    label,
    onChange,
}: RadioButtonProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value);

    return (
        <MUITheme primaryColor="#EB03AD">
            <div className="radio-button">
                <Radio
                    checked={checked}
                    onChange={handleChange}
                    name="radio-button"
                    value={value}
                />
                <Paragraph> {label} </Paragraph> {children}
            </div>
        </MUITheme>
    );
}

export default RadioButton;
