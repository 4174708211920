import { useQuery } from "react-query";
import queryFn from "ApiLayer/queryBuildingFootprints";
import { useAccessToken } from "hooks/useAccessToken";
import { QUERY_STALE_TIME } from "utils/constants";

export const useBuildingFootprintsQuery = (address?: Address) => {
    const accessToken = useAccessToken();

    return useQuery({
        queryKey: ["buildingFootprints", address],
        queryFn: async () => queryFn(accessToken, address!),
        staleTime: QUERY_STALE_TIME,
        enabled: !!address, // only request when address is defined
    });
};
