import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";

// components
import SingleSelectDropdown from "components/atoms/Dropdown";
import MUITheme from "components/MUITheme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// constants
import { DateSelectConditions, filterConditionToDisplayValueMap } from "../FilterSidebar.constants";
import { NumericStringMultiFilterProps } from "../FilterSidebar.interface";

// helpers
import { cloneFilters } from "../FilterSidebar.helpers";

function DateFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<DateFilterField>) {
    const currentFilter = useMemo(
        () => filters.dateFilters.find(({ field }) => field === filterField),
        [filters]
    );

    const [value, setValue] = useState(currentFilter?.value[0]);
    const [endValue, setEndValue] = useState(currentFilter?.value[1]);

    const [dropdownInput, setDropdownInput] = useState(
        currentFilter?.condition
    );

    useEffect(() => {
        setDropdownInput(currentFilter?.condition);
        setValue(currentFilter?.value[0]);
        setEndValue(currentFilter?.value[1]);
    }, [currentFilter]);

    const dropdownItems = DateSelectConditions.map((id) => ({
        id,
        displayValue: filterConditionToDisplayValueMap[id],
    }));

    useEffect(() => {
        if (!value || dropdownInput === undefined) return;

        if (dropdownInput === "between" && !endValue) return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: DateFilter = {
                value:
                    dropdownInput === "between" ? [value, endValue!] : [value],
                condition: dropdownInput,
                field: filterField,
                key: filterKey,
            };

            const index = copy.dateFilters.findIndex(
                ({ field }) => field === filterField
            );
            if (index === -1) copy.dateFilters.push(newFilter);
            else copy.dateFilters[index] = newFilter;
            return copy;
        });
    }, [dropdownInput, value, endValue]);

    const updateValue = (value: string | null) => {
        if (value === null) setValue(undefined);
        else setValue(value);
    };

    const updateEndValue = (value: string | null) => {
        if (value === null) setEndValue(undefined);
        else setEndValue(value);
    };

    return (
        <div
            className={classNames(
                "filter",
                dropdownInput === "between" && "filter--between"
            )}
        >
            <SingleSelectDropdown
                items={dropdownItems}
                onSelectItem={(item) => setDropdownInput(item.id)}
                defaultItem={dropdownItems.find(
                    ({ id }) => id === dropdownInput
                )}
                classname={classNames(
                    "filter__dropdown",
                    dropdownInput === "between" && "filter__dropdown--between"
                )}
            />
            <MUITheme primaryColor="#EB03AD">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className="date-picker"
                        format="DD/MM/YYYY"
                        onChange={updateValue}
                        slotProps={{ textField: { size: "small" } }}
                        value={value || null}
                    />
                    {dropdownInput === "between" && (
                        <DatePicker
                            className="date-picker"
                            format="DD/MM/YYYY"
                            onChange={updateEndValue}
                            slotProps={{ textField: { size: "small" } }}
                            value={endValue || null}
                        />
                    )}
                </LocalizationProvider>
            </MUITheme>
        </div>
    );
}

export default DateFilter;