import "./ErrorState.css";
import { SadFace } from "components/atoms/Icon";
import { Paragraph } from "components/atoms/Typography";

interface ErrorStateProps {
    height?: number;
}

function ErrorState({ height }: ErrorStateProps) {
    return (
        <div
            className="error-state"
            style={height ? { height: `${height}px` } : undefined}
        >
            <SadFace />
            <Paragraph
                size="small"
                style={{ color: "var(--audette-gray-500)" }}
            >
                Couldn’t load the chart, refresh the page
            </Paragraph>
        </div>
    );
}

export function ChartErrorState() {
    return <ErrorState height={450} />;
}

export function CardErrorState() {
    return <ErrorState height={150} />;
}
