import { Header, Paragraph } from "components/atoms/Typography";
import { Button } from "components/atoms/Button";
import Image from "./Image";
import "./ErrorPage.css";

function ErrorPage() {
    return (
        <div className="error-page">
            <div className="error-page--text">
                <div className="error-page--text--title">
                    <Header size="medium">
                        Hmm, we’re having trouble loading the page
                    </Header>
                </div>
                <div className="error-page--text--options">
                    <Header
                        size="small"
                        weight="bold"
                        style={{ color: "var(--audette-gray-500)" }}
                    >
                        A few things to try:
                    </Header>
                    <Paragraph>
                        <ul className="error-page--list">
                            <li>
                                <Button type="link" onClick={refreshPage}>
                                    Refresh{" "}
                                    <span
                                        style={{
                                            color: "var(--audette-black)",
                                        }}
                                    >
                                        the page
                                    </span>
                                </Button>
                            </li>
                            <li>
                                <Button type="link" onClick={openFastDotCom}>
                                    Test your connection
                                </Button>
                            </li>
                            <li>Try again later</li>
                        </ul>
                    </Paragraph>
                </div>
            </div>
            <Image />
        </div>
    );
}

const openFastDotCom = () => {
    window.open("https://fast.com/#", "_blank");
};

const refreshPage = () => {
    window.location.reload();
};

export default ErrorPage;
