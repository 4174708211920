import "./BaselineReport.css";
import { Header } from "components/atoms/Typography";
import {
    ByBuildingBarChart,
    EndUseSankey,
    EmissionIntensityMap,
    BaselineAreaChart,
    EndUseStackedBarChart,
    OperatingCostCard,
    FutureProjectionCarbonEmissionsCard,
    FutureProjectionEnergyConsumptionCard,
    AnnualCumulativePicker,
} from "components/organisms/Charts";
import { RadioButtonSelectMode } from "components/organisms/Charts/AnnualCumulativePicker";
import { useMemo, useState } from "react";
import { CURRENT_YEAR } from "utils/constants";
import Card from "components/molecules/Card";
import { buildSankeyData } from "components/organisms/Charts/EndUseSankey";

import { useCurrentAnnualCarbonEmissionsCard } from "queries/Reports/Baseline/useCurrentAnnualCarbonEmissionsCard";
import { useCurrentAnnualEnergyConsumptionCard } from "queries/Reports/Baseline/useCurrentAnnualEnergyConsumptionCard";
import { useCurrentAnnualOperatingCostCard } from "queries/Reports/Baseline/useCurrentAnnualOperatingCostCard";
import { useCurrentAnnualEndUseChart } from "queries/Reports/Baseline/useCurrentAnnualEndUseChart";
import { useCumulativeCarbonEmissionsCard } from "queries/Reports/Baseline/useCumulativeCarbonEmissionsCard";
import { useCumulativeEnergyConsumptionCard } from "queries/Reports/Baseline/useCumulativeEnergyConsumptionCard";
import { useCumulativeOperatingCostCard } from "queries/Reports/Baseline/useCumulativeOperatingCostCard";
import { useAnnualCarbonEmissionsCard } from "queries/Reports/Baseline/useAnnualCarbonEmissionsCard";
import { useAnnualEnergyConsumptionCard } from "queries/Reports/Baseline/useAnnualEnergyConsumptionCard";
import { useAnnualOperatingCostCard } from "queries/Reports/Baseline/useAnnualOperatingCostCard";
import { useAnnualCarbonEmissionsChart } from "queries/Reports/Baseline/useAnnualCarbonEmissionsChart";
import { useAnnualCarbonEmissionsNaturalGasChart } from "queries/Reports/Baseline/useAnnualCarbonEmissionsNaturalGasChart";
import { useAnnualCarbonEmissionsElectricityChart } from "queries/Reports/Baseline/useAnnualCarbonEmissionsElectricityChart";

function BaselineReport() {
    return (
        <div className="baseline-report">
            <Header size="medium">
                Your buildings at a glance - {CURRENT_YEAR}
            </Header>
            <div className="cards-and-chart-grid">
                <div className="cards">
                    <CurrentAnnualCarbonEmissionsCard />
                    <CurrentAnnualEnergyConsumptionCard />
                    <TotalAnnualOperatingCostCard />
                </div>
                <ByBuildingBarChart />
            </div>
            <div className="side-by-side-charts-grid">
                <CurrentAnnualEndUseChart />
                <EmissionIntensityMap />
            </div>
            <Header size="medium">Future projection</Header>
            <div className="cards-and-chart-grid">
                <FutureProjectionCards />
                <AnnualCarbonEmissionsChart />
            </div>
            <div className="side-by-side-charts-grid">
                <AnnualCarbonEmissionsNaturalGasChart />
                <AnnualCarbonEmissionsElectricityChart />
            </div>
        </div>
    );
}

function FutureProjectionCards() {
    const [selected, setSelected] =
        useState<RadioButtonSelectMode>("cumulative");
    const [sliderCurrentYear, setSliderCurrentYear] = useState(CURRENT_YEAR);

    const onChangeSelect = () => {
        setSelected((prev) => {
            if (prev === "cumulative") return "annual";
            return "cumulative";
        });
    };

    return (
        <div>
            <AnnualCumulativePicker
                selected={selected}
                onChangeSelect={onChangeSelect}
                currentSliderYear={sliderCurrentYear}
                setCurrentSliderYear={setSliderCurrentYear}
            />
            <div className="cards">
                {selected === "cumulative" ? (
                    <>
                        <CumulativeCarbonEmissionsCard />
                        <CumulativeEnergyConsumptionCard />
                        <CumulativeOperatingCostCard />
                    </>
                ) : (
                    <>
                        <AnnualCarbonEmissionsCard
                            selectedYear={sliderCurrentYear}
                        />
                        <AnnualEnergyConsumptionCard
                            selectedYear={sliderCurrentYear}
                        />
                        <AnnualOperatingCostCard
                            selectedYear={sliderCurrentYear}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

function CurrentAnnualCarbonEmissionsCard() {
    const { data, error, isLoading } = useCurrentAnnualCarbonEmissionsCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;

        const { totalCarbonEmissions, averageEmissionsIntensity } = data;
        if (!totalCarbonEmissions || !averageEmissionsIntensity)
            throw new Error(
                "averageEmissionsIntensity and averageEmissionsIntensity should be defined"
            );

        return {
            header: {
                value: totalCarbonEmissions,
                displayInfoKey: "carbonEmissions",
            },
            sections: [
                [
                    {
                        value: averageEmissionsIntensity,
                        displayInfoKey: "carbonEmissionIntensity",
                    },
                ],
            ],
        };
    }, [data]);

    return (
        <Card data={cardData} loading={isLoading} error={error?.toString()} />
    );
}

function CurrentAnnualEnergyConsumptionCard() {
    const { data, error, isLoading } = useCurrentAnnualEnergyConsumptionCard();

    const cardData = useMemo(() => {
        if (!data) return undefined;

        const { totalEnergyConsumption, averageEnergyIntensity } = data;

        return {
            header: {
                value: totalEnergyConsumption,
                displayInfoKey: "energyConsumption",
            },
            sections: [
                [
                    {
                        value: averageEnergyIntensity,
                        displayInfoKey: "energyUseIntensity",
                    },
                ],
            ],
        };
    }, [data]);

    return (
        <Card data={cardData} loading={isLoading} error={error?.toString()} />
    );
}

function TotalAnnualOperatingCostCard() {
    const { data, isLoading, error } = useCurrentAnnualOperatingCostCard();
    return (
        <OperatingCostCard
            data={data}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

function CurrentAnnualEndUseChart() {
    const { data, isLoading, error } = useCurrentAnnualEndUseChart();

    const formattedData = useMemo(() => {
        if (!data) return null;
        const { carbonEmissions, energyConsumption, utilityCost } = data;
        return {
            carbonEmissions: buildSankeyData(carbonEmissions),
            energyConsumption: buildSankeyData(energyConsumption),
            utilityCost: buildSankeyData(utilityCost),
        };
    }, [data]);

    return (
        <EndUseSankey
            data={formattedData}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

function CumulativeCarbonEmissionsCard() {
    const { data, isLoading, error } = useCumulativeCarbonEmissionsCard();
    return (
        <FutureProjectionCarbonEmissionsCard
            data={data}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

function CumulativeEnergyConsumptionCard() {
    const { data, isLoading, error } = useCumulativeEnergyConsumptionCard();
    return (
        <FutureProjectionEnergyConsumptionCard
            data={data}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

function CumulativeOperatingCostCard() {
    const { data, isLoading, error } = useCumulativeOperatingCostCard();
    return (
        <OperatingCostCard
            data={data}
            loading={isLoading}
            cumulative={true}
            error={error?.toString()}
        />
    );
}

function AnnualCarbonEmissionsCard({ selectedYear }: { selectedYear: number }) {
    const { data, isLoading, error } = useAnnualCarbonEmissionsCard();

    const dataForYear = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => datum?.year === selectedYear);
    }, [selectedYear, data]);

    return (
        <FutureProjectionCarbonEmissionsCard
            data={dataForYear}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

function AnnualEnergyConsumptionCard({
    selectedYear,
}: {
    selectedYear: number;
}) {
    const { data, isLoading, error } = useAnnualEnergyConsumptionCard();

    const dataForYear = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => datum?.year === selectedYear);
    }, [selectedYear, data]);

    return (
        <FutureProjectionEnergyConsumptionCard
            data={dataForYear}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

function AnnualOperatingCostCard({ selectedYear }: { selectedYear: number }) {
    const { data, isLoading, error } = useAnnualOperatingCostCard();

    const dataForYear = useMemo(() => {
        if (!data) return undefined;
        return data.find((datum) => datum?.year === selectedYear);
    }, [selectedYear, data]);

    return (
        <OperatingCostCard
            data={dataForYear}
            cumulative={true}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

function AnnualCarbonEmissionsChart() {
    const { data, isLoading, error } = useAnnualCarbonEmissionsChart();
    const areaChartData = useMemo(
        () =>
            data?.map((datum) => ({
                areaY: datum?.totalCarbonEmissions || 0,
                x: datum?.year || 0,
            })),
        [data]
    );
    return (
        <BaselineAreaChart
            data={areaChartData}
            loading={isLoading}
            error={error?.toString()}
        />
    );
}

function AnnualCarbonEmissionsNaturalGasChart() {
    const { data, isLoading, error } =
        useAnnualCarbonEmissionsNaturalGasChart();

    return (
        <EndUseStackedBarChart
            data={data}
            loading={isLoading}
            fuelTypeKey="naturalGas"
            error={error?.toString()}
        />
    );
}

function AnnualCarbonEmissionsElectricityChart() {
    const { data, isLoading, error } =
        useAnnualCarbonEmissionsElectricityChart();

    return (
        <EndUseStackedBarChart
            data={data}
            loading={isLoading}
            fuelTypeKey="electricity"
            error={error?.toString()}
        />
    );
}

export default BaselineReport;
