import { atom, useRecoilState } from "recoil";

interface BuildingsPageState {
    buildingsView: PortfolioPageView;
    propertiesView: PortfolioPageView;
}

const buildingsPageStateAtom = atom<BuildingsPageState>({
    key: "portfolioPageState",
    default: {
        buildingsView: "table",
        propertiesView: "table",
    },
});

const useBuildingsPageState = () => {
    const [pageState, setPageState] = useRecoilState(buildingsPageStateAtom);

    return {
        pageState,
        setPageState,
    };
};

export default useBuildingsPageState;
