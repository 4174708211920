import { useMemo, useState } from "react";
import { useTooltip } from "@nivo/tooltip";
import { area, curveMonotoneX } from "d3-shape";
import { ResponsiveLine } from "@nivo/line";
import { getDisplayInfo } from "utils/formatting";
import ChartTooltip from "./ChartTooltip";
import { theme } from "./ChartTheme";
import ChartLegend from "./ChartLegend";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface yAxisInfo {
    unit: string;
    formatFunction?: (value: number | string) => number | string;
}

export interface AreaChartDatum {
    id: string;
    type: "line" | "area";
    color: string;
    formatKey: string;
    data: {
        x: number | string;
        y: number;
    }[];
}

interface AreaChartProps {
    data: AreaChartDatum[];
    yAxisInfo: yAxisInfo;
    margin?: any;
}

function AreaChart({
    data,
    yAxisInfo,
    margin = { top: 10, right: 20, bottom: 50, left: 60 },
}: AreaChartProps) {
    const xLabelsToShow = useXLabelsToShow(data);

    const dataWithUnit = useMemo(
        () => data.map((datum) => ({ ...datum, unit: yAxisInfo.unit })),
        [data]
    );

    return (
        <>
            <ChartLegend
                keys={data.map(({ id, color }) => ({ color, label: id }))}
            />
            <div className="area-chart" style={{ height: 460, width: "100%" }}>
                <ResponsiveLine
                    data={dataWithUnit}
                    enableGridX={false}
                    theme={theme}
                    margin={margin}
                    layers={[
                        "grid",
                        "axes",
                        "legends",
                        AreaLayer,
                        DashedLine,
                        VerticalLineLayer,
                    ]}
                    axisBottom={{
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: 50,
                        format: (v) => (xLabelsToShow.includes(v) ? v : ""),
                    }}
                    axisLeft={{
                        tickSize: 3,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legend: yAxisInfo.unit,
                        format: yAxisInfo.formatFunction,
                        legendOffset: margin.left * -1 + 5,
                    }}
                />
            </div>
        </>
    );
}

const useXLabelsToShow = (data: AreaChartDatum[]) =>
    useMemo(() => {
        if (data.length === 0 || data[0].data.length === 0) return [];
        const chartDatum = data[0]?.data;
        const { length } = chartDatum;
        return [chartDatum[0].x, chartDatum[length - 1].x];
    }, [data]);

const DashedLine = ({ lineGenerator, xScale, yScale, data }: any) => {
    const linesData = data.filter((d: any) => d.type === "line");

    return linesData.map(({ id, data: lineData, color }: any) => (
        <path
            key={id}
            d={lineGenerator(
                lineData.map((d: any) => ({
                    x: xScale(d.x),
                    y: yScale(d.y),
                }))
            )}
            fill="none"
            stroke={color}
            style={{
                strokeDasharray: "10, 10",
                strokeWidth: 2,
            }}
        />
    ));
};

const VerticalLineLayer = ({ data, yScale, xScale }: any) => {
    const { showTooltipFromEvent, hideTooltip } = useTooltip();
    const [currentTooltipIndex, setCurrentTooltipIndex] = useState<number>();

    const showChartTooltip = (event: React.MouseEvent<Element, MouseEvent>) => {
        if (currentTooltipIndex === undefined) return;
        const title = data[0]?.data[currentTooltipIndex].x;

        const rows = data.map(
            ({ id, color, data: selectedData, formatKey, unit }: any) => {
                const { formatFunction, formatFromUnit } =
                    getDisplayInfo(formatKey);
                const datum = selectedData[currentTooltipIndex];
                return {
                    color,
                    label: id,
                    unit,
                    value: formatFromUnit
                        ? formatFromUnit(unit)(datum.y, false)
                        : formatFunction(datum.y),
                };
            }
        );

        showTooltipFromEvent(
            <ChartTooltip title={title} rows={rows} />,
            event,
            "right"
        );
    };

    const handleMouseEnter =
        (index: number) => (event: React.MouseEvent<Element, MouseEvent>) => {
            setCurrentTooltipIndex(index);
            showChartTooltip(event);
        };

    const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>) => {
        showChartTooltip(event);
    };

    const handleMouseLeave = () => {
        setCurrentTooltipIndex(undefined);
        hideTooltip();
    };

    const xValues = data[0].data.map(({ x }: any, index: number) => ({
        x,
        index,
    }));

    return xValues.map(({ x, index }: any) => {
        const y = Math.max(...data.map(({ data }: any) => data[index].y));

        const scaledY = yScale(y);
        const scaledX = xScale(x);
        const height = yScale(0) - scaledY;

        return (
            <>
                <g key={y} transform={`translate(${scaledX}, ${scaledY})`}>
                    <rect
                        rx={0}
                        ry={0}
                        width={1}
                        height={height}
                        stroke="transparent"
                        fill={
                            index === currentTooltipIndex
                                ? "black"
                                : "transparent"
                        }
                    />
                    <rect
                        width={10}
                        height={height}
                        rx={0}
                        ry={0}
                        fill="transparent"
                        strokeWidth={1}
                        stroke="transparent"
                        onMouseEnter={handleMouseEnter(index)}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    />
                </g>
                <Circles
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    currentTooltipIndex={currentTooltipIndex}
                />
            </>
        );
    });
};

interface CirclesProps {
    data: any;
    xScale: any;
    yScale: any;
    currentTooltipIndex: number | undefined;
}

const Circles = ({ data, xScale, yScale, currentTooltipIndex }: CirclesProps) =>
    data.map(({ data: circleData, color, id }: any) =>
        circleData.map(({ x, y }: any, index: number) => (
            <circle
                key={`${id}${x}`}
                cx={xScale(x)}
                cy={yScale(y)}
                r={4}
                fill={
                    index === currentTooltipIndex ? `${color}` : "transparent"
                }
                stroke={index === currentTooltipIndex ? "white" : "transparent"}
                style={{ pointerEvents: "none" }}
            />
        ))
    );

function AreaLayer({ data, xScale, yScale, height }: any) {
    const areaData = data.find(({ type }: AreaChartDatum) => type === "area");

    const areaGenerator = area()
        .x((datum: any) => xScale(datum.x))
        .y0(() => yScale(0))
        .y1((datum: any) => yScale(datum.y))
        .curve(curveMonotoneX);

    const { color } = areaData;

    return (
        <svg>
            <defs>
                <linearGradient id="gradient" x2="0%" y2="185%">
                    <stop offset="0%" stopColor={color} />
                    <stop offset="100%" stopColor="#FFF" />
                </linearGradient>
            </defs>
            <path
                fill="url(#gradient)"
                d={areaGenerator(areaData.data) || undefined}
            />
        </svg>
    );
}
export default AreaChart;
