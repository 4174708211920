import { atomFamily, useRecoilState } from "recoil";

interface BuildingLoadingState {
    energySidebarLoading: boolean;
    equipmentSidebarLoading: boolean;
    constructionSidebarLoading: boolean;
    buildingReportsLoading: boolean;
}

const buildingLoadingStateAtom = atomFamily<BuildingLoadingState, string>({
    key: "buildingSidebarStateAtom",
    default: {
        energySidebarLoading: false,
        equipmentSidebarLoading: false,
        constructionSidebarLoading: false,
        buildingReportsLoading: false,
    },
});

const useBuildingLoadingState = (buildingModelUid: string) => {
    const [loadingState, setLoadingState] = useRecoilState(
        buildingLoadingStateAtom(buildingModelUid)
    );

    return {
        loadingState,
        setLoadingState,
    };
};

export default useBuildingLoadingState;
