import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// hooks
import useBuildingsPageState from "recoilStore/useBuildingsPageState";
import { useProperties } from "queries/useProperties";
import { useFunds } from "queries/Funds/useFunds";

// components
import {
    AddIcon,
} from "components/atoms/Icon";
import { Header } from "components/atoms/Typography";
import { Button } from "components/atoms/Button";
import TableLoading from "components/molecules/TableLoading";
import { ErrorPage } from "pages/ErrorState";
import { useBuildingsData } from "./hooks";
import Buildings from "./Buildings";
import DataCoverageHeader from "./DataCoverageHeader";
import BuildingsCsvUploader from "./BuildingsCsvUploader";
import AddBuildingSidebar from "./AddBuildingSidebar";
import Properties from "./Properties";
import Funds from "./Funds";

import "./PortfolioPage.scss";
import ViewHeader from "./ViewHeader";
import TabType from "./PortfolioPage.types";

function PortfolioPage() {
    const { pageState, setPageState } = useBuildingsPageState();

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTabFromUrl = (searchParams.get("tab") as TabType) || "buildings";

    const isValidTabType = (tab: string): tab is TabType => ["buildings", "properties", "funds"].includes(tab);

    const defaultTab: TabType = isValidTabType(selectedTabFromUrl) ? selectedTabFromUrl : "buildings";
    const [selectedTab, setSelectedTab] = useState<TabType>(defaultTab);


    useEffect( () => {
        setSearchParams({ tab: selectedTab });
    }, [selectedTab, selectedTabFromUrl])  

    const tabClickHandler = (tabName: TabType) => {
        setSearchParams({ tab: tabName });
        setSelectedTab(tabName)
    }
    const { isLoading, numberOfBuildings, error, ...rest } = useBuildingsData();

    const [selectedProperties, setSelectedProperties] = useState<Property[]>(
        []
    );

    const {
        isLoading: propertiesLoading,
        data: propertiesData,
        error: propertiesError,
    } = useProperties();

    const {
        isLoading: fundsLoading,
        data: fundsData,
        error: fundsError,
    } = useFunds();

    if (isLoading || propertiesLoading || fundsLoading) return <TableLoading />;
    if (error || propertiesError || fundsError) return <ErrorPage />;

    const handleViewChange = (view: PortfolioPageView) => {
        setPageState((prev) => {
            if (selectedTab === "buildings")
                return { ...prev, buildingsView: view };
            return { ...prev, propertiesView: view };
        });
    };

    return (
        <div className="portfolio-page">
            <PageHeader />
            {selectedTab === "buildings" && (
                <DataCoverageHeader dataCoverages={rest.dataCoverages} />
            )}
            <ViewHeader
                numberOfBuildings={numberOfBuildings}
                numberOfProperties={propertiesData?.length}
                numberOfFunds={fundsData?.length}
                handleViewChange={handleViewChange}
                currentView={
                    selectedTab === "buildings"
                        ? pageState.buildingsView
                        : pageState.propertiesView
                }
                selectedTab={selectedTab}
                tabClickHandler={tabClickHandler}
                buildingName={rest.buildingName || undefined}
                numberOfSelectedBuildings={rest.selectedBuildingRows.length}
                selectedBuildingRows={rest.selectedBuildingRows}
                setSelectedBuildingRows={rest.setSelectedBuildingRows}
                selectedProperties={selectedProperties}
                setSelectedProperties={setSelectedProperties}
                selectedTags={rest.selectedTags}
                selectedTagsAreMixed={rest.tagsAreMixed}
            />
            {selectedTab === "buildings" && (
                <Buildings
                    buildingsData={{
                        isLoading,
                        numberOfBuildings,
                        error,
                        ...rest,
                    }}
                    view={pageState.buildingsView}
                />
            )}
            {selectedTab === "properties" && propertiesData && (
                <Properties
                    properties={propertiesData}
                    pageState={pageState.propertiesView}
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                />
            )}
            {selectedTab === "funds" && fundsData && (
                <Funds funds={fundsData} />
            )}
        </div>
    );
}

function PageHeader() {
    const [buildingSidebarIsOpen, setBuildingSidebarIsOpen] = useState(false);

    return (
        <>
            <div className="page-header">
                <div>
                    <Header size="large">Portfolio</Header>
                </div>
                <BuildingsCsvUploader />
                <Button
                    type="primary"
                    id="add-building-button"
                    onClick={() => setBuildingSidebarIsOpen(true)}
                    icon={<AddIcon color="var(--audette-white)" />}
                >
                    Add building
                </Button>
            </div>
            <AddBuildingSidebar
                onCancel={() => setBuildingSidebarIsOpen(false)}
                sidebarOpen={buildingSidebarIsOpen}
                closeSidebar={() => setBuildingSidebarIsOpen(false)}
            />
        </>
    );
}

export default PortfolioPage;
