import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import SingleSelectDropdown from "components/atoms/Dropdown";
import MultiselectDropdown from "components/molecules/MultiselectDropdown";
import { NumericStringMultiFilterProps } from "../FilterSidebar.interface";
import { filterConditionToDisplayValueMap, MultiSelectConditions } from "../FilterSidebar.constants";
import { buildMultiSelectDropdownItems, cloneFilters } from "../FilterSidebar.helpers";

function GenericMultiSelectFilter({
    filters,
    setFilters,
    filterField,
    filterKey,
}: NumericStringMultiFilterProps<StringFilterField>) {
    const { pathname } = useLocation();
    const currentFilter = useMemo(
        () => filters.stringFilters.find(({ field }) => field === filterField),
        [filters]
    );

    const [dropdownInput, setDropdownInput] = useState(
        currentFilter?.condition
    );

    const [selectedItemsMultiSelect, setSelectedItemsMultiSelect] = useState(
        currentFilter?.value
    );

    const dropdownItems = MultiSelectConditions.map((id) => ({
        id,
        displayValue: filterConditionToDisplayValueMap[id],
    }));

    const multiSelectDropdownItems = useMemo(() => {
        const page = pathname.includes("reports") ? "reports" : "buildings";
        return buildMultiSelectDropdownItems(filterKey, page);
    }, [filterKey]);

    useEffect(() => {
        if (!selectedItemsMultiSelect || !dropdownInput) return;

        setFilters((prev) => {
            const copy = cloneFilters(prev);

            const newFilter: StringFilter = {
                value: selectedItemsMultiSelect,
                condition: dropdownInput,
                field: filterField,
                key: filterKey,
            };

            const index = copy.stringFilters.findIndex(
                ({ field }) => field === filterField
            );
            if (index === -1) copy.stringFilters.push(newFilter);
            else copy.stringFilters[index] = newFilter;
            return copy;
        });
    }, [selectedItemsMultiSelect, dropdownInput]);

    useEffect(() => {
        setDropdownInput(currentFilter?.condition);
        setSelectedItemsMultiSelect(currentFilter?.value);
    }, [currentFilter]);

    return (
        <div className="filter">
            <SingleSelectDropdown
                items={dropdownItems}
                onSelectItem={(item) => setDropdownInput(item.id)}
                defaultItem={dropdownItems.find(
                    ({ id }) => id === dropdownInput
                )}
                classname="filter__dropdown"
            />
            <MultiselectDropdown
                items={multiSelectDropdownItems}
                selectedKeys={selectedItemsMultiSelect || []}
                handleChange={(keys) => setSelectedItemsMultiSelect(keys)}
            />
        </div>
    );
}

export default GenericMultiSelectFilter;