import mutationRemoveUsersFn from "ApiLayer/User/queryRemoveUsers";
import { useAccessToken } from "hooks/useAccessToken";
import useAlert from "hooks/useAlert";
import { userQueryKey } from "hooks/useUser";
import { useMutation, useQueryClient } from "react-query";
import { showMutationAlert } from "utils/helpers";

export const useRemoveUsers = () => {
    const accessToken = useAccessToken();
    const alert = useAlert();
    const queryClient = useQueryClient();

    return useMutation({
        onError: (error) => {
            showMutationAlert(error, alert);
        },
        onSuccess: (data, { doNotInvalidate }) => {
            if (doNotInvalidate) return;
            alert.success("User(s) removed.");
            queryClient.invalidateQueries({ queryKey: userQueryKey });
        },
        mutationFn: async (data: { userIds: string[], doNotInvalidate?:boolean }) => (
            mutationRemoveUsersFn(accessToken, data)
        )
    })
}